const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={10}
    fill="none"
    viewBox="0 0 10 13"
    className="pt-[1px]"
  >
    <path
      fill="#000"
      d="M.5 1.1v10.8a.6.6 0 0 0 .943.495l7.814-5.4c.324-.224.324-.763 0-.988L1.443.607A.601.601 0 0 0 .5 1.1Z"
    />
  </svg>
)
export default RightArrow

