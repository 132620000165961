import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../components/firebase';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {    
    if (!auth.currentUser) {
        return <Navigate to="/" />; // Redirect to login if not authenticated
    }

    return <>{children}</>; // Render children if authenticated
};

export default ProtectedRoute;