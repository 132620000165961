// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDrub0e5dJ7WyfdfIQ8UVYlfclF-2OclPA",
  authDomain: "kaktu-map-tool.firebaseapp.com",
  projectId: "kaktu-map-tool",
  storageBucket: "kaktu-map-tool.firebasestorage.app",
  messagingSenderId: "674335516204",
  appId: "1:674335516204:web:4876a4c463539b36e1d389"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth(app);
export const provider = new GoogleAuthProvider();
export default app;