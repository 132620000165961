// SignIn.js
import React from "react";
// import { auth, provider, signInWithPopup } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "./components/firebase";
import { signInWithPopup } from "firebase/auth";
import { allowedUsers } from "./utils/allowedUsers";

const SignIn = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Sign-in successful:", result);
      if(result.user.email && allowedUsers.includes(result.user.email) ) {
        navigate("/home")
      } else {
        window.alert("You aren't allowed to access this app!");
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 max-w-md w-full bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Ka'Ktu Map Tools</h2>
        <button
          onClick={handleGoogleSignIn}
          className="flex items-center justify-center w-full p-3 mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg shadow-md transition duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            className="w-5 h-5 mr-2"
          >
            <path
              fill="#4285F4"
              d="M23.5 12.2c1.9 0 3.6.6 5.1 1.8l3.8-3.8C29.7 8.2 26.8 7 23.5 7 16.6 7 11.1 11.7 9.4 17.8l4.6 3.5c1.1-3.8 4.6-7.1 9.5-7.1z"
            />
            <path
              fill="#34A853"
              d="M12.2 24.3c-.2-.6-.2-1.3-.2-2s.1-1.3.2-2l-4.6-3.5c-.8 1.7-1.3 3.7-1.3 5.5s.5 3.8 1.3 5.5l4.6-3.5z"
            />
            <path
              fill="#FBBC05"
              d="M23.5 38c3.3 0 6.2-1.1 8.5-2.9l-4.1-3.5c-1.3.9-3 1.4-4.4 1.4-4.8 0-8.4-3.4-9.5-7.1l-4.6 3.5C11.1 36.3 16.6 41 23.5 41z"
            />
            <path
              fill="#EA4335"
              d="M42 24.3c0-1.1-.1-2.2-.3-3.3H23.5v7.2h10.4c-.5 2.5-1.8 4.4-3.6 5.8l4.1 3.5c2.5-2.3 4-5.7 4-9.7z"
            />
          </svg>
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default SignIn;