const BottomArrow = () => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={8}
    viewBox="0 0 13 10"
    fill="none"
  >
    <path
      fill="#000"
      d="M11.575.238H.775a.6.6 0 0 0-.494.943l5.4 7.814c.224.324.763.324.987 0l5.4-7.814a.601.601 0 0 0-.493-.943Z"
    />
  </svg>
)
export default BottomArrow