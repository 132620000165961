import {
  ApolloLink,
  createHttpLink,
  from,
  RequestHandler,
  split,
  WatchQueryFetchPolicy,
} from '@apollo/client/core';
// import {
//   ApolloClient,
//   InMemoryCache,
// } from '@apollo/experimental-nextjs-app-support';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { createRestartableClient } from './ws';
import { ApolloClient, InMemoryCache } from '@apollo/client';

const isBrowser = typeof window !== 'undefined';

export type KaktuApolloClientOptions = {
  graphqlUrl?: string;
  headers?: any;
  fetchPolicy?: WatchQueryFetchPolicy;
  connectToDevTools?: boolean;
  cache?: InMemoryCache;
  /**
   * @deprecated Please use `generateLinks` instead.
   */
  onError?: RequestHandler;
  /**
   * @deprecated Please use `generateLinks` instead.
   */
  link?: ApolloLink;
  generateLinks?: (
    links: (ApolloLink | RequestHandler)[],
  ) => (ApolloLink | RequestHandler)[];
};

export const createApolloClient = ({
  graphqlUrl,
  headers = {},
  fetchPolicy,
  cache = new InMemoryCache(),
  connectToDevTools = isBrowser && process.env.NODE_ENV === 'development',
  link: customLink,
  onError,
  generateLinks,
}: KaktuApolloClientOptions) => {
  const backendUrl = graphqlUrl;

  if (!backendUrl) {
    throw Error(
      "Can't initialize the Apollo Client: no backend Url has been provided",
    );
  }

  const uri = backendUrl;

  const getAuthHeaders = async () => {
    // add headers
    const resHeaders = {
      ...headers,
      'x-hasura-admin-secret': 'bettersecret',
      'Sec-WebSocket-Protocol': 'graphql-ws',
    };

    return resHeaders;
  };

  const wsClient = isBrowser
    ? createRestartableClient({
      url: uri.startsWith('https')
        ? uri.replace(/^https/, 'wss')
        : uri.replace(/^http/, 'ws'),
      shouldRetry: () => true,
      retryAttempts: 100,
      retryWait: async (retries: any) => {
        // start with 1 second delay
        const baseDelay = 1000;

        // max 3 seconds of jitter
        const maxJitter = 3000;

        // exponential backoff with jitter
        return new Promise((resolve) => {
          setTimeout(
            resolve,
            baseDelay * 2 ** retries + Math.floor(Math.random() * maxJitter),
          );
        });
      },
      connectionParams: async () => ({
        headers: {
          ...headers,
          ...(await getAuthHeaders()),
        },
      }),
    })
    : null;

  const wsLink = wsClient ? new GraphQLWsLink(wsClient) : null;

  const httpLink = setContext(async (_, ctx) => ({
    headers: {
      ...ctx?.headers,
      ...(await getAuthHeaders()),
    },
  })).concat(createHttpLink({ uri }));

  const splitLink = wsLink
    ? split(
      ({ query }) => {
        const mainDefinition = getMainDefinition(query);

        const { kind } = mainDefinition;
        let operation;
        if ('operation' in mainDefinition) {
          operation = mainDefinition.operation;
        }

        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    )
    : httpLink;

  const links = [];

  if (onError) {
    links.push(onError);
  }

  if (customLink) {
    links.push(customLink);
  }

  links.push(splitLink);

  const link = from(generateLinks ? generateLinks(links) : links);

  const client = new ApolloClient({
    cache: cache || new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy,
      },
    },
    connectToDevTools,
    link,
  });

  return client;
};
