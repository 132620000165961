import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import ProtectedRoute from './context/protectedRoute';
import Login from './login';
import Home from './Home'; // Assuming you have a Home component

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                <Route path="/" element={<Login />} />
                    <Route 
                        path="/home" 
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        } 
                    />
                </Routes>
             
            </Router>
        </AuthProvider>
    );
}

export default App;