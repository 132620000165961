// import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support';
import { ApolloProvider as Apollo } from '@apollo/client';

import { KaktuApolloClientOptions, createApolloClient } from '../../api/apollo/index';

export function ApolloProvider({
  children,
  ...options
}: React.PropsWithChildren<KaktuApolloClientOptions>) {
  return (
    <Apollo client={createApolloClient(options)}>
      {children}
    </Apollo>
  );
}
