function EditIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#1C274C"
      fillRule="evenodd"
      d="M14.757 2.621a4.682 4.682 0 1 1 6.621 6.622l-9.485 9.486c-.542.542-.86.86-1.216 1.137q-.628.492-1.35.835c-.406.193-.834.336-1.56.578l-3.332 1.11-.802.268a1.81 1.81 0 0 1-2.29-2.29l1.378-4.133c.242-.727.385-1.155.578-1.562q.344-.72.834-1.35c.277-.354.596-.673 1.138-1.215zM4.4 20.821l2.842-.948c.791-.264 1.127-.377 1.44-.526q.572-.274 1.073-.663c.273-.214.525-.463 1.115-1.053l7.57-7.57a7.36 7.36 0 0 1-2.757-1.744A7.36 7.36 0 0 1 13.94 5.56l-7.57 7.57c-.59.589-.84.84-1.053 1.114q-.39.5-.663 1.073c-.149.313-.262.649-.526 1.44L3.18 19.6zM15.155 4.343c.035.175.092.413.189.69a5.86 5.86 0 0 0 1.4 2.222 5.86 5.86 0 0 0 2.221 1.4c.278.097.516.154.69.189l.663-.662a3.182 3.182 0 0 0-4.5-4.5z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default EditIcon;
