import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import MyMap from "./components/map";
import "./index.css";
import {
  City_Zone_Origin_Enum,
  useGetAddressTypesQuery,
  useGetCitiesLazyQuery,
  useGetCityZoneLazyQuery,
  Zone_Data_Method_Enum,
} from "./api/generated/graphql";
import Details from "./components/details";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";

function Home() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const formRef = useRef<HTMLFormElement>(null);
  const [polygons, setPolygons] = useState<any[]>([]);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [pointers, setPointers] = useState<any>(null);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState<any>(null);
  const [filterSameBounds, setFilterSameBounds] = useState(false);
  const [isProblematic, setIsProblematic] = useState(false);

  const [selectedTypes, setSelectedTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [typesOptions, setTypesOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [citiesOptions, setCitiesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [selectedDataMethod, setSelectedDataMethod] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedOrigin, setSelectedOrigin] = useState<
    { label: string; value: string }[]
  >([]);
  const zone_data_methods = [
    {
      label: Zone_Data_Method_Enum.Custom,
      value: Zone_Data_Method_Enum.Custom,
    },
    {
      label: Zone_Data_Method_Enum.GoogleMapsGeocodingApi,
      value: Zone_Data_Method_Enum.GoogleMapsGeocodingApi,
    },
    {
      label: Zone_Data_Method_Enum.GoogleMapsPlacesApi,
      value: Zone_Data_Method_Enum.GoogleMapsPlacesApi,
    },
  ];

  const zone_origin = [
    {
      label: City_Zone_Origin_Enum.Pipeline,
      value: City_Zone_Origin_Enum.Pipeline,
    },
    {
      label: City_Zone_Origin_Enum.Seeded,
      value: City_Zone_Origin_Enum.Seeded,
    },
    {
      label: City_Zone_Origin_Enum.User,
      value: City_Zone_Origin_Enum.User,
    },
  ];

  const zone_surface = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: '<= 80000',
      value: '<= 80000',
    },
    {
      label: '> 80000',
      value: '> 80000',
    },
  ];

  const [updateFromDate, setUpdatedFromDate] = useState(null);
  const [updateToDate, setUpdatedToDate] = useState(null);

  const [getCityZone] = useGetCityZoneLazyQuery();
  const { data: typesData } = useGetAddressTypesQuery();
  const [getCities, { data: citiesData }] = useGetCitiesLazyQuery();

  const handleFormSubmit = (data: any) => {
    const selectedCity = data.city ? data.city.value : null;
    const selectedSufaceType = data.surface ? data.surface.value : null;
    setFilteredResults([]);
    setSearchResult([]);
    setPolygons([]);
    setPointers([]);
    let whereObject = {};
    if (selectedCity) {
      whereObject = {
        ...whereObject,
        city: { city_name: { _eq: selectedCity } },
      };
    }
    if (data.zone_name) {
      whereObject = { ...whereObject, zone_name: { _eq: data.zone_name } };
    }
    if (data.viewport) {
      whereObject = { ...whereObject, viewport: { _eq: data.viewport } };
    }
    if (data.place_id) {
      whereObject = { ...whereObject, place_id: { _eq: data.place_id } };
    }
    if (data.bounds) {
      whereObject = { ...whereObject, bounds: { _eq: data.bounds } };
    }
    if (data.location) {
      whereObject = { ...whereObject, location: { _eq: data.location } };
    }
    if (selectedTypes.length > 0) {
      const types = selectedTypes.map((item) => item.value);
      whereObject = { ...whereObject, types: { _has_keys_any: types } };
    }
    if (data.location_type) {
      whereObject = {
        ...whereObject,
        location_type: { _eq: data.location_type },
      };
    }
    if (data.formatted_address) {
      whereObject = {
        ...whereObject,
        formatted_address: { _eq: data.formatted_address },
      };
    }
    if (selectedSufaceType) {
      if (selectedSufaceType === '<= 80000') {
        whereObject = {
          ...whereObject,
          surface_area: { _lte: 80000 },
        };
      } else if (selectedSufaceType === '> 80000') {
        whereObject = {
          ...whereObject,
          surface_area: { _gt: 80000 },
        };
      }
    }
    if (isProblematic) {
      whereObject = { ...whereObject, is_problematic: { _eq: true } };
    }
    if (selectedDataMethod.length > 0) {
      const dataMethods = selectedDataMethod.map((item) => item.value);
      whereObject = { ...whereObject, data_method: { _in: dataMethods } };
    }
    if (selectedOrigin.length > 0) {
      const origins = selectedOrigin.map((item) => item.value);
      whereObject = { ...whereObject, origin: { _in: origins } };
    }
    if (updateFromDate || updateToDate) {
      whereObject = {
        ...whereObject,
        updated_at: {
          ...(updateFromDate ? { _gte: updateFromDate } : {}),
          ...(updateToDate ? { _lte: updateToDate } : {}),
        },
      };
    }
    handler(whereObject);
  };

  const calculateSquareCoords = (
    coord1: [number, number],
    coord2: [number, number]
  ) => {
    const [lat1, lng1] = coord1;
    const [lat2, lng2] = coord2;

    const squareCoords: [number, number][] = [
      [lat1, lng1],
      [lat1, lng2],
      [lat2, lng2],
      [lat2, lng1],
    ];

    return squareCoords;
  };

  const handleDiagonalCoords = (
    coord1: [number, number],
    coord2: [number, number]
  ) => {
    const squareCoords = calculateSquareCoords(coord1, coord2);
    setPolygons((prev) => [...prev, squareCoords]);
  };

  const populatePolygons = (zones: any) => {
    /* save center pointers for each zone */
    let tempPointers: any[] = [];
    zones.forEach((item: any) => {
      tempPointers.push({
        id: item.id,
        center: [item.location.lat, item.location.lng],
        name: item.zone_name,
      });
      // save bounds in format that leaflet can understand
      let temporaryBounds: any[] = [];
      temporaryBounds = item.bounds.map((bound: any) => [bound.lat, bound.lng]);
      if (temporaryBounds.length === 2) {
        handleDiagonalCoords(
          [temporaryBounds[0][0], temporaryBounds[0][1]],
          [temporaryBounds[1][0], temporaryBounds[1][1]]
        );
      } else {
        setPolygons((prev) => [...prev, temporaryBounds as any[]]);
      }
      setPointers(tempPointers as any);
    });
  };

  const handler = async (whereObject: any) => {
    setIsLoadingResults(true);
    try {
      const cityZones = await getCityZone({
        variables: {
          where: whereObject,
        },
        fetchPolicy: "no-cache",
      });
      if (cityZones?.data) {
        // if same bounds filter is not checked, show all results
        if (!filterSameBounds) {
          setSearchResult(cityZones?.data?.city_zone);
          populatePolygons(cityZones.data.city_zone);
        } else {
          // if same bounds filter is checked, show only duplicate zones
          const tempResult = cityZones.data.city_zone;
          const boundsCount = new Map();
          const duplicateZones: any[] = [];
          tempResult.forEach((zone) => {
            const boundsString = JSON.stringify(zone.bounds);
            if (boundsCount.has(boundsString)) {
              boundsCount.set(boundsString, boundsCount.get(boundsString) + 1);
            } else {
              boundsCount.set(boundsString, 1);
            }
          });

          tempResult.forEach((zone) => {
            const boundsString = JSON.stringify(zone.bounds);
            if (boundsCount.get(boundsString) > 1) {
              duplicateZones.push(zone)
            }
          });

          setSearchResult(duplicateZones);
          populatePolygons(duplicateZones);
        }
      } else {
        setPointers([]);
        setSearchResult([]);
        setPolygons([]);
      }
    } finally {
      setIsLoadingResults(false);
    }
  };

  useEffect(() => {
    if (typesData) {
      const tempOptions = typesData.address_types.map((item) => ({
        label: item.type,
        value: item.type,
      }));
      setTypesOptions(tempOptions);
    }
  }, [typesData]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (citiesData?.city) {
      const tempOptions = citiesData.city.map((city) => ({
        label: city.city_name,
        value: city.city_name,
      }));
      setCitiesOptions(tempOptions);
    }
  }, [citiesData]);

  const handlePolygonClick = (coordinates: any) => {
    const coordString = JSON.stringify(coordinates);
    const filtered = searchResult.filter((item) => {
      const itemBounds = item.bounds.map((bound: any) => [
        bound.lat,
        bound.lng,
      ]);

      let itemSquareCoords;
      if (itemBounds.length === 2) {
        itemSquareCoords = [
          [itemBounds[0][0], itemBounds[0][1]],
          [itemBounds[0][0], itemBounds[1][1]],
          [itemBounds[1][0], itemBounds[1][1]],
          [itemBounds[1][0], itemBounds[0][1]],
        ];
      } else {
        itemSquareCoords = itemBounds;
      }

      const itemBoundsString = JSON.stringify(itemSquareCoords);
      return itemBoundsString === coordString;
    });

    setFilteredResults(filtered);
    setSelectedPolygon(coordinates);
  };

  // Custom styles for react-select to ensure full width
  const customSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const handleSelectedZone = (item: any) => {
    const bounds = item.bounds.map((bound: any) => [bound.lat, bound.lng]);
    if (bounds.length === 2) {
      const squareCoords = calculateSquareCoords(bounds[0], bounds[1]);
      setSelectedPolygon(squareCoords);
    } else {
      setSelectedPolygon(bounds);
    }
  };

  return (
    <div className="flex flex-col w-full h-screen p-2">
      {/* Top Section: Form and Buttons */}
      <div className="flex flex-col lg:flex-row w-full space-y-2 lg:space-y-0 lg:space-x-2">
        <div className="flex flex-col w-full lg:w-3/4 overflow-x-auto">
          <form
            className="w-full pt-3"
            ref={formRef}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 pb-3">
              {/* City Select */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="city"
                  className="text-base font-medium truncate text-black mb-1"
                >
                  City
                </label>
                <div className="w-full">
                  <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        options={citiesOptions}
                        placeholder="Select City"
                        className="w-full"
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                        value={field.value}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={customSelectStyles}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Zone Name Input */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="zone_name"
                  className="text-base truncate font-medium text-black mb-1"
                >
                  Zone name
                </label>
                <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                  <input
                    {...register("zone_name")}
                    id="zone_name"
                    placeholder="Zone name"
                    className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                    type="text"
                  />
                </div>
              </div>

              {/* Types Select */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="types"
                  className="text-base truncate font-medium text-black mb-1"
                >
                  Types
                </label>
                <div className="w-full">
                  <Select
                    isMulti
                    name="types"
                    options={typesOptions}
                    value={selectedTypes}
                    onChange={(selectedOption) =>
                      setSelectedTypes(
                        selectedOption as { label: string; value: string }[]
                      )
                    }
                    className="w-full"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customSelectStyles}
                  />
                </div>
              </div>

              {/* Data Method Select */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="data_method"
                  className="text-base font-medium truncate text-black mb-1"
                >
                  Data Method
                </label>
                <div className="w-full">
                  <Select
                    isMulti
                    name="data_method"
                    options={zone_data_methods}
                    value={selectedDataMethod}
                    onChange={(selectedOption) =>
                      setSelectedDataMethod(
                        selectedOption as { label: string; value: string }[]
                      )
                    }
                    className="w-full"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customSelectStyles}
                  />
                </div>
              </div>

              {/* City zone Origin Select */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="origin"
                  className="text-base font-medium truncate text-black mb-1"
                >
                  Origin
                </label>
                <div className="w-full">
                  <Select
                    isMulti
                    name="origin"
                    options={zone_origin}
                    value={selectedOrigin}
                    onChange={(selectedOption) =>
                      setSelectedOrigin(
                        selectedOption as { label: string; value: string }[]
                      )
                    }
                    className="w-full"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={customSelectStyles}
                  />
                </div>
              </div>

              {/* Updated From Input */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="updated_from"
                  className="text-base truncate font-medium text-black mb-1"
                >
                  Updated From
                </label>
                <div className="w-full flex items-center px-2 h-[38px] border border-muted-3 rounded-lg">
                  <DatePicker
                    id="updated_from"
                    selected={updateFromDate}
                    onChange={(date) => {
                      setUpdatedFromDate(date as any);
                    }}
                    showTimeSelect
                    timeIntervals={1}
                    dateFormat="yyyy-MM-dd h:mm aa"
                    placeholderText="Select date (time optional)"
                    isClearable={false}
                  />
                </div>
              </div>

              {/* Updated To Input */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="updated_to"
                  className="text-base truncate font-medium text-black mb-1"
                >
                  Updated To
                </label>
                <div className="w-full flex items-center px-2 h-[42px] border border-muted-3 rounded-lg">
                  <DatePicker
                    id="updated_to"
                    selected={updateToDate}
                    onChange={(date) => {
                      setUpdatedToDate(date as any);
                    }}
                    showTimeSelect
                    timeIntervals={1}
                    dateFormat="yyyy-MM-dd h:mm aa"
                    placeholderText="Select date (time optional)"
                    isClearable={false}
                  />
                </div>
              </div>

              {/* Surface Area Input */}
               <div className="flex flex-col space-y-2">
                <label
                  htmlFor="surface"
                  className="text-base font-medium truncate text-black mb-1"
                >
                  Surface
                </label>
                <div className="w-full">
                  <Controller
                    control={control}
                    name="surface"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        options={zone_surface}
                        placeholder="Select City"
                        className="w-full"
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                        value={field.value}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={customSelectStyles}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Is problematic */}
              <div className="flex items-center justify-center">
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="is_problematic"
                    className="text-base truncate font-medium text-black mb-1"
                  >
                    Is problematic
                  </label>
                  <div className="w-full flex p-1 ">
                    <Switch
                      onChange={setIsProblematic}
                      checked={isProblematic}
                    />
                  </div>
                </div>
              </div>

              {/* Same bounds */}
              <div className="flex items-center justify-center">
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="same_bounds"
                    className="text-base truncate font-medium text-black mb-1"
                  >
                    Same bounds
                  </label>
                  <div className="w-full flex p-1 ">
                    <Switch
                      onChange={setFilterSameBounds}
                      checked={filterSameBounds}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="flex flex-col w-full h-full justify-center lg:w-1/4">
          {/* Buttons */}
          <div className="flex flex-col h-full pl-1">
            <div className="flex flex-col space-y-5 justify-center h-full py-3 pr-5 flex-none">
              <button
                type="submit"
                className={`rounded-lg w-full ${
                  !isValid && "opacity-80"
                } bg-slate-900 px-3 py-2 text-white hover:opacity-80 flex items-center justify-center min-h-10`}
                onClick={() =>
                  formRef.current && handleSubmit(handleFormSubmit)()
                }
                disabled={!isValid || isLoadingResults}
              >
                {isLoadingResults ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </>
                ) : (
                  "Apply Filters"
                )}
              </button>
              <button
                type="button"
                className={`rounded-lg w-full ${
                  !isValid && "opacity-80"
                } bg-slate-200 px-3 py-2 hover:opacity-80`}
                onClick={() => {
                  reset({
                    city: null,
                    zone_name: null,
                    surface: null,
                    location_type: null,
                    updated_from: null,
                    updated_to: null,
                    viewport: null,
                    place_id: null,
                    bounds: null,
                    location: null,
                    formatted_address: null,
                  });
                  setSelectedTypes([]);
                  setSelectedDataMethod([]);
                  setSelectedOrigin([]);
                  setPolygons([]);
                  setPointers([]);
                  setSearchResult([]);
                  setFilteredResults([]);
                  setSelectedPolygon(null);
                }}
                disabled={!isValid}
              >
                Reset
              </button>
              <div className="relative border py-2 rounded-md px-3 flex justify-center items-center">
                <b className="absolute left-3">Total Items:</b>
                <span>{searchResult.length}</span>
              </div>
            </div>
            <hr className="border" />
          </div>
        </div>
      </div>

      {/* Bottom Section: Map and Details */}
      <div className="flex flex-col lg:flex-row flex-1 w-full min-h-0 lg:space-x-2">
        {/* Map */}
        <div className="w-full lg:w-3/4 z-0 h-96 lg:h-full">
          <MyMap
            position={[41.332237, 19.836116]}
            polygons={polygons}
            pointers={pointers}
            zoom={13}
            handlePolygonClick={handlePolygonClick}
            selectedPolygon={selectedPolygon}
          />
        </div>

        {/* Details Section */}
        <Details
          searchResult={
            filteredResults.length > 0 ? filteredResults : searchResult
          }
          getSelectedZone={handleSelectedZone}
          getUpdatedZones={handleSubmit(handleFormSubmit)}
        />
      </div>
    </div>
  );
}

export default Home;
