import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  bpchar: { input: any; output: any; }
  bytea: { input: any; output: any; }
  citext: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  geography: { input: any; output: any; }
  geometry: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Input = {
  amenities_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  features_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  session_id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
};

export type InsertOrderOrderInsertInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  membership_package_id?: InputMaybe<Scalars['uuid']['input']>;
  order_response?: InputMaybe<Scalars['jsonb']['input']>;
  sdkOrderId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type InsertOrderOutput = {
  __typename?: 'InsertOrderOutput';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  membership_package_id?: Maybe<Scalars['uuid']['output']>;
  order_response?: Maybe<Scalars['jsonb']['output']>;
  sdkOrderId?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Output = {
  __typename?: 'Output';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  notification_id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['String']['output']>;
};

export type PaymentSuccessInput = {
  order_id?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentSuccessOutput = {
  __typename?: 'PaymentSuccessOutput';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PhoneInput = {
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneOutput = {
  __typename?: 'PhoneOutput';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type QuestionInput = {
  drawed_zone?: InputMaybe<Scalars['jsonb']['input']>;
  filters?: InputMaybe<SearchFilters_ActionType>;
  old_session_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  query_id?: InputMaybe<Scalars['String']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionOutput = {
  __typename?: 'QuestionOutput';
  filters?: Maybe<Scalars['jsonb']['output']>;
  map_center_location?: Maybe<Map_Location>;
  message?: Maybe<Scalars['String']['output']>;
  nlq_id?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Array<Maybe<Property_ActionType>>>;
  query_properties: Array<Queries_Property_Result>;
  query_properties_aggregate: Queries_Property_Result_Aggregate;
  response?: Maybe<Scalars['String']['output']>;
  response_type?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  total_found_properties?: Maybe<Scalars['Int']['output']>;
  whereObject?: Maybe<Scalars['String']['output']>;
};


export type QuestionOutputQuery_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


export type QuestionOutputQuery_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};

export type SampleInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  accessToken: Scalars['String']['output'];
};

export type SearchFilters_ActionType = {
  area_max?: InputMaybe<Scalars['Float']['input']>;
  area_min?: InputMaybe<Scalars['Float']['input']>;
  city_zone_id?: InputMaybe<Scalars['String']['input']>;
  price_max?: InputMaybe<Scalars['Float']['input']>;
  price_min?: InputMaybe<Scalars['Float']['input']>;
  property_cluster?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_Notification_Property_Typetype = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_Notificationstatus_Property = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_Notificationtype_Property_Origin = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_Search_Contextcluster = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_Search_Contexttype = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_User_Filtersproperty_Origin = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_User_Filtersstatus_Property = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp_Remote_Rel_User_Filterstype_Property = {
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type VerfiyCodeInput = {
  phone_number?: InputMaybe<Scalars['String']['input']>;
  verification_code?: InputMaybe<Scalars['String']['input']>;
};

export type VerfiyCodeOutput = {
  __typename?: 'VerfiyCodeOutput';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** columns and relationships of "address_types" */
export type Address_Types = {
  __typename?: 'address_types';
  type: Scalars['String']['output'];
};

/** aggregated selection of "address_types" */
export type Address_Types_Aggregate = {
  __typename?: 'address_types_aggregate';
  aggregate?: Maybe<Address_Types_Aggregate_Fields>;
  nodes: Array<Address_Types>;
};

/** aggregate fields of "address_types" */
export type Address_Types_Aggregate_Fields = {
  __typename?: 'address_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Address_Types_Max_Fields>;
  min?: Maybe<Address_Types_Min_Fields>;
};


/** aggregate fields of "address_types" */
export type Address_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "address_types". All fields are combined with a logical 'AND'. */
export type Address_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Types_Bool_Exp>>;
  _not?: InputMaybe<Address_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address_types" */
export enum Address_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  AddressTypesPkey = 'address_types_pkey'
}

/** input type for inserting data into table "address_types" */
export type Address_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Address_Types_Max_Fields = {
  __typename?: 'address_types_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Address_Types_Min_Fields = {
  __typename?: 'address_types_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "address_types" */
export type Address_Types_Mutation_Response = {
  __typename?: 'address_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Address_Types>;
};

/** on_conflict condition type for table "address_types" */
export type Address_Types_On_Conflict = {
  constraint: Address_Types_Constraint;
  update_columns?: Array<Address_Types_Update_Column>;
  where?: InputMaybe<Address_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "address_types". */
export type Address_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address_types */
export type Address_Types_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "address_types" */
export enum Address_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "address_types" */
export type Address_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "address_types" */
export type Address_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Types_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "address_types" */
export enum Address_Types_Update_Column {
  /** column name */
  Type = 'type'
}

export type Address_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Types_Bool_Exp;
};

/** columns and relationships of "agency" */
export type Agency = {
  __typename?: 'agency';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  property_sources: Array<Property_Source>;
  /** An aggregate relationship */
  property_sources_aggregate: Property_Source_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "agency" */
export type AgencyProperty_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Property_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Source_Order_By>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};


/** columns and relationships of "agency" */
export type AgencyProperty_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Source_Order_By>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};

/** aggregated selection of "agency" */
export type Agency_Aggregate = {
  __typename?: 'agency_aggregate';
  aggregate?: Maybe<Agency_Aggregate_Fields>;
  nodes: Array<Agency>;
};

/** aggregate fields of "agency" */
export type Agency_Aggregate_Fields = {
  __typename?: 'agency_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Agency_Max_Fields>;
  min?: Maybe<Agency_Min_Fields>;
};


/** aggregate fields of "agency" */
export type Agency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "agency". All fields are combined with a logical 'AND'. */
export type Agency_Bool_Exp = {
  _and?: InputMaybe<Array<Agency_Bool_Exp>>;
  _not?: InputMaybe<Agency_Bool_Exp>;
  _or?: InputMaybe<Array<Agency_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  property_sources?: InputMaybe<Property_Source_Bool_Exp>;
  property_sources_aggregate?: InputMaybe<Property_Source_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "agency" */
export enum Agency_Constraint {
  /** unique or primary key constraint on columns "name" */
  AgencyNameKey = 'agency_name_key',
  /** unique or primary key constraint on columns "id" */
  AgencyPkey = 'agency_pkey'
}

/** input type for inserting data into table "agency" */
export type Agency_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  property_sources?: InputMaybe<Property_Source_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Agency_Max_Fields = {
  __typename?: 'agency_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Agency_Min_Fields = {
  __typename?: 'agency_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "agency" */
export type Agency_Mutation_Response = {
  __typename?: 'agency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Agency>;
};

/** input type for inserting object relation for remote table "agency" */
export type Agency_Obj_Rel_Insert_Input = {
  data: Agency_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Agency_On_Conflict>;
};

/** on_conflict condition type for table "agency" */
export type Agency_On_Conflict = {
  constraint: Agency_Constraint;
  update_columns?: Array<Agency_Update_Column>;
  where?: InputMaybe<Agency_Bool_Exp>;
};

/** Ordering options when selecting data from "agency". */
export type Agency_Order_By = {
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  property_sources_aggregate?: InputMaybe<Property_Source_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agency */
export type Agency_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "agency" */
export enum Agency_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "agency" */
export type Agency_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "agency" */
export type Agency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Agency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agency_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "agency" */
export enum Agency_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Agency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Agency_Bool_Exp;
};

/** columns and relationships of "amenities" */
export type Amenities = {
  __typename?: 'amenities';
  amenity_name_id?: Maybe<Scalars['uuid']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  text_content?: Maybe<Text_Content>;
};

/** aggregated selection of "amenities" */
export type Amenities_Aggregate = {
  __typename?: 'amenities_aggregate';
  aggregate?: Maybe<Amenities_Aggregate_Fields>;
  nodes: Array<Amenities>;
};

/** aggregate fields of "amenities" */
export type Amenities_Aggregate_Fields = {
  __typename?: 'amenities_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Amenities_Max_Fields>;
  min?: Maybe<Amenities_Min_Fields>;
};


/** aggregate fields of "amenities" */
export type Amenities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Amenities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "amenities". All fields are combined with a logical 'AND'. */
export type Amenities_Bool_Exp = {
  _and?: InputMaybe<Array<Amenities_Bool_Exp>>;
  _not?: InputMaybe<Amenities_Bool_Exp>;
  _or?: InputMaybe<Array<Amenities_Bool_Exp>>;
  amenity_name_id?: InputMaybe<Uuid_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  text_content?: InputMaybe<Text_Content_Bool_Exp>;
};

/** unique or primary key constraints on table "amenities" */
export enum Amenities_Constraint {
  /** unique or primary key constraint on columns "id" */
  AmenitiesPkey = 'amenities_pkey'
}

/** input type for inserting data into table "amenities" */
export type Amenities_Insert_Input = {
  amenity_name_id?: InputMaybe<Scalars['uuid']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  text_content?: InputMaybe<Text_Content_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Amenities_Max_Fields = {
  __typename?: 'amenities_max_fields';
  amenity_name_id?: Maybe<Scalars['uuid']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Amenities_Min_Fields = {
  __typename?: 'amenities_min_fields';
  amenity_name_id?: Maybe<Scalars['uuid']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "amenities" */
export type Amenities_Mutation_Response = {
  __typename?: 'amenities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Amenities>;
};

/** input type for inserting object relation for remote table "amenities" */
export type Amenities_Obj_Rel_Insert_Input = {
  data: Amenities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Amenities_On_Conflict>;
};

/** on_conflict condition type for table "amenities" */
export type Amenities_On_Conflict = {
  constraint: Amenities_Constraint;
  update_columns?: Array<Amenities_Update_Column>;
  where?: InputMaybe<Amenities_Bool_Exp>;
};

/** Ordering options when selecting data from "amenities". */
export type Amenities_Order_By = {
  amenity_name_id?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  text_content?: InputMaybe<Text_Content_Order_By>;
};

/** primary key columns input for table: amenities */
export type Amenities_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "amenities" */
export enum Amenities_Select_Column {
  /** column name */
  AmenityNameId = 'amenity_name_id',
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "amenities" */
export type Amenities_Set_Input = {
  amenity_name_id?: InputMaybe<Scalars['uuid']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "amenities" */
export type Amenities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Amenities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Amenities_Stream_Cursor_Value_Input = {
  amenity_name_id?: InputMaybe<Scalars['uuid']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "amenities" */
export enum Amenities_Update_Column {
  /** column name */
  AmenityNameId = 'amenity_name_id',
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id'
}

export type Amenities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Amenities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Amenities_Bool_Exp;
};

/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequests = {
  __typename?: 'authProviderRequests';
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
};


/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequestsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate = {
  __typename?: 'authProviderRequests_aggregate';
  aggregate?: Maybe<AuthProviderRequests_Aggregate_Fields>;
  nodes: Array<AuthProviderRequests>;
};

/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate_Fields = {
  __typename?: 'authProviderRequests_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviderRequests_Max_Fields>;
  min?: Maybe<AuthProviderRequests_Min_Fields>;
};


/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequests_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
export type AuthProviderRequests_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProviderRequests_Bool_Exp>>;
  _not?: InputMaybe<AuthProviderRequests_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProviderRequests_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.provider_requests" */
export enum AuthProviderRequests_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProviderRequestsPkey = 'provider_requests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthProviderRequests_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthProviderRequests_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthProviderRequests_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.provider_requests" */
export type AuthProviderRequests_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type AuthProviderRequests_Max_Fields = {
  __typename?: 'authProviderRequests_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthProviderRequests_Min_Fields = {
  __typename?: 'authProviderRequests_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.provider_requests" */
export type AuthProviderRequests_Mutation_Response = {
  __typename?: 'authProviderRequests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviderRequests>;
};

/** on_conflict condition type for table "auth.provider_requests" */
export type AuthProviderRequests_On_Conflict = {
  constraint: AuthProviderRequests_Constraint;
  update_columns?: Array<AuthProviderRequests_Update_Column>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.provider_requests". */
export type AuthProviderRequests_Order_By = {
  id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.provider_requests */
export type AuthProviderRequests_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequests_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.provider_requests" */
export enum AuthProviderRequests_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

/** input type for updating data in table "auth.provider_requests" */
export type AuthProviderRequests_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "authProviderRequests" */
export type AuthProviderRequests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProviderRequests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviderRequests_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "auth.provider_requests" */
export enum AuthProviderRequests_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

export type AuthProviderRequests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProviderRequests_Bool_Exp;
};

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviders = {
  __typename?: 'authProviders';
  id: Scalars['String']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProviders_Aggregate;
};


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type AuthProviders_Aggregate = {
  __typename?: 'authProviders_aggregate';
  aggregate?: Maybe<AuthProviders_Aggregate_Fields>;
  nodes: Array<AuthProviders>;
};

/** aggregate fields of "auth.providers" */
export type AuthProviders_Aggregate_Fields = {
  __typename?: 'authProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviders_Max_Fields>;
  min?: Maybe<AuthProviders_Min_Fields>;
};


/** aggregate fields of "auth.providers" */
export type AuthProviders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type AuthProviders_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProviders_Bool_Exp>>;
  _not?: InputMaybe<AuthProviders_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProviders_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  userProviders?: InputMaybe<AuthUserProviders_Bool_Exp>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProviders_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type AuthProviders_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<AuthUserProviders_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AuthProviders_Max_Fields = {
  __typename?: 'authProviders_max_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthProviders_Min_Fields = {
  __typename?: 'authProviders_min_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.providers" */
export type AuthProviders_Mutation_Response = {
  __typename?: 'authProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviders>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type AuthProviders_Obj_Rel_Insert_Input = {
  data: AuthProviders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};

/** on_conflict condition type for table "auth.providers" */
export type AuthProviders_On_Conflict = {
  constraint: AuthProviders_Constraint;
  update_columns?: Array<AuthProviders_Update_Column>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type AuthProviders_Order_By = {
  id?: InputMaybe<Order_By>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.providers */
export type AuthProviders_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "auth.providers" */
export enum AuthProviders_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.providers" */
export type AuthProviders_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authProviders" */
export type AuthProviders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProviders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviders_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.providers" */
export enum AuthProviders_Update_Column {
  /** column name */
  Id = 'id'
}

export type AuthProviders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviders_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProviders_Bool_Exp;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes = {
  __typename?: 'authRefreshTokenTypes';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokens_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** aggregated selection of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate = {
  __typename?: 'authRefreshTokenTypes_aggregate';
  aggregate?: Maybe<AuthRefreshTokenTypes_Aggregate_Fields>;
  nodes: Array<AuthRefreshTokenTypes>;
};

/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate_Fields = {
  __typename?: 'authRefreshTokenTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokenTypes_Max_Fields>;
  min?: Maybe<AuthRefreshTokenTypes_Min_Fields>;
};


/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokenTypes_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokenTypes_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokenTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey'
}

export enum AuthRefreshTokenTypes_Enum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular'
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export type AuthRefreshTokenTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _in?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _nin?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
};

/** input type for inserting data into table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokenTypes_Max_Fields = {
  __typename?: 'authRefreshTokenTypes_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRefreshTokenTypes_Min_Fields = {
  __typename?: 'authRefreshTokenTypes_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Mutation_Response = {
  __typename?: 'authRefreshTokenTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokenTypes>;
};

/** on_conflict condition type for table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_On_Conflict = {
  constraint: AuthRefreshTokenTypes_Constraint;
  update_columns?: Array<AuthRefreshTokenTypes_Update_Column>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_token_types". */
export type AuthRefreshTokenTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_token_types */
export type AuthRefreshTokenTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRefreshTokenTypes" */
export type AuthRefreshTokenTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokenTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokenTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type AuthRefreshTokenTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokenTypes_Bool_Exp;
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokens = {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  type: AuthRefreshTokenTypes_Enum;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};


/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate = {
  __typename?: 'authRefreshTokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokens_Aggregate_Fields>;
  nodes: Array<AuthRefreshTokens>;
};

export type AuthRefreshTokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp_Count>;
};

export type AuthRefreshTokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Fields = {
  __typename?: 'authRefreshTokens_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokens_Max_Fields>;
  min?: Maybe<AuthRefreshTokens_Min_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthRefreshTokens_Max_Order_By>;
  min?: InputMaybe<AuthRefreshTokens_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokens_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type AuthRefreshTokens_Arr_Rel_Insert_Input = {
  data: Array<AuthRefreshTokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokens_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  refreshTokenHash?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthRefreshTokens_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthRefreshTokens_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthRefreshTokens_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.refresh_tokens" */
export type AuthRefreshTokens_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokens_Max_Fields = {
  __typename?: 'authRefreshTokens_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthRefreshTokens_Min_Fields = {
  __typename?: 'authRefreshTokens_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokens_Mutation_Response = {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
};

/** on_conflict condition type for table "auth.refresh_tokens" */
export type AuthRefreshTokens_On_Conflict = {
  constraint: AuthRefreshTokens_Constraint;
  update_columns?: Array<AuthRefreshTokens_Update_Column>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type AuthRefreshTokens_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_tokens */
export type AuthRefreshTokens_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokens_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type AuthRefreshTokens_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authRefreshTokens" */
export type AuthRefreshTokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokens_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type AuthRefreshTokens_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokens_Bool_Exp;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRoles = {
  __typename?: 'authRoles';
  role: Scalars['String']['output'];
  /** An array relationship */
  userRoles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  userRoles_aggregate: AuthUserRoles_Aggregate;
  /** An array relationship */
  usersByDefaultRole: Array<Users>;
  /** An aggregate relationship */
  usersByDefaultRole_aggregate: Users_Aggregate;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRoleArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type AuthRoles_Aggregate = {
  __typename?: 'authRoles_aggregate';
  aggregate?: Maybe<AuthRoles_Aggregate_Fields>;
  nodes: Array<AuthRoles>;
};

/** aggregate fields of "auth.roles" */
export type AuthRoles_Aggregate_Fields = {
  __typename?: 'authRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRoles_Max_Fields>;
  min?: Maybe<AuthRoles_Min_Fields>;
};


/** aggregate fields of "auth.roles" */
export type AuthRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type AuthRoles_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRoles_Bool_Exp>>;
  _not?: InputMaybe<AuthRoles_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRoles_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
  userRoles?: InputMaybe<AuthUserRoles_Bool_Exp>;
  userRoles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp>;
  usersByDefaultRole?: InputMaybe<Users_Bool_Exp>;
  usersByDefaultRole_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRoles_Constraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

export enum AuthRoles_Enum {
  Seeker = 'SEEKER',
  Anonymous = 'anonymous',
  Me = 'me',
  User = 'user'
}

/** Boolean expression to compare columns of type "authRoles_enum". All fields are combined with logical 'AND'. */
export type AuthRoles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AuthRoles_Enum>;
  _in?: InputMaybe<Array<AuthRoles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRoles_Enum>;
  _nin?: InputMaybe<Array<AuthRoles_Enum>>;
};

/** input type for inserting data into table "auth.roles" */
export type AuthRoles_Insert_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<AuthUserRoles_Arr_Rel_Insert_Input>;
  usersByDefaultRole?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AuthRoles_Max_Fields = {
  __typename?: 'authRoles_max_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRoles_Min_Fields = {
  __typename?: 'authRoles_min_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.roles" */
export type AuthRoles_Mutation_Response = {
  __typename?: 'authRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRoles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type AuthRoles_Obj_Rel_Insert_Input = {
  data: AuthRoles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};

/** on_conflict condition type for table "auth.roles" */
export type AuthRoles_On_Conflict = {
  constraint: AuthRoles_Constraint;
  update_columns?: Array<AuthRoles_Update_Column>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type AuthRoles_Order_By = {
  role?: InputMaybe<Order_By>;
  userRoles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Order_By>;
  usersByDefaultRole_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.roles */
export type AuthRoles_Pk_Columns_Input = {
  role: Scalars['String']['input'];
};

/** select columns of table "auth.roles" */
export enum AuthRoles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type AuthRoles_Set_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRoles" */
export type AuthRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRoles_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.roles" */
export enum AuthRoles_Update_Column {
  /** column name */
  Role = 'role'
}

export type AuthRoles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRoles_Bool_Exp;
};

/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserProviders = {
  __typename?: 'authUserProviders';
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  provider: AuthProviders;
  providerId: Scalars['String']['output'];
  providerUserId: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_providers" */
export type AuthUserProviders_Aggregate = {
  __typename?: 'authUserProviders_aggregate';
  aggregate?: Maybe<AuthUserProviders_Aggregate_Fields>;
  nodes: Array<AuthUserProviders>;
};

export type AuthUserProviders_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp_Count>;
};

export type AuthUserProviders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserProviders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_providers" */
export type AuthUserProviders_Aggregate_Fields = {
  __typename?: 'authUserProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserProviders_Max_Fields>;
  min?: Maybe<AuthUserProviders_Min_Fields>;
};


/** aggregate fields of "auth.user_providers" */
export type AuthUserProviders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_providers" */
export type AuthUserProviders_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserProviders_Max_Order_By>;
  min?: InputMaybe<AuthUserProviders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_providers" */
export type AuthUserProviders_Arr_Rel_Insert_Input = {
  data: Array<AuthUserProviders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
export type AuthUserProviders_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserProviders_Bool_Exp>>;
  _not?: InputMaybe<AuthUserProviders_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserProviders_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<AuthProviders_Bool_Exp>;
  providerId?: InputMaybe<String_Comparison_Exp>;
  providerUserId?: InputMaybe<String_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_providers" */
export enum AuthUserProviders_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key'
}

/** input type for inserting data into table "auth.user_providers" */
export type AuthUserProviders_Insert_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<AuthProviders_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserProviders_Max_Fields = {
  __typename?: 'authUserProviders_max_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_providers" */
export type AuthUserProviders_Max_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserProviders_Min_Fields = {
  __typename?: 'authUserProviders_min_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_providers" */
export type AuthUserProviders_Min_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_providers" */
export type AuthUserProviders_Mutation_Response = {
  __typename?: 'authUserProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserProviders>;
};

/** on_conflict condition type for table "auth.user_providers" */
export type AuthUserProviders_On_Conflict = {
  constraint: AuthUserProviders_Constraint;
  update_columns?: Array<AuthUserProviders_Update_Column>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_providers". */
export type AuthUserProviders_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<AuthProviders_Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_providers */
export type AuthUserProviders_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_providers" */
export enum AuthUserProviders_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_providers" */
export type AuthUserProviders_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserProviders" */
export type AuthUserProviders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserProviders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserProviders_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_providers" */
export enum AuthUserProviders_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type AuthUserProviders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserProviders_Bool_Exp;
};

/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserRoles = {
  __typename?: 'authUserRoles';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  role: AuthRoles_Enum;
  /** An object relationship */
  roleByRole: AuthRoles;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_roles" */
export type AuthUserRoles_Aggregate = {
  __typename?: 'authUserRoles_aggregate';
  aggregate?: Maybe<AuthUserRoles_Aggregate_Fields>;
  nodes: Array<AuthUserRoles>;
};

export type AuthUserRoles_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp_Count>;
};

export type AuthUserRoles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserRoles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_roles" */
export type AuthUserRoles_Aggregate_Fields = {
  __typename?: 'authUserRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserRoles_Max_Fields>;
  min?: Maybe<AuthUserRoles_Min_Fields>;
};


/** aggregate fields of "auth.user_roles" */
export type AuthUserRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_roles" */
export type AuthUserRoles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserRoles_Max_Order_By>;
  min?: InputMaybe<AuthUserRoles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_roles" */
export type AuthUserRoles_Arr_Rel_Insert_Input = {
  data: Array<AuthUserRoles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
export type AuthUserRoles_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserRoles_Bool_Exp>>;
  _not?: InputMaybe<AuthUserRoles_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserRoles_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<AuthRoles_Enum_Comparison_Exp>;
  roleByRole?: InputMaybe<AuthRoles_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_roles" */
export enum AuthUserRoles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key'
}

/** input type for inserting data into table "auth.user_roles" */
export type AuthUserRoles_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<AuthRoles_Enum>;
  roleByRole?: InputMaybe<AuthRoles_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserRoles_Max_Fields = {
  __typename?: 'authUserRoles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_roles" */
export type AuthUserRoles_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserRoles_Min_Fields = {
  __typename?: 'authUserRoles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_roles" */
export type AuthUserRoles_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_roles" */
export type AuthUserRoles_Mutation_Response = {
  __typename?: 'authUserRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserRoles>;
};

/** on_conflict condition type for table "auth.user_roles" */
export type AuthUserRoles_On_Conflict = {
  constraint: AuthUserRoles_Constraint;
  update_columns?: Array<AuthUserRoles_Update_Column>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_roles". */
export type AuthUserRoles_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<AuthRoles_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_roles */
export type AuthUserRoles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_roles" */
export enum AuthUserRoles_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_roles" */
export type AuthUserRoles_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<AuthRoles_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserRoles" */
export type AuthUserRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserRoles_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<AuthRoles_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_roles" */
export enum AuthUserRoles_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export type AuthUserRoles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserRoles_Bool_Exp;
};

/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserSecurityKeys = {
  __typename?: 'authUserSecurityKeys';
  counter: Scalars['bigint']['output'];
  credentialId: Scalars['String']['output'];
  credentialPublicKey?: Maybe<Scalars['bytea']['output']>;
  id: Scalars['uuid']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  transports: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate = {
  __typename?: 'authUserSecurityKeys_aggregate';
  aggregate?: Maybe<AuthUserSecurityKeys_Aggregate_Fields>;
  nodes: Array<AuthUserSecurityKeys>;
};

export type AuthUserSecurityKeys_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserSecurityKeys_Aggregate_Bool_Exp_Count>;
};

export type AuthUserSecurityKeys_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_Fields = {
  __typename?: 'authUserSecurityKeys_aggregate_fields';
  avg?: Maybe<AuthUserSecurityKeys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserSecurityKeys_Max_Fields>;
  min?: Maybe<AuthUserSecurityKeys_Min_Fields>;
  stddev?: Maybe<AuthUserSecurityKeys_Stddev_Fields>;
  stddev_pop?: Maybe<AuthUserSecurityKeys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuthUserSecurityKeys_Stddev_Samp_Fields>;
  sum?: Maybe<AuthUserSecurityKeys_Sum_Fields>;
  var_pop?: Maybe<AuthUserSecurityKeys_Var_Pop_Fields>;
  var_samp?: Maybe<AuthUserSecurityKeys_Var_Samp_Fields>;
  variance?: Maybe<AuthUserSecurityKeys_Variance_Fields>;
};


/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_Order_By = {
  avg?: InputMaybe<AuthUserSecurityKeys_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserSecurityKeys_Max_Order_By>;
  min?: InputMaybe<AuthUserSecurityKeys_Min_Order_By>;
  stddev?: InputMaybe<AuthUserSecurityKeys_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AuthUserSecurityKeys_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AuthUserSecurityKeys_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AuthUserSecurityKeys_Sum_Order_By>;
  var_pop?: InputMaybe<AuthUserSecurityKeys_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AuthUserSecurityKeys_Var_Samp_Order_By>;
  variance?: InputMaybe<AuthUserSecurityKeys_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Arr_Rel_Insert_Input = {
  data: Array<AuthUserSecurityKeys_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};

/** aggregate avg on columns */
export type AuthUserSecurityKeys_Avg_Fields = {
  __typename?: 'authUserSecurityKeys_avg_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Avg_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
export type AuthUserSecurityKeys_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserSecurityKeys_Bool_Exp>>;
  _not?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserSecurityKeys_Bool_Exp>>;
  counter?: InputMaybe<Bigint_Comparison_Exp>;
  credentialId?: InputMaybe<String_Comparison_Exp>;
  credentialPublicKey?: InputMaybe<Bytea_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  transports?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Constraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey'
}

/** input type for incrementing numeric columns in table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Inc_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Insert_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserSecurityKeys_Max_Fields = {
  __typename?: 'authUserSecurityKeys_max_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Max_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserSecurityKeys_Min_Fields = {
  __typename?: 'authUserSecurityKeys_min_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Min_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Mutation_Response = {
  __typename?: 'authUserSecurityKeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserSecurityKeys>;
};

/** on_conflict condition type for table "auth.user_security_keys" */
export type AuthUserSecurityKeys_On_Conflict = {
  constraint: AuthUserSecurityKeys_Constraint;
  update_columns?: Array<AuthUserSecurityKeys_Update_Column>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_security_keys". */
export type AuthUserSecurityKeys_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  credentialPublicKey?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_security_keys */
export type AuthUserSecurityKeys_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Select_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Set_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type AuthUserSecurityKeys_Stddev_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AuthUserSecurityKeys_Stddev_Pop_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Pop_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AuthUserSecurityKeys_Stddev_Samp_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Samp_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "authUserSecurityKeys" */
export type AuthUserSecurityKeys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserSecurityKeys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserSecurityKeys_Stream_Cursor_Value_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type AuthUserSecurityKeys_Sum_Fields = {
  __typename?: 'authUserSecurityKeys_sum_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Sum_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** update columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Update_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export type AuthUserSecurityKeys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserSecurityKeys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuthUserSecurityKeys_Var_Pop_Fields = {
  __typename?: 'authUserSecurityKeys_var_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Var_Pop_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AuthUserSecurityKeys_Var_Samp_Fields = {
  __typename?: 'authUserSecurityKeys_var_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Var_Samp_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AuthUserSecurityKeys_Variance_Fields = {
  __typename?: 'authUserSecurityKeys_variance_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Variance_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']['input']>;
  _gt?: InputMaybe<Scalars['bpchar']['input']>;
  _gte?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']['input']>;
  _in?: InputMaybe<Array<Scalars['bpchar']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']['input']>;
  _lt?: InputMaybe<Scalars['bpchar']['input']>;
  _lte?: InputMaybe<Scalars['bpchar']['input']>;
  _neq?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']['input']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']['input']>;
};

/** columns and relationships of "storage.buckets" */
export type Buckets = {
  __typename?: 'buckets';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  downloadExpiration: Scalars['Int']['output'];
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  id: Scalars['String']['output'];
  maxUploadFileSize: Scalars['Int']['output'];
  minUploadFileSize: Scalars['Int']['output'];
  presignedUrlsEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "storage.buckets" */
export type BucketsFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "storage.buckets" */
export type BucketsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "storage.buckets" */
export type Buckets_Aggregate = {
  __typename?: 'buckets_aggregate';
  aggregate?: Maybe<Buckets_Aggregate_Fields>;
  nodes: Array<Buckets>;
};

/** aggregate fields of "storage.buckets" */
export type Buckets_Aggregate_Fields = {
  __typename?: 'buckets_aggregate_fields';
  avg?: Maybe<Buckets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Buckets_Max_Fields>;
  min?: Maybe<Buckets_Min_Fields>;
  stddev?: Maybe<Buckets_Stddev_Fields>;
  stddev_pop?: Maybe<Buckets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Buckets_Stddev_Samp_Fields>;
  sum?: Maybe<Buckets_Sum_Fields>;
  var_pop?: Maybe<Buckets_Var_Pop_Fields>;
  var_samp?: Maybe<Buckets_Var_Samp_Fields>;
  variance?: Maybe<Buckets_Variance_Fields>;
};


/** aggregate fields of "storage.buckets" */
export type Buckets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buckets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Buckets_Avg_Fields = {
  __typename?: 'buckets_avg_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
export type Buckets_Bool_Exp = {
  _and?: InputMaybe<Array<Buckets_Bool_Exp>>;
  _not?: InputMaybe<Buckets_Bool_Exp>;
  _or?: InputMaybe<Array<Buckets_Bool_Exp>>;
  cacheControl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  downloadExpiration?: InputMaybe<Int_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  maxUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  minUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  presignedUrlsEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage.buckets" */
export enum Buckets_Constraint {
  /** unique or primary key constraint on columns "id" */
  BucketsPkey = 'buckets_pkey'
}

/** input type for incrementing numeric columns in table "storage.buckets" */
export type Buckets_Inc_Input = {
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.buckets" */
export type Buckets_Insert_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Buckets_Max_Fields = {
  __typename?: 'buckets_max_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Buckets_Min_Fields = {
  __typename?: 'buckets_min_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "storage.buckets" */
export type Buckets_Mutation_Response = {
  __typename?: 'buckets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buckets>;
};

/** input type for inserting object relation for remote table "storage.buckets" */
export type Buckets_Obj_Rel_Insert_Input = {
  data: Buckets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};

/** on_conflict condition type for table "storage.buckets" */
export type Buckets_On_Conflict = {
  constraint: Buckets_Constraint;
  update_columns?: Array<Buckets_Update_Column>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.buckets". */
export type Buckets_Order_By = {
  cacheControl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadExpiration?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  maxUploadFileSize?: InputMaybe<Order_By>;
  minUploadFileSize?: InputMaybe<Order_By>;
  presignedUrlsEnabled?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage.buckets */
export type Buckets_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "storage.buckets" */
export enum Buckets_Select_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "storage.buckets" */
export type Buckets_Set_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Buckets_Stddev_Fields = {
  __typename?: 'buckets_stddev_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Buckets_Stddev_Pop_Fields = {
  __typename?: 'buckets_stddev_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Buckets_Stddev_Samp_Fields = {
  __typename?: 'buckets_stddev_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "buckets" */
export type Buckets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Buckets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buckets_Stream_Cursor_Value_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Buckets_Sum_Fields = {
  __typename?: 'buckets_sum_fields';
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "storage.buckets" */
export enum Buckets_Update_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Buckets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Buckets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buckets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buckets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Buckets_Var_Pop_Fields = {
  __typename?: 'buckets_var_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Buckets_Var_Samp_Fields = {
  __typename?: 'buckets_var_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Buckets_Variance_Fields = {
  __typename?: 'buckets_variance_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
};

/** columns and relationships of "chat_response_type" */
export type Chat_Response_Type = {
  __typename?: 'chat_response_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "chat_response_type" */
export type Chat_Response_Type_Aggregate = {
  __typename?: 'chat_response_type_aggregate';
  aggregate?: Maybe<Chat_Response_Type_Aggregate_Fields>;
  nodes: Array<Chat_Response_Type>;
};

/** aggregate fields of "chat_response_type" */
export type Chat_Response_Type_Aggregate_Fields = {
  __typename?: 'chat_response_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Chat_Response_Type_Max_Fields>;
  min?: Maybe<Chat_Response_Type_Min_Fields>;
};


/** aggregate fields of "chat_response_type" */
export type Chat_Response_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Response_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "chat_response_type". All fields are combined with a logical 'AND'. */
export type Chat_Response_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Response_Type_Bool_Exp>>;
  _not?: InputMaybe<Chat_Response_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Response_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_response_type" */
export enum Chat_Response_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  ChatResponseTypePkey = 'chat_response_type_pkey'
}

export enum Chat_Response_Type_Enum {
  NewConversation = 'NEW_CONVERSATION',
  NotAboutRealEstate = 'NOT_ABOUT_REAL_ESTATE',
  OutOfContext = 'OUT_OF_CONTEXT',
  PropertyList = 'PROPERTY_LIST',
  PropertyStatusAndTypeMissing = 'PROPERTY_STATUS_AND_TYPE_MISSING',
  PropertyStatusMissing = 'PROPERTY_STATUS_MISSING',
  PropertyTypeMissing = 'PROPERTY_TYPE_MISSING',
  StatisticalIntelligence = 'STATISTICAL_INTELLIGENCE'
}

/** Boolean expression to compare columns of type "chat_response_type_enum". All fields are combined with logical 'AND'. */
export type Chat_Response_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Chat_Response_Type_Enum>;
  _in?: InputMaybe<Array<Chat_Response_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Chat_Response_Type_Enum>;
  _nin?: InputMaybe<Array<Chat_Response_Type_Enum>>;
};

/** input type for inserting data into table "chat_response_type" */
export type Chat_Response_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Chat_Response_Type_Max_Fields = {
  __typename?: 'chat_response_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Chat_Response_Type_Min_Fields = {
  __typename?: 'chat_response_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chat_response_type" */
export type Chat_Response_Type_Mutation_Response = {
  __typename?: 'chat_response_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Response_Type>;
};

/** on_conflict condition type for table "chat_response_type" */
export type Chat_Response_Type_On_Conflict = {
  constraint: Chat_Response_Type_Constraint;
  update_columns?: Array<Chat_Response_Type_Update_Column>;
  where?: InputMaybe<Chat_Response_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_response_type". */
export type Chat_Response_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_response_type */
export type Chat_Response_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "chat_response_type" */
export enum Chat_Response_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_response_type" */
export type Chat_Response_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chat_response_type" */
export type Chat_Response_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Response_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Response_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chat_response_type" */
export enum Chat_Response_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Chat_Response_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Response_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Response_Type_Bool_Exp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** columns and relationships of "city" */
export type City = {
  __typename?: 'city';
  city_name: Scalars['String']['output'];
  country_id: Scalars['uuid']['output'];
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "city" */
export type City_Aggregate = {
  __typename?: 'city_aggregate';
  aggregate?: Maybe<City_Aggregate_Fields>;
  nodes: Array<City>;
};

/** aggregate fields of "city" */
export type City_Aggregate_Fields = {
  __typename?: 'city_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<City_Max_Fields>;
  min?: Maybe<City_Min_Fields>;
};


/** aggregate fields of "city" */
export type City_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<City_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "city". All fields are combined with a logical 'AND'. */
export type City_Bool_Exp = {
  _and?: InputMaybe<Array<City_Bool_Exp>>;
  _not?: InputMaybe<City_Bool_Exp>;
  _or?: InputMaybe<Array<City_Bool_Exp>>;
  city_name?: InputMaybe<String_Comparison_Exp>;
  country_id?: InputMaybe<Uuid_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "city". All fields are combined with a logical 'AND'. */
export type City_Bool_Exp_Remote_Rel_Notificationcity = {
  _and?: InputMaybe<Array<City_Bool_Exp>>;
  _not?: InputMaybe<City_Bool_Exp>;
  _or?: InputMaybe<Array<City_Bool_Exp>>;
  city_name?: InputMaybe<String_Comparison_Exp>;
  country_id?: InputMaybe<Uuid_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_Notificationcity>;
};

/** Boolean expression to filter rows from the table "city". All fields are combined with a logical 'AND'. */
export type City_Bool_Exp_Remote_Rel_User_Filterscity = {
  _and?: InputMaybe<Array<City_Bool_Exp>>;
  _not?: InputMaybe<City_Bool_Exp>;
  _or?: InputMaybe<Array<City_Bool_Exp>>;
  city_name?: InputMaybe<String_Comparison_Exp>;
  country_id?: InputMaybe<Uuid_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_User_Filterscity>;
};

/** unique or primary key constraints on table "city" */
export enum City_Constraint {
  /** unique or primary key constraint on columns "city_name" */
  CityCityNameKey = 'city_city_name_key',
  /** unique or primary key constraint on columns "id" */
  CityPkey = 'city_pkey'
}

/** input type for inserting data into table "city" */
export type City_Insert_Input = {
  city_name?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['uuid']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type City_Max_Fields = {
  __typename?: 'city_max_fields';
  city_name?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['uuid']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type City_Min_Fields = {
  __typename?: 'city_min_fields';
  city_name?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['uuid']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "city" */
export type City_Mutation_Response = {
  __typename?: 'city_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<City>;
};

/** input type for inserting object relation for remote table "city" */
export type City_Obj_Rel_Insert_Input = {
  data: City_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<City_On_Conflict>;
};

/** on_conflict condition type for table "city" */
export type City_On_Conflict = {
  constraint: City_Constraint;
  update_columns?: Array<City_Update_Column>;
  where?: InputMaybe<City_Bool_Exp>;
};

/** Ordering options when selecting data from "city". */
export type City_Order_By = {
  city_name?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: city */
export type City_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "city" */
export enum City_Select_Column {
  /** column name */
  CityName = 'city_name',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "city" */
export type City_Set_Input = {
  city_name?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['uuid']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "city" */
export type City_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: City_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type City_Stream_Cursor_Value_Input = {
  city_name?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['uuid']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "city" */
export enum City_Update_Column {
  /** column name */
  CityName = 'city_name',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id'
}

export type City_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<City_Set_Input>;
  /** filter the rows which have to be updated */
  where: City_Bool_Exp;
};

/** columns and relationships of "city_zone" */
export type City_Zone = {
  __typename?: 'city_zone';
  bounds?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  city: City;
  city_id: Scalars['uuid']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  data_method?: Maybe<Zone_Data_Method_Enum>;
  formatted_address?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_problematic?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['jsonb']['output']>;
  location_type?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<City_Zone_Origin_Enum>;
  place_id?: Maybe<Scalars['String']['output']>;
  surface_area?: Maybe<Scalars['float8']['output']>;
  types?: Maybe<Scalars['jsonb']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  viewport?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  zone_data_method?: Maybe<Zone_Data_Method>;
  zone_name: Scalars['String']['output'];
};


/** columns and relationships of "city_zone" */
export type City_ZoneBoundsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "city_zone" */
export type City_ZoneLocationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "city_zone" */
export type City_ZoneTypesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "city_zone" */
export type City_ZoneViewportArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "city_zone" */
export type City_Zone_Aggregate = {
  __typename?: 'city_zone_aggregate';
  aggregate?: Maybe<City_Zone_Aggregate_Fields>;
  nodes: Array<City_Zone>;
};

/** aggregate fields of "city_zone" */
export type City_Zone_Aggregate_Fields = {
  __typename?: 'city_zone_aggregate_fields';
  avg?: Maybe<City_Zone_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<City_Zone_Max_Fields>;
  min?: Maybe<City_Zone_Min_Fields>;
  stddev?: Maybe<City_Zone_Stddev_Fields>;
  stddev_pop?: Maybe<City_Zone_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<City_Zone_Stddev_Samp_Fields>;
  sum?: Maybe<City_Zone_Sum_Fields>;
  var_pop?: Maybe<City_Zone_Var_Pop_Fields>;
  var_samp?: Maybe<City_Zone_Var_Samp_Fields>;
  variance?: Maybe<City_Zone_Variance_Fields>;
};


/** aggregate fields of "city_zone" */
export type City_Zone_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<City_Zone_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type City_Zone_Append_Input = {
  bounds?: InputMaybe<Scalars['jsonb']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  types?: InputMaybe<Scalars['jsonb']['input']>;
  viewport?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type City_Zone_Avg_Fields = {
  __typename?: 'city_zone_avg_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "city_zone". All fields are combined with a logical 'AND'. */
export type City_Zone_Bool_Exp = {
  _and?: InputMaybe<Array<City_Zone_Bool_Exp>>;
  _not?: InputMaybe<City_Zone_Bool_Exp>;
  _or?: InputMaybe<Array<City_Zone_Bool_Exp>>;
  bounds?: InputMaybe<Jsonb_Comparison_Exp>;
  city?: InputMaybe<City_Bool_Exp>;
  city_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_method?: InputMaybe<Zone_Data_Method_Enum_Comparison_Exp>;
  formatted_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_problematic?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  location_type?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<City_Zone_Origin_Enum_Comparison_Exp>;
  place_id?: InputMaybe<String_Comparison_Exp>;
  surface_area?: InputMaybe<Float8_Comparison_Exp>;
  types?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  viewport?: InputMaybe<Jsonb_Comparison_Exp>;
  zone_data_method?: InputMaybe<Zone_Data_Method_Bool_Exp>;
  zone_name?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "city_zone". All fields are combined with a logical 'AND'. */
export type City_Zone_Bool_Exp_Remote_Rel_Notificationcity_Zone = {
  _and?: InputMaybe<Array<City_Zone_Bool_Exp>>;
  _not?: InputMaybe<City_Zone_Bool_Exp>;
  _or?: InputMaybe<Array<City_Zone_Bool_Exp>>;
  bounds?: InputMaybe<Jsonb_Comparison_Exp>;
  city?: InputMaybe<City_Bool_Exp>;
  city_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_method?: InputMaybe<Zone_Data_Method_Enum_Comparison_Exp>;
  formatted_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_Notificationcity_Zone>;
  is_problematic?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  location_type?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<City_Zone_Origin_Enum_Comparison_Exp>;
  place_id?: InputMaybe<String_Comparison_Exp>;
  surface_area?: InputMaybe<Float8_Comparison_Exp>;
  types?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  viewport?: InputMaybe<Jsonb_Comparison_Exp>;
  zone_data_method?: InputMaybe<Zone_Data_Method_Bool_Exp>;
  zone_name?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "city_zone". All fields are combined with a logical 'AND'. */
export type City_Zone_Bool_Exp_Remote_Rel_User_Filterscity_Zone = {
  _and?: InputMaybe<Array<City_Zone_Bool_Exp>>;
  _not?: InputMaybe<City_Zone_Bool_Exp>;
  _or?: InputMaybe<Array<City_Zone_Bool_Exp>>;
  bounds?: InputMaybe<Jsonb_Comparison_Exp>;
  city?: InputMaybe<City_Bool_Exp>;
  city_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_method?: InputMaybe<Zone_Data_Method_Enum_Comparison_Exp>;
  formatted_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_User_Filterscity_Zone>;
  is_problematic?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  location_type?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<City_Zone_Origin_Enum_Comparison_Exp>;
  place_id?: InputMaybe<String_Comparison_Exp>;
  surface_area?: InputMaybe<Float8_Comparison_Exp>;
  types?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  viewport?: InputMaybe<Jsonb_Comparison_Exp>;
  zone_data_method?: InputMaybe<Zone_Data_Method_Bool_Exp>;
  zone_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "city_zone" */
export enum City_Zone_Constraint {
  /** unique or primary key constraint on columns "id" */
  CityZonePkey = 'city_zone_pkey',
  /** unique or primary key constraint on columns "zone_name", "city_id" */
  CityZoneZoneNameCityIdKey = 'city_zone_zone_name_city_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type City_Zone_Delete_At_Path_Input = {
  bounds?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  viewport?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type City_Zone_Delete_Elem_Input = {
  bounds?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Scalars['Int']['input']>;
  viewport?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type City_Zone_Delete_Key_Input = {
  bounds?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Scalars['String']['input']>;
  viewport?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "city_zone" */
export type City_Zone_Inc_Input = {
  surface_area?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "city_zone" */
export type City_Zone_Insert_Input = {
  bounds?: InputMaybe<Scalars['jsonb']['input']>;
  city?: InputMaybe<City_Obj_Rel_Insert_Input>;
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_method?: InputMaybe<Zone_Data_Method_Enum>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_problematic?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  location_type?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<City_Zone_Origin_Enum>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  surface_area?: InputMaybe<Scalars['float8']['input']>;
  types?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  viewport?: InputMaybe<Scalars['jsonb']['input']>;
  zone_data_method?: InputMaybe<Zone_Data_Method_Obj_Rel_Insert_Input>;
  zone_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type City_Zone_Max_Fields = {
  __typename?: 'city_zone_max_fields';
  city_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  formatted_address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_type?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['String']['output']>;
  surface_area?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zone_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type City_Zone_Min_Fields = {
  __typename?: 'city_zone_min_fields';
  city_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  formatted_address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_type?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['String']['output']>;
  surface_area?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zone_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "city_zone" */
export type City_Zone_Mutation_Response = {
  __typename?: 'city_zone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<City_Zone>;
};

/** on_conflict condition type for table "city_zone" */
export type City_Zone_On_Conflict = {
  constraint: City_Zone_Constraint;
  update_columns?: Array<City_Zone_Update_Column>;
  where?: InputMaybe<City_Zone_Bool_Exp>;
};

/** Ordering options when selecting data from "city_zone". */
export type City_Zone_Order_By = {
  bounds?: InputMaybe<Order_By>;
  city?: InputMaybe<City_Order_By>;
  city_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_method?: InputMaybe<Order_By>;
  formatted_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_problematic?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  location_type?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  surface_area?: InputMaybe<Order_By>;
  types?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  viewport?: InputMaybe<Order_By>;
  zone_data_method?: InputMaybe<Zone_Data_Method_Order_By>;
  zone_name?: InputMaybe<Order_By>;
};

/** columns and relationships of "city_zone_origin" */
export type City_Zone_Origin = {
  __typename?: 'city_zone_origin';
  origin: Scalars['String']['output'];
};

/** aggregated selection of "city_zone_origin" */
export type City_Zone_Origin_Aggregate = {
  __typename?: 'city_zone_origin_aggregate';
  aggregate?: Maybe<City_Zone_Origin_Aggregate_Fields>;
  nodes: Array<City_Zone_Origin>;
};

/** aggregate fields of "city_zone_origin" */
export type City_Zone_Origin_Aggregate_Fields = {
  __typename?: 'city_zone_origin_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<City_Zone_Origin_Max_Fields>;
  min?: Maybe<City_Zone_Origin_Min_Fields>;
};


/** aggregate fields of "city_zone_origin" */
export type City_Zone_Origin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<City_Zone_Origin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "city_zone_origin". All fields are combined with a logical 'AND'. */
export type City_Zone_Origin_Bool_Exp = {
  _and?: InputMaybe<Array<City_Zone_Origin_Bool_Exp>>;
  _not?: InputMaybe<City_Zone_Origin_Bool_Exp>;
  _or?: InputMaybe<Array<City_Zone_Origin_Bool_Exp>>;
  origin?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "city_zone_origin" */
export enum City_Zone_Origin_Constraint {
  /** unique or primary key constraint on columns "origin" */
  CityZoneOriginPkey = 'city_zone_origin_pkey'
}

export enum City_Zone_Origin_Enum {
  Pipeline = 'PIPELINE',
  Seeded = 'SEEDED',
  User = 'USER'
}

/** Boolean expression to compare columns of type "city_zone_origin_enum". All fields are combined with logical 'AND'. */
export type City_Zone_Origin_Enum_Comparison_Exp = {
  _eq?: InputMaybe<City_Zone_Origin_Enum>;
  _in?: InputMaybe<Array<City_Zone_Origin_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<City_Zone_Origin_Enum>;
  _nin?: InputMaybe<Array<City_Zone_Origin_Enum>>;
};

/** input type for inserting data into table "city_zone_origin" */
export type City_Zone_Origin_Insert_Input = {
  origin?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type City_Zone_Origin_Max_Fields = {
  __typename?: 'city_zone_origin_max_fields';
  origin?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type City_Zone_Origin_Min_Fields = {
  __typename?: 'city_zone_origin_min_fields';
  origin?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "city_zone_origin" */
export type City_Zone_Origin_Mutation_Response = {
  __typename?: 'city_zone_origin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<City_Zone_Origin>;
};

/** on_conflict condition type for table "city_zone_origin" */
export type City_Zone_Origin_On_Conflict = {
  constraint: City_Zone_Origin_Constraint;
  update_columns?: Array<City_Zone_Origin_Update_Column>;
  where?: InputMaybe<City_Zone_Origin_Bool_Exp>;
};

/** Ordering options when selecting data from "city_zone_origin". */
export type City_Zone_Origin_Order_By = {
  origin?: InputMaybe<Order_By>;
};

/** primary key columns input for table: city_zone_origin */
export type City_Zone_Origin_Pk_Columns_Input = {
  origin: Scalars['String']['input'];
};

/** select columns of table "city_zone_origin" */
export enum City_Zone_Origin_Select_Column {
  /** column name */
  Origin = 'origin'
}

/** input type for updating data in table "city_zone_origin" */
export type City_Zone_Origin_Set_Input = {
  origin?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "city_zone_origin" */
export type City_Zone_Origin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: City_Zone_Origin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type City_Zone_Origin_Stream_Cursor_Value_Input = {
  origin?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "city_zone_origin" */
export enum City_Zone_Origin_Update_Column {
  /** column name */
  Origin = 'origin'
}

export type City_Zone_Origin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<City_Zone_Origin_Set_Input>;
  /** filter the rows which have to be updated */
  where: City_Zone_Origin_Bool_Exp;
};

/** primary key columns input for table: city_zone */
export type City_Zone_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type City_Zone_Prepend_Input = {
  bounds?: InputMaybe<Scalars['jsonb']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  types?: InputMaybe<Scalars['jsonb']['input']>;
  viewport?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "city_zone" */
export enum City_Zone_Select_Column {
  /** column name */
  Bounds = 'bounds',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataMethod = 'data_method',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Id = 'id',
  /** column name */
  IsProblematic = 'is_problematic',
  /** column name */
  Location = 'location',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  Origin = 'origin',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  SurfaceArea = 'surface_area',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Viewport = 'viewport',
  /** column name */
  ZoneName = 'zone_name'
}

/** input type for updating data in table "city_zone" */
export type City_Zone_Set_Input = {
  bounds?: InputMaybe<Scalars['jsonb']['input']>;
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_method?: InputMaybe<Zone_Data_Method_Enum>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_problematic?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  location_type?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<City_Zone_Origin_Enum>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  surface_area?: InputMaybe<Scalars['float8']['input']>;
  types?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  viewport?: InputMaybe<Scalars['jsonb']['input']>;
  zone_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type City_Zone_Stddev_Fields = {
  __typename?: 'city_zone_stddev_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type City_Zone_Stddev_Pop_Fields = {
  __typename?: 'city_zone_stddev_pop_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type City_Zone_Stddev_Samp_Fields = {
  __typename?: 'city_zone_stddev_samp_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "city_zone" */
export type City_Zone_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: City_Zone_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type City_Zone_Stream_Cursor_Value_Input = {
  bounds?: InputMaybe<Scalars['jsonb']['input']>;
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_method?: InputMaybe<Zone_Data_Method_Enum>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_problematic?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  location_type?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<City_Zone_Origin_Enum>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  surface_area?: InputMaybe<Scalars['float8']['input']>;
  types?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  viewport?: InputMaybe<Scalars['jsonb']['input']>;
  zone_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type City_Zone_Sum_Fields = {
  __typename?: 'city_zone_sum_fields';
  surface_area?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "city_zone" */
export enum City_Zone_Update_Column {
  /** column name */
  Bounds = 'bounds',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataMethod = 'data_method',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Id = 'id',
  /** column name */
  IsProblematic = 'is_problematic',
  /** column name */
  Location = 'location',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  Origin = 'origin',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  SurfaceArea = 'surface_area',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Viewport = 'viewport',
  /** column name */
  ZoneName = 'zone_name'
}

export type City_Zone_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<City_Zone_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<City_Zone_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<City_Zone_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<City_Zone_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<City_Zone_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<City_Zone_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<City_Zone_Set_Input>;
  /** filter the rows which have to be updated */
  where: City_Zone_Bool_Exp;
};

/** aggregate var_pop on columns */
export type City_Zone_Var_Pop_Fields = {
  __typename?: 'city_zone_var_pop_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type City_Zone_Var_Samp_Fields = {
  __typename?: 'city_zone_var_samp_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type City_Zone_Variance_Fields = {
  __typename?: 'city_zone_variance_fields';
  surface_area?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "construction_status" */
export type Construction_Status = {
  __typename?: 'construction_status';
  status: Scalars['String']['output'];
};

export type Construction_Status_ActionType = {
  __typename?: 'construction_status_ActionType';
  status: Scalars['String']['output'];
};

/** aggregated selection of "construction_status" */
export type Construction_Status_Aggregate = {
  __typename?: 'construction_status_aggregate';
  aggregate?: Maybe<Construction_Status_Aggregate_Fields>;
  nodes: Array<Construction_Status>;
};

/** aggregate fields of "construction_status" */
export type Construction_Status_Aggregate_Fields = {
  __typename?: 'construction_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Construction_Status_Max_Fields>;
  min?: Maybe<Construction_Status_Min_Fields>;
};


/** aggregate fields of "construction_status" */
export type Construction_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Construction_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "construction_status". All fields are combined with a logical 'AND'. */
export type Construction_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Construction_Status_Bool_Exp>>;
  _not?: InputMaybe<Construction_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Construction_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "construction_status" */
export enum Construction_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  ConstructionStatusPkey = 'construction_status_pkey'
}

export enum Construction_Status_Enum {
  NewBuilding = 'new_building',
  OldBuilding = 'old_building',
  UnderConstruction = 'under_construction'
}

/** Boolean expression to compare columns of type "construction_status_enum". All fields are combined with logical 'AND'. */
export type Construction_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Construction_Status_Enum>;
  _in?: InputMaybe<Array<Construction_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Construction_Status_Enum>;
  _nin?: InputMaybe<Array<Construction_Status_Enum>>;
};

/** input type for inserting data into table "construction_status" */
export type Construction_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Construction_Status_Max_Fields = {
  __typename?: 'construction_status_max_fields';
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Construction_Status_Min_Fields = {
  __typename?: 'construction_status_min_fields';
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "construction_status" */
export type Construction_Status_Mutation_Response = {
  __typename?: 'construction_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Construction_Status>;
};

/** on_conflict condition type for table "construction_status" */
export type Construction_Status_On_Conflict = {
  constraint: Construction_Status_Constraint;
  update_columns?: Array<Construction_Status_Update_Column>;
  where?: InputMaybe<Construction_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "construction_status". */
export type Construction_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: construction_status */
export type Construction_Status_Pk_Columns_Input = {
  status: Scalars['String']['input'];
};

/** select columns of table "construction_status" */
export enum Construction_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "construction_status" */
export type Construction_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "construction_status" */
export type Construction_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Construction_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Construction_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "construction_status" */
export enum Construction_Status_Update_Column {
  /** column name */
  Status = 'status'
}

export type Construction_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Construction_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Construction_Status_Bool_Exp;
};

/** columns and relationships of "contact" */
export type Contact = {
  __typename?: 'contact';
  /** An array relationship */
  contact_phone_numbers: Array<Contact_Phone_Number>;
  /** An aggregate relationship */
  contact_phone_numbers_aggregate: Contact_Phone_Number_Aggregate;
  contact_type?: Maybe<Contact_Type_Enum>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  property_lists: Array<Property_List>;
  /** An aggregate relationship */
  property_lists_aggregate: Property_List_Aggregate;
};


/** columns and relationships of "contact" */
export type ContactContact_Phone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Phone_Number_Order_By>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactContact_Phone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Phone_Number_Order_By>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactProperty_ListsArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactProperty_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};

/** aggregated selection of "contact" */
export type Contact_Aggregate = {
  __typename?: 'contact_aggregate';
  aggregate?: Maybe<Contact_Aggregate_Fields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type Contact_Aggregate_Fields = {
  __typename?: 'contact_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Max_Fields>;
  min?: Maybe<Contact_Min_Fields>;
};


/** aggregate fields of "contact" */
export type Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Bool_Exp>>;
  _not?: InputMaybe<Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Bool_Exp>>;
  contact_phone_numbers?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
  contact_phone_numbers_aggregate?: InputMaybe<Contact_Phone_Number_Aggregate_Bool_Exp>;
  contact_type?: InputMaybe<Contact_Type_Enum_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property_lists?: InputMaybe<Property_List_Bool_Exp>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "contact" */
export enum Contact_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'contact_pkey'
}

/** input type for inserting data into table "contact" */
export type Contact_Insert_Input = {
  contact_phone_numbers?: InputMaybe<Contact_Phone_Number_Arr_Rel_Insert_Input>;
  contact_type?: InputMaybe<Contact_Type_Enum>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_lists?: InputMaybe<Property_List_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contact_Max_Fields = {
  __typename?: 'contact_max_fields';
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Contact_Min_Fields = {
  __typename?: 'contact_min_fields';
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "contact" */
export type Contact_Mutation_Response = {
  __typename?: 'contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact>;
};

/** input type for inserting object relation for remote table "contact" */
export type Contact_Obj_Rel_Insert_Input = {
  data: Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** on_conflict condition type for table "contact" */
export type Contact_On_Conflict = {
  constraint: Contact_Constraint;
  update_columns?: Array<Contact_Update_Column>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "contact". */
export type Contact_Order_By = {
  contact_phone_numbers_aggregate?: InputMaybe<Contact_Phone_Number_Aggregate_Order_By>;
  contact_type?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Order_By>;
};

/** Tabele per lidhjen e numrit te telefonit me kontaktin dhe agjensine perkatese */
export type Contact_Phone_Number = {
  __typename?: 'contact_phone_number';
  /** An object relationship */
  agency?: Maybe<Agency>;
  agency_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['uuid']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  phone_number: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "contact_phone_number" */
export type Contact_Phone_Number_Aggregate = {
  __typename?: 'contact_phone_number_aggregate';
  aggregate?: Maybe<Contact_Phone_Number_Aggregate_Fields>;
  nodes: Array<Contact_Phone_Number>;
};

export type Contact_Phone_Number_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contact_Phone_Number_Aggregate_Bool_Exp_Count>;
};

export type Contact_Phone_Number_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contact_phone_number" */
export type Contact_Phone_Number_Aggregate_Fields = {
  __typename?: 'contact_phone_number_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Phone_Number_Max_Fields>;
  min?: Maybe<Contact_Phone_Number_Min_Fields>;
};


/** aggregate fields of "contact_phone_number" */
export type Contact_Phone_Number_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contact_phone_number" */
export type Contact_Phone_Number_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Phone_Number_Max_Order_By>;
  min?: InputMaybe<Contact_Phone_Number_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contact_phone_number" */
export type Contact_Phone_Number_Arr_Rel_Insert_Input = {
  data: Array<Contact_Phone_Number_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Phone_Number_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contact_phone_number". All fields are combined with a logical 'AND'. */
export type Contact_Phone_Number_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Phone_Number_Bool_Exp>>;
  _not?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Phone_Number_Bool_Exp>>;
  agency?: InputMaybe<Agency_Bool_Exp>;
  agency_id?: InputMaybe<Uuid_Comparison_Exp>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_phone_number" */
export enum Contact_Phone_Number_Constraint {
  /** unique or primary key constraint on columns "contact_id", "phone_number", "agency_id" */
  ContactPhoneNumberContactIdPhoneNumberAgencyIdKey = 'contact_phone_number_contact_id_phone_number_agency_id_key',
  /** unique or primary key constraint on columns "id" */
  ContactPhoneNumberPkey = 'contact_phone_number_pkey'
}

/** input type for inserting data into table "contact_phone_number" */
export type Contact_Phone_Number_Insert_Input = {
  agency?: InputMaybe<Agency_Obj_Rel_Insert_Input>;
  agency_id?: InputMaybe<Scalars['uuid']['input']>;
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Contact_Phone_Number_Max_Fields = {
  __typename?: 'contact_phone_number_max_fields';
  agency_id?: Maybe<Scalars['uuid']['output']>;
  contact_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "contact_phone_number" */
export type Contact_Phone_Number_Max_Order_By = {
  agency_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Phone_Number_Min_Fields = {
  __typename?: 'contact_phone_number_min_fields';
  agency_id?: Maybe<Scalars['uuid']['output']>;
  contact_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "contact_phone_number" */
export type Contact_Phone_Number_Min_Order_By = {
  agency_id?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contact_phone_number" */
export type Contact_Phone_Number_Mutation_Response = {
  __typename?: 'contact_phone_number_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Phone_Number>;
};

/** on_conflict condition type for table "contact_phone_number" */
export type Contact_Phone_Number_On_Conflict = {
  constraint: Contact_Phone_Number_Constraint;
  update_columns?: Array<Contact_Phone_Number_Update_Column>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_phone_number". */
export type Contact_Phone_Number_Order_By = {
  agency?: InputMaybe<Agency_Order_By>;
  agency_id?: InputMaybe<Order_By>;
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_phone_number */
export type Contact_Phone_Number_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contact_phone_number" */
export enum Contact_Phone_Number_Select_Column {
  /** column name */
  AgencyId = 'agency_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact_phone_number" */
export type Contact_Phone_Number_Set_Input = {
  agency_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "contact_phone_number" */
export type Contact_Phone_Number_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Phone_Number_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Phone_Number_Stream_Cursor_Value_Input = {
  agency_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "contact_phone_number" */
export enum Contact_Phone_Number_Update_Column {
  /** column name */
  AgencyId = 'agency_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contact_Phone_Number_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Phone_Number_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Phone_Number_Bool_Exp;
};

/** primary key columns input for table: contact */
export type Contact_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contact" */
export enum Contact_Select_Column {
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contact" */
export type Contact_Set_Input = {
  contact_type?: InputMaybe<Contact_Type_Enum>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "contact" */
export type Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Stream_Cursor_Value_Input = {
  contact_type?: InputMaybe<Contact_Type_Enum>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "contact_type" */
export type Contact_Type = {
  __typename?: 'contact_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "contact_type" */
export type Contact_Type_Aggregate = {
  __typename?: 'contact_type_aggregate';
  aggregate?: Maybe<Contact_Type_Aggregate_Fields>;
  nodes: Array<Contact_Type>;
};

/** aggregate fields of "contact_type" */
export type Contact_Type_Aggregate_Fields = {
  __typename?: 'contact_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Type_Max_Fields>;
  min?: Maybe<Contact_Type_Min_Fields>;
};


/** aggregate fields of "contact_type" */
export type Contact_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "contact_type". All fields are combined with a logical 'AND'. */
export type Contact_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Type_Bool_Exp>>;
  _not?: InputMaybe<Contact_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_type" */
export enum Contact_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  ContactTypePkey = 'contact_type_pkey'
}

export enum Contact_Type_Enum {
  Agent = 'agent'
}

/** Boolean expression to compare columns of type "contact_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contact_Type_Enum>;
  _in?: InputMaybe<Array<Contact_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Contact_Type_Enum>;
  _nin?: InputMaybe<Array<Contact_Type_Enum>>;
};

/** input type for inserting data into table "contact_type" */
export type Contact_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contact_Type_Max_Fields = {
  __typename?: 'contact_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Contact_Type_Min_Fields = {
  __typename?: 'contact_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "contact_type" */
export type Contact_Type_Mutation_Response = {
  __typename?: 'contact_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Type>;
};

/** on_conflict condition type for table "contact_type" */
export type Contact_Type_On_Conflict = {
  constraint: Contact_Type_Constraint;
  update_columns?: Array<Contact_Type_Update_Column>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_type". */
export type Contact_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_type */
export type Contact_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "contact_type" */
export enum Contact_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "contact_type" */
export type Contact_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contact_type" */
export type Contact_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "contact_type" */
export enum Contact_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Contact_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Type_Bool_Exp;
};

/** update columns of table "contact" */
export enum Contact_Update_Column {
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id'
}

export type Contact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Bool_Exp;
};

/** in conjunction with translate and entities it specifies what part of a table is translated ex title, description etc */
export type Content_Type = {
  __typename?: 'content_type';
  content_type: Scalars['String']['output'];
};

/** aggregated selection of "content_type" */
export type Content_Type_Aggregate = {
  __typename?: 'content_type_aggregate';
  aggregate?: Maybe<Content_Type_Aggregate_Fields>;
  nodes: Array<Content_Type>;
};

/** aggregate fields of "content_type" */
export type Content_Type_Aggregate_Fields = {
  __typename?: 'content_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Content_Type_Max_Fields>;
  min?: Maybe<Content_Type_Min_Fields>;
};


/** aggregate fields of "content_type" */
export type Content_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "content_type". All fields are combined with a logical 'AND'. */
export type Content_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Type_Bool_Exp>>;
  _not?: InputMaybe<Content_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Type_Bool_Exp>>;
  content_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_type" */
export enum Content_Type_Constraint {
  /** unique or primary key constraint on columns "content_type" */
  ContentTypePkey = 'content_type_pkey'
}

export enum Content_Type_Enum {
  Description = 'description',
  Notification = 'notification',
  SeoDescription = 'seo_description',
  SeoKeywords = 'seo_keywords',
  SeoTitle = 'seo_title',
  Title = 'title'
}

/** Boolean expression to compare columns of type "content_type_enum". All fields are combined with logical 'AND'. */
export type Content_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Content_Type_Enum>;
  _in?: InputMaybe<Array<Content_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Content_Type_Enum>;
  _nin?: InputMaybe<Array<Content_Type_Enum>>;
};

/** input type for inserting data into table "content_type" */
export type Content_Type_Insert_Input = {
  content_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Content_Type_Max_Fields = {
  __typename?: 'content_type_max_fields';
  content_type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Content_Type_Min_Fields = {
  __typename?: 'content_type_min_fields';
  content_type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "content_type" */
export type Content_Type_Mutation_Response = {
  __typename?: 'content_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Type>;
};

/** on_conflict condition type for table "content_type" */
export type Content_Type_On_Conflict = {
  constraint: Content_Type_Constraint;
  update_columns?: Array<Content_Type_Update_Column>;
  where?: InputMaybe<Content_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "content_type". */
export type Content_Type_Order_By = {
  content_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content_type */
export type Content_Type_Pk_Columns_Input = {
  content_type: Scalars['String']['input'];
};

/** select columns of table "content_type" */
export enum Content_Type_Select_Column {
  /** column name */
  ContentType = 'content_type'
}

/** input type for updating data in table "content_type" */
export type Content_Type_Set_Input = {
  content_type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "content_type" */
export type Content_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Type_Stream_Cursor_Value_Input = {
  content_type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "content_type" */
export enum Content_Type_Update_Column {
  /** column name */
  ContentType = 'content_type'
}

export type Content_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Type_Bool_Exp;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone_code: Scalars['String']['output'];
};

/** aggregated selection of "countries" */
export type Countries_Aggregate = {
  __typename?: 'countries_aggregate';
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "countries" */
export type Countries_Aggregate_Fields = {
  __typename?: 'countries_aggregate_fields';
  avg?: Maybe<Countries_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
  stddev?: Maybe<Countries_Stddev_Fields>;
  stddev_pop?: Maybe<Countries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Countries_Stddev_Samp_Fields>;
  sum?: Maybe<Countries_Sum_Fields>;
  var_pop?: Maybe<Countries_Var_Pop_Fields>;
  var_samp?: Maybe<Countries_Var_Samp_Fields>;
  variance?: Maybe<Countries_Variance_Fields>;
};


/** aggregate fields of "countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Countries_Avg_Fields = {
  __typename?: 'countries_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountriesPkey = 'countries_pkey'
}

/** input type for incrementing numeric columns in table "countries" */
export type Countries_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "countries" */
export type Countries_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: 'countries_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone_code?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: 'countries_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone_code?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "countries" */
export type Countries_Mutation_Response = {
  __typename?: 'countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** on_conflict condition type for table "countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: countries */
export type Countries_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneCode = 'phone_code'
}

/** input type for updating data in table "countries" */
export type Countries_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Countries_Stddev_Fields = {
  __typename?: 'countries_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Countries_Stddev_Pop_Fields = {
  __typename?: 'countries_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Countries_Stddev_Samp_Fields = {
  __typename?: 'countries_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Countries_Sum_Fields = {
  __typename?: 'countries_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "countries" */
export enum Countries_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneCode = 'phone_code'
}

export type Countries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Countries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Countries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Countries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Countries_Var_Pop_Fields = {
  __typename?: 'countries_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Countries_Var_Samp_Fields = {
  __typename?: 'countries_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Countries_Variance_Fields = {
  __typename?: 'countries_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  country_name: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  country_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "country_name" */
  CountryCountryNameKey = 'country_country_name_key',
  /** unique or primary key constraint on columns "id" */
  CountryPkey = 'country_pkey'
}

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  country_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  country_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  country_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  country_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  CountryName = 'country_name',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  country_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  country_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  CountryName = 'country_name',
  /** column name */
  Id = 'id'
}

export type Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** columns and relationships of "currency" */
export type Currency = {
  __typename?: 'currency';
  currency: Scalars['String']['output'];
};

export type Currency_ActionType = {
  __typename?: 'currency_ActionType';
  currency: Scalars['String']['output'];
};

/** aggregated selection of "currency" */
export type Currency_Aggregate = {
  __typename?: 'currency_aggregate';
  aggregate?: Maybe<Currency_Aggregate_Fields>;
  nodes: Array<Currency>;
};

/** aggregate fields of "currency" */
export type Currency_Aggregate_Fields = {
  __typename?: 'currency_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Currency_Max_Fields>;
  min?: Maybe<Currency_Min_Fields>;
};


/** aggregate fields of "currency" */
export type Currency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "currency". All fields are combined with a logical 'AND'. */
export type Currency_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Bool_Exp>>;
  _not?: InputMaybe<Currency_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_Bool_Exp>>;
  currency?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency" */
export enum Currency_Constraint {
  /** unique or primary key constraint on columns "currency" */
  CurrencyPkey = 'currency_pkey'
}

export enum Currency_Enum {
  Eur = 'EUR',
  Leke = 'LEKE',
  Usd = 'USD'
}

/** Boolean expression to compare columns of type "currency_enum". All fields are combined with logical 'AND'. */
export type Currency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Currency_Enum>;
  _in?: InputMaybe<Array<Currency_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Currency_Enum>;
  _nin?: InputMaybe<Array<Currency_Enum>>;
};

/** currency exchange rates updated */
export type Currency_Exchange_Rates = {
  __typename?: 'currency_exchange_rates';
  base_currency_code: Scalars['String']['output'];
  exchange_rate: Scalars['numeric']['output'];
  id: Scalars['Int']['output'];
  target_currency_code: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "currency_exchange_rates" */
export type Currency_Exchange_Rates_Aggregate = {
  __typename?: 'currency_exchange_rates_aggregate';
  aggregate?: Maybe<Currency_Exchange_Rates_Aggregate_Fields>;
  nodes: Array<Currency_Exchange_Rates>;
};

/** aggregate fields of "currency_exchange_rates" */
export type Currency_Exchange_Rates_Aggregate_Fields = {
  __typename?: 'currency_exchange_rates_aggregate_fields';
  avg?: Maybe<Currency_Exchange_Rates_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Currency_Exchange_Rates_Max_Fields>;
  min?: Maybe<Currency_Exchange_Rates_Min_Fields>;
  stddev?: Maybe<Currency_Exchange_Rates_Stddev_Fields>;
  stddev_pop?: Maybe<Currency_Exchange_Rates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currency_Exchange_Rates_Stddev_Samp_Fields>;
  sum?: Maybe<Currency_Exchange_Rates_Sum_Fields>;
  var_pop?: Maybe<Currency_Exchange_Rates_Var_Pop_Fields>;
  var_samp?: Maybe<Currency_Exchange_Rates_Var_Samp_Fields>;
  variance?: Maybe<Currency_Exchange_Rates_Variance_Fields>;
};


/** aggregate fields of "currency_exchange_rates" */
export type Currency_Exchange_Rates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Exchange_Rates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Currency_Exchange_Rates_Avg_Fields = {
  __typename?: 'currency_exchange_rates_avg_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "currency_exchange_rates". All fields are combined with a logical 'AND'. */
export type Currency_Exchange_Rates_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Exchange_Rates_Bool_Exp>>;
  _not?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_Exchange_Rates_Bool_Exp>>;
  base_currency_code?: InputMaybe<String_Comparison_Exp>;
  exchange_rate?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  target_currency_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency_exchange_rates" */
export enum Currency_Exchange_Rates_Constraint {
  /** unique or primary key constraint on columns "target_currency_code", "base_currency_code" */
  CurrencyExchangeRatesBaseCurrencyCodeTargetCurrencyKey = 'currency_exchange_rates_base_currency_code_target_currency__key',
  /** unique or primary key constraint on columns "id" */
  CurrencyExchangeRatesPkey = 'currency_exchange_rates_pkey'
}

/** input type for incrementing numeric columns in table "currency_exchange_rates" */
export type Currency_Exchange_Rates_Inc_Input = {
  exchange_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "currency_exchange_rates" */
export type Currency_Exchange_Rates_Insert_Input = {
  base_currency_code?: InputMaybe<Scalars['String']['input']>;
  exchange_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  target_currency_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Currency_Exchange_Rates_Max_Fields = {
  __typename?: 'currency_exchange_rates_max_fields';
  base_currency_code?: Maybe<Scalars['String']['output']>;
  exchange_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  target_currency_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Currency_Exchange_Rates_Min_Fields = {
  __typename?: 'currency_exchange_rates_min_fields';
  base_currency_code?: Maybe<Scalars['String']['output']>;
  exchange_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  target_currency_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "currency_exchange_rates" */
export type Currency_Exchange_Rates_Mutation_Response = {
  __typename?: 'currency_exchange_rates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_Exchange_Rates>;
};

/** on_conflict condition type for table "currency_exchange_rates" */
export type Currency_Exchange_Rates_On_Conflict = {
  constraint: Currency_Exchange_Rates_Constraint;
  update_columns?: Array<Currency_Exchange_Rates_Update_Column>;
  where?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
};

/** Ordering options when selecting data from "currency_exchange_rates". */
export type Currency_Exchange_Rates_Order_By = {
  base_currency_code?: InputMaybe<Order_By>;
  exchange_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_currency_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency_exchange_rates */
export type Currency_Exchange_Rates_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "currency_exchange_rates" */
export enum Currency_Exchange_Rates_Select_Column {
  /** column name */
  BaseCurrencyCode = 'base_currency_code',
  /** column name */
  ExchangeRate = 'exchange_rate',
  /** column name */
  Id = 'id',
  /** column name */
  TargetCurrencyCode = 'target_currency_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "currency_exchange_rates" */
export type Currency_Exchange_Rates_Set_Input = {
  base_currency_code?: InputMaybe<Scalars['String']['input']>;
  exchange_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  target_currency_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Currency_Exchange_Rates_Stddev_Fields = {
  __typename?: 'currency_exchange_rates_stddev_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Currency_Exchange_Rates_Stddev_Pop_Fields = {
  __typename?: 'currency_exchange_rates_stddev_pop_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Currency_Exchange_Rates_Stddev_Samp_Fields = {
  __typename?: 'currency_exchange_rates_stddev_samp_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "currency_exchange_rates" */
export type Currency_Exchange_Rates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Exchange_Rates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_Exchange_Rates_Stream_Cursor_Value_Input = {
  base_currency_code?: InputMaybe<Scalars['String']['input']>;
  exchange_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  target_currency_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Currency_Exchange_Rates_Sum_Fields = {
  __typename?: 'currency_exchange_rates_sum_fields';
  exchange_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "currency_exchange_rates" */
export enum Currency_Exchange_Rates_Update_Column {
  /** column name */
  BaseCurrencyCode = 'base_currency_code',
  /** column name */
  ExchangeRate = 'exchange_rate',
  /** column name */
  Id = 'id',
  /** column name */
  TargetCurrencyCode = 'target_currency_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Currency_Exchange_Rates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Currency_Exchange_Rates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Exchange_Rates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_Exchange_Rates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Currency_Exchange_Rates_Var_Pop_Fields = {
  __typename?: 'currency_exchange_rates_var_pop_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Currency_Exchange_Rates_Var_Samp_Fields = {
  __typename?: 'currency_exchange_rates_var_samp_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Currency_Exchange_Rates_Variance_Fields = {
  __typename?: 'currency_exchange_rates_variance_fields';
  exchange_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting data into table "currency" */
export type Currency_Insert_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Currency_Max_Fields = {
  __typename?: 'currency_max_fields';
  currency?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Currency_Min_Fields = {
  __typename?: 'currency_min_fields';
  currency?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "currency" */
export type Currency_Mutation_Response = {
  __typename?: 'currency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency>;
};

/** on_conflict condition type for table "currency" */
export type Currency_On_Conflict = {
  constraint: Currency_Constraint;
  update_columns?: Array<Currency_Update_Column>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

/** Ordering options when selecting data from "currency". */
export type Currency_Order_By = {
  currency?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency */
export type Currency_Pk_Columns_Input = {
  currency: Scalars['String']['input'];
};

/** select columns of table "currency" */
export enum Currency_Select_Column {
  /** column name */
  Currency = 'currency'
}

/** input type for updating data in table "currency" */
export type Currency_Set_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "currency" */
export type Currency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "currency" */
export enum Currency_Update_Column {
  /** column name */
  Currency = 'currency'
}

export type Currency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** is used in conjunction with translate to point out what translation is about, user, property etc */
export type Entities = {
  __typename?: 'entities';
  entity_type: Scalars['String']['output'];
};

/** aggregated selection of "entities" */
export type Entities_Aggregate = {
  __typename?: 'entities_aggregate';
  aggregate?: Maybe<Entities_Aggregate_Fields>;
  nodes: Array<Entities>;
};

/** aggregate fields of "entities" */
export type Entities_Aggregate_Fields = {
  __typename?: 'entities_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Entities_Max_Fields>;
  min?: Maybe<Entities_Min_Fields>;
};


/** aggregate fields of "entities" */
export type Entities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "entities". All fields are combined with a logical 'AND'. */
export type Entities_Bool_Exp = {
  _and?: InputMaybe<Array<Entities_Bool_Exp>>;
  _not?: InputMaybe<Entities_Bool_Exp>;
  _or?: InputMaybe<Array<Entities_Bool_Exp>>;
  entity_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entities" */
export enum Entities_Constraint {
  /** unique or primary key constraint on columns "entity_type" */
  EntitiesPkey = 'entities_pkey'
}

export enum Entities_Enum {
  Notification = 'notification',
  Property = 'property',
  User = 'user'
}

/** Boolean expression to compare columns of type "entities_enum". All fields are combined with logical 'AND'. */
export type Entities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Entities_Enum>;
  _in?: InputMaybe<Array<Entities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Entities_Enum>;
  _nin?: InputMaybe<Array<Entities_Enum>>;
};

/** input type for inserting data into table "entities" */
export type Entities_Insert_Input = {
  entity_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Entities_Max_Fields = {
  __typename?: 'entities_max_fields';
  entity_type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Entities_Min_Fields = {
  __typename?: 'entities_min_fields';
  entity_type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entities" */
export type Entities_Mutation_Response = {
  __typename?: 'entities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entities>;
};

/** on_conflict condition type for table "entities" */
export type Entities_On_Conflict = {
  constraint: Entities_Constraint;
  update_columns?: Array<Entities_Update_Column>;
  where?: InputMaybe<Entities_Bool_Exp>;
};

/** Ordering options when selecting data from "entities". */
export type Entities_Order_By = {
  entity_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entities */
export type Entities_Pk_Columns_Input = {
  entity_type: Scalars['String']['input'];
};

/** select columns of table "entities" */
export enum Entities_Select_Column {
  /** column name */
  EntityType = 'entity_type'
}

/** input type for updating data in table "entities" */
export type Entities_Set_Input = {
  entity_type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entities" */
export type Entities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entities_Stream_Cursor_Value_Input = {
  entity_type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entities" */
export enum Entities_Update_Column {
  /** column name */
  EntityType = 'entity_type'
}

export type Entities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entities_Bool_Exp;
};

/** columns and relationships of "feature" */
export type Feature = {
  __typename?: 'feature';
  feature_name_id?: Maybe<Scalars['uuid']['output']>;
  feature_type: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  property_features: Array<Property_Feature>;
  /** An aggregate relationship */
  property_features_aggregate: Property_Feature_Aggregate;
  /** An object relationship */
  text_content?: Maybe<Text_Content>;
};


/** columns and relationships of "feature" */
export type FeatureProperty_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


/** columns and relationships of "feature" */
export type FeatureProperty_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};

/** aggregated selection of "feature" */
export type Feature_Aggregate = {
  __typename?: 'feature_aggregate';
  aggregate?: Maybe<Feature_Aggregate_Fields>;
  nodes: Array<Feature>;
};

/** aggregate fields of "feature" */
export type Feature_Aggregate_Fields = {
  __typename?: 'feature_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Feature_Max_Fields>;
  min?: Maybe<Feature_Min_Fields>;
};


/** aggregate fields of "feature" */
export type Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "feature". All fields are combined with a logical 'AND'. */
export type Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Bool_Exp>>;
  feature_name_id?: InputMaybe<Uuid_Comparison_Exp>;
  feature_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  text_content?: InputMaybe<Text_Content_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "feature". All fields are combined with a logical 'AND'. */
export type Feature_Bool_Exp_Remote_Rel_Notification_Featurefeature = {
  _and?: InputMaybe<Array<Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Bool_Exp>>;
  feature_name_id?: InputMaybe<Uuid_Comparison_Exp>;
  feature_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_Notification_Featurefeature>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  text_content?: InputMaybe<Text_Content_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "feature". All fields are combined with a logical 'AND'. */
export type Feature_Bool_Exp_Remote_Rel_User_Filters_Featuresfeature = {
  _and?: InputMaybe<Array<Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Bool_Exp>>;
  feature_name_id?: InputMaybe<Uuid_Comparison_Exp>;
  feature_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_User_Filters_Featuresfeature>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  text_content?: InputMaybe<Text_Content_Bool_Exp>;
};

/** unique or primary key constraints on table "feature" */
export enum Feature_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturePkey = 'feature_pkey'
}

/** input type for inserting data into table "feature" */
export type Feature_Insert_Input = {
  feature_name_id?: InputMaybe<Scalars['uuid']['input']>;
  feature_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_features?: InputMaybe<Property_Feature_Arr_Rel_Insert_Input>;
  text_content?: InputMaybe<Text_Content_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Feature_Max_Fields = {
  __typename?: 'feature_max_fields';
  feature_name_id?: Maybe<Scalars['uuid']['output']>;
  feature_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Feature_Min_Fields = {
  __typename?: 'feature_min_fields';
  feature_name_id?: Maybe<Scalars['uuid']['output']>;
  feature_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "feature" */
export type Feature_Mutation_Response = {
  __typename?: 'feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature>;
};

/** on_conflict condition type for table "feature" */
export type Feature_On_Conflict = {
  constraint: Feature_Constraint;
  update_columns?: Array<Feature_Update_Column>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "feature". */
export type Feature_Order_By = {
  feature_name_id?: InputMaybe<Order_By>;
  feature_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Order_By>;
  text_content?: InputMaybe<Text_Content_Order_By>;
};

/** primary key columns input for table: feature */
export type Feature_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "feature" */
export enum Feature_Select_Column {
  /** column name */
  FeatureNameId = 'feature_name_id',
  /** column name */
  FeatureType = 'feature_type',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "feature" */
export type Feature_Set_Input = {
  feature_name_id?: InputMaybe<Scalars['uuid']['input']>;
  feature_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "feature" */
export type Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Stream_Cursor_Value_Input = {
  feature_name_id?: InputMaybe<Scalars['uuid']['input']>;
  feature_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "feature_type" */
export type Feature_Type = {
  __typename?: 'feature_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "feature_type" */
export type Feature_Type_Aggregate = {
  __typename?: 'feature_type_aggregate';
  aggregate?: Maybe<Feature_Type_Aggregate_Fields>;
  nodes: Array<Feature_Type>;
};

/** aggregate fields of "feature_type" */
export type Feature_Type_Aggregate_Fields = {
  __typename?: 'feature_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Feature_Type_Max_Fields>;
  min?: Maybe<Feature_Type_Min_Fields>;
};


/** aggregate fields of "feature_type" */
export type Feature_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "feature_type". All fields are combined with a logical 'AND'. */
export type Feature_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Type_Bool_Exp>>;
  _not?: InputMaybe<Feature_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feature_type" */
export enum Feature_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  FeatureTypePkey = 'feature_type_pkey'
}

/** input type for inserting data into table "feature_type" */
export type Feature_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Feature_Type_Max_Fields = {
  __typename?: 'feature_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Feature_Type_Min_Fields = {
  __typename?: 'feature_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "feature_type" */
export type Feature_Type_Mutation_Response = {
  __typename?: 'feature_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Type>;
};

/** on_conflict condition type for table "feature_type" */
export type Feature_Type_On_Conflict = {
  constraint: Feature_Type_Constraint;
  update_columns?: Array<Feature_Type_Update_Column>;
  where?: InputMaybe<Feature_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_type". */
export type Feature_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature_type */
export type Feature_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "feature_type" */
export enum Feature_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "feature_type" */
export type Feature_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "feature_type" */
export type Feature_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "feature_type" */
export enum Feature_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Feature_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Type_Bool_Exp;
};

/** update columns of table "feature" */
export enum Feature_Update_Column {
  /** column name */
  FeatureNameId = 'feature_name_id',
  /** column name */
  FeatureType = 'feature_type',
  /** column name */
  Id = 'id'
}

export type Feature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Bool_Exp;
};

/** columns and relationships of "storage.files" */
export type Files = {
  __typename?: 'files';
  /** An object relationship */
  bucket: Buckets;
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "storage.files" */
export type FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "storage.files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage.files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<Files_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
  stddev?: Maybe<Files_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Sum_Fields>;
  var_pop?: Maybe<Files_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Var_Samp_Fields>;
  variance?: Maybe<Files_Variance_Fields>;
};


/** aggregate fields of "storage.files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage.files" */
export type Files_Aggregate_Order_By = {
  avg?: InputMaybe<Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
  stddev?: InputMaybe<Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Files_Sum_Order_By>;
  var_pop?: InputMaybe<Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Files_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Files_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "storage.files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Avg_Fields = {
  __typename?: 'files_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "storage.files" */
export type Files_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  bucket?: InputMaybe<Buckets_Bool_Exp>;
  bucketId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  etag?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isUploaded?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage.files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Files_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Files_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Files_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "storage.files" */
export type Files_Inc_Input = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.files" */
export type Files_Insert_Input = {
  bucket?: InputMaybe<Buckets_Obj_Rel_Insert_Input>;
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "storage.files" */
export type Files_Max_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "storage.files" */
export type Files_Min_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage.files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage.files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "storage.files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.files". */
export type Files_Order_By = {
  bucket?: InputMaybe<Buckets_Order_By>;
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isUploaded?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage.files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Files_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "storage.files" */
export enum Files_Select_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId'
}

/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded'
}

/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded'
}

/** input type for updating data in table "storage.files" */
export type Files_Set_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Files_Stddev_Fields = {
  __typename?: 'files_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "storage.files" */
export type Files_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Stddev_Pop_Fields = {
  __typename?: 'files_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "storage.files" */
export type Files_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Stddev_Samp_Fields = {
  __typename?: 'files_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "storage.files" */
export type Files_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Files_Sum_Fields = {
  __typename?: 'files_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "storage.files" */
export type Files_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** update columns of table "storage.files" */
export enum Files_Update_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId'
}

export type Files_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Files_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Files_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Files_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Var_Pop_Fields = {
  __typename?: 'files_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "storage.files" */
export type Files_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Var_Samp_Fields = {
  __typename?: 'files_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "storage.files" */
export type Files_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Variance_Fields = {
  __typename?: 'files_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "storage.files" */
export type Files_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "furnishing_status" */
export type Furnishing_Status = {
  __typename?: 'furnishing_status';
  status: Scalars['String']['output'];
};

export type Furnishing_Status_ActionType = {
  __typename?: 'furnishing_status_ActionType';
  status: Scalars['String']['output'];
};

/** aggregated selection of "furnishing_status" */
export type Furnishing_Status_Aggregate = {
  __typename?: 'furnishing_status_aggregate';
  aggregate?: Maybe<Furnishing_Status_Aggregate_Fields>;
  nodes: Array<Furnishing_Status>;
};

/** aggregate fields of "furnishing_status" */
export type Furnishing_Status_Aggregate_Fields = {
  __typename?: 'furnishing_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Furnishing_Status_Max_Fields>;
  min?: Maybe<Furnishing_Status_Min_Fields>;
};


/** aggregate fields of "furnishing_status" */
export type Furnishing_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Furnishing_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "furnishing_status". All fields are combined with a logical 'AND'. */
export type Furnishing_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Furnishing_Status_Bool_Exp>>;
  _not?: InputMaybe<Furnishing_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Furnishing_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "furnishing_status" */
export enum Furnishing_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  FurnishingStatusPkey = 'furnishing_status_pkey'
}

export enum Furnishing_Status_Enum {
  FullyFurnished = 'fully_furnished',
  PartiallyFurnished = 'partially_furnished',
  Unfurnished = 'unfurnished'
}

/** Boolean expression to compare columns of type "furnishing_status_enum". All fields are combined with logical 'AND'. */
export type Furnishing_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Furnishing_Status_Enum>;
  _in?: InputMaybe<Array<Furnishing_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Furnishing_Status_Enum>;
  _nin?: InputMaybe<Array<Furnishing_Status_Enum>>;
};

/** input type for inserting data into table "furnishing_status" */
export type Furnishing_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Furnishing_Status_Max_Fields = {
  __typename?: 'furnishing_status_max_fields';
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Furnishing_Status_Min_Fields = {
  __typename?: 'furnishing_status_min_fields';
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "furnishing_status" */
export type Furnishing_Status_Mutation_Response = {
  __typename?: 'furnishing_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Furnishing_Status>;
};

/** on_conflict condition type for table "furnishing_status" */
export type Furnishing_Status_On_Conflict = {
  constraint: Furnishing_Status_Constraint;
  update_columns?: Array<Furnishing_Status_Update_Column>;
  where?: InputMaybe<Furnishing_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "furnishing_status". */
export type Furnishing_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: furnishing_status */
export type Furnishing_Status_Pk_Columns_Input = {
  status: Scalars['String']['input'];
};

/** select columns of table "furnishing_status" */
export enum Furnishing_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "furnishing_status" */
export type Furnishing_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "furnishing_status" */
export type Furnishing_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Furnishing_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Furnishing_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "furnishing_status" */
export enum Furnishing_Status_Update_Column {
  /** column name */
  Status = 'status'
}

export type Furnishing_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Furnishing_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Furnishing_Status_Bool_Exp;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']['input']>;
  _gt?: InputMaybe<Scalars['geography']['input']>;
  _gte?: InputMaybe<Scalars['geography']['input']>;
  _in?: InputMaybe<Array<Scalars['geography']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geography']['input']>;
  _lte?: InputMaybe<Scalars['geography']['input']>;
  _neq?: InputMaybe<Scalars['geography']['input']>;
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']['input']>;
  _gt?: InputMaybe<Scalars['geometry']['input']>;
  _gte?: InputMaybe<Scalars['geometry']['input']>;
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geometry']['input']>;
  _lte?: InputMaybe<Scalars['geometry']['input']>;
  _neq?: InputMaybe<Scalars['geometry']['input']>;
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>;
};

/** columns and relationships of "heatingType" */
export type HeatingType = {
  __typename?: 'heatingType';
  type: Scalars['String']['output'];
};

/** aggregated selection of "heatingType" */
export type HeatingType_Aggregate = {
  __typename?: 'heatingType_aggregate';
  aggregate?: Maybe<HeatingType_Aggregate_Fields>;
  nodes: Array<HeatingType>;
};

/** aggregate fields of "heatingType" */
export type HeatingType_Aggregate_Fields = {
  __typename?: 'heatingType_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<HeatingType_Max_Fields>;
  min?: Maybe<HeatingType_Min_Fields>;
};


/** aggregate fields of "heatingType" */
export type HeatingType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HeatingType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "heatingType". All fields are combined with a logical 'AND'. */
export type HeatingType_Bool_Exp = {
  _and?: InputMaybe<Array<HeatingType_Bool_Exp>>;
  _not?: InputMaybe<HeatingType_Bool_Exp>;
  _or?: InputMaybe<Array<HeatingType_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "heatingType" */
export enum HeatingType_Constraint {
  /** unique or primary key constraint on columns "type" */
  HeatingTypePkey = 'heatingType_pkey'
}

/** input type for inserting data into table "heatingType" */
export type HeatingType_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type HeatingType_Max_Fields = {
  __typename?: 'heatingType_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type HeatingType_Min_Fields = {
  __typename?: 'heatingType_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "heatingType" */
export type HeatingType_Mutation_Response = {
  __typename?: 'heatingType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<HeatingType>;
};

/** on_conflict condition type for table "heatingType" */
export type HeatingType_On_Conflict = {
  constraint: HeatingType_Constraint;
  update_columns?: Array<HeatingType_Update_Column>;
  where?: InputMaybe<HeatingType_Bool_Exp>;
};

/** Ordering options when selecting data from "heatingType". */
export type HeatingType_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: heatingType */
export type HeatingType_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "heatingType" */
export enum HeatingType_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "heatingType" */
export type HeatingType_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "heatingType" */
export type HeatingType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HeatingType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HeatingType_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "heatingType" */
export enum HeatingType_Update_Column {
  /** column name */
  Type = 'type'
}

export type HeatingType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HeatingType_Set_Input>;
  /** filter the rows which have to be updated */
  where: HeatingType_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "languages" */
export type Languages = {
  __typename?: 'languages';
  language_code: Scalars['bpchar']['output'];
  language_id: Scalars['Int']['output'];
  language_name: Scalars['String']['output'];
};

/** aggregated selection of "languages" */
export type Languages_Aggregate = {
  __typename?: 'languages_aggregate';
  aggregate?: Maybe<Languages_Aggregate_Fields>;
  nodes: Array<Languages>;
};

/** aggregate fields of "languages" */
export type Languages_Aggregate_Fields = {
  __typename?: 'languages_aggregate_fields';
  avg?: Maybe<Languages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Languages_Max_Fields>;
  min?: Maybe<Languages_Min_Fields>;
  stddev?: Maybe<Languages_Stddev_Fields>;
  stddev_pop?: Maybe<Languages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Languages_Stddev_Samp_Fields>;
  sum?: Maybe<Languages_Sum_Fields>;
  var_pop?: Maybe<Languages_Var_Pop_Fields>;
  var_samp?: Maybe<Languages_Var_Samp_Fields>;
  variance?: Maybe<Languages_Variance_Fields>;
};


/** aggregate fields of "languages" */
export type Languages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Languages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Languages_Avg_Fields = {
  __typename?: 'languages_avg_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "languages". All fields are combined with a logical 'AND'. */
export type Languages_Bool_Exp = {
  _and?: InputMaybe<Array<Languages_Bool_Exp>>;
  _not?: InputMaybe<Languages_Bool_Exp>;
  _or?: InputMaybe<Array<Languages_Bool_Exp>>;
  language_code?: InputMaybe<Bpchar_Comparison_Exp>;
  language_id?: InputMaybe<Int_Comparison_Exp>;
  language_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "languages" */
export enum Languages_Constraint {
  /** unique or primary key constraint on columns "language_name" */
  LanguagesLanguageNameKey = 'languages_language_name_key',
  /** unique or primary key constraint on columns "language_id" */
  LanguagesPkey = 'languages_pkey'
}

/** input type for incrementing numeric columns in table "languages" */
export type Languages_Inc_Input = {
  language_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "languages" */
export type Languages_Insert_Input = {
  language_code?: InputMaybe<Scalars['bpchar']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  language_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Languages_Max_Fields = {
  __typename?: 'languages_max_fields';
  language_code?: Maybe<Scalars['bpchar']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  language_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Languages_Min_Fields = {
  __typename?: 'languages_min_fields';
  language_code?: Maybe<Scalars['bpchar']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  language_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "languages" */
export type Languages_Mutation_Response = {
  __typename?: 'languages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Languages>;
};

/** input type for inserting object relation for remote table "languages" */
export type Languages_Obj_Rel_Insert_Input = {
  data: Languages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Languages_On_Conflict>;
};

/** on_conflict condition type for table "languages" */
export type Languages_On_Conflict = {
  constraint: Languages_Constraint;
  update_columns?: Array<Languages_Update_Column>;
  where?: InputMaybe<Languages_Bool_Exp>;
};

/** Ordering options when selecting data from "languages". */
export type Languages_Order_By = {
  language_code?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  language_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: languages */
export type Languages_Pk_Columns_Input = {
  language_id: Scalars['Int']['input'];
};

/** select columns of table "languages" */
export enum Languages_Select_Column {
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  LanguageName = 'language_name'
}

/** input type for updating data in table "languages" */
export type Languages_Set_Input = {
  language_code?: InputMaybe<Scalars['bpchar']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  language_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Languages_Stddev_Fields = {
  __typename?: 'languages_stddev_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Languages_Stddev_Pop_Fields = {
  __typename?: 'languages_stddev_pop_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Languages_Stddev_Samp_Fields = {
  __typename?: 'languages_stddev_samp_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "languages" */
export type Languages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Languages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Languages_Stream_Cursor_Value_Input = {
  language_code?: InputMaybe<Scalars['bpchar']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  language_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Languages_Sum_Fields = {
  __typename?: 'languages_sum_fields';
  language_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "languages" */
export enum Languages_Update_Column {
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  LanguageName = 'language_name'
}

export type Languages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Languages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Languages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Languages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Languages_Var_Pop_Fields = {
  __typename?: 'languages_var_pop_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Languages_Var_Samp_Fields = {
  __typename?: 'languages_var_samp_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Languages_Variance_Fields = {
  __typename?: 'languages_variance_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "location" */
export type Location = {
  __typename?: 'location';
  administrative_area_level_1?: Maybe<Scalars['String']['output']>;
  administrative_area_level_2?: Maybe<Scalars['String']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  confidence?: Maybe<Scalars['Boolean']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  formatted_address?: Maybe<Scalars['String']['output']>;
  geom?: Maybe<Scalars['geography']['output']>;
  id: Scalars['uuid']['output'];
  lat: Scalars['float8']['output'];
  lng: Scalars['float8']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  raw_location_id?: Maybe<Scalars['uuid']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: 'location_aggregate';
  aggregate?: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
};

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: 'location_aggregate_fields';
  avg?: Maybe<Location_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Location_Max_Fields>;
  min?: Maybe<Location_Min_Fields>;
  stddev?: Maybe<Location_Stddev_Fields>;
  stddev_pop?: Maybe<Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Location_Stddev_Samp_Fields>;
  sum?: Maybe<Location_Sum_Fields>;
  var_pop?: Maybe<Location_Var_Pop_Fields>;
  var_samp?: Maybe<Location_Var_Samp_Fields>;
  variance?: Maybe<Location_Variance_Fields>;
};


/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Location_Avg_Fields = {
  __typename?: 'location_avg_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: InputMaybe<Array<Location_Bool_Exp>>;
  _not?: InputMaybe<Location_Bool_Exp>;
  _or?: InputMaybe<Array<Location_Bool_Exp>>;
  administrative_area_level_1?: InputMaybe<String_Comparison_Exp>;
  administrative_area_level_2?: InputMaybe<String_Comparison_Exp>;
  city_zone_id?: InputMaybe<Uuid_Comparison_Exp>;
  confidence?: InputMaybe<Boolean_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  formatted_address?: InputMaybe<String_Comparison_Exp>;
  geom?: InputMaybe<Geography_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  place_id?: InputMaybe<String_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
  raw_location_id?: InputMaybe<Uuid_Comparison_Exp>;
  route?: InputMaybe<String_Comparison_Exp>;
  street_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** input type for incrementing numeric columns in table "location" */
export type Location_Inc_Input = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  administrative_area_level_1?: InputMaybe<Scalars['String']['input']>;
  administrative_area_level_2?: InputMaybe<Scalars['String']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  confidence?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  geom?: InputMaybe<Scalars['geography']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  raw_location_id?: InputMaybe<Scalars['uuid']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: 'location_max_fields';
  administrative_area_level_1?: Maybe<Scalars['String']['output']>;
  administrative_area_level_2?: Maybe<Scalars['String']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  formatted_address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  raw_location_id?: Maybe<Scalars['uuid']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: 'location_min_fields';
  administrative_area_level_1?: Maybe<Scalars['String']['output']>;
  administrative_area_level_2?: Maybe<Scalars['String']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  formatted_address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  raw_location_id?: Maybe<Scalars['uuid']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
};

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** on_conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint;
  update_columns?: Array<Location_Update_Column>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  administrative_area_level_1?: InputMaybe<Order_By>;
  administrative_area_level_2?: InputMaybe<Order_By>;
  city_zone_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  formatted_address?: InputMaybe<Order_By>;
  geom?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  raw_location_id?: InputMaybe<Order_By>;
  route?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  AdministrativeAreaLevel_1 = 'administrative_area_level_1',
  /** column name */
  AdministrativeAreaLevel_2 = 'administrative_area_level_2',
  /** column name */
  CityZoneId = 'city_zone_id',
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Geom = 'geom',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Locality = 'locality',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  RawLocationId = 'raw_location_id',
  /** column name */
  Route = 'route',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  administrative_area_level_1?: InputMaybe<Scalars['String']['input']>;
  administrative_area_level_2?: InputMaybe<Scalars['String']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  confidence?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  geom?: InputMaybe<Scalars['geography']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  raw_location_id?: InputMaybe<Scalars['uuid']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Location_Stddev_Fields = {
  __typename?: 'location_stddev_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Location_Stddev_Pop_Fields = {
  __typename?: 'location_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Location_Stddev_Samp_Fields = {
  __typename?: 'location_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "location" */
export type Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Stream_Cursor_Value_Input = {
  administrative_area_level_1?: InputMaybe<Scalars['String']['input']>;
  administrative_area_level_2?: InputMaybe<Scalars['String']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  confidence?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  geom?: InputMaybe<Scalars['geography']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  raw_location_id?: InputMaybe<Scalars['uuid']['input']>;
  route?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Location_Sum_Fields = {
  __typename?: 'location_sum_fields';
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  AdministrativeAreaLevel_1 = 'administrative_area_level_1',
  /** column name */
  AdministrativeAreaLevel_2 = 'administrative_area_level_2',
  /** column name */
  CityZoneId = 'city_zone_id',
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormattedAddress = 'formatted_address',
  /** column name */
  Geom = 'geom',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Locality = 'locality',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  RawLocationId = 'raw_location_id',
  /** column name */
  Route = 'route',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Var_Pop_Fields = {
  __typename?: 'location_var_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Location_Var_Samp_Fields = {
  __typename?: 'location_var_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Location_Variance_Fields = {
  __typename?: 'location_variance_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type Map_Location = {
  __typename?: 'map_location';
  lat?: Maybe<Scalars['String']['output']>;
  lng?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "membership_package" */
export type Membership_Package = {
  __typename?: 'membership_package';
  active: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  duration_in_days?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  number_of_prompts?: Maybe<Scalars['Int']['output']>;
  price: Scalars['numeric']['output'];
};

/** aggregated selection of "membership_package" */
export type Membership_Package_Aggregate = {
  __typename?: 'membership_package_aggregate';
  aggregate?: Maybe<Membership_Package_Aggregate_Fields>;
  nodes: Array<Membership_Package>;
};

/** aggregate fields of "membership_package" */
export type Membership_Package_Aggregate_Fields = {
  __typename?: 'membership_package_aggregate_fields';
  avg?: Maybe<Membership_Package_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Membership_Package_Max_Fields>;
  min?: Maybe<Membership_Package_Min_Fields>;
  stddev?: Maybe<Membership_Package_Stddev_Fields>;
  stddev_pop?: Maybe<Membership_Package_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Membership_Package_Stddev_Samp_Fields>;
  sum?: Maybe<Membership_Package_Sum_Fields>;
  var_pop?: Maybe<Membership_Package_Var_Pop_Fields>;
  var_samp?: Maybe<Membership_Package_Var_Samp_Fields>;
  variance?: Maybe<Membership_Package_Variance_Fields>;
};


/** aggregate fields of "membership_package" */
export type Membership_Package_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Membership_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Membership_Package_Avg_Fields = {
  __typename?: 'membership_package_avg_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "membership_package". All fields are combined with a logical 'AND'. */
export type Membership_Package_Bool_Exp = {
  _and?: InputMaybe<Array<Membership_Package_Bool_Exp>>;
  _not?: InputMaybe<Membership_Package_Bool_Exp>;
  _or?: InputMaybe<Array<Membership_Package_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration_in_days?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  number_of_prompts?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "membership_package" */
export enum Membership_Package_Constraint {
  /** unique or primary key constraint on columns "id" */
  MembershipPackagePkey = 'membership_package_pkey'
}

/** input type for incrementing numeric columns in table "membership_package" */
export type Membership_Package_Inc_Input = {
  duration_in_days?: InputMaybe<Scalars['Int']['input']>;
  number_of_prompts?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "membership_package" */
export type Membership_Package_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration_in_days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number_of_prompts?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Membership_Package_Max_Fields = {
  __typename?: 'membership_package_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  duration_in_days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number_of_prompts?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Membership_Package_Min_Fields = {
  __typename?: 'membership_package_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  duration_in_days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number_of_prompts?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "membership_package" */
export type Membership_Package_Mutation_Response = {
  __typename?: 'membership_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Membership_Package>;
};

/** input type for inserting object relation for remote table "membership_package" */
export type Membership_Package_Obj_Rel_Insert_Input = {
  data: Membership_Package_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Membership_Package_On_Conflict>;
};

/** on_conflict condition type for table "membership_package" */
export type Membership_Package_On_Conflict = {
  constraint: Membership_Package_Constraint;
  update_columns?: Array<Membership_Package_Update_Column>;
  where?: InputMaybe<Membership_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "membership_package". */
export type Membership_Package_Order_By = {
  active?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration_in_days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number_of_prompts?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: membership_package */
export type Membership_Package_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "membership_package" */
export enum Membership_Package_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  DurationInDays = 'duration_in_days',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfPrompts = 'number_of_prompts',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "membership_package" */
export type Membership_Package_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration_in_days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number_of_prompts?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Membership_Package_Stddev_Fields = {
  __typename?: 'membership_package_stddev_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Membership_Package_Stddev_Pop_Fields = {
  __typename?: 'membership_package_stddev_pop_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Membership_Package_Stddev_Samp_Fields = {
  __typename?: 'membership_package_stddev_samp_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "membership_package" */
export type Membership_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Membership_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Membership_Package_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration_in_days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number_of_prompts?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Membership_Package_Sum_Fields = {
  __typename?: 'membership_package_sum_fields';
  duration_in_days?: Maybe<Scalars['Int']['output']>;
  number_of_prompts?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "membership_package" */
export enum Membership_Package_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  DurationInDays = 'duration_in_days',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfPrompts = 'number_of_prompts',
  /** column name */
  Price = 'price'
}

export type Membership_Package_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Membership_Package_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Membership_Package_Set_Input>;
  /** filter the rows which have to be updated */
  where: Membership_Package_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Membership_Package_Var_Pop_Fields = {
  __typename?: 'membership_package_var_pop_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Membership_Package_Var_Samp_Fields = {
  __typename?: 'membership_package_var_samp_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Membership_Package_Variance_Fields = {
  __typename?: 'membership_package_variance_fields';
  duration_in_days?: Maybe<Scalars['Float']['output']>;
  number_of_prompts?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete single row from the table: "auth.providers" */
  deleteAuthProvider?: Maybe<AuthProviders>;
  /** delete single row from the table: "auth.provider_requests" */
  deleteAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** delete data from the table: "auth.provider_requests" */
  deleteAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** delete data from the table: "auth.providers" */
  deleteAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** delete single row from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** delete data from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  deleteAuthRole?: Maybe<AuthRoles>;
  /** delete data from the table: "auth.roles" */
  deleteAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** delete single row from the table: "auth.user_providers" */
  deleteAuthUserProvider?: Maybe<AuthUserProviders>;
  /** delete data from the table: "auth.user_providers" */
  deleteAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** delete single row from the table: "auth.user_roles" */
  deleteAuthUserRole?: Maybe<AuthUserRoles>;
  /** delete data from the table: "auth.user_roles" */
  deleteAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** delete single row from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** delete data from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** delete single row from the table: "storage.buckets" */
  deleteBucket?: Maybe<Buckets>;
  /** delete data from the table: "storage.buckets" */
  deleteBuckets?: Maybe<Buckets_Mutation_Response>;
  /** delete single row from the table: "storage.files" */
  deleteFile?: Maybe<Files>;
  /** delete data from the table: "storage.files" */
  deleteFiles?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "auth.users" */
  deleteUser?: Maybe<Users>;
  /** delete data from the table: "auth.users" */
  deleteUsers?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "storage.virus" */
  deleteVirus?: Maybe<Virus>;
  /** delete data from the table: "storage.virus" */
  deleteViruses?: Maybe<Virus_Mutation_Response>;
  /** delete data from the table: "address_types" */
  delete_address_types?: Maybe<Address_Types_Mutation_Response>;
  /** delete single row from the table: "address_types" */
  delete_address_types_by_pk?: Maybe<Address_Types>;
  /** delete data from the table: "agency" */
  delete_agency?: Maybe<Agency_Mutation_Response>;
  /** delete single row from the table: "agency" */
  delete_agency_by_pk?: Maybe<Agency>;
  /** delete data from the table: "amenities" */
  delete_amenities?: Maybe<Amenities_Mutation_Response>;
  /** delete single row from the table: "amenities" */
  delete_amenities_by_pk?: Maybe<Amenities>;
  /** delete data from the table: "chat_response_type" */
  delete_chat_response_type?: Maybe<Chat_Response_Type_Mutation_Response>;
  /** delete single row from the table: "chat_response_type" */
  delete_chat_response_type_by_pk?: Maybe<Chat_Response_Type>;
  /** delete data from the table: "city" */
  delete_city?: Maybe<City_Mutation_Response>;
  /** delete single row from the table: "city" */
  delete_city_by_pk?: Maybe<City>;
  /** delete data from the table: "city_zone" */
  delete_city_zone?: Maybe<City_Zone_Mutation_Response>;
  /** delete single row from the table: "city_zone" */
  delete_city_zone_by_pk?: Maybe<City_Zone>;
  /** delete data from the table: "city_zone_origin" */
  delete_city_zone_origin?: Maybe<City_Zone_Origin_Mutation_Response>;
  /** delete single row from the table: "city_zone_origin" */
  delete_city_zone_origin_by_pk?: Maybe<City_Zone_Origin>;
  /** delete data from the table: "construction_status" */
  delete_construction_status?: Maybe<Construction_Status_Mutation_Response>;
  /** delete single row from the table: "construction_status" */
  delete_construction_status_by_pk?: Maybe<Construction_Status>;
  /** delete data from the table: "contact" */
  delete_contact?: Maybe<Contact_Mutation_Response>;
  /** delete single row from the table: "contact" */
  delete_contact_by_pk?: Maybe<Contact>;
  /** delete data from the table: "contact_phone_number" */
  delete_contact_phone_number?: Maybe<Contact_Phone_Number_Mutation_Response>;
  /** delete single row from the table: "contact_phone_number" */
  delete_contact_phone_number_by_pk?: Maybe<Contact_Phone_Number>;
  /** delete data from the table: "contact_type" */
  delete_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** delete single row from the table: "contact_type" */
  delete_contact_type_by_pk?: Maybe<Contact_Type>;
  /** delete data from the table: "content_type" */
  delete_content_type?: Maybe<Content_Type_Mutation_Response>;
  /** delete single row from the table: "content_type" */
  delete_content_type_by_pk?: Maybe<Content_Type>;
  /** delete data from the table: "countries" */
  delete_countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "countries" */
  delete_countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "currency" */
  delete_currency?: Maybe<Currency_Mutation_Response>;
  /** delete single row from the table: "currency" */
  delete_currency_by_pk?: Maybe<Currency>;
  /** delete data from the table: "currency_exchange_rates" */
  delete_currency_exchange_rates?: Maybe<Currency_Exchange_Rates_Mutation_Response>;
  /** delete single row from the table: "currency_exchange_rates" */
  delete_currency_exchange_rates_by_pk?: Maybe<Currency_Exchange_Rates>;
  /** delete data from the table: "entities" */
  delete_entities?: Maybe<Entities_Mutation_Response>;
  /** delete single row from the table: "entities" */
  delete_entities_by_pk?: Maybe<Entities>;
  /** delete data from the table: "feature" */
  delete_feature?: Maybe<Feature_Mutation_Response>;
  /** delete single row from the table: "feature" */
  delete_feature_by_pk?: Maybe<Feature>;
  /** delete data from the table: "feature_type" */
  delete_feature_type?: Maybe<Feature_Type_Mutation_Response>;
  /** delete single row from the table: "feature_type" */
  delete_feature_type_by_pk?: Maybe<Feature_Type>;
  /** delete data from the table: "furnishing_status" */
  delete_furnishing_status?: Maybe<Furnishing_Status_Mutation_Response>;
  /** delete single row from the table: "furnishing_status" */
  delete_furnishing_status_by_pk?: Maybe<Furnishing_Status>;
  /** delete data from the table: "heatingType" */
  delete_heatingType?: Maybe<HeatingType_Mutation_Response>;
  /** delete single row from the table: "heatingType" */
  delete_heatingType_by_pk?: Maybe<HeatingType>;
  /** delete data from the table: "languages" */
  delete_languages?: Maybe<Languages_Mutation_Response>;
  /** delete single row from the table: "languages" */
  delete_languages_by_pk?: Maybe<Languages>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>;
  /** delete data from the table: "membership_package" */
  delete_membership_package?: Maybe<Membership_Package_Mutation_Response>;
  /** delete single row from the table: "membership_package" */
  delete_membership_package_by_pk?: Maybe<Membership_Package>;
  /** delete data from the table: "natural_language_queries" */
  delete_natural_language_queries?: Maybe<Natural_Language_Queries_Mutation_Response>;
  /** delete single row from the table: "natural_language_queries" */
  delete_natural_language_queries_by_pk?: Maybe<Natural_Language_Queries>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>;
  /** delete data from the table: "notification_amenities" */
  delete_notification_amenities?: Maybe<Notification_Amenities_Mutation_Response>;
  /** delete single row from the table: "notification_amenities" */
  delete_notification_amenities_by_pk?: Maybe<Notification_Amenities>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "notification_feature" */
  delete_notification_feature?: Maybe<Notification_Feature_Mutation_Response>;
  /** delete single row from the table: "notification_feature" */
  delete_notification_feature_by_pk?: Maybe<Notification_Feature>;
  /** delete data from the table: "notification_property_type" */
  delete_notification_property_type?: Maybe<Notification_Property_Type_Mutation_Response>;
  /** delete single row from the table: "notification_property_type" */
  delete_notification_property_type_by_pk?: Maybe<Notification_Property_Type>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<Notification_Type>;
  /** delete data from the table: "order" */
  delete_order?: Maybe<Order_Mutation_Response>;
  /** delete single row from the table: "order" */
  delete_order_by_pk?: Maybe<Order>;
  /** delete data from the table: "parking_type" */
  delete_parking_type?: Maybe<Parking_Type_Mutation_Response>;
  /** delete single row from the table: "parking_type" */
  delete_parking_type_by_pk?: Maybe<Parking_Type>;
  /** delete data from the table: "property" */
  delete_property?: Maybe<Property_Mutation_Response>;
  /** delete data from the table: "property_amenities" */
  delete_property_amenities?: Maybe<Property_Amenities_Mutation_Response>;
  /** delete single row from the table: "property_amenities" */
  delete_property_amenities_by_pk?: Maybe<Property_Amenities>;
  /** delete single row from the table: "property" */
  delete_property_by_pk?: Maybe<Property>;
  /** delete data from the table: "property_cluster" */
  delete_property_cluster?: Maybe<Property_Cluster_Mutation_Response>;
  /** delete single row from the table: "property_cluster" */
  delete_property_cluster_by_pk?: Maybe<Property_Cluster>;
  /** delete data from the table: "property_composition" */
  delete_property_composition?: Maybe<Property_Composition_Mutation_Response>;
  /** delete single row from the table: "property_composition" */
  delete_property_composition_by_pk?: Maybe<Property_Composition>;
  /** delete data from the table: "property_feature" */
  delete_property_feature?: Maybe<Property_Feature_Mutation_Response>;
  /** delete single row from the table: "property_feature" */
  delete_property_feature_by_pk?: Maybe<Property_Feature>;
  /** delete data from the table: "property_image" */
  delete_property_image?: Maybe<Property_Image_Mutation_Response>;
  /** delete single row from the table: "property_image" */
  delete_property_image_by_pk?: Maybe<Property_Image>;
  /** delete data from the table: "property_list" */
  delete_property_list?: Maybe<Property_List_Mutation_Response>;
  /** delete single row from the table: "property_list" */
  delete_property_list_by_pk?: Maybe<Property_List>;
  /** delete data from the table: "property_origin" */
  delete_property_origin?: Maybe<Property_Origin_Mutation_Response>;
  /** delete single row from the table: "property_origin" */
  delete_property_origin_by_pk?: Maybe<Property_Origin>;
  /** delete data from the table: "property_origin_type" */
  delete_property_origin_type?: Maybe<Property_Origin_Type_Mutation_Response>;
  /** delete single row from the table: "property_origin_type" */
  delete_property_origin_type_by_pk?: Maybe<Property_Origin_Type>;
  /** delete data from the table: "property_price_history" */
  delete_property_price_history?: Maybe<Property_Price_History_Mutation_Response>;
  /** delete single row from the table: "property_price_history" */
  delete_property_price_history_by_pk?: Maybe<Property_Price_History>;
  /** delete data from the table: "property_source" */
  delete_property_source?: Maybe<Property_Source_Mutation_Response>;
  /** delete single row from the table: "property_source" */
  delete_property_source_by_pk?: Maybe<Property_Source>;
  /** delete data from the table: "property_status" */
  delete_property_status?: Maybe<Property_Status_Mutation_Response>;
  /** delete single row from the table: "property_status" */
  delete_property_status_by_pk?: Maybe<Property_Status>;
  /** delete data from the table: "property_type" */
  delete_property_type?: Maybe<Property_Type_Mutation_Response>;
  /** delete single row from the table: "property_type" */
  delete_property_type_by_pk?: Maybe<Property_Type>;
  /** delete data from the table: "property_views" */
  delete_property_views?: Maybe<Property_Views_Mutation_Response>;
  /** delete single row from the table: "property_views" */
  delete_property_views_by_pk?: Maybe<Property_Views>;
  /** delete data from the table: "queries_property_result" */
  delete_queries_property_result?: Maybe<Queries_Property_Result_Mutation_Response>;
  /** delete single row from the table: "queries_property_result" */
  delete_queries_property_result_by_pk?: Maybe<Queries_Property_Result>;
  /** delete data from the table: "query_classification" */
  delete_query_classification?: Maybe<Query_Classification_Mutation_Response>;
  /** delete single row from the table: "query_classification" */
  delete_query_classification_by_pk?: Maybe<Query_Classification>;
  /** delete data from the table: "raw_location" */
  delete_raw_location?: Maybe<Raw_Location_Mutation_Response>;
  /** delete single row from the table: "raw_location" */
  delete_raw_location_by_pk?: Maybe<Raw_Location>;
  /** delete data from the table: "redirect" */
  delete_redirect?: Maybe<Redirect_Mutation_Response>;
  /** delete single row from the table: "redirect" */
  delete_redirect_by_pk?: Maybe<Redirect>;
  /** delete data from the table: "search_context" */
  delete_search_context?: Maybe<Search_Context_Mutation_Response>;
  /** delete single row from the table: "search_context" */
  delete_search_context_by_pk?: Maybe<Search_Context>;
  /** delete data from the table: "search_domain" */
  delete_search_domain?: Maybe<Search_Domain_Mutation_Response>;
  /** delete single row from the table: "search_domain" */
  delete_search_domain_by_pk?: Maybe<Search_Domain>;
  /** delete data from the table: "search_sessions" */
  delete_search_sessions?: Maybe<Search_Sessions_Mutation_Response>;
  /** delete single row from the table: "search_sessions" */
  delete_search_sessions_by_pk?: Maybe<Search_Sessions>;
  /** delete data from the table: "text_content" */
  delete_text_content?: Maybe<Text_Content_Mutation_Response>;
  /** delete single row from the table: "text_content" */
  delete_text_content_by_pk?: Maybe<Text_Content>;
  /** delete data from the table: "translations" */
  delete_translations?: Maybe<Translations_Mutation_Response>;
  /** delete single row from the table: "translations" */
  delete_translations_by_pk?: Maybe<Translations>;
  /** delete data from the table: "url_slug" */
  delete_url_slug?: Maybe<Url_Slug_Mutation_Response>;
  /** delete single row from the table: "url_slug" */
  delete_url_slug_by_pk?: Maybe<Url_Slug>;
  /** delete data from the table: "user_card_information" */
  delete_user_card_information?: Maybe<User_Card_Information_Mutation_Response>;
  /** delete single row from the table: "user_card_information" */
  delete_user_card_information_by_pk?: Maybe<User_Card_Information>;
  /** delete data from the table: "user_filters" */
  delete_user_filters?: Maybe<User_Filters_Mutation_Response>;
  /** delete single row from the table: "user_filters" */
  delete_user_filters_by_pk?: Maybe<User_Filters>;
  /** delete data from the table: "user_filters_features" */
  delete_user_filters_features?: Maybe<User_Filters_Features_Mutation_Response>;
  /** delete single row from the table: "user_filters_features" */
  delete_user_filters_features_by_pk?: Maybe<User_Filters_Features>;
  /** delete data from the table: "user_membership" */
  delete_user_membership?: Maybe<User_Membership_Mutation_Response>;
  /** delete single row from the table: "user_membership" */
  delete_user_membership_by_pk?: Maybe<User_Membership>;
  /** delete data from the table: "user_preferences" */
  delete_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** delete single row from the table: "user_preferences" */
  delete_user_preferences_by_pk?: Maybe<User_Preferences>;
  /** delete data from the table: "user_property_notifiation" */
  delete_user_property_notifiation?: Maybe<User_Property_Notifiation_Mutation_Response>;
  /** delete single row from the table: "user_property_notifiation" */
  delete_user_property_notifiation_by_pk?: Maybe<User_Property_Notifiation>;
  /** delete data from the table: "user_saved_properties" */
  delete_user_saved_properties?: Maybe<User_Saved_Properties_Mutation_Response>;
  /** delete single row from the table: "user_saved_properties" */
  delete_user_saved_properties_by_pk?: Maybe<User_Saved_Properties>;
  /** delete data from the table: "user_seen_property" */
  delete_user_seen_property?: Maybe<User_Seen_Property_Mutation_Response>;
  /** delete single row from the table: "user_seen_property" */
  delete_user_seen_property_by_pk?: Maybe<User_Seen_Property>;
  /** delete data from the table: "zone_data_method" */
  delete_zone_data_method?: Maybe<Zone_Data_Method_Mutation_Response>;
  /** delete single row from the table: "zone_data_method" */
  delete_zone_data_method_by_pk?: Maybe<Zone_Data_Method>;
  /** Initialize order for a membership */
  initialize_order?: Maybe<InsertOrderOutput>;
  /** insert a single row into the table: "auth.providers" */
  insertAuthProvider?: Maybe<AuthProviders>;
  /** insert a single row into the table: "auth.provider_requests" */
  insertAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** insert data into the table: "auth.provider_requests" */
  insertAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** insert data into the table: "auth.providers" */
  insertAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insertAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** insert a single row into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** insert data into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** insert data into the table: "auth.refresh_tokens" */
  insertAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insertAuthRole?: Maybe<AuthRoles>;
  /** insert data into the table: "auth.roles" */
  insertAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** insert a single row into the table: "auth.user_providers" */
  insertAuthUserProvider?: Maybe<AuthUserProviders>;
  /** insert data into the table: "auth.user_providers" */
  insertAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** insert a single row into the table: "auth.user_roles" */
  insertAuthUserRole?: Maybe<AuthUserRoles>;
  /** insert data into the table: "auth.user_roles" */
  insertAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** insert a single row into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** insert data into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** insert a single row into the table: "storage.buckets" */
  insertBucket?: Maybe<Buckets>;
  /** insert data into the table: "storage.buckets" */
  insertBuckets?: Maybe<Buckets_Mutation_Response>;
  /** insert a single row into the table: "storage.files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage.files" */
  insertFiles?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "auth.users" */
  insertUser?: Maybe<Users>;
  /** insert data into the table: "auth.users" */
  insertUsers?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "storage.virus" */
  insertVirus?: Maybe<Virus>;
  /** insert data into the table: "storage.virus" */
  insertViruses?: Maybe<Virus_Mutation_Response>;
  /** insert data into the table: "address_types" */
  insert_address_types?: Maybe<Address_Types_Mutation_Response>;
  /** insert a single row into the table: "address_types" */
  insert_address_types_one?: Maybe<Address_Types>;
  /** insert data into the table: "agency" */
  insert_agency?: Maybe<Agency_Mutation_Response>;
  /** insert a single row into the table: "agency" */
  insert_agency_one?: Maybe<Agency>;
  /** insert data into the table: "amenities" */
  insert_amenities?: Maybe<Amenities_Mutation_Response>;
  /** insert a single row into the table: "amenities" */
  insert_amenities_one?: Maybe<Amenities>;
  /** insert data into the table: "chat_response_type" */
  insert_chat_response_type?: Maybe<Chat_Response_Type_Mutation_Response>;
  /** insert a single row into the table: "chat_response_type" */
  insert_chat_response_type_one?: Maybe<Chat_Response_Type>;
  /** insert data into the table: "city" */
  insert_city?: Maybe<City_Mutation_Response>;
  /** insert a single row into the table: "city" */
  insert_city_one?: Maybe<City>;
  /** insert data into the table: "city_zone" */
  insert_city_zone?: Maybe<City_Zone_Mutation_Response>;
  /** insert a single row into the table: "city_zone" */
  insert_city_zone_one?: Maybe<City_Zone>;
  /** insert data into the table: "city_zone_origin" */
  insert_city_zone_origin?: Maybe<City_Zone_Origin_Mutation_Response>;
  /** insert a single row into the table: "city_zone_origin" */
  insert_city_zone_origin_one?: Maybe<City_Zone_Origin>;
  /** insert data into the table: "construction_status" */
  insert_construction_status?: Maybe<Construction_Status_Mutation_Response>;
  /** insert a single row into the table: "construction_status" */
  insert_construction_status_one?: Maybe<Construction_Status>;
  /** insert data into the table: "contact" */
  insert_contact?: Maybe<Contact_Mutation_Response>;
  /** insert a single row into the table: "contact" */
  insert_contact_one?: Maybe<Contact>;
  /** insert data into the table: "contact_phone_number" */
  insert_contact_phone_number?: Maybe<Contact_Phone_Number_Mutation_Response>;
  /** insert a single row into the table: "contact_phone_number" */
  insert_contact_phone_number_one?: Maybe<Contact_Phone_Number>;
  /** insert data into the table: "contact_type" */
  insert_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** insert a single row into the table: "contact_type" */
  insert_contact_type_one?: Maybe<Contact_Type>;
  /** insert data into the table: "content_type" */
  insert_content_type?: Maybe<Content_Type_Mutation_Response>;
  /** insert a single row into the table: "content_type" */
  insert_content_type_one?: Maybe<Content_Type>;
  /** insert data into the table: "countries" */
  insert_countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "countries" */
  insert_countries_one?: Maybe<Countries>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "currency" */
  insert_currency?: Maybe<Currency_Mutation_Response>;
  /** insert data into the table: "currency_exchange_rates" */
  insert_currency_exchange_rates?: Maybe<Currency_Exchange_Rates_Mutation_Response>;
  /** insert a single row into the table: "currency_exchange_rates" */
  insert_currency_exchange_rates_one?: Maybe<Currency_Exchange_Rates>;
  /** insert a single row into the table: "currency" */
  insert_currency_one?: Maybe<Currency>;
  /** insert data into the table: "entities" */
  insert_entities?: Maybe<Entities_Mutation_Response>;
  /** insert a single row into the table: "entities" */
  insert_entities_one?: Maybe<Entities>;
  /** insert data into the table: "feature" */
  insert_feature?: Maybe<Feature_Mutation_Response>;
  /** insert a single row into the table: "feature" */
  insert_feature_one?: Maybe<Feature>;
  /** insert data into the table: "feature_type" */
  insert_feature_type?: Maybe<Feature_Type_Mutation_Response>;
  /** insert a single row into the table: "feature_type" */
  insert_feature_type_one?: Maybe<Feature_Type>;
  /** insert data into the table: "furnishing_status" */
  insert_furnishing_status?: Maybe<Furnishing_Status_Mutation_Response>;
  /** insert a single row into the table: "furnishing_status" */
  insert_furnishing_status_one?: Maybe<Furnishing_Status>;
  /** insert data into the table: "heatingType" */
  insert_heatingType?: Maybe<HeatingType_Mutation_Response>;
  /** insert a single row into the table: "heatingType" */
  insert_heatingType_one?: Maybe<HeatingType>;
  /** insert data into the table: "languages" */
  insert_languages?: Maybe<Languages_Mutation_Response>;
  /** insert a single row into the table: "languages" */
  insert_languages_one?: Maybe<Languages>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>;
  /** insert data into the table: "membership_package" */
  insert_membership_package?: Maybe<Membership_Package_Mutation_Response>;
  /** insert a single row into the table: "membership_package" */
  insert_membership_package_one?: Maybe<Membership_Package>;
  /** insert data into the table: "natural_language_queries" */
  insert_natural_language_queries?: Maybe<Natural_Language_Queries_Mutation_Response>;
  /** insert a single row into the table: "natural_language_queries" */
  insert_natural_language_queries_one?: Maybe<Natural_Language_Queries>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>;
  /** insert data into the table: "notification_amenities" */
  insert_notification_amenities?: Maybe<Notification_Amenities_Mutation_Response>;
  /** insert a single row into the table: "notification_amenities" */
  insert_notification_amenities_one?: Maybe<Notification_Amenities>;
  /** insert data into the table: "notification_feature" */
  insert_notification_feature?: Maybe<Notification_Feature_Mutation_Response>;
  /** insert a single row into the table: "notification_feature" */
  insert_notification_feature_one?: Maybe<Notification_Feature>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "notification_property_type" */
  insert_notification_property_type?: Maybe<Notification_Property_Type_Mutation_Response>;
  /** insert a single row into the table: "notification_property_type" */
  insert_notification_property_type_one?: Maybe<Notification_Property_Type>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<Notification_Type>;
  /** insert data into the table: "order" */
  insert_order?: Maybe<Order_Mutation_Response>;
  /** insert a single row into the table: "order" */
  insert_order_one?: Maybe<Order>;
  /** insert data into the table: "parking_type" */
  insert_parking_type?: Maybe<Parking_Type_Mutation_Response>;
  /** insert a single row into the table: "parking_type" */
  insert_parking_type_one?: Maybe<Parking_Type>;
  /** insert data into the table: "property" */
  insert_property?: Maybe<Property_Mutation_Response>;
  /** insert data into the table: "property_amenities" */
  insert_property_amenities?: Maybe<Property_Amenities_Mutation_Response>;
  /** insert a single row into the table: "property_amenities" */
  insert_property_amenities_one?: Maybe<Property_Amenities>;
  /** insert data into the table: "property_cluster" */
  insert_property_cluster?: Maybe<Property_Cluster_Mutation_Response>;
  /** insert a single row into the table: "property_cluster" */
  insert_property_cluster_one?: Maybe<Property_Cluster>;
  /** insert data into the table: "property_composition" */
  insert_property_composition?: Maybe<Property_Composition_Mutation_Response>;
  /** insert a single row into the table: "property_composition" */
  insert_property_composition_one?: Maybe<Property_Composition>;
  /** insert data into the table: "property_feature" */
  insert_property_feature?: Maybe<Property_Feature_Mutation_Response>;
  /** insert a single row into the table: "property_feature" */
  insert_property_feature_one?: Maybe<Property_Feature>;
  /** insert data into the table: "property_image" */
  insert_property_image?: Maybe<Property_Image_Mutation_Response>;
  /** insert a single row into the table: "property_image" */
  insert_property_image_one?: Maybe<Property_Image>;
  /** insert data into the table: "property_list" */
  insert_property_list?: Maybe<Property_List_Mutation_Response>;
  /** insert a single row into the table: "property_list" */
  insert_property_list_one?: Maybe<Property_List>;
  /** insert a single row into the table: "property" */
  insert_property_one?: Maybe<Property>;
  /** insert data into the table: "property_origin" */
  insert_property_origin?: Maybe<Property_Origin_Mutation_Response>;
  /** insert a single row into the table: "property_origin" */
  insert_property_origin_one?: Maybe<Property_Origin>;
  /** insert data into the table: "property_origin_type" */
  insert_property_origin_type?: Maybe<Property_Origin_Type_Mutation_Response>;
  /** insert a single row into the table: "property_origin_type" */
  insert_property_origin_type_one?: Maybe<Property_Origin_Type>;
  /** insert data into the table: "property_price_history" */
  insert_property_price_history?: Maybe<Property_Price_History_Mutation_Response>;
  /** insert a single row into the table: "property_price_history" */
  insert_property_price_history_one?: Maybe<Property_Price_History>;
  /** insert data into the table: "property_source" */
  insert_property_source?: Maybe<Property_Source_Mutation_Response>;
  /** insert a single row into the table: "property_source" */
  insert_property_source_one?: Maybe<Property_Source>;
  /** insert data into the table: "property_status" */
  insert_property_status?: Maybe<Property_Status_Mutation_Response>;
  /** insert a single row into the table: "property_status" */
  insert_property_status_one?: Maybe<Property_Status>;
  /** insert data into the table: "property_type" */
  insert_property_type?: Maybe<Property_Type_Mutation_Response>;
  /** insert a single row into the table: "property_type" */
  insert_property_type_one?: Maybe<Property_Type>;
  /** insert data into the table: "property_views" */
  insert_property_views?: Maybe<Property_Views_Mutation_Response>;
  /** insert a single row into the table: "property_views" */
  insert_property_views_one?: Maybe<Property_Views>;
  /** insert data into the table: "queries_property_result" */
  insert_queries_property_result?: Maybe<Queries_Property_Result_Mutation_Response>;
  /** insert a single row into the table: "queries_property_result" */
  insert_queries_property_result_one?: Maybe<Queries_Property_Result>;
  /** insert data into the table: "query_classification" */
  insert_query_classification?: Maybe<Query_Classification_Mutation_Response>;
  /** insert a single row into the table: "query_classification" */
  insert_query_classification_one?: Maybe<Query_Classification>;
  /** insert data into the table: "raw_location" */
  insert_raw_location?: Maybe<Raw_Location_Mutation_Response>;
  /** insert a single row into the table: "raw_location" */
  insert_raw_location_one?: Maybe<Raw_Location>;
  /** insert data into the table: "redirect" */
  insert_redirect?: Maybe<Redirect_Mutation_Response>;
  /** insert a single row into the table: "redirect" */
  insert_redirect_one?: Maybe<Redirect>;
  /** insert data into the table: "search_context" */
  insert_search_context?: Maybe<Search_Context_Mutation_Response>;
  /** insert a single row into the table: "search_context" */
  insert_search_context_one?: Maybe<Search_Context>;
  /** insert data into the table: "search_domain" */
  insert_search_domain?: Maybe<Search_Domain_Mutation_Response>;
  /** insert a single row into the table: "search_domain" */
  insert_search_domain_one?: Maybe<Search_Domain>;
  /** insert data into the table: "search_sessions" */
  insert_search_sessions?: Maybe<Search_Sessions_Mutation_Response>;
  /** insert a single row into the table: "search_sessions" */
  insert_search_sessions_one?: Maybe<Search_Sessions>;
  /** insert data into the table: "text_content" */
  insert_text_content?: Maybe<Text_Content_Mutation_Response>;
  /** insert a single row into the table: "text_content" */
  insert_text_content_one?: Maybe<Text_Content>;
  /** insert data into the table: "translations" */
  insert_translations?: Maybe<Translations_Mutation_Response>;
  /** insert a single row into the table: "translations" */
  insert_translations_one?: Maybe<Translations>;
  /** insert data into the table: "url_slug" */
  insert_url_slug?: Maybe<Url_Slug_Mutation_Response>;
  /** insert a single row into the table: "url_slug" */
  insert_url_slug_one?: Maybe<Url_Slug>;
  /** insert data into the table: "user_card_information" */
  insert_user_card_information?: Maybe<User_Card_Information_Mutation_Response>;
  /** insert a single row into the table: "user_card_information" */
  insert_user_card_information_one?: Maybe<User_Card_Information>;
  /** insert data into the table: "user_filters" */
  insert_user_filters?: Maybe<User_Filters_Mutation_Response>;
  /** insert data into the table: "user_filters_features" */
  insert_user_filters_features?: Maybe<User_Filters_Features_Mutation_Response>;
  /** insert a single row into the table: "user_filters_features" */
  insert_user_filters_features_one?: Maybe<User_Filters_Features>;
  /** insert a single row into the table: "user_filters" */
  insert_user_filters_one?: Maybe<User_Filters>;
  /** insert data into the table: "user_membership" */
  insert_user_membership?: Maybe<User_Membership_Mutation_Response>;
  /** insert a single row into the table: "user_membership" */
  insert_user_membership_one?: Maybe<User_Membership>;
  /** insert data into the table: "user_preferences" */
  insert_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** insert a single row into the table: "user_preferences" */
  insert_user_preferences_one?: Maybe<User_Preferences>;
  /** insert data into the table: "user_property_notifiation" */
  insert_user_property_notifiation?: Maybe<User_Property_Notifiation_Mutation_Response>;
  /** insert a single row into the table: "user_property_notifiation" */
  insert_user_property_notifiation_one?: Maybe<User_Property_Notifiation>;
  /** insert data into the table: "user_saved_properties" */
  insert_user_saved_properties?: Maybe<User_Saved_Properties_Mutation_Response>;
  /** insert a single row into the table: "user_saved_properties" */
  insert_user_saved_properties_one?: Maybe<User_Saved_Properties>;
  /** insert data into the table: "user_seen_property" */
  insert_user_seen_property?: Maybe<User_Seen_Property_Mutation_Response>;
  /** insert a single row into the table: "user_seen_property" */
  insert_user_seen_property_one?: Maybe<User_Seen_Property>;
  /** insert data into the table: "zone_data_method" */
  insert_zone_data_method?: Maybe<Zone_Data_Method_Mutation_Response>;
  /** insert a single row into the table: "zone_data_method" */
  insert_zone_data_method_one?: Maybe<Zone_Data_Method>;
  payment_success?: Maybe<PaymentSuccessOutput>;
  process_user_notification?: Maybe<Output>;
  promptSearch?: Maybe<QuestionOutput>;
  querySearch?: Maybe<SampleOutput>;
  /** Action to send SMS verify code using twilio */
  send_verification_code?: Maybe<PhoneOutput>;
  /** update single row of the table: "auth.providers" */
  updateAuthProvider?: Maybe<AuthProviders>;
  /** update single row of the table: "auth.provider_requests" */
  updateAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** update data of the table: "auth.provider_requests" */
  updateAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** update data of the table: "auth.providers" */
  updateAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  updateAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** update single row of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** update data of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** update data of the table: "auth.refresh_tokens" */
  updateAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  updateAuthRole?: Maybe<AuthRoles>;
  /** update data of the table: "auth.roles" */
  updateAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** update single row of the table: "auth.user_providers" */
  updateAuthUserProvider?: Maybe<AuthUserProviders>;
  /** update data of the table: "auth.user_providers" */
  updateAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** update single row of the table: "auth.user_roles" */
  updateAuthUserRole?: Maybe<AuthUserRoles>;
  /** update data of the table: "auth.user_roles" */
  updateAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** update single row of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** update data of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** update single row of the table: "storage.buckets" */
  updateBucket?: Maybe<Buckets>;
  /** update data of the table: "storage.buckets" */
  updateBuckets?: Maybe<Buckets_Mutation_Response>;
  /** update single row of the table: "storage.files" */
  updateFile?: Maybe<Files>;
  /** update data of the table: "storage.files" */
  updateFiles?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "auth.users" */
  updateUser?: Maybe<Users>;
  /** update data of the table: "auth.users" */
  updateUsers?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "storage.virus" */
  updateVirus?: Maybe<Virus>;
  /** update data of the table: "storage.virus" */
  updateViruses?: Maybe<Virus_Mutation_Response>;
  /** update data of the table: "address_types" */
  update_address_types?: Maybe<Address_Types_Mutation_Response>;
  /** update single row of the table: "address_types" */
  update_address_types_by_pk?: Maybe<Address_Types>;
  /** update multiples rows of table: "address_types" */
  update_address_types_many?: Maybe<Array<Maybe<Address_Types_Mutation_Response>>>;
  /** update data of the table: "agency" */
  update_agency?: Maybe<Agency_Mutation_Response>;
  /** update single row of the table: "agency" */
  update_agency_by_pk?: Maybe<Agency>;
  /** update multiples rows of table: "agency" */
  update_agency_many?: Maybe<Array<Maybe<Agency_Mutation_Response>>>;
  /** update data of the table: "amenities" */
  update_amenities?: Maybe<Amenities_Mutation_Response>;
  /** update single row of the table: "amenities" */
  update_amenities_by_pk?: Maybe<Amenities>;
  /** update multiples rows of table: "amenities" */
  update_amenities_many?: Maybe<Array<Maybe<Amenities_Mutation_Response>>>;
  /** update multiples rows of table: "auth.provider_requests" */
  update_authProviderRequests_many?: Maybe<Array<Maybe<AuthProviderRequests_Mutation_Response>>>;
  /** update multiples rows of table: "auth.providers" */
  update_authProviders_many?: Maybe<Array<Maybe<AuthProviders_Mutation_Response>>>;
  /** update multiples rows of table: "auth.refresh_token_types" */
  update_authRefreshTokenTypes_many?: Maybe<Array<Maybe<AuthRefreshTokenTypes_Mutation_Response>>>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_authRefreshTokens_many?: Maybe<Array<Maybe<AuthRefreshTokens_Mutation_Response>>>;
  /** update multiples rows of table: "auth.roles" */
  update_authRoles_many?: Maybe<Array<Maybe<AuthRoles_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_providers" */
  update_authUserProviders_many?: Maybe<Array<Maybe<AuthUserProviders_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_roles" */
  update_authUserRoles_many?: Maybe<Array<Maybe<AuthUserRoles_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_security_keys" */
  update_authUserSecurityKeys_many?: Maybe<Array<Maybe<AuthUserSecurityKeys_Mutation_Response>>>;
  /** update multiples rows of table: "storage.buckets" */
  update_buckets_many?: Maybe<Array<Maybe<Buckets_Mutation_Response>>>;
  /** update data of the table: "chat_response_type" */
  update_chat_response_type?: Maybe<Chat_Response_Type_Mutation_Response>;
  /** update single row of the table: "chat_response_type" */
  update_chat_response_type_by_pk?: Maybe<Chat_Response_Type>;
  /** update multiples rows of table: "chat_response_type" */
  update_chat_response_type_many?: Maybe<Array<Maybe<Chat_Response_Type_Mutation_Response>>>;
  /** update data of the table: "city" */
  update_city?: Maybe<City_Mutation_Response>;
  /** update single row of the table: "city" */
  update_city_by_pk?: Maybe<City>;
  /** update multiples rows of table: "city" */
  update_city_many?: Maybe<Array<Maybe<City_Mutation_Response>>>;
  /** update data of the table: "city_zone" */
  update_city_zone?: Maybe<City_Zone_Mutation_Response>;
  /** update single row of the table: "city_zone" */
  update_city_zone_by_pk?: Maybe<City_Zone>;
  /** update multiples rows of table: "city_zone" */
  update_city_zone_many?: Maybe<Array<Maybe<City_Zone_Mutation_Response>>>;
  /** update data of the table: "city_zone_origin" */
  update_city_zone_origin?: Maybe<City_Zone_Origin_Mutation_Response>;
  /** update single row of the table: "city_zone_origin" */
  update_city_zone_origin_by_pk?: Maybe<City_Zone_Origin>;
  /** update multiples rows of table: "city_zone_origin" */
  update_city_zone_origin_many?: Maybe<Array<Maybe<City_Zone_Origin_Mutation_Response>>>;
  /** update data of the table: "construction_status" */
  update_construction_status?: Maybe<Construction_Status_Mutation_Response>;
  /** update single row of the table: "construction_status" */
  update_construction_status_by_pk?: Maybe<Construction_Status>;
  /** update multiples rows of table: "construction_status" */
  update_construction_status_many?: Maybe<Array<Maybe<Construction_Status_Mutation_Response>>>;
  /** update data of the table: "contact" */
  update_contact?: Maybe<Contact_Mutation_Response>;
  /** update single row of the table: "contact" */
  update_contact_by_pk?: Maybe<Contact>;
  /** update multiples rows of table: "contact" */
  update_contact_many?: Maybe<Array<Maybe<Contact_Mutation_Response>>>;
  /** update data of the table: "contact_phone_number" */
  update_contact_phone_number?: Maybe<Contact_Phone_Number_Mutation_Response>;
  /** update single row of the table: "contact_phone_number" */
  update_contact_phone_number_by_pk?: Maybe<Contact_Phone_Number>;
  /** update multiples rows of table: "contact_phone_number" */
  update_contact_phone_number_many?: Maybe<Array<Maybe<Contact_Phone_Number_Mutation_Response>>>;
  /** update data of the table: "contact_type" */
  update_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** update single row of the table: "contact_type" */
  update_contact_type_by_pk?: Maybe<Contact_Type>;
  /** update multiples rows of table: "contact_type" */
  update_contact_type_many?: Maybe<Array<Maybe<Contact_Type_Mutation_Response>>>;
  /** update data of the table: "content_type" */
  update_content_type?: Maybe<Content_Type_Mutation_Response>;
  /** update single row of the table: "content_type" */
  update_content_type_by_pk?: Maybe<Content_Type>;
  /** update multiples rows of table: "content_type" */
  update_content_type_many?: Maybe<Array<Maybe<Content_Type_Mutation_Response>>>;
  /** update data of the table: "countries" */
  update_countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "countries" */
  update_countries_by_pk?: Maybe<Countries>;
  /** update multiples rows of table: "countries" */
  update_countries_many?: Maybe<Array<Maybe<Countries_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "currency" */
  update_currency?: Maybe<Currency_Mutation_Response>;
  /** update single row of the table: "currency" */
  update_currency_by_pk?: Maybe<Currency>;
  /** update data of the table: "currency_exchange_rates" */
  update_currency_exchange_rates?: Maybe<Currency_Exchange_Rates_Mutation_Response>;
  /** update single row of the table: "currency_exchange_rates" */
  update_currency_exchange_rates_by_pk?: Maybe<Currency_Exchange_Rates>;
  /** update multiples rows of table: "currency_exchange_rates" */
  update_currency_exchange_rates_many?: Maybe<Array<Maybe<Currency_Exchange_Rates_Mutation_Response>>>;
  /** update multiples rows of table: "currency" */
  update_currency_many?: Maybe<Array<Maybe<Currency_Mutation_Response>>>;
  /** update data of the table: "entities" */
  update_entities?: Maybe<Entities_Mutation_Response>;
  /** update single row of the table: "entities" */
  update_entities_by_pk?: Maybe<Entities>;
  /** update multiples rows of table: "entities" */
  update_entities_many?: Maybe<Array<Maybe<Entities_Mutation_Response>>>;
  /** update data of the table: "feature" */
  update_feature?: Maybe<Feature_Mutation_Response>;
  /** update single row of the table: "feature" */
  update_feature_by_pk?: Maybe<Feature>;
  /** update multiples rows of table: "feature" */
  update_feature_many?: Maybe<Array<Maybe<Feature_Mutation_Response>>>;
  /** update data of the table: "feature_type" */
  update_feature_type?: Maybe<Feature_Type_Mutation_Response>;
  /** update single row of the table: "feature_type" */
  update_feature_type_by_pk?: Maybe<Feature_Type>;
  /** update multiples rows of table: "feature_type" */
  update_feature_type_many?: Maybe<Array<Maybe<Feature_Type_Mutation_Response>>>;
  /** update multiples rows of table: "storage.files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "furnishing_status" */
  update_furnishing_status?: Maybe<Furnishing_Status_Mutation_Response>;
  /** update single row of the table: "furnishing_status" */
  update_furnishing_status_by_pk?: Maybe<Furnishing_Status>;
  /** update multiples rows of table: "furnishing_status" */
  update_furnishing_status_many?: Maybe<Array<Maybe<Furnishing_Status_Mutation_Response>>>;
  /** update data of the table: "heatingType" */
  update_heatingType?: Maybe<HeatingType_Mutation_Response>;
  /** update single row of the table: "heatingType" */
  update_heatingType_by_pk?: Maybe<HeatingType>;
  /** update multiples rows of table: "heatingType" */
  update_heatingType_many?: Maybe<Array<Maybe<HeatingType_Mutation_Response>>>;
  /** update data of the table: "languages" */
  update_languages?: Maybe<Languages_Mutation_Response>;
  /** update single row of the table: "languages" */
  update_languages_by_pk?: Maybe<Languages>;
  /** update multiples rows of table: "languages" */
  update_languages_many?: Maybe<Array<Maybe<Languages_Mutation_Response>>>;
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>;
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>;
  /** update data of the table: "membership_package" */
  update_membership_package?: Maybe<Membership_Package_Mutation_Response>;
  /** update single row of the table: "membership_package" */
  update_membership_package_by_pk?: Maybe<Membership_Package>;
  /** update multiples rows of table: "membership_package" */
  update_membership_package_many?: Maybe<Array<Maybe<Membership_Package_Mutation_Response>>>;
  /** update data of the table: "natural_language_queries" */
  update_natural_language_queries?: Maybe<Natural_Language_Queries_Mutation_Response>;
  /** update single row of the table: "natural_language_queries" */
  update_natural_language_queries_by_pk?: Maybe<Natural_Language_Queries>;
  /** update multiples rows of table: "natural_language_queries" */
  update_natural_language_queries_many?: Maybe<Array<Maybe<Natural_Language_Queries_Mutation_Response>>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>;
  /** update data of the table: "notification_amenities" */
  update_notification_amenities?: Maybe<Notification_Amenities_Mutation_Response>;
  /** update single row of the table: "notification_amenities" */
  update_notification_amenities_by_pk?: Maybe<Notification_Amenities>;
  /** update multiples rows of table: "notification_amenities" */
  update_notification_amenities_many?: Maybe<Array<Maybe<Notification_Amenities_Mutation_Response>>>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update data of the table: "notification_feature" */
  update_notification_feature?: Maybe<Notification_Feature_Mutation_Response>;
  /** update single row of the table: "notification_feature" */
  update_notification_feature_by_pk?: Maybe<Notification_Feature>;
  /** update multiples rows of table: "notification_feature" */
  update_notification_feature_many?: Maybe<Array<Maybe<Notification_Feature_Mutation_Response>>>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<Notification_Mutation_Response>>>;
  /** update data of the table: "notification_property_type" */
  update_notification_property_type?: Maybe<Notification_Property_Type_Mutation_Response>;
  /** update single row of the table: "notification_property_type" */
  update_notification_property_type_by_pk?: Maybe<Notification_Property_Type>;
  /** update multiples rows of table: "notification_property_type" */
  update_notification_property_type_many?: Maybe<Array<Maybe<Notification_Property_Type_Mutation_Response>>>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<Notification_Type>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<Array<Maybe<Notification_Type_Mutation_Response>>>;
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>;
  /** update single row of the table: "order" */
  update_order_by_pk?: Maybe<Order>;
  /** update multiples rows of table: "order" */
  update_order_many?: Maybe<Array<Maybe<Order_Mutation_Response>>>;
  /** update data of the table: "parking_type" */
  update_parking_type?: Maybe<Parking_Type_Mutation_Response>;
  /** update single row of the table: "parking_type" */
  update_parking_type_by_pk?: Maybe<Parking_Type>;
  /** update multiples rows of table: "parking_type" */
  update_parking_type_many?: Maybe<Array<Maybe<Parking_Type_Mutation_Response>>>;
  /** update data of the table: "property" */
  update_property?: Maybe<Property_Mutation_Response>;
  /** update data of the table: "property_amenities" */
  update_property_amenities?: Maybe<Property_Amenities_Mutation_Response>;
  /** update single row of the table: "property_amenities" */
  update_property_amenities_by_pk?: Maybe<Property_Amenities>;
  /** update multiples rows of table: "property_amenities" */
  update_property_amenities_many?: Maybe<Array<Maybe<Property_Amenities_Mutation_Response>>>;
  /** update single row of the table: "property" */
  update_property_by_pk?: Maybe<Property>;
  /** update data of the table: "property_cluster" */
  update_property_cluster?: Maybe<Property_Cluster_Mutation_Response>;
  /** update single row of the table: "property_cluster" */
  update_property_cluster_by_pk?: Maybe<Property_Cluster>;
  /** update multiples rows of table: "property_cluster" */
  update_property_cluster_many?: Maybe<Array<Maybe<Property_Cluster_Mutation_Response>>>;
  /** update data of the table: "property_composition" */
  update_property_composition?: Maybe<Property_Composition_Mutation_Response>;
  /** update single row of the table: "property_composition" */
  update_property_composition_by_pk?: Maybe<Property_Composition>;
  /** update multiples rows of table: "property_composition" */
  update_property_composition_many?: Maybe<Array<Maybe<Property_Composition_Mutation_Response>>>;
  /** update data of the table: "property_feature" */
  update_property_feature?: Maybe<Property_Feature_Mutation_Response>;
  /** update single row of the table: "property_feature" */
  update_property_feature_by_pk?: Maybe<Property_Feature>;
  /** update multiples rows of table: "property_feature" */
  update_property_feature_many?: Maybe<Array<Maybe<Property_Feature_Mutation_Response>>>;
  /** update data of the table: "property_image" */
  update_property_image?: Maybe<Property_Image_Mutation_Response>;
  /** update single row of the table: "property_image" */
  update_property_image_by_pk?: Maybe<Property_Image>;
  /** update multiples rows of table: "property_image" */
  update_property_image_many?: Maybe<Array<Maybe<Property_Image_Mutation_Response>>>;
  /** update data of the table: "property_list" */
  update_property_list?: Maybe<Property_List_Mutation_Response>;
  /** update single row of the table: "property_list" */
  update_property_list_by_pk?: Maybe<Property_List>;
  /** update multiples rows of table: "property_list" */
  update_property_list_many?: Maybe<Array<Maybe<Property_List_Mutation_Response>>>;
  /** update multiples rows of table: "property" */
  update_property_many?: Maybe<Array<Maybe<Property_Mutation_Response>>>;
  /** update data of the table: "property_origin" */
  update_property_origin?: Maybe<Property_Origin_Mutation_Response>;
  /** update single row of the table: "property_origin" */
  update_property_origin_by_pk?: Maybe<Property_Origin>;
  /** update multiples rows of table: "property_origin" */
  update_property_origin_many?: Maybe<Array<Maybe<Property_Origin_Mutation_Response>>>;
  /** update data of the table: "property_origin_type" */
  update_property_origin_type?: Maybe<Property_Origin_Type_Mutation_Response>;
  /** update single row of the table: "property_origin_type" */
  update_property_origin_type_by_pk?: Maybe<Property_Origin_Type>;
  /** update multiples rows of table: "property_origin_type" */
  update_property_origin_type_many?: Maybe<Array<Maybe<Property_Origin_Type_Mutation_Response>>>;
  /** update data of the table: "property_price_history" */
  update_property_price_history?: Maybe<Property_Price_History_Mutation_Response>;
  /** update single row of the table: "property_price_history" */
  update_property_price_history_by_pk?: Maybe<Property_Price_History>;
  /** update multiples rows of table: "property_price_history" */
  update_property_price_history_many?: Maybe<Array<Maybe<Property_Price_History_Mutation_Response>>>;
  /** update data of the table: "property_source" */
  update_property_source?: Maybe<Property_Source_Mutation_Response>;
  /** update single row of the table: "property_source" */
  update_property_source_by_pk?: Maybe<Property_Source>;
  /** update multiples rows of table: "property_source" */
  update_property_source_many?: Maybe<Array<Maybe<Property_Source_Mutation_Response>>>;
  /** update data of the table: "property_status" */
  update_property_status?: Maybe<Property_Status_Mutation_Response>;
  /** update single row of the table: "property_status" */
  update_property_status_by_pk?: Maybe<Property_Status>;
  /** update multiples rows of table: "property_status" */
  update_property_status_many?: Maybe<Array<Maybe<Property_Status_Mutation_Response>>>;
  /** update data of the table: "property_type" */
  update_property_type?: Maybe<Property_Type_Mutation_Response>;
  /** update single row of the table: "property_type" */
  update_property_type_by_pk?: Maybe<Property_Type>;
  /** update multiples rows of table: "property_type" */
  update_property_type_many?: Maybe<Array<Maybe<Property_Type_Mutation_Response>>>;
  /** update data of the table: "property_views" */
  update_property_views?: Maybe<Property_Views_Mutation_Response>;
  /** update single row of the table: "property_views" */
  update_property_views_by_pk?: Maybe<Property_Views>;
  /** update multiples rows of table: "property_views" */
  update_property_views_many?: Maybe<Array<Maybe<Property_Views_Mutation_Response>>>;
  /** update data of the table: "queries_property_result" */
  update_queries_property_result?: Maybe<Queries_Property_Result_Mutation_Response>;
  /** update single row of the table: "queries_property_result" */
  update_queries_property_result_by_pk?: Maybe<Queries_Property_Result>;
  /** update multiples rows of table: "queries_property_result" */
  update_queries_property_result_many?: Maybe<Array<Maybe<Queries_Property_Result_Mutation_Response>>>;
  /** update data of the table: "query_classification" */
  update_query_classification?: Maybe<Query_Classification_Mutation_Response>;
  /** update single row of the table: "query_classification" */
  update_query_classification_by_pk?: Maybe<Query_Classification>;
  /** update multiples rows of table: "query_classification" */
  update_query_classification_many?: Maybe<Array<Maybe<Query_Classification_Mutation_Response>>>;
  /** update data of the table: "raw_location" */
  update_raw_location?: Maybe<Raw_Location_Mutation_Response>;
  /** update single row of the table: "raw_location" */
  update_raw_location_by_pk?: Maybe<Raw_Location>;
  /** update multiples rows of table: "raw_location" */
  update_raw_location_many?: Maybe<Array<Maybe<Raw_Location_Mutation_Response>>>;
  /** update data of the table: "redirect" */
  update_redirect?: Maybe<Redirect_Mutation_Response>;
  /** update single row of the table: "redirect" */
  update_redirect_by_pk?: Maybe<Redirect>;
  /** update multiples rows of table: "redirect" */
  update_redirect_many?: Maybe<Array<Maybe<Redirect_Mutation_Response>>>;
  /** update data of the table: "search_context" */
  update_search_context?: Maybe<Search_Context_Mutation_Response>;
  /** update single row of the table: "search_context" */
  update_search_context_by_pk?: Maybe<Search_Context>;
  /** update multiples rows of table: "search_context" */
  update_search_context_many?: Maybe<Array<Maybe<Search_Context_Mutation_Response>>>;
  /** update data of the table: "search_domain" */
  update_search_domain?: Maybe<Search_Domain_Mutation_Response>;
  /** update single row of the table: "search_domain" */
  update_search_domain_by_pk?: Maybe<Search_Domain>;
  /** update multiples rows of table: "search_domain" */
  update_search_domain_many?: Maybe<Array<Maybe<Search_Domain_Mutation_Response>>>;
  /** update data of the table: "search_sessions" */
  update_search_sessions?: Maybe<Search_Sessions_Mutation_Response>;
  /** update single row of the table: "search_sessions" */
  update_search_sessions_by_pk?: Maybe<Search_Sessions>;
  /** update multiples rows of table: "search_sessions" */
  update_search_sessions_many?: Maybe<Array<Maybe<Search_Sessions_Mutation_Response>>>;
  /** update data of the table: "text_content" */
  update_text_content?: Maybe<Text_Content_Mutation_Response>;
  /** update single row of the table: "text_content" */
  update_text_content_by_pk?: Maybe<Text_Content>;
  /** update multiples rows of table: "text_content" */
  update_text_content_many?: Maybe<Array<Maybe<Text_Content_Mutation_Response>>>;
  /** update data of the table: "translations" */
  update_translations?: Maybe<Translations_Mutation_Response>;
  /** update single row of the table: "translations" */
  update_translations_by_pk?: Maybe<Translations>;
  /** update multiples rows of table: "translations" */
  update_translations_many?: Maybe<Array<Maybe<Translations_Mutation_Response>>>;
  /** update data of the table: "url_slug" */
  update_url_slug?: Maybe<Url_Slug_Mutation_Response>;
  /** update single row of the table: "url_slug" */
  update_url_slug_by_pk?: Maybe<Url_Slug>;
  /** update multiples rows of table: "url_slug" */
  update_url_slug_many?: Maybe<Array<Maybe<Url_Slug_Mutation_Response>>>;
  /** update data of the table: "user_card_information" */
  update_user_card_information?: Maybe<User_Card_Information_Mutation_Response>;
  /** update single row of the table: "user_card_information" */
  update_user_card_information_by_pk?: Maybe<User_Card_Information>;
  /** update multiples rows of table: "user_card_information" */
  update_user_card_information_many?: Maybe<Array<Maybe<User_Card_Information_Mutation_Response>>>;
  /** update data of the table: "user_filters" */
  update_user_filters?: Maybe<User_Filters_Mutation_Response>;
  /** update single row of the table: "user_filters" */
  update_user_filters_by_pk?: Maybe<User_Filters>;
  /** update data of the table: "user_filters_features" */
  update_user_filters_features?: Maybe<User_Filters_Features_Mutation_Response>;
  /** update single row of the table: "user_filters_features" */
  update_user_filters_features_by_pk?: Maybe<User_Filters_Features>;
  /** update multiples rows of table: "user_filters_features" */
  update_user_filters_features_many?: Maybe<Array<Maybe<User_Filters_Features_Mutation_Response>>>;
  /** update multiples rows of table: "user_filters" */
  update_user_filters_many?: Maybe<Array<Maybe<User_Filters_Mutation_Response>>>;
  /** update data of the table: "user_membership" */
  update_user_membership?: Maybe<User_Membership_Mutation_Response>;
  /** update single row of the table: "user_membership" */
  update_user_membership_by_pk?: Maybe<User_Membership>;
  /** update multiples rows of table: "user_membership" */
  update_user_membership_many?: Maybe<Array<Maybe<User_Membership_Mutation_Response>>>;
  /** update data of the table: "user_preferences" */
  update_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** update single row of the table: "user_preferences" */
  update_user_preferences_by_pk?: Maybe<User_Preferences>;
  /** update multiples rows of table: "user_preferences" */
  update_user_preferences_many?: Maybe<Array<Maybe<User_Preferences_Mutation_Response>>>;
  /** update data of the table: "user_property_notifiation" */
  update_user_property_notifiation?: Maybe<User_Property_Notifiation_Mutation_Response>;
  /** update single row of the table: "user_property_notifiation" */
  update_user_property_notifiation_by_pk?: Maybe<User_Property_Notifiation>;
  /** update multiples rows of table: "user_property_notifiation" */
  update_user_property_notifiation_many?: Maybe<Array<Maybe<User_Property_Notifiation_Mutation_Response>>>;
  /** update data of the table: "user_saved_properties" */
  update_user_saved_properties?: Maybe<User_Saved_Properties_Mutation_Response>;
  /** update single row of the table: "user_saved_properties" */
  update_user_saved_properties_by_pk?: Maybe<User_Saved_Properties>;
  /** update multiples rows of table: "user_saved_properties" */
  update_user_saved_properties_many?: Maybe<Array<Maybe<User_Saved_Properties_Mutation_Response>>>;
  /** update data of the table: "user_seen_property" */
  update_user_seen_property?: Maybe<User_Seen_Property_Mutation_Response>;
  /** update single row of the table: "user_seen_property" */
  update_user_seen_property_by_pk?: Maybe<User_Seen_Property>;
  /** update multiples rows of table: "user_seen_property" */
  update_user_seen_property_many?: Maybe<Array<Maybe<User_Seen_Property_Mutation_Response>>>;
  /** update multiples rows of table: "auth.users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update multiples rows of table: "storage.virus" */
  update_virus_many?: Maybe<Array<Maybe<Virus_Mutation_Response>>>;
  /** update data of the table: "zone_data_method" */
  update_zone_data_method?: Maybe<Zone_Data_Method_Mutation_Response>;
  /** update single row of the table: "zone_data_method" */
  update_zone_data_method_by_pk?: Maybe<Zone_Data_Method>;
  /** update multiples rows of table: "zone_data_method" */
  update_zone_data_method_many?: Maybe<Array<Maybe<Zone_Data_Method_Mutation_Response>>>;
  /** Action to verify code */
  verify_sms_code?: Maybe<VerfiyCodeOutput>;
};


/** mutation root */
export type Mutation_RootDeleteAuthProviderArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthProviderRequestsArgs = {
  where: AuthProviderRequests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthProvidersArgs = {
  where: AuthProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenTypesArgs = {
  where: AuthRefreshTokenTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthRoleArgs = {
  role: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthRolesArgs = {
  where: AuthRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserProvidersArgs = {
  where: AuthUserProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserRolesArgs = {
  where: AuthUserRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserSecurityKeysArgs = {
  where: AuthUserSecurityKeys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteBucketArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteBucketsArgs = {
  where: Buckets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteFilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteVirusArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVirusesArgs = {
  where: Virus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_TypesArgs = {
  where: Address_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_Types_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AgencyArgs = {
  where: Agency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Agency_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AmenitiesArgs = {
  where: Amenities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Chat_Response_TypeArgs = {
  where: Chat_Response_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Response_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CityArgs = {
  where: City_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_City_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_City_ZoneArgs = {
  where: City_Zone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_City_Zone_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_City_Zone_OriginArgs = {
  where: City_Zone_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_City_Zone_Origin_By_PkArgs = {
  origin: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Construction_StatusArgs = {
  where: Construction_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Construction_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ContactArgs = {
  where: Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_Phone_NumberArgs = {
  where: Contact_Phone_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Phone_Number_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_TypeArgs = {
  where: Contact_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Content_TypeArgs = {
  where: Content_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Type_By_PkArgs = {
  content_type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CurrencyArgs = {
  where: Currency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_By_PkArgs = {
  currency: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Currency_Exchange_RatesArgs = {
  where: Currency_Exchange_Rates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_Exchange_Rates_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EntitiesArgs = {
  where: Entities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entities_By_PkArgs = {
  entity_type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FeatureArgs = {
  where: Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Feature_TypeArgs = {
  where: Feature_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feature_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Furnishing_StatusArgs = {
  where: Furnishing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Furnishing_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HeatingTypeArgs = {
  where: HeatingType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_HeatingType_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LanguagesArgs = {
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Languages_By_PkArgs = {
  language_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Membership_PackageArgs = {
  where: Membership_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Membership_Package_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Natural_Language_QueriesArgs = {
  where: Natural_Language_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Natural_Language_Queries_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_AmenitiesArgs = {
  where: Notification_Amenities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_FeatureArgs = {
  where: Notification_Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_Property_TypeArgs = {
  where: Notification_Property_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Property_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_TypeArgs = {
  where: Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Parking_TypeArgs = {
  where: Parking_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parking_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PropertyArgs = {
  where: Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_AmenitiesArgs = {
  where: Property_Amenities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_ClusterArgs = {
  where: Property_Cluster_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Cluster_By_PkArgs = {
  cluster: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_CompositionArgs = {
  where: Property_Composition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Composition_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_FeatureArgs = {
  where: Property_Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_ImageArgs = {
  where: Property_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Image_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_ListArgs = {
  where: Property_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_List_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_OriginArgs = {
  where: Property_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Origin_By_PkArgs = {
  origin: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_Origin_TypeArgs = {
  where: Property_Origin_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Origin_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_Price_HistoryArgs = {
  where: Property_Price_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Price_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_SourceArgs = {
  where: Property_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Source_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_StatusArgs = {
  where: Property_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_TypeArgs = {
  where: Property_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Property_ViewsArgs = {
  where: Property_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Property_Views_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Queries_Property_ResultArgs = {
  where: Queries_Property_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Queries_Property_Result_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Query_ClassificationArgs = {
  where: Query_Classification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Query_Classification_By_PkArgs = {
  class: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Raw_LocationArgs = {
  where: Raw_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Raw_Location_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RedirectArgs = {
  where: Redirect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Redirect_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Search_ContextArgs = {
  where: Search_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Search_Context_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Search_DomainArgs = {
  where: Search_Domain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Search_Domain_By_PkArgs = {
  domain: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Search_SessionsArgs = {
  where: Search_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Search_Sessions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Text_ContentArgs = {
  where: Text_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Text_Content_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TranslationsArgs = {
  where: Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Translations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Url_SlugArgs = {
  where: Url_Slug_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Url_Slug_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Card_InformationArgs = {
  where: User_Card_Information_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Card_Information_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_FiltersArgs = {
  where: User_Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Filters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Filters_FeaturesArgs = {
  where: User_Filters_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Filters_Features_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_MembershipArgs = {
  where: User_Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Membership_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_PreferencesArgs = {
  where: User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Preferences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Property_NotifiationArgs = {
  where: User_Property_Notifiation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Property_Notifiation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Saved_PropertiesArgs = {
  where: User_Saved_Properties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Saved_Properties_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Seen_PropertyArgs = {
  where: User_Seen_Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Seen_Property_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Zone_Data_MethodArgs = {
  where: Zone_Data_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zone_Data_Method_By_PkArgs = {
  method: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInitialize_OrderArgs = {
  object: InsertOrderOrderInsertInput;
};


/** mutation root */
export type Mutation_RootInsertAuthProviderArgs = {
  object: AuthProviders_Insert_Input;
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthProviderRequestArgs = {
  object: AuthProviderRequests_Insert_Input;
  on_conflict?: InputMaybe<AuthProviderRequests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthProviderRequestsArgs = {
  objects: Array<AuthProviderRequests_Insert_Input>;
  on_conflict?: InputMaybe<AuthProviderRequests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthProvidersArgs = {
  objects: Array<AuthProviders_Insert_Input>;
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenArgs = {
  object: AuthRefreshTokens_Insert_Input;
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenTypeArgs = {
  object: AuthRefreshTokenTypes_Insert_Input;
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenTypesArgs = {
  objects: Array<AuthRefreshTokenTypes_Insert_Input>;
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokensArgs = {
  objects: Array<AuthRefreshTokens_Insert_Input>;
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRoleArgs = {
  object: AuthRoles_Insert_Input;
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRolesArgs = {
  objects: Array<AuthRoles_Insert_Input>;
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserProviderArgs = {
  object: AuthUserProviders_Insert_Input;
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserProvidersArgs = {
  objects: Array<AuthUserProviders_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserRoleArgs = {
  object: AuthUserRoles_Insert_Input;
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserRolesArgs = {
  objects: Array<AuthUserRoles_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserSecurityKeyArgs = {
  object: AuthUserSecurityKeys_Insert_Input;
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserSecurityKeysArgs = {
  objects: Array<AuthUserSecurityKeys_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertBucketArgs = {
  object: Buckets_Insert_Input;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertBucketsArgs = {
  objects: Array<Buckets_Insert_Input>;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertFileArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertFilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUserArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertVirusArgs = {
  object: Virus_Insert_Input;
  on_conflict?: InputMaybe<Virus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertVirusesArgs = {
  objects: Array<Virus_Insert_Input>;
  on_conflict?: InputMaybe<Virus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_TypesArgs = {
  objects: Array<Address_Types_Insert_Input>;
  on_conflict?: InputMaybe<Address_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_Types_OneArgs = {
  object: Address_Types_Insert_Input;
  on_conflict?: InputMaybe<Address_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AgencyArgs = {
  objects: Array<Agency_Insert_Input>;
  on_conflict?: InputMaybe<Agency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Agency_OneArgs = {
  object: Agency_Insert_Input;
  on_conflict?: InputMaybe<Agency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AmenitiesArgs = {
  objects: Array<Amenities_Insert_Input>;
  on_conflict?: InputMaybe<Amenities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Amenities_OneArgs = {
  object: Amenities_Insert_Input;
  on_conflict?: InputMaybe<Amenities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Response_TypeArgs = {
  objects: Array<Chat_Response_Type_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Response_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Response_Type_OneArgs = {
  object: Chat_Response_Type_Insert_Input;
  on_conflict?: InputMaybe<Chat_Response_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CityArgs = {
  objects: Array<City_Insert_Input>;
  on_conflict?: InputMaybe<City_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_City_OneArgs = {
  object: City_Insert_Input;
  on_conflict?: InputMaybe<City_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_City_ZoneArgs = {
  objects: Array<City_Zone_Insert_Input>;
  on_conflict?: InputMaybe<City_Zone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_City_Zone_OneArgs = {
  object: City_Zone_Insert_Input;
  on_conflict?: InputMaybe<City_Zone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_City_Zone_OriginArgs = {
  objects: Array<City_Zone_Origin_Insert_Input>;
  on_conflict?: InputMaybe<City_Zone_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_City_Zone_Origin_OneArgs = {
  object: City_Zone_Origin_Insert_Input;
  on_conflict?: InputMaybe<City_Zone_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Construction_StatusArgs = {
  objects: Array<Construction_Status_Insert_Input>;
  on_conflict?: InputMaybe<Construction_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Construction_Status_OneArgs = {
  object: Construction_Status_Insert_Input;
  on_conflict?: InputMaybe<Construction_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactArgs = {
  objects: Array<Contact_Insert_Input>;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_OneArgs = {
  object: Contact_Insert_Input;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Phone_NumberArgs = {
  objects: Array<Contact_Phone_Number_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Phone_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Phone_Number_OneArgs = {
  object: Contact_Phone_Number_Insert_Input;
  on_conflict?: InputMaybe<Contact_Phone_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_TypeArgs = {
  objects: Array<Contact_Type_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Type_OneArgs = {
  object: Contact_Type_Insert_Input;
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_TypeArgs = {
  objects: Array<Content_Type_Insert_Input>;
  on_conflict?: InputMaybe<Content_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Type_OneArgs = {
  object: Content_Type_Insert_Input;
  on_conflict?: InputMaybe<Content_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurrencyArgs = {
  objects: Array<Currency_Insert_Input>;
  on_conflict?: InputMaybe<Currency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_Exchange_RatesArgs = {
  objects: Array<Currency_Exchange_Rates_Insert_Input>;
  on_conflict?: InputMaybe<Currency_Exchange_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_Exchange_Rates_OneArgs = {
  object: Currency_Exchange_Rates_Insert_Input;
  on_conflict?: InputMaybe<Currency_Exchange_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_OneArgs = {
  object: Currency_Insert_Input;
  on_conflict?: InputMaybe<Currency_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntitiesArgs = {
  objects: Array<Entities_Insert_Input>;
  on_conflict?: InputMaybe<Entities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entities_OneArgs = {
  object: Entities_Insert_Input;
  on_conflict?: InputMaybe<Entities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeatureArgs = {
  objects: Array<Feature_Insert_Input>;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_OneArgs = {
  object: Feature_Insert_Input;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_TypeArgs = {
  objects: Array<Feature_Type_Insert_Input>;
  on_conflict?: InputMaybe<Feature_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_Type_OneArgs = {
  object: Feature_Type_Insert_Input;
  on_conflict?: InputMaybe<Feature_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Furnishing_StatusArgs = {
  objects: Array<Furnishing_Status_Insert_Input>;
  on_conflict?: InputMaybe<Furnishing_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Furnishing_Status_OneArgs = {
  object: Furnishing_Status_Insert_Input;
  on_conflict?: InputMaybe<Furnishing_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeatingTypeArgs = {
  objects: Array<HeatingType_Insert_Input>;
  on_conflict?: InputMaybe<HeatingType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeatingType_OneArgs = {
  object: HeatingType_Insert_Input;
  on_conflict?: InputMaybe<HeatingType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LanguagesArgs = {
  objects: Array<Languages_Insert_Input>;
  on_conflict?: InputMaybe<Languages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Languages_OneArgs = {
  object: Languages_Insert_Input;
  on_conflict?: InputMaybe<Languages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_PackageArgs = {
  objects: Array<Membership_Package_Insert_Input>;
  on_conflict?: InputMaybe<Membership_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_Package_OneArgs = {
  object: Membership_Package_Insert_Input;
  on_conflict?: InputMaybe<Membership_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Natural_Language_QueriesArgs = {
  objects: Array<Natural_Language_Queries_Insert_Input>;
  on_conflict?: InputMaybe<Natural_Language_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Natural_Language_Queries_OneArgs = {
  object: Natural_Language_Queries_Insert_Input;
  on_conflict?: InputMaybe<Natural_Language_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_AmenitiesArgs = {
  objects: Array<Notification_Amenities_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Amenities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Amenities_OneArgs = {
  object: Notification_Amenities_Insert_Input;
  on_conflict?: InputMaybe<Notification_Amenities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_FeatureArgs = {
  objects: Array<Notification_Feature_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Feature_OneArgs = {
  object: Notification_Feature_Insert_Input;
  on_conflict?: InputMaybe<Notification_Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Property_TypeArgs = {
  objects: Array<Notification_Property_Type_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Property_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Property_Type_OneArgs = {
  object: Notification_Property_Type_Insert_Input;
  on_conflict?: InputMaybe<Notification_Property_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_TypeArgs = {
  objects: Array<Notification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Type_OneArgs = {
  object: Notification_Type_Insert_Input;
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>;
  on_conflict?: InputMaybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input;
  on_conflict?: InputMaybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parking_TypeArgs = {
  objects: Array<Parking_Type_Insert_Input>;
  on_conflict?: InputMaybe<Parking_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parking_Type_OneArgs = {
  object: Parking_Type_Insert_Input;
  on_conflict?: InputMaybe<Parking_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PropertyArgs = {
  objects: Array<Property_Insert_Input>;
  on_conflict?: InputMaybe<Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_AmenitiesArgs = {
  objects: Array<Property_Amenities_Insert_Input>;
  on_conflict?: InputMaybe<Property_Amenities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Amenities_OneArgs = {
  object: Property_Amenities_Insert_Input;
  on_conflict?: InputMaybe<Property_Amenities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_ClusterArgs = {
  objects: Array<Property_Cluster_Insert_Input>;
  on_conflict?: InputMaybe<Property_Cluster_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Cluster_OneArgs = {
  object: Property_Cluster_Insert_Input;
  on_conflict?: InputMaybe<Property_Cluster_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_CompositionArgs = {
  objects: Array<Property_Composition_Insert_Input>;
  on_conflict?: InputMaybe<Property_Composition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Composition_OneArgs = {
  object: Property_Composition_Insert_Input;
  on_conflict?: InputMaybe<Property_Composition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_FeatureArgs = {
  objects: Array<Property_Feature_Insert_Input>;
  on_conflict?: InputMaybe<Property_Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Feature_OneArgs = {
  object: Property_Feature_Insert_Input;
  on_conflict?: InputMaybe<Property_Feature_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_ImageArgs = {
  objects: Array<Property_Image_Insert_Input>;
  on_conflict?: InputMaybe<Property_Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Image_OneArgs = {
  object: Property_Image_Insert_Input;
  on_conflict?: InputMaybe<Property_Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_ListArgs = {
  objects: Array<Property_List_Insert_Input>;
  on_conflict?: InputMaybe<Property_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_List_OneArgs = {
  object: Property_List_Insert_Input;
  on_conflict?: InputMaybe<Property_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_OneArgs = {
  object: Property_Insert_Input;
  on_conflict?: InputMaybe<Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_OriginArgs = {
  objects: Array<Property_Origin_Insert_Input>;
  on_conflict?: InputMaybe<Property_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Origin_OneArgs = {
  object: Property_Origin_Insert_Input;
  on_conflict?: InputMaybe<Property_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Origin_TypeArgs = {
  objects: Array<Property_Origin_Type_Insert_Input>;
  on_conflict?: InputMaybe<Property_Origin_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Origin_Type_OneArgs = {
  object: Property_Origin_Type_Insert_Input;
  on_conflict?: InputMaybe<Property_Origin_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Price_HistoryArgs = {
  objects: Array<Property_Price_History_Insert_Input>;
  on_conflict?: InputMaybe<Property_Price_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Price_History_OneArgs = {
  object: Property_Price_History_Insert_Input;
  on_conflict?: InputMaybe<Property_Price_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_SourceArgs = {
  objects: Array<Property_Source_Insert_Input>;
  on_conflict?: InputMaybe<Property_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Source_OneArgs = {
  object: Property_Source_Insert_Input;
  on_conflict?: InputMaybe<Property_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_StatusArgs = {
  objects: Array<Property_Status_Insert_Input>;
  on_conflict?: InputMaybe<Property_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Status_OneArgs = {
  object: Property_Status_Insert_Input;
  on_conflict?: InputMaybe<Property_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_TypeArgs = {
  objects: Array<Property_Type_Insert_Input>;
  on_conflict?: InputMaybe<Property_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Type_OneArgs = {
  object: Property_Type_Insert_Input;
  on_conflict?: InputMaybe<Property_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_ViewsArgs = {
  objects: Array<Property_Views_Insert_Input>;
  on_conflict?: InputMaybe<Property_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Property_Views_OneArgs = {
  object: Property_Views_Insert_Input;
  on_conflict?: InputMaybe<Property_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Queries_Property_ResultArgs = {
  objects: Array<Queries_Property_Result_Insert_Input>;
  on_conflict?: InputMaybe<Queries_Property_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Queries_Property_Result_OneArgs = {
  object: Queries_Property_Result_Insert_Input;
  on_conflict?: InputMaybe<Queries_Property_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Query_ClassificationArgs = {
  objects: Array<Query_Classification_Insert_Input>;
  on_conflict?: InputMaybe<Query_Classification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Query_Classification_OneArgs = {
  object: Query_Classification_Insert_Input;
  on_conflict?: InputMaybe<Query_Classification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_LocationArgs = {
  objects: Array<Raw_Location_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Raw_Location_OneArgs = {
  object: Raw_Location_Insert_Input;
  on_conflict?: InputMaybe<Raw_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RedirectArgs = {
  objects: Array<Redirect_Insert_Input>;
  on_conflict?: InputMaybe<Redirect_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Redirect_OneArgs = {
  object: Redirect_Insert_Input;
  on_conflict?: InputMaybe<Redirect_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_ContextArgs = {
  objects: Array<Search_Context_Insert_Input>;
  on_conflict?: InputMaybe<Search_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_Context_OneArgs = {
  object: Search_Context_Insert_Input;
  on_conflict?: InputMaybe<Search_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_DomainArgs = {
  objects: Array<Search_Domain_Insert_Input>;
  on_conflict?: InputMaybe<Search_Domain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_Domain_OneArgs = {
  object: Search_Domain_Insert_Input;
  on_conflict?: InputMaybe<Search_Domain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_SessionsArgs = {
  objects: Array<Search_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Search_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Search_Sessions_OneArgs = {
  object: Search_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Search_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_ContentArgs = {
  objects: Array<Text_Content_Insert_Input>;
  on_conflict?: InputMaybe<Text_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Text_Content_OneArgs = {
  object: Text_Content_Insert_Input;
  on_conflict?: InputMaybe<Text_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TranslationsArgs = {
  objects: Array<Translations_Insert_Input>;
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Translations_OneArgs = {
  object: Translations_Insert_Input;
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Url_SlugArgs = {
  objects: Array<Url_Slug_Insert_Input>;
  on_conflict?: InputMaybe<Url_Slug_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Url_Slug_OneArgs = {
  object: Url_Slug_Insert_Input;
  on_conflict?: InputMaybe<Url_Slug_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Card_InformationArgs = {
  objects: Array<User_Card_Information_Insert_Input>;
  on_conflict?: InputMaybe<User_Card_Information_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Card_Information_OneArgs = {
  object: User_Card_Information_Insert_Input;
  on_conflict?: InputMaybe<User_Card_Information_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_FiltersArgs = {
  objects: Array<User_Filters_Insert_Input>;
  on_conflict?: InputMaybe<User_Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Filters_FeaturesArgs = {
  objects: Array<User_Filters_Features_Insert_Input>;
  on_conflict?: InputMaybe<User_Filters_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Filters_Features_OneArgs = {
  object: User_Filters_Features_Insert_Input;
  on_conflict?: InputMaybe<User_Filters_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Filters_OneArgs = {
  object: User_Filters_Insert_Input;
  on_conflict?: InputMaybe<User_Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_MembershipArgs = {
  objects: Array<User_Membership_Insert_Input>;
  on_conflict?: InputMaybe<User_Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Membership_OneArgs = {
  object: User_Membership_Insert_Input;
  on_conflict?: InputMaybe<User_Membership_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PreferencesArgs = {
  objects: Array<User_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Preferences_OneArgs = {
  object: User_Preferences_Insert_Input;
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Property_NotifiationArgs = {
  objects: Array<User_Property_Notifiation_Insert_Input>;
  on_conflict?: InputMaybe<User_Property_Notifiation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Property_Notifiation_OneArgs = {
  object: User_Property_Notifiation_Insert_Input;
  on_conflict?: InputMaybe<User_Property_Notifiation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Saved_PropertiesArgs = {
  objects: Array<User_Saved_Properties_Insert_Input>;
  on_conflict?: InputMaybe<User_Saved_Properties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Saved_Properties_OneArgs = {
  object: User_Saved_Properties_Insert_Input;
  on_conflict?: InputMaybe<User_Saved_Properties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Seen_PropertyArgs = {
  objects: Array<User_Seen_Property_Insert_Input>;
  on_conflict?: InputMaybe<User_Seen_Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Seen_Property_OneArgs = {
  object: User_Seen_Property_Insert_Input;
  on_conflict?: InputMaybe<User_Seen_Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zone_Data_MethodArgs = {
  objects: Array<Zone_Data_Method_Insert_Input>;
  on_conflict?: InputMaybe<Zone_Data_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zone_Data_Method_OneArgs = {
  object: Zone_Data_Method_Insert_Input;
  on_conflict?: InputMaybe<Zone_Data_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootPayment_SuccessArgs = {
  body: PaymentSuccessInput;
};


/** mutation root */
export type Mutation_RootProcess_User_NotificationArgs = {
  body: Input;
};


/** mutation root */
export type Mutation_RootPromptSearchArgs = {
  body?: InputMaybe<QuestionInput>;
};


/** mutation root */
export type Mutation_RootQuerySearchArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootSend_Verification_CodeArgs = {
  body: PhoneInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthProviderArgs = {
  _set?: InputMaybe<AuthProviders_Set_Input>;
  pk_columns: AuthProviders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthProviderRequestArgs = {
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  pk_columns: AuthProviderRequests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthProviderRequestsArgs = {
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  where: AuthProviderRequests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthProvidersArgs = {
  _set?: InputMaybe<AuthProviders_Set_Input>;
  where: AuthProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenArgs = {
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  pk_columns: AuthRefreshTokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenTypeArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  pk_columns: AuthRefreshTokenTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenTypesArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  where: AuthRefreshTokenTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokensArgs = {
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  where: AuthRefreshTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthRoleArgs = {
  _set?: InputMaybe<AuthRoles_Set_Input>;
  pk_columns: AuthRoles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthRolesArgs = {
  _set?: InputMaybe<AuthRoles_Set_Input>;
  where: AuthRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserProviderArgs = {
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  pk_columns: AuthUserProviders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserProvidersArgs = {
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  where: AuthUserProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserRoleArgs = {
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  pk_columns: AuthUserRoles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserRolesArgs = {
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  where: AuthUserRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserSecurityKeyArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  pk_columns: AuthUserSecurityKeys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserSecurityKeysArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  where: AuthUserSecurityKeys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateBucketArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  pk_columns: Buckets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateBucketsArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  where: Buckets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateFileArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _inc?: InputMaybe<Files_Inc_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateFilesArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _inc?: InputMaybe<Files_Inc_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateVirusArgs = {
  _append?: InputMaybe<Virus_Append_Input>;
  _delete_at_path?: InputMaybe<Virus_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Virus_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Virus_Delete_Key_Input>;
  _prepend?: InputMaybe<Virus_Prepend_Input>;
  _set?: InputMaybe<Virus_Set_Input>;
  pk_columns: Virus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateVirusesArgs = {
  _append?: InputMaybe<Virus_Append_Input>;
  _delete_at_path?: InputMaybe<Virus_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Virus_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Virus_Delete_Key_Input>;
  _prepend?: InputMaybe<Virus_Prepend_Input>;
  _set?: InputMaybe<Virus_Set_Input>;
  where: Virus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_TypesArgs = {
  _set?: InputMaybe<Address_Types_Set_Input>;
  where: Address_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_Types_By_PkArgs = {
  _set?: InputMaybe<Address_Types_Set_Input>;
  pk_columns: Address_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_Types_ManyArgs = {
  updates: Array<Address_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AgencyArgs = {
  _set?: InputMaybe<Agency_Set_Input>;
  where: Agency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_By_PkArgs = {
  _set?: InputMaybe<Agency_Set_Input>;
  pk_columns: Agency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Agency_ManyArgs = {
  updates: Array<Agency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AmenitiesArgs = {
  _set?: InputMaybe<Amenities_Set_Input>;
  where: Amenities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Amenities_By_PkArgs = {
  _set?: InputMaybe<Amenities_Set_Input>;
  pk_columns: Amenities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Amenities_ManyArgs = {
  updates: Array<Amenities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProviderRequests_ManyArgs = {
  updates: Array<AuthProviderRequests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProviders_ManyArgs = {
  updates: Array<AuthProviders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthRefreshTokenTypes_ManyArgs = {
  updates: Array<AuthRefreshTokenTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthRefreshTokens_ManyArgs = {
  updates: Array<AuthRefreshTokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthRoles_ManyArgs = {
  updates: Array<AuthRoles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthUserProviders_ManyArgs = {
  updates: Array<AuthUserProviders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthUserRoles_ManyArgs = {
  updates: Array<AuthUserRoles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthUserSecurityKeys_ManyArgs = {
  updates: Array<AuthUserSecurityKeys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Buckets_ManyArgs = {
  updates: Array<Buckets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Response_TypeArgs = {
  _set?: InputMaybe<Chat_Response_Type_Set_Input>;
  where: Chat_Response_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Response_Type_By_PkArgs = {
  _set?: InputMaybe<Chat_Response_Type_Set_Input>;
  pk_columns: Chat_Response_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Response_Type_ManyArgs = {
  updates: Array<Chat_Response_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CityArgs = {
  _set?: InputMaybe<City_Set_Input>;
  where: City_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_City_By_PkArgs = {
  _set?: InputMaybe<City_Set_Input>;
  pk_columns: City_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_City_ManyArgs = {
  updates: Array<City_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_City_ZoneArgs = {
  _append?: InputMaybe<City_Zone_Append_Input>;
  _delete_at_path?: InputMaybe<City_Zone_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<City_Zone_Delete_Elem_Input>;
  _delete_key?: InputMaybe<City_Zone_Delete_Key_Input>;
  _inc?: InputMaybe<City_Zone_Inc_Input>;
  _prepend?: InputMaybe<City_Zone_Prepend_Input>;
  _set?: InputMaybe<City_Zone_Set_Input>;
  where: City_Zone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_City_Zone_By_PkArgs = {
  _append?: InputMaybe<City_Zone_Append_Input>;
  _delete_at_path?: InputMaybe<City_Zone_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<City_Zone_Delete_Elem_Input>;
  _delete_key?: InputMaybe<City_Zone_Delete_Key_Input>;
  _inc?: InputMaybe<City_Zone_Inc_Input>;
  _prepend?: InputMaybe<City_Zone_Prepend_Input>;
  _set?: InputMaybe<City_Zone_Set_Input>;
  pk_columns: City_Zone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_City_Zone_ManyArgs = {
  updates: Array<City_Zone_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_City_Zone_OriginArgs = {
  _set?: InputMaybe<City_Zone_Origin_Set_Input>;
  where: City_Zone_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_City_Zone_Origin_By_PkArgs = {
  _set?: InputMaybe<City_Zone_Origin_Set_Input>;
  pk_columns: City_Zone_Origin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_City_Zone_Origin_ManyArgs = {
  updates: Array<City_Zone_Origin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_StatusArgs = {
  _set?: InputMaybe<Construction_Status_Set_Input>;
  where: Construction_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_Status_By_PkArgs = {
  _set?: InputMaybe<Construction_Status_Set_Input>;
  pk_columns: Construction_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_Status_ManyArgs = {
  updates: Array<Construction_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactArgs = {
  _set?: InputMaybe<Contact_Set_Input>;
  where: Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_By_PkArgs = {
  _set?: InputMaybe<Contact_Set_Input>;
  pk_columns: Contact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_ManyArgs = {
  updates: Array<Contact_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Phone_NumberArgs = {
  _set?: InputMaybe<Contact_Phone_Number_Set_Input>;
  where: Contact_Phone_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Phone_Number_By_PkArgs = {
  _set?: InputMaybe<Contact_Phone_Number_Set_Input>;
  pk_columns: Contact_Phone_Number_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Phone_Number_ManyArgs = {
  updates: Array<Contact_Phone_Number_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_TypeArgs = {
  _set?: InputMaybe<Contact_Type_Set_Input>;
  where: Contact_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Type_By_PkArgs = {
  _set?: InputMaybe<Contact_Type_Set_Input>;
  pk_columns: Contact_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Type_ManyArgs = {
  updates: Array<Contact_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Content_TypeArgs = {
  _set?: InputMaybe<Content_Type_Set_Input>;
  where: Content_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Type_By_PkArgs = {
  _set?: InputMaybe<Content_Type_Set_Input>;
  pk_columns: Content_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Type_ManyArgs = {
  updates: Array<Content_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_ManyArgs = {
  updates: Array<Countries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CurrencyArgs = {
  _set?: InputMaybe<Currency_Set_Input>;
  where: Currency_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_By_PkArgs = {
  _set?: InputMaybe<Currency_Set_Input>;
  pk_columns: Currency_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Exchange_RatesArgs = {
  _inc?: InputMaybe<Currency_Exchange_Rates_Inc_Input>;
  _set?: InputMaybe<Currency_Exchange_Rates_Set_Input>;
  where: Currency_Exchange_Rates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Exchange_Rates_By_PkArgs = {
  _inc?: InputMaybe<Currency_Exchange_Rates_Inc_Input>;
  _set?: InputMaybe<Currency_Exchange_Rates_Set_Input>;
  pk_columns: Currency_Exchange_Rates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Exchange_Rates_ManyArgs = {
  updates: Array<Currency_Exchange_Rates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_ManyArgs = {
  updates: Array<Currency_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EntitiesArgs = {
  _set?: InputMaybe<Entities_Set_Input>;
  where: Entities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entities_By_PkArgs = {
  _set?: InputMaybe<Entities_Set_Input>;
  pk_columns: Entities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entities_ManyArgs = {
  updates: Array<Entities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeatureArgs = {
  _set?: InputMaybe<Feature_Set_Input>;
  where: Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_By_PkArgs = {
  _set?: InputMaybe<Feature_Set_Input>;
  pk_columns: Feature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_ManyArgs = {
  updates: Array<Feature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_TypeArgs = {
  _set?: InputMaybe<Feature_Type_Set_Input>;
  where: Feature_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_Type_By_PkArgs = {
  _set?: InputMaybe<Feature_Type_Set_Input>;
  pk_columns: Feature_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_Type_ManyArgs = {
  updates: Array<Feature_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Furnishing_StatusArgs = {
  _set?: InputMaybe<Furnishing_Status_Set_Input>;
  where: Furnishing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Furnishing_Status_By_PkArgs = {
  _set?: InputMaybe<Furnishing_Status_Set_Input>;
  pk_columns: Furnishing_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Furnishing_Status_ManyArgs = {
  updates: Array<Furnishing_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HeatingTypeArgs = {
  _set?: InputMaybe<HeatingType_Set_Input>;
  where: HeatingType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_HeatingType_By_PkArgs = {
  _set?: InputMaybe<HeatingType_Set_Input>;
  pk_columns: HeatingType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HeatingType_ManyArgs = {
  updates: Array<HeatingType_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LanguagesArgs = {
  _inc?: InputMaybe<Languages_Inc_Input>;
  _set?: InputMaybe<Languages_Set_Input>;
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_By_PkArgs = {
  _inc?: InputMaybe<Languages_Inc_Input>;
  _set?: InputMaybe<Languages_Set_Input>;
  pk_columns: Languages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_ManyArgs = {
  updates: Array<Languages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _inc?: InputMaybe<Location_Inc_Input>;
  _set?: InputMaybe<Location_Set_Input>;
  where: Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _inc?: InputMaybe<Location_Inc_Input>;
  _set?: InputMaybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Location_ManyArgs = {
  updates: Array<Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_PackageArgs = {
  _inc?: InputMaybe<Membership_Package_Inc_Input>;
  _set?: InputMaybe<Membership_Package_Set_Input>;
  where: Membership_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_Package_By_PkArgs = {
  _inc?: InputMaybe<Membership_Package_Inc_Input>;
  _set?: InputMaybe<Membership_Package_Set_Input>;
  pk_columns: Membership_Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_Package_ManyArgs = {
  updates: Array<Membership_Package_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Natural_Language_QueriesArgs = {
  _append?: InputMaybe<Natural_Language_Queries_Append_Input>;
  _delete_at_path?: InputMaybe<Natural_Language_Queries_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Natural_Language_Queries_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Natural_Language_Queries_Delete_Key_Input>;
  _prepend?: InputMaybe<Natural_Language_Queries_Prepend_Input>;
  _set?: InputMaybe<Natural_Language_Queries_Set_Input>;
  where: Natural_Language_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Natural_Language_Queries_By_PkArgs = {
  _append?: InputMaybe<Natural_Language_Queries_Append_Input>;
  _delete_at_path?: InputMaybe<Natural_Language_Queries_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Natural_Language_Queries_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Natural_Language_Queries_Delete_Key_Input>;
  _prepend?: InputMaybe<Natural_Language_Queries_Prepend_Input>;
  _set?: InputMaybe<Natural_Language_Queries_Set_Input>;
  pk_columns: Natural_Language_Queries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Natural_Language_Queries_ManyArgs = {
  updates: Array<Natural_Language_Queries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _inc?: InputMaybe<Notification_Inc_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_AmenitiesArgs = {
  _set?: InputMaybe<Notification_Amenities_Set_Input>;
  where: Notification_Amenities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Amenities_By_PkArgs = {
  _set?: InputMaybe<Notification_Amenities_Set_Input>;
  pk_columns: Notification_Amenities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Amenities_ManyArgs = {
  updates: Array<Notification_Amenities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _inc?: InputMaybe<Notification_Inc_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  pk_columns: Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_FeatureArgs = {
  _set?: InputMaybe<Notification_Feature_Set_Input>;
  where: Notification_Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Feature_By_PkArgs = {
  _set?: InputMaybe<Notification_Feature_Set_Input>;
  pk_columns: Notification_Feature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Feature_ManyArgs = {
  updates: Array<Notification_Feature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_ManyArgs = {
  updates: Array<Notification_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Property_TypeArgs = {
  _set?: InputMaybe<Notification_Property_Type_Set_Input>;
  where: Notification_Property_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Property_Type_By_PkArgs = {
  _set?: InputMaybe<Notification_Property_Type_Set_Input>;
  pk_columns: Notification_Property_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Property_Type_ManyArgs = {
  updates: Array<Notification_Property_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_TypeArgs = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  where: Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Type_By_PkArgs = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  pk_columns: Notification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Type_ManyArgs = {
  updates: Array<Notification_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _append?: InputMaybe<Order_Append_Input>;
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Order_Delete_Key_Input>;
  _inc?: InputMaybe<Order_Inc_Input>;
  _prepend?: InputMaybe<Order_Prepend_Input>;
  _set?: InputMaybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _append?: InputMaybe<Order_Append_Input>;
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Order_Delete_Key_Input>;
  _inc?: InputMaybe<Order_Inc_Input>;
  _prepend?: InputMaybe<Order_Prepend_Input>;
  _set?: InputMaybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_ManyArgs = {
  updates: Array<Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Parking_TypeArgs = {
  _set?: InputMaybe<Parking_Type_Set_Input>;
  where: Parking_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parking_Type_By_PkArgs = {
  _set?: InputMaybe<Parking_Type_Set_Input>;
  pk_columns: Parking_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parking_Type_ManyArgs = {
  updates: Array<Parking_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PropertyArgs = {
  _set?: InputMaybe<Property_Set_Input>;
  where: Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_AmenitiesArgs = {
  _set?: InputMaybe<Property_Amenities_Set_Input>;
  where: Property_Amenities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Amenities_By_PkArgs = {
  _set?: InputMaybe<Property_Amenities_Set_Input>;
  pk_columns: Property_Amenities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Amenities_ManyArgs = {
  updates: Array<Property_Amenities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_By_PkArgs = {
  _set?: InputMaybe<Property_Set_Input>;
  pk_columns: Property_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ClusterArgs = {
  _set?: InputMaybe<Property_Cluster_Set_Input>;
  where: Property_Cluster_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Cluster_By_PkArgs = {
  _set?: InputMaybe<Property_Cluster_Set_Input>;
  pk_columns: Property_Cluster_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Cluster_ManyArgs = {
  updates: Array<Property_Cluster_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_CompositionArgs = {
  _inc?: InputMaybe<Property_Composition_Inc_Input>;
  _set?: InputMaybe<Property_Composition_Set_Input>;
  where: Property_Composition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Composition_By_PkArgs = {
  _inc?: InputMaybe<Property_Composition_Inc_Input>;
  _set?: InputMaybe<Property_Composition_Set_Input>;
  pk_columns: Property_Composition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Composition_ManyArgs = {
  updates: Array<Property_Composition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_FeatureArgs = {
  _set?: InputMaybe<Property_Feature_Set_Input>;
  where: Property_Feature_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Feature_By_PkArgs = {
  _set?: InputMaybe<Property_Feature_Set_Input>;
  pk_columns: Property_Feature_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Feature_ManyArgs = {
  updates: Array<Property_Feature_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ImageArgs = {
  _set?: InputMaybe<Property_Image_Set_Input>;
  where: Property_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Image_By_PkArgs = {
  _set?: InputMaybe<Property_Image_Set_Input>;
  pk_columns: Property_Image_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Image_ManyArgs = {
  updates: Array<Property_Image_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ListArgs = {
  _append?: InputMaybe<Property_List_Append_Input>;
  _delete_at_path?: InputMaybe<Property_List_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Property_List_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Property_List_Delete_Key_Input>;
  _inc?: InputMaybe<Property_List_Inc_Input>;
  _prepend?: InputMaybe<Property_List_Prepend_Input>;
  _set?: InputMaybe<Property_List_Set_Input>;
  where: Property_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_List_By_PkArgs = {
  _append?: InputMaybe<Property_List_Append_Input>;
  _delete_at_path?: InputMaybe<Property_List_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Property_List_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Property_List_Delete_Key_Input>;
  _inc?: InputMaybe<Property_List_Inc_Input>;
  _prepend?: InputMaybe<Property_List_Prepend_Input>;
  _set?: InputMaybe<Property_List_Set_Input>;
  pk_columns: Property_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_List_ManyArgs = {
  updates: Array<Property_List_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ManyArgs = {
  updates: Array<Property_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_OriginArgs = {
  _set?: InputMaybe<Property_Origin_Set_Input>;
  where: Property_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Origin_By_PkArgs = {
  _set?: InputMaybe<Property_Origin_Set_Input>;
  pk_columns: Property_Origin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Origin_ManyArgs = {
  updates: Array<Property_Origin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Origin_TypeArgs = {
  _set?: InputMaybe<Property_Origin_Type_Set_Input>;
  where: Property_Origin_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Origin_Type_By_PkArgs = {
  _set?: InputMaybe<Property_Origin_Type_Set_Input>;
  pk_columns: Property_Origin_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Origin_Type_ManyArgs = {
  updates: Array<Property_Origin_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Price_HistoryArgs = {
  _inc?: InputMaybe<Property_Price_History_Inc_Input>;
  _set?: InputMaybe<Property_Price_History_Set_Input>;
  where: Property_Price_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Price_History_By_PkArgs = {
  _inc?: InputMaybe<Property_Price_History_Inc_Input>;
  _set?: InputMaybe<Property_Price_History_Set_Input>;
  pk_columns: Property_Price_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Price_History_ManyArgs = {
  updates: Array<Property_Price_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_SourceArgs = {
  _set?: InputMaybe<Property_Source_Set_Input>;
  where: Property_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Source_By_PkArgs = {
  _set?: InputMaybe<Property_Source_Set_Input>;
  pk_columns: Property_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Source_ManyArgs = {
  updates: Array<Property_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_StatusArgs = {
  _set?: InputMaybe<Property_Status_Set_Input>;
  where: Property_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Status_By_PkArgs = {
  _set?: InputMaybe<Property_Status_Set_Input>;
  pk_columns: Property_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Status_ManyArgs = {
  updates: Array<Property_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_TypeArgs = {
  _set?: InputMaybe<Property_Type_Set_Input>;
  where: Property_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Type_By_PkArgs = {
  _set?: InputMaybe<Property_Type_Set_Input>;
  pk_columns: Property_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Type_ManyArgs = {
  updates: Array<Property_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Property_ViewsArgs = {
  _inc?: InputMaybe<Property_Views_Inc_Input>;
  _set?: InputMaybe<Property_Views_Set_Input>;
  where: Property_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Views_By_PkArgs = {
  _inc?: InputMaybe<Property_Views_Inc_Input>;
  _set?: InputMaybe<Property_Views_Set_Input>;
  pk_columns: Property_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Property_Views_ManyArgs = {
  updates: Array<Property_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Queries_Property_ResultArgs = {
  _set?: InputMaybe<Queries_Property_Result_Set_Input>;
  where: Queries_Property_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Queries_Property_Result_By_PkArgs = {
  _set?: InputMaybe<Queries_Property_Result_Set_Input>;
  pk_columns: Queries_Property_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Queries_Property_Result_ManyArgs = {
  updates: Array<Queries_Property_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Query_ClassificationArgs = {
  _set?: InputMaybe<Query_Classification_Set_Input>;
  where: Query_Classification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Query_Classification_By_PkArgs = {
  _set?: InputMaybe<Query_Classification_Set_Input>;
  pk_columns: Query_Classification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Query_Classification_ManyArgs = {
  updates: Array<Query_Classification_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_LocationArgs = {
  _append?: InputMaybe<Raw_Location_Append_Input>;
  _delete_at_path?: InputMaybe<Raw_Location_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Raw_Location_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Raw_Location_Delete_Key_Input>;
  _prepend?: InputMaybe<Raw_Location_Prepend_Input>;
  _set?: InputMaybe<Raw_Location_Set_Input>;
  where: Raw_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Location_By_PkArgs = {
  _append?: InputMaybe<Raw_Location_Append_Input>;
  _delete_at_path?: InputMaybe<Raw_Location_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Raw_Location_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Raw_Location_Delete_Key_Input>;
  _prepend?: InputMaybe<Raw_Location_Prepend_Input>;
  _set?: InputMaybe<Raw_Location_Set_Input>;
  pk_columns: Raw_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Raw_Location_ManyArgs = {
  updates: Array<Raw_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RedirectArgs = {
  _inc?: InputMaybe<Redirect_Inc_Input>;
  _set?: InputMaybe<Redirect_Set_Input>;
  where: Redirect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Redirect_By_PkArgs = {
  _inc?: InputMaybe<Redirect_Inc_Input>;
  _set?: InputMaybe<Redirect_Set_Input>;
  pk_columns: Redirect_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Redirect_ManyArgs = {
  updates: Array<Redirect_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Search_ContextArgs = {
  _append?: InputMaybe<Search_Context_Append_Input>;
  _delete_at_path?: InputMaybe<Search_Context_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Search_Context_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Search_Context_Delete_Key_Input>;
  _prepend?: InputMaybe<Search_Context_Prepend_Input>;
  _set?: InputMaybe<Search_Context_Set_Input>;
  where: Search_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Context_By_PkArgs = {
  _append?: InputMaybe<Search_Context_Append_Input>;
  _delete_at_path?: InputMaybe<Search_Context_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Search_Context_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Search_Context_Delete_Key_Input>;
  _prepend?: InputMaybe<Search_Context_Prepend_Input>;
  _set?: InputMaybe<Search_Context_Set_Input>;
  pk_columns: Search_Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Context_ManyArgs = {
  updates: Array<Search_Context_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Search_DomainArgs = {
  _set?: InputMaybe<Search_Domain_Set_Input>;
  where: Search_Domain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Domain_By_PkArgs = {
  _set?: InputMaybe<Search_Domain_Set_Input>;
  pk_columns: Search_Domain_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Domain_ManyArgs = {
  updates: Array<Search_Domain_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Search_SessionsArgs = {
  _set?: InputMaybe<Search_Sessions_Set_Input>;
  where: Search_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Sessions_By_PkArgs = {
  _set?: InputMaybe<Search_Sessions_Set_Input>;
  pk_columns: Search_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Search_Sessions_ManyArgs = {
  updates: Array<Search_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Text_ContentArgs = {
  _inc?: InputMaybe<Text_Content_Inc_Input>;
  _set?: InputMaybe<Text_Content_Set_Input>;
  where: Text_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Content_By_PkArgs = {
  _inc?: InputMaybe<Text_Content_Inc_Input>;
  _set?: InputMaybe<Text_Content_Set_Input>;
  pk_columns: Text_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Text_Content_ManyArgs = {
  updates: Array<Text_Content_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TranslationsArgs = {
  _inc?: InputMaybe<Translations_Inc_Input>;
  _set?: InputMaybe<Translations_Set_Input>;
  where: Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Translations_By_PkArgs = {
  _inc?: InputMaybe<Translations_Inc_Input>;
  _set?: InputMaybe<Translations_Set_Input>;
  pk_columns: Translations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Translations_ManyArgs = {
  updates: Array<Translations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Url_SlugArgs = {
  _set?: InputMaybe<Url_Slug_Set_Input>;
  where: Url_Slug_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Url_Slug_By_PkArgs = {
  _set?: InputMaybe<Url_Slug_Set_Input>;
  pk_columns: Url_Slug_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Url_Slug_ManyArgs = {
  updates: Array<Url_Slug_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Card_InformationArgs = {
  _set?: InputMaybe<User_Card_Information_Set_Input>;
  where: User_Card_Information_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Card_Information_By_PkArgs = {
  _set?: InputMaybe<User_Card_Information_Set_Input>;
  pk_columns: User_Card_Information_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Card_Information_ManyArgs = {
  updates: Array<User_Card_Information_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_FiltersArgs = {
  _inc?: InputMaybe<User_Filters_Inc_Input>;
  _set?: InputMaybe<User_Filters_Set_Input>;
  where: User_Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Filters_By_PkArgs = {
  _inc?: InputMaybe<User_Filters_Inc_Input>;
  _set?: InputMaybe<User_Filters_Set_Input>;
  pk_columns: User_Filters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Filters_FeaturesArgs = {
  _set?: InputMaybe<User_Filters_Features_Set_Input>;
  where: User_Filters_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Filters_Features_By_PkArgs = {
  _set?: InputMaybe<User_Filters_Features_Set_Input>;
  pk_columns: User_Filters_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Filters_Features_ManyArgs = {
  updates: Array<User_Filters_Features_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Filters_ManyArgs = {
  updates: Array<User_Filters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_MembershipArgs = {
  _set?: InputMaybe<User_Membership_Set_Input>;
  where: User_Membership_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Membership_By_PkArgs = {
  _set?: InputMaybe<User_Membership_Set_Input>;
  pk_columns: User_Membership_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Membership_ManyArgs = {
  updates: Array<User_Membership_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PreferencesArgs = {
  _set?: InputMaybe<User_Preferences_Set_Input>;
  where: User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Preferences_By_PkArgs = {
  _set?: InputMaybe<User_Preferences_Set_Input>;
  pk_columns: User_Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Preferences_ManyArgs = {
  updates: Array<User_Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Property_NotifiationArgs = {
  _set?: InputMaybe<User_Property_Notifiation_Set_Input>;
  where: User_Property_Notifiation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Property_Notifiation_By_PkArgs = {
  _set?: InputMaybe<User_Property_Notifiation_Set_Input>;
  pk_columns: User_Property_Notifiation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Property_Notifiation_ManyArgs = {
  updates: Array<User_Property_Notifiation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Saved_PropertiesArgs = {
  _set?: InputMaybe<User_Saved_Properties_Set_Input>;
  where: User_Saved_Properties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Saved_Properties_By_PkArgs = {
  _set?: InputMaybe<User_Saved_Properties_Set_Input>;
  pk_columns: User_Saved_Properties_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Saved_Properties_ManyArgs = {
  updates: Array<User_Saved_Properties_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Seen_PropertyArgs = {
  _set?: InputMaybe<User_Seen_Property_Set_Input>;
  where: User_Seen_Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Seen_Property_By_PkArgs = {
  _set?: InputMaybe<User_Seen_Property_Set_Input>;
  pk_columns: User_Seen_Property_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Seen_Property_ManyArgs = {
  updates: Array<User_Seen_Property_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Virus_ManyArgs = {
  updates: Array<Virus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zone_Data_MethodArgs = {
  _set?: InputMaybe<Zone_Data_Method_Set_Input>;
  where: Zone_Data_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zone_Data_Method_By_PkArgs = {
  _set?: InputMaybe<Zone_Data_Method_Set_Input>;
  pk_columns: Zone_Data_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zone_Data_Method_ManyArgs = {
  updates: Array<Zone_Data_Method_Updates>;
};


/** mutation root */
export type Mutation_RootVerify_Sms_CodeArgs = {
  body: VerfiyCodeInput;
};

/** natural language text user types */
export type Natural_Language_Queries = {
  __typename?: 'natural_language_queries';
  amenities_ids?: Maybe<Scalars['jsonb']['output']>;
  chat_response_type?: Maybe<Chat_Response_Type_Enum>;
  class?: Maybe<Query_Classification_Enum>;
  created_at: Scalars['timestamptz']['output'];
  features_ids?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  map_center_location?: Maybe<Scalars['jsonb']['output']>;
  notify?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  queries_property_results: Array<Queries_Property_Result>;
  /** An aggregate relationship */
  queries_property_results_aggregate: Queries_Property_Result_Aggregate;
  query: Scalars['String']['output'];
  /** An object relationship */
  search_context?: Maybe<Search_Context>;
  search_context_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  search_session: Search_Sessions;
  session_id: Scalars['uuid']['output'];
  /** An array relationship */
  user_notifiations: Array<User_Property_Notifiation>;
  /** An aggregate relationship */
  user_notifiations_aggregate: User_Property_Notifiation_Aggregate;
  where_bool_exp?: Maybe<Scalars['jsonb']['output']>;
};


/** natural language text user types */
export type Natural_Language_QueriesAmenities_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** natural language text user types */
export type Natural_Language_QueriesFeatures_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** natural language text user types */
export type Natural_Language_QueriesMap_Center_LocationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** natural language text user types */
export type Natural_Language_QueriesQueries_Property_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


/** natural language text user types */
export type Natural_Language_QueriesQueries_Property_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


/** natural language text user types */
export type Natural_Language_QueriesUser_NotifiationsArgs = {
  distinct_on?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Property_Notifiation_Order_By>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


/** natural language text user types */
export type Natural_Language_QueriesUser_Notifiations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Property_Notifiation_Order_By>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


/** natural language text user types */
export type Natural_Language_QueriesWhere_Bool_ExpArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "natural_language_queries" */
export type Natural_Language_Queries_Aggregate = {
  __typename?: 'natural_language_queries_aggregate';
  aggregate?: Maybe<Natural_Language_Queries_Aggregate_Fields>;
  nodes: Array<Natural_Language_Queries>;
};

export type Natural_Language_Queries_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Natural_Language_Queries_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Natural_Language_Queries_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Natural_Language_Queries_Aggregate_Bool_Exp_Count>;
};

export type Natural_Language_Queries_Aggregate_Bool_Exp_Bool_And = {
  arguments: Natural_Language_Queries_Select_Column_Natural_Language_Queries_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Natural_Language_Queries_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Natural_Language_Queries_Select_Column_Natural_Language_Queries_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Natural_Language_Queries_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "natural_language_queries" */
export type Natural_Language_Queries_Aggregate_Fields = {
  __typename?: 'natural_language_queries_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Natural_Language_Queries_Max_Fields>;
  min?: Maybe<Natural_Language_Queries_Min_Fields>;
};


/** aggregate fields of "natural_language_queries" */
export type Natural_Language_Queries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "natural_language_queries" */
export type Natural_Language_Queries_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Natural_Language_Queries_Max_Order_By>;
  min?: InputMaybe<Natural_Language_Queries_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Natural_Language_Queries_Append_Input = {
  amenities_ids?: InputMaybe<Scalars['jsonb']['input']>;
  features_ids?: InputMaybe<Scalars['jsonb']['input']>;
  map_center_location?: InputMaybe<Scalars['jsonb']['input']>;
  where_bool_exp?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "natural_language_queries" */
export type Natural_Language_Queries_Arr_Rel_Insert_Input = {
  data: Array<Natural_Language_Queries_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Natural_Language_Queries_On_Conflict>;
};

/** Boolean expression to filter rows from the table "natural_language_queries". All fields are combined with a logical 'AND'. */
export type Natural_Language_Queries_Bool_Exp = {
  _and?: InputMaybe<Array<Natural_Language_Queries_Bool_Exp>>;
  _not?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
  _or?: InputMaybe<Array<Natural_Language_Queries_Bool_Exp>>;
  amenities_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  chat_response_type?: InputMaybe<Chat_Response_Type_Enum_Comparison_Exp>;
  class?: InputMaybe<Query_Classification_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  features_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  map_center_location?: InputMaybe<Jsonb_Comparison_Exp>;
  notify?: InputMaybe<Boolean_Comparison_Exp>;
  queries_property_results?: InputMaybe<Queries_Property_Result_Bool_Exp>;
  queries_property_results_aggregate?: InputMaybe<Queries_Property_Result_Aggregate_Bool_Exp>;
  query?: InputMaybe<String_Comparison_Exp>;
  search_context?: InputMaybe<Search_Context_Bool_Exp>;
  search_context_id?: InputMaybe<Uuid_Comparison_Exp>;
  search_session?: InputMaybe<Search_Sessions_Bool_Exp>;
  session_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_notifiations?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
  user_notifiations_aggregate?: InputMaybe<User_Property_Notifiation_Aggregate_Bool_Exp>;
  where_bool_exp?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "natural_language_queries" */
export enum Natural_Language_Queries_Constraint {
  /** unique or primary key constraint on columns "id" */
  NaturalLanguageQueriesPkey = 'natural_language_queries_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Natural_Language_Queries_Delete_At_Path_Input = {
  amenities_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  features_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  map_center_location?: InputMaybe<Array<Scalars['String']['input']>>;
  where_bool_exp?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Natural_Language_Queries_Delete_Elem_Input = {
  amenities_ids?: InputMaybe<Scalars['Int']['input']>;
  features_ids?: InputMaybe<Scalars['Int']['input']>;
  map_center_location?: InputMaybe<Scalars['Int']['input']>;
  where_bool_exp?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Natural_Language_Queries_Delete_Key_Input = {
  amenities_ids?: InputMaybe<Scalars['String']['input']>;
  features_ids?: InputMaybe<Scalars['String']['input']>;
  map_center_location?: InputMaybe<Scalars['String']['input']>;
  where_bool_exp?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "natural_language_queries" */
export type Natural_Language_Queries_Insert_Input = {
  amenities_ids?: InputMaybe<Scalars['jsonb']['input']>;
  chat_response_type?: InputMaybe<Chat_Response_Type_Enum>;
  class?: InputMaybe<Query_Classification_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  features_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  map_center_location?: InputMaybe<Scalars['jsonb']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  queries_property_results?: InputMaybe<Queries_Property_Result_Arr_Rel_Insert_Input>;
  query?: InputMaybe<Scalars['String']['input']>;
  search_context?: InputMaybe<Search_Context_Obj_Rel_Insert_Input>;
  search_context_id?: InputMaybe<Scalars['uuid']['input']>;
  search_session?: InputMaybe<Search_Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['uuid']['input']>;
  user_notifiations?: InputMaybe<User_Property_Notifiation_Arr_Rel_Insert_Input>;
  where_bool_exp?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Natural_Language_Queries_Max_Fields = {
  __typename?: 'natural_language_queries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  search_context_id?: Maybe<Scalars['uuid']['output']>;
  session_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "natural_language_queries" */
export type Natural_Language_Queries_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  search_context_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Natural_Language_Queries_Min_Fields = {
  __typename?: 'natural_language_queries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  search_context_id?: Maybe<Scalars['uuid']['output']>;
  session_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "natural_language_queries" */
export type Natural_Language_Queries_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  query?: InputMaybe<Order_By>;
  search_context_id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "natural_language_queries" */
export type Natural_Language_Queries_Mutation_Response = {
  __typename?: 'natural_language_queries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Natural_Language_Queries>;
};

/** input type for inserting object relation for remote table "natural_language_queries" */
export type Natural_Language_Queries_Obj_Rel_Insert_Input = {
  data: Natural_Language_Queries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Natural_Language_Queries_On_Conflict>;
};

/** on_conflict condition type for table "natural_language_queries" */
export type Natural_Language_Queries_On_Conflict = {
  constraint: Natural_Language_Queries_Constraint;
  update_columns?: Array<Natural_Language_Queries_Update_Column>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};

/** Ordering options when selecting data from "natural_language_queries". */
export type Natural_Language_Queries_Order_By = {
  amenities_ids?: InputMaybe<Order_By>;
  chat_response_type?: InputMaybe<Order_By>;
  class?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  features_ids?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  map_center_location?: InputMaybe<Order_By>;
  notify?: InputMaybe<Order_By>;
  queries_property_results_aggregate?: InputMaybe<Queries_Property_Result_Aggregate_Order_By>;
  query?: InputMaybe<Order_By>;
  search_context?: InputMaybe<Search_Context_Order_By>;
  search_context_id?: InputMaybe<Order_By>;
  search_session?: InputMaybe<Search_Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  user_notifiations_aggregate?: InputMaybe<User_Property_Notifiation_Aggregate_Order_By>;
  where_bool_exp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: natural_language_queries */
export type Natural_Language_Queries_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Natural_Language_Queries_Prepend_Input = {
  amenities_ids?: InputMaybe<Scalars['jsonb']['input']>;
  features_ids?: InputMaybe<Scalars['jsonb']['input']>;
  map_center_location?: InputMaybe<Scalars['jsonb']['input']>;
  where_bool_exp?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "natural_language_queries" */
export enum Natural_Language_Queries_Select_Column {
  /** column name */
  AmenitiesIds = 'amenities_ids',
  /** column name */
  ChatResponseType = 'chat_response_type',
  /** column name */
  Class = 'class',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeaturesIds = 'features_ids',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MapCenterLocation = 'map_center_location',
  /** column name */
  Notify = 'notify',
  /** column name */
  Query = 'query',
  /** column name */
  SearchContextId = 'search_context_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  WhereBoolExp = 'where_bool_exp'
}

/** select "natural_language_queries_aggregate_bool_exp_bool_and_arguments_columns" columns of table "natural_language_queries" */
export enum Natural_Language_Queries_Select_Column_Natural_Language_Queries_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Notify = 'notify'
}

/** select "natural_language_queries_aggregate_bool_exp_bool_or_arguments_columns" columns of table "natural_language_queries" */
export enum Natural_Language_Queries_Select_Column_Natural_Language_Queries_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Notify = 'notify'
}

/** input type for updating data in table "natural_language_queries" */
export type Natural_Language_Queries_Set_Input = {
  amenities_ids?: InputMaybe<Scalars['jsonb']['input']>;
  chat_response_type?: InputMaybe<Chat_Response_Type_Enum>;
  class?: InputMaybe<Query_Classification_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  features_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  map_center_location?: InputMaybe<Scalars['jsonb']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  search_context_id?: InputMaybe<Scalars['uuid']['input']>;
  session_id?: InputMaybe<Scalars['uuid']['input']>;
  where_bool_exp?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "natural_language_queries" */
export type Natural_Language_Queries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Natural_Language_Queries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Natural_Language_Queries_Stream_Cursor_Value_Input = {
  amenities_ids?: InputMaybe<Scalars['jsonb']['input']>;
  chat_response_type?: InputMaybe<Chat_Response_Type_Enum>;
  class?: InputMaybe<Query_Classification_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  features_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  map_center_location?: InputMaybe<Scalars['jsonb']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  search_context_id?: InputMaybe<Scalars['uuid']['input']>;
  session_id?: InputMaybe<Scalars['uuid']['input']>;
  where_bool_exp?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "natural_language_queries" */
export enum Natural_Language_Queries_Update_Column {
  /** column name */
  AmenitiesIds = 'amenities_ids',
  /** column name */
  ChatResponseType = 'chat_response_type',
  /** column name */
  Class = 'class',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeaturesIds = 'features_ids',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MapCenterLocation = 'map_center_location',
  /** column name */
  Notify = 'notify',
  /** column name */
  Query = 'query',
  /** column name */
  SearchContextId = 'search_context_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  WhereBoolExp = 'where_bool_exp'
}

export type Natural_Language_Queries_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Natural_Language_Queries_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Natural_Language_Queries_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Natural_Language_Queries_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Natural_Language_Queries_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Natural_Language_Queries_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Natural_Language_Queries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Natural_Language_Queries_Bool_Exp;
};

/** columns and relationships of "notification" */
export type Notification = {
  __typename?: 'notification';
  city: Array<City>;
  city_id?: Maybe<Scalars['uuid']['output']>;
  city_zone: Array<City_Zone>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  notification_amenities: Array<Notification_Amenities>;
  /** An aggregate relationship */
  notification_amenities_aggregate: Notification_Amenities_Aggregate;
  /** An array relationship */
  notification_features: Array<Notification_Feature>;
  /** An aggregate relationship */
  notification_features_aggregate: Notification_Feature_Aggregate;
  /** An array relationship */
  notification_property_types: Array<Notification_Property_Type>;
  /** An aggregate relationship */
  notification_property_types_aggregate: Notification_Property_Type_Aggregate;
  opened?: Maybe<Scalars['Boolean']['output']>;
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  property_composition: Array<Property_Composition>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin_type?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
  status_property: Array<Property_Status>;
  title?: Maybe<Scalars['String']['output']>;
  type_property_origin: Array<Property_Origin_Type>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
  user_membership_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "notification" */
export type NotificationCityArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp_Remote_Rel_Notificationcity>;
};


/** columns and relationships of "notification" */
export type NotificationCity_ZoneArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Order_By>>;
  where?: InputMaybe<City_Zone_Bool_Exp_Remote_Rel_Notificationcity_Zone>;
};


/** columns and relationships of "notification" */
export type NotificationNotification_AmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Amenities_Order_By>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationNotification_Amenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Amenities_Order_By>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationNotification_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Feature_Order_By>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationNotification_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Feature_Order_By>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationNotification_Property_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Property_Type_Order_By>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationNotification_Property_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Property_Type_Order_By>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationProperty_CompositionArgs = {
  distinct_on?: InputMaybe<Array<Property_Composition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Composition_Order_By>>;
  where?: InputMaybe<Property_Composition_Bool_Exp_Remote_Rel_Notificationproperty_Composition>;
};


/** columns and relationships of "notification" */
export type NotificationStatus_PropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Status_Order_By>>;
  where?: InputMaybe<Property_Status_Bool_Exp_Remote_Rel_Notificationstatus_Property>;
};


/** columns and relationships of "notification" */
export type NotificationType_Property_OriginArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Type_Order_By>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp_Remote_Rel_Notificationtype_Property_Origin>;
};

/** aggregated selection of "notification" */
export type Notification_Aggregate = {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<Notification_Aggregate_Fields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'notification_aggregate_fields';
  avg?: Maybe<Notification_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Max_Fields>;
  min?: Maybe<Notification_Min_Fields>;
  stddev?: Maybe<Notification_Stddev_Fields>;
  stddev_pop?: Maybe<Notification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notification_Stddev_Samp_Fields>;
  sum?: Maybe<Notification_Sum_Fields>;
  var_pop?: Maybe<Notification_Var_Pop_Fields>;
  var_samp?: Maybe<Notification_Var_Samp_Fields>;
  variance?: Maybe<Notification_Variance_Fields>;
};


/** aggregate fields of "notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "notification_amenities" */
export type Notification_Amenities = {
  __typename?: 'notification_amenities';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification: Notification;
  notification_id: Scalars['uuid']['output'];
  property_amenities_id: Scalars['uuid']['output'];
  property_amenity: Array<Property_Amenities>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "notification_amenities" */
export type Notification_AmenitiesProperty_AmenityArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp_Remote_Rel_Notification_Amenitiesproperty_Amenity>;
};

/** aggregated selection of "notification_amenities" */
export type Notification_Amenities_Aggregate = {
  __typename?: 'notification_amenities_aggregate';
  aggregate?: Maybe<Notification_Amenities_Aggregate_Fields>;
  nodes: Array<Notification_Amenities>;
};

export type Notification_Amenities_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notification_Amenities_Aggregate_Bool_Exp_Count>;
};

export type Notification_Amenities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Amenities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_amenities" */
export type Notification_Amenities_Aggregate_Fields = {
  __typename?: 'notification_amenities_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Amenities_Max_Fields>;
  min?: Maybe<Notification_Amenities_Min_Fields>;
};


/** aggregate fields of "notification_amenities" */
export type Notification_Amenities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification_amenities" */
export type Notification_Amenities_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Amenities_Max_Order_By>;
  min?: InputMaybe<Notification_Amenities_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_amenities" */
export type Notification_Amenities_Arr_Rel_Insert_Input = {
  data: Array<Notification_Amenities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Amenities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_amenities". All fields are combined with a logical 'AND'. */
export type Notification_Amenities_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Amenities_Bool_Exp>>;
  _not?: InputMaybe<Notification_Amenities_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Amenities_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notification_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_amenities_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_amenities" */
export enum Notification_Amenities_Constraint {
  /** unique or primary key constraint on columns "property_amenities_id", "notification_id" */
  NotificationAmenitiesNotificationIdPropertyAmenitiesIKey = 'notification_amenities_notification_id_property_amenities_i_key',
  /** unique or primary key constraint on columns "id" */
  NotificationAmenitiesPkey = 'notification_amenities_pkey'
}

/** input type for inserting data into table "notification_amenities" */
export type Notification_Amenities_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification?: InputMaybe<Notification_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_amenities_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Notification_Amenities_Max_Fields = {
  __typename?: 'notification_amenities_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property_amenities_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "notification_amenities" */
export type Notification_Amenities_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_amenities_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Amenities_Min_Fields = {
  __typename?: 'notification_amenities_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property_amenities_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "notification_amenities" */
export type Notification_Amenities_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_amenities_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_amenities" */
export type Notification_Amenities_Mutation_Response = {
  __typename?: 'notification_amenities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Amenities>;
};

/** on_conflict condition type for table "notification_amenities" */
export type Notification_Amenities_On_Conflict = {
  constraint: Notification_Amenities_Constraint;
  update_columns?: Array<Notification_Amenities_Update_Column>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_amenities". */
export type Notification_Amenities_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notification_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_amenities_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_amenities */
export type Notification_Amenities_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notification_amenities" */
export enum Notification_Amenities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PropertyAmenitiesId = 'property_amenities_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "notification_amenities" */
export type Notification_Amenities_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_amenities_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "notification_amenities" */
export type Notification_Amenities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Amenities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Amenities_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_amenities_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "notification_amenities" */
export enum Notification_Amenities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PropertyAmenitiesId = 'property_amenities_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Notification_Amenities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Amenities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Amenities_Bool_Exp;
};

/** aggregate avg on columns */
export type Notification_Avg_Fields = {
  __typename?: 'notification_avg_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Bool_Exp>>;
  _not?: InputMaybe<Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Bool_Exp>>;
  city_id?: InputMaybe<Uuid_Comparison_Exp>;
  city_zone_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_amenities?: InputMaybe<Notification_Amenities_Bool_Exp>;
  notification_amenities_aggregate?: InputMaybe<Notification_Amenities_Aggregate_Bool_Exp>;
  notification_features?: InputMaybe<Notification_Feature_Bool_Exp>;
  notification_features_aggregate?: InputMaybe<Notification_Feature_Aggregate_Bool_Exp>;
  notification_property_types?: InputMaybe<Notification_Property_Type_Bool_Exp>;
  notification_property_types_aggregate?: InputMaybe<Notification_Property_Type_Aggregate_Bool_Exp>;
  opened?: InputMaybe<Boolean_Comparison_Exp>;
  price_max?: InputMaybe<Float8_Comparison_Exp>;
  price_min?: InputMaybe<Float8_Comparison_Exp>;
  property_composition_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_origin_type?: InputMaybe<String_Comparison_Exp>;
  property_status?: InputMaybe<String_Comparison_Exp>;
  sq_max?: InputMaybe<Float8_Comparison_Exp>;
  sq_min?: InputMaybe<Float8_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_membership_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey'
}

/** columns and relationships of "notification_feature" */
export type Notification_Feature = {
  __typename?: 'notification_feature';
  created_at: Scalars['timestamptz']['output'];
  feature: Array<Feature>;
  feature_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification: Notification;
  notification_id: Scalars['uuid']['output'];
  removed: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "notification_feature" */
export type Notification_FeatureFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp_Remote_Rel_Notification_Featurefeature>;
};

/** aggregated selection of "notification_feature" */
export type Notification_Feature_Aggregate = {
  __typename?: 'notification_feature_aggregate';
  aggregate?: Maybe<Notification_Feature_Aggregate_Fields>;
  nodes: Array<Notification_Feature>;
};

export type Notification_Feature_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notification_Feature_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Notification_Feature_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Notification_Feature_Aggregate_Bool_Exp_Count>;
};

export type Notification_Feature_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Feature_Select_Column_Notification_Feature_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Feature_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notification_Feature_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Feature_Select_Column_Notification_Feature_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Feature_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notification_Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_feature" */
export type Notification_Feature_Aggregate_Fields = {
  __typename?: 'notification_feature_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Feature_Max_Fields>;
  min?: Maybe<Notification_Feature_Min_Fields>;
};


/** aggregate fields of "notification_feature" */
export type Notification_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification_feature" */
export type Notification_Feature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Feature_Max_Order_By>;
  min?: InputMaybe<Notification_Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_feature" */
export type Notification_Feature_Arr_Rel_Insert_Input = {
  data: Array<Notification_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_feature". All fields are combined with a logical 'AND'. */
export type Notification_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Feature_Bool_Exp>>;
  _not?: InputMaybe<Notification_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Feature_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notification_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  removed?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_feature" */
export enum Notification_Feature_Constraint {
  /** unique or primary key constraint on columns "feature_id", "notification_id" */
  NotificationFeatureFeatureIdNotificationIdKey = 'notification_feature_feature_id_notification_id_key',
  /** unique or primary key constraint on columns "id" */
  NotificationFeaturePkey = 'notification_feature_pkey'
}

/** input type for inserting data into table "notification_feature" */
export type Notification_Feature_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification?: InputMaybe<Notification_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  removed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Notification_Feature_Max_Fields = {
  __typename?: 'notification_feature_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  feature_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "notification_feature" */
export type Notification_Feature_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Feature_Min_Fields = {
  __typename?: 'notification_feature_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  feature_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "notification_feature" */
export type Notification_Feature_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_feature" */
export type Notification_Feature_Mutation_Response = {
  __typename?: 'notification_feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Feature>;
};

/** on_conflict condition type for table "notification_feature" */
export type Notification_Feature_On_Conflict = {
  constraint: Notification_Feature_Constraint;
  update_columns?: Array<Notification_Feature_Update_Column>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_feature". */
export type Notification_Feature_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notification_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  removed?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_feature */
export type Notification_Feature_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notification_feature" */
export enum Notification_Feature_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  Removed = 'removed',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "notification_feature_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_feature" */
export enum Notification_Feature_Select_Column_Notification_Feature_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Removed = 'removed'
}

/** select "notification_feature_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_feature" */
export enum Notification_Feature_Select_Column_Notification_Feature_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Removed = 'removed'
}

/** input type for updating data in table "notification_feature" */
export type Notification_Feature_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  removed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "notification_feature" */
export type Notification_Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Feature_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  removed?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "notification_feature" */
export enum Notification_Feature_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  Removed = 'removed',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Notification_Feature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Feature_Bool_Exp;
};

/** input type for incrementing numeric columns in table "notification" */
export type Notification_Inc_Input = {
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_amenities?: InputMaybe<Notification_Amenities_Arr_Rel_Insert_Input>;
  notification_features?: InputMaybe<Notification_Feature_Arr_Rel_Insert_Input>;
  notification_property_types?: InputMaybe<Notification_Property_Type_Arr_Rel_Insert_Input>;
  opened?: InputMaybe<Scalars['Boolean']['input']>;
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_membership_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'notification_max_fields';
  city_id?: Maybe<Scalars['uuid']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin_type?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  user_membership_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'notification_min_fields';
  city_id?: Maybe<Scalars['uuid']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin_type?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  user_membership_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** input type for inserting object relation for remote table "notification" */
export type Notification_Obj_Rel_Insert_Input = {
  data: Notification_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};

/** on_conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns?: Array<Notification_Update_Column>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  city_id?: InputMaybe<Order_By>;
  city_zone_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_amenities_aggregate?: InputMaybe<Notification_Amenities_Aggregate_Order_By>;
  notification_features_aggregate?: InputMaybe<Notification_Feature_Aggregate_Order_By>;
  notification_property_types_aggregate?: InputMaybe<Notification_Property_Type_Aggregate_Order_By>;
  opened?: InputMaybe<Order_By>;
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  property_origin_type?: InputMaybe<Order_By>;
  property_status?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_membership_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "notification_property_type" */
export type Notification_Property_Type = {
  __typename?: 'notification_property_type';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification: Notification;
  notification_id: Scalars['uuid']['output'];
  property_type: Scalars['String']['output'];
  type: Array<Property_Type>;
};


/** columns and relationships of "notification_property_type" */
export type Notification_Property_TypeTypeArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp_Remote_Rel_Notification_Property_Typetype>;
};

/** aggregated selection of "notification_property_type" */
export type Notification_Property_Type_Aggregate = {
  __typename?: 'notification_property_type_aggregate';
  aggregate?: Maybe<Notification_Property_Type_Aggregate_Fields>;
  nodes: Array<Notification_Property_Type>;
};

export type Notification_Property_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notification_Property_Type_Aggregate_Bool_Exp_Count>;
};

export type Notification_Property_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Property_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_property_type" */
export type Notification_Property_Type_Aggregate_Fields = {
  __typename?: 'notification_property_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Property_Type_Max_Fields>;
  min?: Maybe<Notification_Property_Type_Min_Fields>;
};


/** aggregate fields of "notification_property_type" */
export type Notification_Property_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification_property_type" */
export type Notification_Property_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Property_Type_Max_Order_By>;
  min?: InputMaybe<Notification_Property_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_property_type" */
export type Notification_Property_Type_Arr_Rel_Insert_Input = {
  data: Array<Notification_Property_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Property_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_property_type". All fields are combined with a logical 'AND'. */
export type Notification_Property_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Property_Type_Bool_Exp>>;
  _not?: InputMaybe<Notification_Property_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Property_Type_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notification_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_property_type" */
export enum Notification_Property_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPropertyTypePkey = 'notification_property_type_pkey'
}

/** input type for inserting data into table "notification_property_type" */
export type Notification_Property_Type_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification?: InputMaybe<Notification_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Property_Type_Max_Fields = {
  __typename?: 'notification_property_type_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "notification_property_type" */
export type Notification_Property_Type_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Property_Type_Min_Fields = {
  __typename?: 'notification_property_type_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "notification_property_type" */
export type Notification_Property_Type_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_property_type" */
export type Notification_Property_Type_Mutation_Response = {
  __typename?: 'notification_property_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Property_Type>;
};

/** on_conflict condition type for table "notification_property_type" */
export type Notification_Property_Type_On_Conflict = {
  constraint: Notification_Property_Type_Constraint;
  update_columns?: Array<Notification_Property_Type_Update_Column>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_property_type". */
export type Notification_Property_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notification_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_property_type */
export type Notification_Property_Type_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notification_property_type" */
export enum Notification_Property_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PropertyType = 'property_type'
}

/** input type for updating data in table "notification_property_type" */
export type Notification_Property_Type_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_property_type" */
export type Notification_Property_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Property_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Property_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_property_type" */
export enum Notification_Property_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PropertyType = 'property_type'
}

export type Notification_Property_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Property_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Property_Type_Bool_Exp;
};

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CityZoneId = 'city_zone_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Opened = 'opened',
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  PropertyCompositionId = 'property_composition_id',
  /** column name */
  PropertyOriginType = 'property_origin_type',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserMembershipId = 'user_membership_id'
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  opened?: InputMaybe<Scalars['Boolean']['input']>;
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_membership_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Notification_Stddev_Fields = {
  __typename?: 'notification_stddev_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Notification_Stddev_Pop_Fields = {
  __typename?: 'notification_stddev_pop_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Notification_Stddev_Samp_Fields = {
  __typename?: 'notification_stddev_samp_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "notification" */
export type Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Stream_Cursor_Value_Input = {
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  opened?: InputMaybe<Scalars['Boolean']['input']>;
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_membership_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Notification_Sum_Fields = {
  __typename?: 'notification_sum_fields';
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
};

/** columns and relationships of "notification_type" */
export type Notification_Type = {
  __typename?: 'notification_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "notification_type" */
export type Notification_Type_Aggregate = {
  __typename?: 'notification_type_aggregate';
  aggregate?: Maybe<Notification_Type_Aggregate_Fields>;
  nodes: Array<Notification_Type>;
};

/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_Fields = {
  __typename?: 'notification_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Type_Max_Fields>;
  min?: Maybe<Notification_Type_Min_Fields>;
};


/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export type Notification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  _not?: InputMaybe<Notification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_type" */
export enum Notification_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  NotificationTypePkey = 'notification_type_pkey'
}

/** input type for inserting data into table "notification_type" */
export type Notification_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Type_Max_Fields = {
  __typename?: 'notification_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notification_Type_Min_Fields = {
  __typename?: 'notification_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_type" */
export type Notification_Type_Mutation_Response = {
  __typename?: 'notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Type>;
};

/** on_conflict condition type for table "notification_type" */
export type Notification_Type_On_Conflict = {
  constraint: Notification_Type_Constraint;
  update_columns?: Array<Notification_Type_Update_Column>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_type". */
export type Notification_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_type */
export type Notification_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "notification_type" */
export enum Notification_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "notification_type" */
export type Notification_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_type" */
export type Notification_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_type" */
export enum Notification_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Notification_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Type_Bool_Exp;
};

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CityZoneId = 'city_zone_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Opened = 'opened',
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  PropertyCompositionId = 'property_composition_id',
  /** column name */
  PropertyOriginType = 'property_origin_type',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserMembershipId = 'user_membership_id'
}

export type Notification_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Notification_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Notification_Var_Pop_Fields = {
  __typename?: 'notification_var_pop_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Notification_Var_Samp_Fields = {
  __typename?: 'notification_var_samp_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Notification_Variance_Fields = {
  __typename?: 'notification_variance_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'order';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  membership_package_id?: Maybe<Scalars['uuid']['output']>;
  order_response?: Maybe<Scalars['jsonb']['output']>;
  payment_captured?: Maybe<Scalars['Boolean']['output']>;
  sdkOrderId?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};


/** columns and relationships of "order" */
export type OrderOrder_ResponseArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
  __typename?: 'order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  __typename?: 'order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
  order_response?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'order_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership_package_id?: InputMaybe<Uuid_Comparison_Exp>;
  order_response?: InputMaybe<Jsonb_Comparison_Exp>;
  payment_captured?: InputMaybe<Boolean_Comparison_Exp>;
  sdkOrderId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
  order_response?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
  order_response?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
  order_response?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  membership_package_id?: InputMaybe<Scalars['uuid']['input']>;
  order_response?: InputMaybe<Scalars['jsonb']['input']>;
  payment_captured?: InputMaybe<Scalars['Boolean']['input']>;
  sdkOrderId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'order_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  membership_package_id?: Maybe<Scalars['uuid']['output']>;
  sdkOrderId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'order_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  membership_package_id?: Maybe<Scalars['uuid']['output']>;
  sdkOrderId?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  __typename?: 'order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** on_conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns?: Array<Order_Update_Column>;
  where?: InputMaybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership_package_id?: InputMaybe<Order_By>;
  order_response?: InputMaybe<Order_By>;
  payment_captured?: InputMaybe<Order_By>;
  sdkOrderId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
  order_response?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipPackageId = 'membership_package_id',
  /** column name */
  OrderResponse = 'order_response',
  /** column name */
  PaymentCaptured = 'payment_captured',
  /** column name */
  SdkOrderId = 'sdkOrderId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  membership_package_id?: InputMaybe<Scalars['uuid']['input']>;
  order_response?: InputMaybe<Scalars['jsonb']['input']>;
  payment_captured?: InputMaybe<Scalars['Boolean']['input']>;
  sdkOrderId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'order_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'order_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'order_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  membership_package_id?: InputMaybe<Scalars['uuid']['input']>;
  order_response?: InputMaybe<Scalars['jsonb']['input']>;
  payment_captured?: InputMaybe<Scalars['Boolean']['input']>;
  sdkOrderId?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'order_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipPackageId = 'membership_package_id',
  /** column name */
  OrderResponse = 'order_response',
  /** column name */
  PaymentCaptured = 'payment_captured',
  /** column name */
  SdkOrderId = 'sdkOrderId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Order_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Order_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Order_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Order_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Order_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Order_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'order_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'order_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'order_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "parking_type" */
export type Parking_Type = {
  __typename?: 'parking_type';
  type: Scalars['String']['output'];
};

export type Parking_Type_ActionType = {
  __typename?: 'parking_type_ActionType';
  type: Scalars['String']['output'];
};

/** aggregated selection of "parking_type" */
export type Parking_Type_Aggregate = {
  __typename?: 'parking_type_aggregate';
  aggregate?: Maybe<Parking_Type_Aggregate_Fields>;
  nodes: Array<Parking_Type>;
};

/** aggregate fields of "parking_type" */
export type Parking_Type_Aggregate_Fields = {
  __typename?: 'parking_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Parking_Type_Max_Fields>;
  min?: Maybe<Parking_Type_Min_Fields>;
};


/** aggregate fields of "parking_type" */
export type Parking_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parking_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "parking_type". All fields are combined with a logical 'AND'. */
export type Parking_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Parking_Type_Bool_Exp>>;
  _not?: InputMaybe<Parking_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Parking_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "parking_type" */
export enum Parking_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  ParkingTypePkey = 'parking_type_pkey'
}

export enum Parking_Type_Enum {
  Garage = 'garage',
  NoParking = 'no_parking',
  StreetParking = 'street_parking',
  Underground = 'underground'
}

/** Boolean expression to compare columns of type "parking_type_enum". All fields are combined with logical 'AND'. */
export type Parking_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Parking_Type_Enum>;
  _in?: InputMaybe<Array<Parking_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Parking_Type_Enum>;
  _nin?: InputMaybe<Array<Parking_Type_Enum>>;
};

/** input type for inserting data into table "parking_type" */
export type Parking_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Parking_Type_Max_Fields = {
  __typename?: 'parking_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Parking_Type_Min_Fields = {
  __typename?: 'parking_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "parking_type" */
export type Parking_Type_Mutation_Response = {
  __typename?: 'parking_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parking_Type>;
};

/** on_conflict condition type for table "parking_type" */
export type Parking_Type_On_Conflict = {
  constraint: Parking_Type_Constraint;
  update_columns?: Array<Parking_Type_Update_Column>;
  where?: InputMaybe<Parking_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "parking_type". */
export type Parking_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parking_type */
export type Parking_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "parking_type" */
export enum Parking_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "parking_type" */
export type Parking_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "parking_type" */
export type Parking_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parking_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parking_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "parking_type" */
export enum Parking_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Parking_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parking_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parking_Type_Bool_Exp;
};

/** columns and relationships of "property" */
export type Property = {
  __typename?: 'property';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  json_ld?: Maybe<Scalars['String']['output']>;
  last_seen: Scalars['timestamptz']['output'];
  main_listing_id: Scalars['uuid']['output'];
  /** An object relationship */
  main_property: Property_List;
  /** An array relationship */
  property_features: Array<Property_Feature>;
  /** An aggregate relationship */
  property_features_aggregate: Property_Feature_Aggregate;
  /** An array relationship */
  property_images: Array<Property_Image>;
  /** An aggregate relationship */
  property_images_aggregate: Property_Image_Aggregate;
  /** An array relationship */
  property_lists: Array<Property_List>;
  /** An aggregate relationship */
  property_lists_aggregate: Property_List_Aggregate;
  /** An array relationship */
  property_price_histories: Array<Property_Price_History>;
  /** An aggregate relationship */
  property_price_histories_aggregate: Property_Price_History_Aggregate;
  /** An array relationship */
  property_views: Array<Property_Views>;
  /** An aggregate relationship */
  property_views_aggregate: Property_Views_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  url_slugs: Array<Url_Slug>;
  /** An aggregate relationship */
  url_slugs_aggregate: Url_Slug_Aggregate;
  /** An array relationship */
  user_saved_properties: Array<User_Saved_Properties>;
  /** An aggregate relationship */
  user_saved_properties_aggregate: User_Saved_Properties_Aggregate;
  /** An array relationship */
  user_seen_properties: Array<User_Seen_Property>;
  /** An aggregate relationship */
  user_seen_properties_aggregate: User_Seen_Property_Aggregate;
};


/** columns and relationships of "property" */
export type PropertyProperty_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_ListsArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_Price_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_Price_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Property_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Views_Order_By>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyProperty_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Views_Order_By>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyUrl_SlugsArgs = {
  distinct_on?: InputMaybe<Array<Url_Slug_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Slug_Order_By>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyUrl_Slugs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Slug_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Slug_Order_By>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyUser_Saved_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Saved_Properties_Order_By>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyUser_Saved_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Saved_Properties_Order_By>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyUser_Seen_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Seen_Property_Order_By>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};


/** columns and relationships of "property" */
export type PropertyUser_Seen_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Seen_Property_Order_By>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};

export type Property_ActionType = {
  __typename?: 'property_ActionType';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  json_ld?: Maybe<Scalars['String']['output']>;
  last_seen: Scalars['timestamptz']['output'];
  main_listing_id: Scalars['uuid']['output'];
  main_property?: Maybe<Property_List_ActionType>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "property" */
export type Property_Aggregate = {
  __typename?: 'property_aggregate';
  aggregate?: Maybe<Property_Aggregate_Fields>;
  nodes: Array<Property>;
};

/** aggregate fields of "property" */
export type Property_Aggregate_Fields = {
  __typename?: 'property_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Max_Fields>;
  min?: Maybe<Property_Min_Fields>;
};


/** aggregate fields of "property" */
export type Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "property_amenities" */
export type Property_Amenities = {
  __typename?: 'property_amenities';
  /** An object relationship */
  amenity: Amenities;
  amenity_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  property_list: Property_List;
  property_list_id: Scalars['uuid']['output'];
};

/** aggregated selection of "property_amenities" */
export type Property_Amenities_Aggregate = {
  __typename?: 'property_amenities_aggregate';
  aggregate?: Maybe<Property_Amenities_Aggregate_Fields>;
  nodes: Array<Property_Amenities>;
};

export type Property_Amenities_Aggregate_Bool_Exp = {
  count?: InputMaybe<Property_Amenities_Aggregate_Bool_Exp_Count>;
};

export type Property_Amenities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Amenities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "property_amenities" */
export type Property_Amenities_Aggregate_Fields = {
  __typename?: 'property_amenities_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Amenities_Max_Fields>;
  min?: Maybe<Property_Amenities_Min_Fields>;
};


/** aggregate fields of "property_amenities" */
export type Property_Amenities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_amenities" */
export type Property_Amenities_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Amenities_Max_Order_By>;
  min?: InputMaybe<Property_Amenities_Min_Order_By>;
};

/** input type for inserting array relation for remote table "property_amenities" */
export type Property_Amenities_Arr_Rel_Insert_Input = {
  data: Array<Property_Amenities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Amenities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "property_amenities". All fields are combined with a logical 'AND'. */
export type Property_Amenities_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Amenities_Bool_Exp>>;
  _not?: InputMaybe<Property_Amenities_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Amenities_Bool_Exp>>;
  amenity?: InputMaybe<Amenities_Bool_Exp>;
  amenity_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property_list?: InputMaybe<Property_List_Bool_Exp>;
  property_list_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_amenities". All fields are combined with a logical 'AND'. */
export type Property_Amenities_Bool_Exp_Remote_Rel_Notification_Amenitiesproperty_Amenity = {
  _and?: InputMaybe<Array<Property_Amenities_Bool_Exp>>;
  _not?: InputMaybe<Property_Amenities_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Amenities_Bool_Exp>>;
  amenity?: InputMaybe<Amenities_Bool_Exp>;
  amenity_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_Notification_Amenitiesproperty_Amenity>;
  property_list?: InputMaybe<Property_List_Bool_Exp>;
  property_list_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_amenities" */
export enum Property_Amenities_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyAmenitiesPkey = 'property_amenities_pkey',
  /** unique or primary key constraint on columns "amenity_id", "property_list_id" */
  PropertyAmenitiesPropertyListIdAmenityIdKey = 'property_amenities_property_list_id_amenity_id_key'
}

/** input type for inserting data into table "property_amenities" */
export type Property_Amenities_Insert_Input = {
  amenity?: InputMaybe<Amenities_Obj_Rel_Insert_Input>;
  amenity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_list?: InputMaybe<Property_List_Obj_Rel_Insert_Input>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Property_Amenities_Max_Fields = {
  __typename?: 'property_amenities_max_fields';
  amenity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "property_amenities" */
export type Property_Amenities_Max_Order_By = {
  amenity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Amenities_Min_Fields = {
  __typename?: 'property_amenities_min_fields';
  amenity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "property_amenities" */
export type Property_Amenities_Min_Order_By = {
  amenity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_amenities" */
export type Property_Amenities_Mutation_Response = {
  __typename?: 'property_amenities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Amenities>;
};

/** on_conflict condition type for table "property_amenities" */
export type Property_Amenities_On_Conflict = {
  constraint: Property_Amenities_Constraint;
  update_columns?: Array<Property_Amenities_Update_Column>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};

/** Ordering options when selecting data from "property_amenities". */
export type Property_Amenities_Order_By = {
  amenity?: InputMaybe<Amenities_Order_By>;
  amenity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_list?: InputMaybe<Property_List_Order_By>;
  property_list_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_amenities */
export type Property_Amenities_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_amenities" */
export enum Property_Amenities_Select_Column {
  /** column name */
  AmenityId = 'amenity_id',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyListId = 'property_list_id'
}

/** input type for updating data in table "property_amenities" */
export type Property_Amenities_Set_Input = {
  amenity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "property_amenities" */
export type Property_Amenities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Amenities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Amenities_Stream_Cursor_Value_Input = {
  amenity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "property_amenities" */
export enum Property_Amenities_Update_Column {
  /** column name */
  AmenityId = 'amenity_id',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyListId = 'property_list_id'
}

export type Property_Amenities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Amenities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Amenities_Bool_Exp;
};

/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
export type Property_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Bool_Exp>>;
  _not?: InputMaybe<Property_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  json_ld?: InputMaybe<String_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  main_listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  main_property?: InputMaybe<Property_List_Bool_Exp>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  property_images?: InputMaybe<Property_Image_Bool_Exp>;
  property_images_aggregate?: InputMaybe<Property_Image_Aggregate_Bool_Exp>;
  property_lists?: InputMaybe<Property_List_Bool_Exp>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Bool_Exp>;
  property_price_histories?: InputMaybe<Property_Price_History_Bool_Exp>;
  property_price_histories_aggregate?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp>;
  property_views?: InputMaybe<Property_Views_Bool_Exp>;
  property_views_aggregate?: InputMaybe<Property_Views_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url_slugs?: InputMaybe<Url_Slug_Bool_Exp>;
  url_slugs_aggregate?: InputMaybe<Url_Slug_Aggregate_Bool_Exp>;
  user_saved_properties?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  user_saved_properties_aggregate?: InputMaybe<User_Saved_Properties_Aggregate_Bool_Exp>;
  user_seen_properties?: InputMaybe<User_Seen_Property_Bool_Exp>;
  user_seen_properties_aggregate?: InputMaybe<User_Seen_Property_Aggregate_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
export type Property_Bool_Exp_Remote_Rel_Queries_Property_Resultproperty = {
  _and?: InputMaybe<Array<Property_Bool_Exp>>;
  _not?: InputMaybe<Property_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_Queries_Property_Resultproperty>;
  json_ld?: InputMaybe<String_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  main_listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  main_property?: InputMaybe<Property_List_Bool_Exp>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  property_images?: InputMaybe<Property_Image_Bool_Exp>;
  property_images_aggregate?: InputMaybe<Property_Image_Aggregate_Bool_Exp>;
  property_lists?: InputMaybe<Property_List_Bool_Exp>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Bool_Exp>;
  property_price_histories?: InputMaybe<Property_Price_History_Bool_Exp>;
  property_price_histories_aggregate?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp>;
  property_views?: InputMaybe<Property_Views_Bool_Exp>;
  property_views_aggregate?: InputMaybe<Property_Views_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url_slugs?: InputMaybe<Url_Slug_Bool_Exp>;
  url_slugs_aggregate?: InputMaybe<Url_Slug_Aggregate_Bool_Exp>;
  user_saved_properties?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  user_saved_properties_aggregate?: InputMaybe<User_Saved_Properties_Aggregate_Bool_Exp>;
  user_seen_properties?: InputMaybe<User_Seen_Property_Bool_Exp>;
  user_seen_properties_aggregate?: InputMaybe<User_Seen_Property_Aggregate_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
export type Property_Bool_Exp_Remote_Rel_User_Property_Notifiationproperty = {
  _and?: InputMaybe<Array<Property_Bool_Exp>>;
  _not?: InputMaybe<Property_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_User_Property_Notifiationproperty>;
  json_ld?: InputMaybe<String_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  main_listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  main_property?: InputMaybe<Property_List_Bool_Exp>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  property_images?: InputMaybe<Property_Image_Bool_Exp>;
  property_images_aggregate?: InputMaybe<Property_Image_Aggregate_Bool_Exp>;
  property_lists?: InputMaybe<Property_List_Bool_Exp>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Bool_Exp>;
  property_price_histories?: InputMaybe<Property_Price_History_Bool_Exp>;
  property_price_histories_aggregate?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp>;
  property_views?: InputMaybe<Property_Views_Bool_Exp>;
  property_views_aggregate?: InputMaybe<Property_Views_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url_slugs?: InputMaybe<Url_Slug_Bool_Exp>;
  url_slugs_aggregate?: InputMaybe<Url_Slug_Aggregate_Bool_Exp>;
  user_saved_properties?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  user_saved_properties_aggregate?: InputMaybe<User_Saved_Properties_Aggregate_Bool_Exp>;
  user_seen_properties?: InputMaybe<User_Seen_Property_Bool_Exp>;
  user_seen_properties_aggregate?: InputMaybe<User_Seen_Property_Aggregate_Bool_Exp>;
};

/** columns and relationships of "property_cluster" */
export type Property_Cluster = {
  __typename?: 'property_cluster';
  cluster: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "property_cluster" */
export type Property_Cluster_Aggregate = {
  __typename?: 'property_cluster_aggregate';
  aggregate?: Maybe<Property_Cluster_Aggregate_Fields>;
  nodes: Array<Property_Cluster>;
};

/** aggregate fields of "property_cluster" */
export type Property_Cluster_Aggregate_Fields = {
  __typename?: 'property_cluster_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Cluster_Max_Fields>;
  min?: Maybe<Property_Cluster_Min_Fields>;
};


/** aggregate fields of "property_cluster" */
export type Property_Cluster_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Cluster_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_cluster". All fields are combined with a logical 'AND'. */
export type Property_Cluster_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Cluster_Bool_Exp>>;
  _not?: InputMaybe<Property_Cluster_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Cluster_Bool_Exp>>;
  cluster?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_cluster". All fields are combined with a logical 'AND'. */
export type Property_Cluster_Bool_Exp_Remote_Rel_Search_Contextcluster = {
  _and?: InputMaybe<Array<Property_Cluster_Bool_Exp>>;
  _not?: InputMaybe<Property_Cluster_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Cluster_Bool_Exp>>;
  cluster?: InputMaybe<String_Comparison_Exp_Remote_Rel_Search_Contextcluster>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_cluster" */
export enum Property_Cluster_Constraint {
  /** unique or primary key constraint on columns "cluster" */
  PropertyClusterPkey = 'property_cluster_pkey'
}

/** input type for inserting data into table "property_cluster" */
export type Property_Cluster_Insert_Input = {
  cluster?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Property_Cluster_Max_Fields = {
  __typename?: 'property_cluster_max_fields';
  cluster?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Property_Cluster_Min_Fields = {
  __typename?: 'property_cluster_min_fields';
  cluster?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "property_cluster" */
export type Property_Cluster_Mutation_Response = {
  __typename?: 'property_cluster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Cluster>;
};

/** on_conflict condition type for table "property_cluster" */
export type Property_Cluster_On_Conflict = {
  constraint: Property_Cluster_Constraint;
  update_columns?: Array<Property_Cluster_Update_Column>;
  where?: InputMaybe<Property_Cluster_Bool_Exp>;
};

/** Ordering options when selecting data from "property_cluster". */
export type Property_Cluster_Order_By = {
  cluster?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_cluster */
export type Property_Cluster_Pk_Columns_Input = {
  cluster: Scalars['String']['input'];
};

/** select columns of table "property_cluster" */
export enum Property_Cluster_Select_Column {
  /** column name */
  Cluster = 'cluster',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "property_cluster" */
export type Property_Cluster_Set_Input = {
  cluster?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "property_cluster" */
export type Property_Cluster_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Cluster_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Cluster_Stream_Cursor_Value_Input = {
  cluster?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "property_cluster" */
export enum Property_Cluster_Update_Column {
  /** column name */
  Cluster = 'cluster',
  /** column name */
  Id = 'id'
}

export type Property_Cluster_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Cluster_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Cluster_Bool_Exp;
};

/** columns and relationships of "property_composition" */
export type Property_Composition = {
  __typename?: 'property_composition';
  balconies?: Maybe<Scalars['Int']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  bedrooms: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  kitchens?: Maybe<Scalars['Int']['output']>;
  total_rooms?: Maybe<Scalars['Int']['output']>;
};

export type Property_Composition_ActionType = {
  __typename?: 'property_composition_ActionType';
  balconies?: Maybe<Scalars['Int']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  bedrooms: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  kitchens?: Maybe<Scalars['Int']['output']>;
  total_rooms?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "property_composition" */
export type Property_Composition_Aggregate = {
  __typename?: 'property_composition_aggregate';
  aggregate?: Maybe<Property_Composition_Aggregate_Fields>;
  nodes: Array<Property_Composition>;
};

/** aggregate fields of "property_composition" */
export type Property_Composition_Aggregate_Fields = {
  __typename?: 'property_composition_aggregate_fields';
  avg?: Maybe<Property_Composition_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Composition_Max_Fields>;
  min?: Maybe<Property_Composition_Min_Fields>;
  stddev?: Maybe<Property_Composition_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Composition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Composition_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Composition_Sum_Fields>;
  var_pop?: Maybe<Property_Composition_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Composition_Var_Samp_Fields>;
  variance?: Maybe<Property_Composition_Variance_Fields>;
};


/** aggregate fields of "property_composition" */
export type Property_Composition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Composition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Property_Composition_Avg_Fields = {
  __typename?: 'property_composition_avg_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "property_composition". All fields are combined with a logical 'AND'. */
export type Property_Composition_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Composition_Bool_Exp>>;
  _not?: InputMaybe<Property_Composition_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Composition_Bool_Exp>>;
  balconies?: InputMaybe<Int_Comparison_Exp>;
  bathrooms?: InputMaybe<Int_Comparison_Exp>;
  bedrooms?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kitchens?: InputMaybe<Int_Comparison_Exp>;
  total_rooms?: InputMaybe<Int_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_composition". All fields are combined with a logical 'AND'. */
export type Property_Composition_Bool_Exp_Remote_Rel_Notificationproperty_Composition = {
  _and?: InputMaybe<Array<Property_Composition_Bool_Exp>>;
  _not?: InputMaybe<Property_Composition_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Composition_Bool_Exp>>;
  balconies?: InputMaybe<Int_Comparison_Exp>;
  bathrooms?: InputMaybe<Int_Comparison_Exp>;
  bedrooms?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp_Remote_Rel_Notificationproperty_Composition>;
  kitchens?: InputMaybe<Int_Comparison_Exp>;
  total_rooms?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_composition" */
export enum Property_Composition_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyCompositionPkey = 'property_composition_pkey'
}

/** input type for incrementing numeric columns in table "property_composition" */
export type Property_Composition_Inc_Input = {
  balconies?: InputMaybe<Scalars['Int']['input']>;
  bathrooms?: InputMaybe<Scalars['Int']['input']>;
  bedrooms?: InputMaybe<Scalars['Int']['input']>;
  kitchens?: InputMaybe<Scalars['Int']['input']>;
  total_rooms?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "property_composition" */
export type Property_Composition_Insert_Input = {
  balconies?: InputMaybe<Scalars['Int']['input']>;
  bathrooms?: InputMaybe<Scalars['Int']['input']>;
  bedrooms?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kitchens?: InputMaybe<Scalars['Int']['input']>;
  total_rooms?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Property_Composition_Max_Fields = {
  __typename?: 'property_composition_max_fields';
  balconies?: Maybe<Scalars['Int']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  bedrooms?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kitchens?: Maybe<Scalars['Int']['output']>;
  total_rooms?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Property_Composition_Min_Fields = {
  __typename?: 'property_composition_min_fields';
  balconies?: Maybe<Scalars['Int']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  bedrooms?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kitchens?: Maybe<Scalars['Int']['output']>;
  total_rooms?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "property_composition" */
export type Property_Composition_Mutation_Response = {
  __typename?: 'property_composition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Composition>;
};

/** input type for inserting object relation for remote table "property_composition" */
export type Property_Composition_Obj_Rel_Insert_Input = {
  data: Property_Composition_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Composition_On_Conflict>;
};

/** on_conflict condition type for table "property_composition" */
export type Property_Composition_On_Conflict = {
  constraint: Property_Composition_Constraint;
  update_columns?: Array<Property_Composition_Update_Column>;
  where?: InputMaybe<Property_Composition_Bool_Exp>;
};

/** Ordering options when selecting data from "property_composition". */
export type Property_Composition_Order_By = {
  balconies?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kitchens?: InputMaybe<Order_By>;
  total_rooms?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_composition */
export type Property_Composition_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_composition" */
export enum Property_Composition_Select_Column {
  /** column name */
  Balconies = 'balconies',
  /** column name */
  Bathrooms = 'bathrooms',
  /** column name */
  Bedrooms = 'bedrooms',
  /** column name */
  Id = 'id',
  /** column name */
  Kitchens = 'kitchens',
  /** column name */
  TotalRooms = 'total_rooms'
}

/** input type for updating data in table "property_composition" */
export type Property_Composition_Set_Input = {
  balconies?: InputMaybe<Scalars['Int']['input']>;
  bathrooms?: InputMaybe<Scalars['Int']['input']>;
  bedrooms?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kitchens?: InputMaybe<Scalars['Int']['input']>;
  total_rooms?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Property_Composition_Stddev_Fields = {
  __typename?: 'property_composition_stddev_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Property_Composition_Stddev_Pop_Fields = {
  __typename?: 'property_composition_stddev_pop_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Property_Composition_Stddev_Samp_Fields = {
  __typename?: 'property_composition_stddev_samp_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "property_composition" */
export type Property_Composition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Composition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Composition_Stream_Cursor_Value_Input = {
  balconies?: InputMaybe<Scalars['Int']['input']>;
  bathrooms?: InputMaybe<Scalars['Int']['input']>;
  bedrooms?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kitchens?: InputMaybe<Scalars['Int']['input']>;
  total_rooms?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Property_Composition_Sum_Fields = {
  __typename?: 'property_composition_sum_fields';
  balconies?: Maybe<Scalars['Int']['output']>;
  bathrooms?: Maybe<Scalars['Int']['output']>;
  bedrooms?: Maybe<Scalars['Int']['output']>;
  kitchens?: Maybe<Scalars['Int']['output']>;
  total_rooms?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "property_composition" */
export enum Property_Composition_Update_Column {
  /** column name */
  Balconies = 'balconies',
  /** column name */
  Bathrooms = 'bathrooms',
  /** column name */
  Bedrooms = 'bedrooms',
  /** column name */
  Id = 'id',
  /** column name */
  Kitchens = 'kitchens',
  /** column name */
  TotalRooms = 'total_rooms'
}

export type Property_Composition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Composition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Composition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Composition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Property_Composition_Var_Pop_Fields = {
  __typename?: 'property_composition_var_pop_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Property_Composition_Var_Samp_Fields = {
  __typename?: 'property_composition_var_samp_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Property_Composition_Variance_Fields = {
  __typename?: 'property_composition_variance_fields';
  balconies?: Maybe<Scalars['Float']['output']>;
  bathrooms?: Maybe<Scalars['Float']['output']>;
  bedrooms?: Maybe<Scalars['Float']['output']>;
  kitchens?: Maybe<Scalars['Float']['output']>;
  total_rooms?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "property" */
export enum Property_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyPkey = 'property_pkey'
}

/** columns and relationships of "property_feature" */
export type Property_Feature = {
  __typename?: 'property_feature';
  feature_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
  value: Scalars['Boolean']['output'];
};

/** aggregated selection of "property_feature" */
export type Property_Feature_Aggregate = {
  __typename?: 'property_feature_aggregate';
  aggregate?: Maybe<Property_Feature_Aggregate_Fields>;
  nodes: Array<Property_Feature>;
};

export type Property_Feature_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Property_Feature_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Property_Feature_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Property_Feature_Aggregate_Bool_Exp_Count>;
};

export type Property_Feature_Aggregate_Bool_Exp_Bool_And = {
  arguments: Property_Feature_Select_Column_Property_Feature_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Feature_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_Feature_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Property_Feature_Select_Column_Property_Feature_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Feature_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "property_feature" */
export type Property_Feature_Aggregate_Fields = {
  __typename?: 'property_feature_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Feature_Max_Fields>;
  min?: Maybe<Property_Feature_Min_Fields>;
};


/** aggregate fields of "property_feature" */
export type Property_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_feature" */
export type Property_Feature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Feature_Max_Order_By>;
  min?: InputMaybe<Property_Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "property_feature" */
export type Property_Feature_Arr_Rel_Insert_Input = {
  data: Array<Property_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "property_feature". All fields are combined with a logical 'AND'. */
export type Property_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Feature_Bool_Exp>>;
  _not?: InputMaybe<Property_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Feature_Bool_Exp>>;
  feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_list_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_feature" */
export enum Property_Feature_Constraint {
  /** unique or primary key constraint on columns "feature_id", "property_id" */
  PropertyFeatureFeatureIdPropertyIdKey = 'property_feature_feature_id_property_id_key',
  /** unique or primary key constraint on columns "feature_id", "property_list_id" */
  PropertyFeatureFeatureIdPropertyListIdKey = 'property_feature_feature_id_property_list_id_key',
  /** unique or primary key constraint on columns "id" */
  PropertyFeaturePkey = 'property_feature_pkey'
}

/** input type for inserting data into table "property_feature" */
export type Property_Feature_Insert_Input = {
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Property_Feature_Max_Fields = {
  __typename?: 'property_feature_max_fields';
  feature_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "property_feature" */
export type Property_Feature_Max_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Feature_Min_Fields = {
  __typename?: 'property_feature_min_fields';
  feature_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "property_feature" */
export type Property_Feature_Min_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_feature" */
export type Property_Feature_Mutation_Response = {
  __typename?: 'property_feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Feature>;
};

/** on_conflict condition type for table "property_feature" */
export type Property_Feature_On_Conflict = {
  constraint: Property_Feature_Constraint;
  update_columns?: Array<Property_Feature_Update_Column>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "property_feature". */
export type Property_Feature_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_feature */
export type Property_Feature_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_feature" */
export enum Property_Feature_Select_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  PropertyListId = 'property_list_id',
  /** column name */
  Value = 'value'
}

/** select "property_feature_aggregate_bool_exp_bool_and_arguments_columns" columns of table "property_feature" */
export enum Property_Feature_Select_Column_Property_Feature_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "property_feature_aggregate_bool_exp_bool_or_arguments_columns" columns of table "property_feature" */
export enum Property_Feature_Select_Column_Property_Feature_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "property_feature" */
export type Property_Feature_Set_Input = {
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "property_feature" */
export type Property_Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Feature_Stream_Cursor_Value_Input = {
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "property_feature" */
export enum Property_Feature_Update_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  PropertyListId = 'property_list_id',
  /** column name */
  Value = 'value'
}

export type Property_Feature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Feature_Bool_Exp;
};

/** columns and relationships of "property_image" */
export type Property_Image = {
  __typename?: 'property_image';
  blurhash?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  image: Scalars['String']['output'];
  origin_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  property?: Maybe<Property>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  property_list?: Maybe<Property_List>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  property_lists: Array<Property_List>;
  /** An aggregate relationship */
  property_lists_aggregate: Property_List_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "property_image" */
export type Property_ImageProperty_ListsArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


/** columns and relationships of "property_image" */
export type Property_ImageProperty_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};

/** aggregated selection of "property_image" */
export type Property_Image_Aggregate = {
  __typename?: 'property_image_aggregate';
  aggregate?: Maybe<Property_Image_Aggregate_Fields>;
  nodes: Array<Property_Image>;
};

export type Property_Image_Aggregate_Bool_Exp = {
  count?: InputMaybe<Property_Image_Aggregate_Bool_Exp_Count>;
};

export type Property_Image_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Image_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Image_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "property_image" */
export type Property_Image_Aggregate_Fields = {
  __typename?: 'property_image_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Image_Max_Fields>;
  min?: Maybe<Property_Image_Min_Fields>;
};


/** aggregate fields of "property_image" */
export type Property_Image_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Image_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_image" */
export type Property_Image_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Image_Max_Order_By>;
  min?: InputMaybe<Property_Image_Min_Order_By>;
};

/** input type for inserting array relation for remote table "property_image" */
export type Property_Image_Arr_Rel_Insert_Input = {
  data: Array<Property_Image_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Image_On_Conflict>;
};

/** Boolean expression to filter rows from the table "property_image". All fields are combined with a logical 'AND'. */
export type Property_Image_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Image_Bool_Exp>>;
  _not?: InputMaybe<Property_Image_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Image_Bool_Exp>>;
  blurhash?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  origin_url?: InputMaybe<String_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_list?: InputMaybe<Property_List_Bool_Exp>;
  property_list_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_lists?: InputMaybe<Property_List_Bool_Exp>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_image" */
export enum Property_Image_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyImgrPkey = 'property_imgr_pkey',
  /** unique or primary key constraint on columns "image", "property_list_id" */
  PropertyImgrPropertyListIdImageKey = 'property_imgr_property_list_id_image_key'
}

/** input type for inserting data into table "property_image" */
export type Property_Image_Insert_Input = {
  blurhash?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  origin_url?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list?: InputMaybe<Property_List_Obj_Rel_Insert_Input>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  property_lists?: InputMaybe<Property_List_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Property_Image_Max_Fields = {
  __typename?: 'property_image_max_fields';
  blurhash?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  origin_url?: Maybe<Scalars['String']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "property_image" */
export type Property_Image_Max_Order_By = {
  blurhash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  origin_url?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Image_Min_Fields = {
  __typename?: 'property_image_min_fields';
  blurhash?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  origin_url?: Maybe<Scalars['String']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "property_image" */
export type Property_Image_Min_Order_By = {
  blurhash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  origin_url?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_image" */
export type Property_Image_Mutation_Response = {
  __typename?: 'property_image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Image>;
};

/** input type for inserting object relation for remote table "property_image" */
export type Property_Image_Obj_Rel_Insert_Input = {
  data: Property_Image_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Image_On_Conflict>;
};

/** on_conflict condition type for table "property_image" */
export type Property_Image_On_Conflict = {
  constraint: Property_Image_Constraint;
  update_columns?: Array<Property_Image_Update_Column>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};

/** Ordering options when selecting data from "property_image". */
export type Property_Image_Order_By = {
  blurhash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  origin_url?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list?: InputMaybe<Property_List_Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_image */
export type Property_Image_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_image" */
export enum Property_Image_Select_Column {
  /** column name */
  Blurhash = 'blurhash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  OriginUrl = 'origin_url',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  PropertyListId = 'property_list_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "property_image" */
export type Property_Image_Set_Input = {
  blurhash?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  origin_url?: InputMaybe<Scalars['String']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "property_image" */
export type Property_Image_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Image_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Image_Stream_Cursor_Value_Input = {
  blurhash?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  origin_url?: InputMaybe<Scalars['String']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "property_image" */
export enum Property_Image_Update_Column {
  /** column name */
  Blurhash = 'blurhash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  OriginUrl = 'origin_url',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  PropertyListId = 'property_list_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Property_Image_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Image_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Image_Bool_Exp;
};

/** input type for inserting data into table "property" */
export type Property_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  json_ld?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  main_listing_id?: InputMaybe<Scalars['uuid']['input']>;
  main_property?: InputMaybe<Property_List_Obj_Rel_Insert_Input>;
  property_features?: InputMaybe<Property_Feature_Arr_Rel_Insert_Input>;
  property_images?: InputMaybe<Property_Image_Arr_Rel_Insert_Input>;
  property_lists?: InputMaybe<Property_List_Arr_Rel_Insert_Input>;
  property_price_histories?: InputMaybe<Property_Price_History_Arr_Rel_Insert_Input>;
  property_views?: InputMaybe<Property_Views_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url_slugs?: InputMaybe<Url_Slug_Arr_Rel_Insert_Input>;
  user_saved_properties?: InputMaybe<User_Saved_Properties_Arr_Rel_Insert_Input>;
  user_seen_properties?: InputMaybe<User_Seen_Property_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "property_list" */
export type Property_List = {
  __typename?: 'property_list';
  building_floors?: Maybe<Scalars['Int']['output']>;
  canonical_url?: Maybe<Scalars['String']['output']>;
  construction_status?: Maybe<Construction_Status_Enum>;
  construction_year?: Maybe<Scalars['Int']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['uuid']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_1?: Maybe<Scalars['String']['output']>;
  contact_phone_2?: Maybe<Scalars['String']['output']>;
  content_location?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description_id: Scalars['uuid']['output'];
  /** An object relationship */
  description_text_content: Text_Content;
  features_json?: Maybe<Scalars['jsonb']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  furnishing_status?: Maybe<Furnishing_Status_Enum>;
  has_balcony?: Maybe<Scalars['Boolean']['output']>;
  has_carport?: Maybe<Scalars['Boolean']['output']>;
  has_elevator?: Maybe<Scalars['Boolean']['output']>;
  has_garage?: Maybe<Scalars['Boolean']['output']>;
  has_garden?: Maybe<Scalars['Boolean']['output']>;
  has_parking_space?: Maybe<Scalars['Boolean']['output']>;
  has_terrace?: Maybe<Scalars['Boolean']['output']>;
  heating_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_barrier_free?: Maybe<Scalars['Boolean']['output']>;
  is_main_listing?: Maybe<Scalars['Boolean']['output']>;
  json_ld?: Maybe<Scalars['jsonb']['output']>;
  last_renovation_year?: Maybe<Scalars['Int']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  living_area?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  lot_size?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  main_image?: Maybe<Property_Image>;
  main_image_id?: Maybe<Scalars['uuid']['output']>;
  parent_property?: Maybe<Scalars['uuid']['output']>;
  parking_spaces?: Maybe<Scalars['Int']['output']>;
  parking_type?: Maybe<Parking_Type_Enum>;
  pets_allowed?: Maybe<Scalars['Boolean']['output']>;
  plot_area?: Maybe<Scalars['float8']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  price_currency?: Maybe<Currency_Enum>;
  /** An object relationship */
  property?: Maybe<Property>;
  /** An array relationship */
  property_amenities: Array<Property_Amenities>;
  /** An aggregate relationship */
  property_amenities_aggregate: Property_Amenities_Aggregate;
  /** An object relationship */
  property_composition?: Maybe<Property_Composition>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  property_features: Array<Property_Feature>;
  /** An aggregate relationship */
  property_features_aggregate: Property_Feature_Aggregate;
  /** An array relationship */
  property_images: Array<Property_Image>;
  /** An aggregate relationship */
  property_images_aggregate: Property_Image_Aggregate;
  property_origin_type?: Maybe<Property_Origin_Type_Enum>;
  /** An array relationship */
  property_price_histories: Array<Property_Price_History>;
  /** An aggregate relationship */
  property_price_histories_aggregate: Property_Price_History_Aggregate;
  /** An object relationship */
  property_source?: Maybe<Property_Source>;
  property_status: Property_Status_Enum;
  property_type: Property_Type_Enum;
  rental_terms?: Maybe<Scalars['String']['output']>;
  sale_terms?: Maybe<Scalars['String']['output']>;
  search_vector?: Maybe<Scalars['String']['output']>;
  smoking_allowed?: Maybe<Scalars['Boolean']['output']>;
  source_id?: Maybe<Scalars['uuid']['output']>;
  source_url?: Maybe<Scalars['String']['output']>;
  title_id: Scalars['uuid']['output'];
  /** An object relationship */
  title_text_content: Text_Content;
  total_area?: Maybe<Scalars['float8']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "property_list" */
export type Property_ListFeatures_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "property_list" */
export type Property_ListJson_LdArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_AmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_Amenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_Price_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


/** columns and relationships of "property_list" */
export type Property_ListProperty_Price_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};

export type Property_List_ActionType = {
  __typename?: 'property_list_ActionType';
  building_floors?: Maybe<Scalars['Int']['output']>;
  canonical_url?: Maybe<Scalars['String']['output']>;
  construction_status?: Maybe<Construction_Status_ActionType>;
  construction_year?: Maybe<Scalars['Int']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['uuid']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_1?: Maybe<Scalars['String']['output']>;
  contact_phone_2?: Maybe<Scalars['String']['output']>;
  content_location?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description_id: Scalars['uuid']['output'];
  features_json?: Maybe<Scalars['jsonb']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  furnishing_status?: Maybe<Furnishing_Status_ActionType>;
  has_balcony?: Maybe<Scalars['Boolean']['output']>;
  has_carport?: Maybe<Scalars['Boolean']['output']>;
  has_elevator?: Maybe<Scalars['Boolean']['output']>;
  has_garage?: Maybe<Scalars['Boolean']['output']>;
  has_garden?: Maybe<Scalars['Boolean']['output']>;
  has_parking_space?: Maybe<Scalars['Boolean']['output']>;
  has_terrace?: Maybe<Scalars['Boolean']['output']>;
  heating_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_barrier_free?: Maybe<Scalars['Boolean']['output']>;
  is_main_listing?: Maybe<Scalars['Boolean']['output']>;
  json_ld?: Maybe<Scalars['jsonb']['output']>;
  last_renovation_year?: Maybe<Scalars['Int']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  living_area?: Maybe<Scalars['float8']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  lot_size?: Maybe<Scalars['String']['output']>;
  main_image_id?: Maybe<Scalars['uuid']['output']>;
  parent_property?: Maybe<Scalars['uuid']['output']>;
  parking_spaces?: Maybe<Scalars['Int']['output']>;
  parking_type?: Maybe<Parking_Type_ActionType>;
  pets_allowed?: Maybe<Scalars['Boolean']['output']>;
  plot_area?: Maybe<Scalars['float8']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  price_currency?: Maybe<Currency_ActionType>;
  property_composition?: Maybe<Array<Maybe<Property_Composition_ActionType>>>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin_type?: Maybe<Property_Origin_Type_ActionType>;
  property_status?: Maybe<Property_Status_ActionType>;
  property_type?: Maybe<Property_Type_ActionType>;
  rental_terms?: Maybe<Scalars['String']['output']>;
  sale_terms?: Maybe<Scalars['String']['output']>;
  search_vector?: Maybe<Scalars['String']['output']>;
  seo_description?: Maybe<Scalars['String']['output']>;
  seo_keywords?: Maybe<Scalars['String']['output']>;
  seo_title?: Maybe<Scalars['String']['output']>;
  smoking_allowed?: Maybe<Scalars['Boolean']['output']>;
  source_id?: Maybe<Scalars['uuid']['output']>;
  source_url?: Maybe<Scalars['String']['output']>;
  title_id: Scalars['uuid']['output'];
  total_area?: Maybe<Scalars['float8']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "property_list" */
export type Property_List_Aggregate = {
  __typename?: 'property_list_aggregate';
  aggregate?: Maybe<Property_List_Aggregate_Fields>;
  nodes: Array<Property_List>;
};

export type Property_List_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Property_List_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Property_List_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Property_List_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Property_List_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Property_List_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Property_List_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Property_List_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Property_List_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Property_List_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Property_List_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Property_List_Aggregate_Bool_Exp_Var_Samp>;
};

export type Property_List_Aggregate_Bool_Exp_Avg = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Bool_And = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Corr = {
  arguments: Property_List_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Property_List_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Property_List_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Property_List_Aggregate_Bool_Exp_Max = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Min = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Sum = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_List_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_List_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "property_list" */
export type Property_List_Aggregate_Fields = {
  __typename?: 'property_list_aggregate_fields';
  avg?: Maybe<Property_List_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Property_List_Max_Fields>;
  min?: Maybe<Property_List_Min_Fields>;
  stddev?: Maybe<Property_List_Stddev_Fields>;
  stddev_pop?: Maybe<Property_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_List_Stddev_Samp_Fields>;
  sum?: Maybe<Property_List_Sum_Fields>;
  var_pop?: Maybe<Property_List_Var_Pop_Fields>;
  var_samp?: Maybe<Property_List_Var_Samp_Fields>;
  variance?: Maybe<Property_List_Variance_Fields>;
};


/** aggregate fields of "property_list" */
export type Property_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_list" */
export type Property_List_Aggregate_Order_By = {
  avg?: InputMaybe<Property_List_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_List_Max_Order_By>;
  min?: InputMaybe<Property_List_Min_Order_By>;
  stddev?: InputMaybe<Property_List_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Property_List_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Property_List_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Property_List_Sum_Order_By>;
  var_pop?: InputMaybe<Property_List_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Property_List_Var_Samp_Order_By>;
  variance?: InputMaybe<Property_List_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Property_List_Append_Input = {
  features_json?: InputMaybe<Scalars['jsonb']['input']>;
  json_ld?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "property_list" */
export type Property_List_Arr_Rel_Insert_Input = {
  data: Array<Property_List_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_List_On_Conflict>;
};

/** aggregate avg on columns */
export type Property_List_Avg_Fields = {
  __typename?: 'property_list_avg_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "property_list" */
export type Property_List_Avg_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property_list". All fields are combined with a logical 'AND'. */
export type Property_List_Bool_Exp = {
  _and?: InputMaybe<Array<Property_List_Bool_Exp>>;
  _not?: InputMaybe<Property_List_Bool_Exp>;
  _or?: InputMaybe<Array<Property_List_Bool_Exp>>;
  building_floors?: InputMaybe<Int_Comparison_Exp>;
  canonical_url?: InputMaybe<String_Comparison_Exp>;
  construction_status?: InputMaybe<Construction_Status_Enum_Comparison_Exp>;
  construction_year?: InputMaybe<Int_Comparison_Exp>;
  contact_email?: InputMaybe<String_Comparison_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  contact_name?: InputMaybe<String_Comparison_Exp>;
  contact_phone_1?: InputMaybe<String_Comparison_Exp>;
  contact_phone_2?: InputMaybe<String_Comparison_Exp>;
  content_location?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description_id?: InputMaybe<Uuid_Comparison_Exp>;
  description_text_content?: InputMaybe<Text_Content_Bool_Exp>;
  features_json?: InputMaybe<Jsonb_Comparison_Exp>;
  floor?: InputMaybe<Int_Comparison_Exp>;
  furnishing_status?: InputMaybe<Furnishing_Status_Enum_Comparison_Exp>;
  has_balcony?: InputMaybe<Boolean_Comparison_Exp>;
  has_carport?: InputMaybe<Boolean_Comparison_Exp>;
  has_elevator?: InputMaybe<Boolean_Comparison_Exp>;
  has_garage?: InputMaybe<Boolean_Comparison_Exp>;
  has_garden?: InputMaybe<Boolean_Comparison_Exp>;
  has_parking_space?: InputMaybe<Boolean_Comparison_Exp>;
  has_terrace?: InputMaybe<Boolean_Comparison_Exp>;
  heating_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_barrier_free?: InputMaybe<Boolean_Comparison_Exp>;
  is_main_listing?: InputMaybe<Boolean_Comparison_Exp>;
  json_ld?: InputMaybe<Jsonb_Comparison_Exp>;
  last_renovation_year?: InputMaybe<Int_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  living_area?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  lot_size?: InputMaybe<String_Comparison_Exp>;
  main_image?: InputMaybe<Property_Image_Bool_Exp>;
  main_image_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_property?: InputMaybe<Uuid_Comparison_Exp>;
  parking_spaces?: InputMaybe<Int_Comparison_Exp>;
  parking_type?: InputMaybe<Parking_Type_Enum_Comparison_Exp>;
  pets_allowed?: InputMaybe<Boolean_Comparison_Exp>;
  plot_area?: InputMaybe<Float8_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  price_currency?: InputMaybe<Currency_Enum_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_amenities?: InputMaybe<Property_Amenities_Bool_Exp>;
  property_amenities_aggregate?: InputMaybe<Property_Amenities_Aggregate_Bool_Exp>;
  property_composition?: InputMaybe<Property_Composition_Bool_Exp>;
  property_composition_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_features?: InputMaybe<Property_Feature_Bool_Exp>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Bool_Exp>;
  property_images?: InputMaybe<Property_Image_Bool_Exp>;
  property_images_aggregate?: InputMaybe<Property_Image_Aggregate_Bool_Exp>;
  property_origin_type?: InputMaybe<Property_Origin_Type_Enum_Comparison_Exp>;
  property_price_histories?: InputMaybe<Property_Price_History_Bool_Exp>;
  property_price_histories_aggregate?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp>;
  property_source?: InputMaybe<Property_Source_Bool_Exp>;
  property_status?: InputMaybe<Property_Status_Enum_Comparison_Exp>;
  property_type?: InputMaybe<Property_Type_Enum_Comparison_Exp>;
  rental_terms?: InputMaybe<String_Comparison_Exp>;
  sale_terms?: InputMaybe<String_Comparison_Exp>;
  search_vector?: InputMaybe<String_Comparison_Exp>;
  smoking_allowed?: InputMaybe<Boolean_Comparison_Exp>;
  source_id?: InputMaybe<Uuid_Comparison_Exp>;
  source_url?: InputMaybe<String_Comparison_Exp>;
  title_id?: InputMaybe<Uuid_Comparison_Exp>;
  title_text_content?: InputMaybe<Text_Content_Bool_Exp>;
  total_area?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_list" */
export enum Property_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyListPkey = 'property_list_pkey',
  /** unique or primary key constraint on columns "title_id", "source_id" */
  PropertyListTitleIdSourceIdKey = 'property_list_title_id_source_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Property_List_Delete_At_Path_Input = {
  features_json?: InputMaybe<Array<Scalars['String']['input']>>;
  json_ld?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Property_List_Delete_Elem_Input = {
  features_json?: InputMaybe<Scalars['Int']['input']>;
  json_ld?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Property_List_Delete_Key_Input = {
  features_json?: InputMaybe<Scalars['String']['input']>;
  json_ld?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "property_list" */
export type Property_List_Inc_Input = {
  building_floors?: InputMaybe<Scalars['Int']['input']>;
  construction_year?: InputMaybe<Scalars['Int']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  last_renovation_year?: InputMaybe<Scalars['Int']['input']>;
  living_area?: InputMaybe<Scalars['float8']['input']>;
  parking_spaces?: InputMaybe<Scalars['Int']['input']>;
  plot_area?: InputMaybe<Scalars['float8']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  total_area?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "property_list" */
export type Property_List_Insert_Input = {
  building_floors?: InputMaybe<Scalars['Int']['input']>;
  canonical_url?: InputMaybe<Scalars['String']['input']>;
  construction_status?: InputMaybe<Construction_Status_Enum>;
  construction_year?: InputMaybe<Scalars['Int']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_phone_1?: InputMaybe<Scalars['String']['input']>;
  contact_phone_2?: InputMaybe<Scalars['String']['input']>;
  content_location?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['uuid']['input']>;
  description_text_content?: InputMaybe<Text_Content_Obj_Rel_Insert_Input>;
  features_json?: InputMaybe<Scalars['jsonb']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  furnishing_status?: InputMaybe<Furnishing_Status_Enum>;
  has_balcony?: InputMaybe<Scalars['Boolean']['input']>;
  has_carport?: InputMaybe<Scalars['Boolean']['input']>;
  has_elevator?: InputMaybe<Scalars['Boolean']['input']>;
  has_garage?: InputMaybe<Scalars['Boolean']['input']>;
  has_garden?: InputMaybe<Scalars['Boolean']['input']>;
  has_parking_space?: InputMaybe<Scalars['Boolean']['input']>;
  has_terrace?: InputMaybe<Scalars['Boolean']['input']>;
  heating_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_barrier_free?: InputMaybe<Scalars['Boolean']['input']>;
  is_main_listing?: InputMaybe<Scalars['Boolean']['input']>;
  json_ld?: InputMaybe<Scalars['jsonb']['input']>;
  last_renovation_year?: InputMaybe<Scalars['Int']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  living_area?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  lot_size?: InputMaybe<Scalars['String']['input']>;
  main_image?: InputMaybe<Property_Image_Obj_Rel_Insert_Input>;
  main_image_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_property?: InputMaybe<Scalars['uuid']['input']>;
  parking_spaces?: InputMaybe<Scalars['Int']['input']>;
  parking_type?: InputMaybe<Parking_Type_Enum>;
  pets_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  plot_area?: InputMaybe<Scalars['float8']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  price_currency?: InputMaybe<Currency_Enum>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_amenities?: InputMaybe<Property_Amenities_Arr_Rel_Insert_Input>;
  property_composition?: InputMaybe<Property_Composition_Obj_Rel_Insert_Input>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_features?: InputMaybe<Property_Feature_Arr_Rel_Insert_Input>;
  property_images?: InputMaybe<Property_Image_Arr_Rel_Insert_Input>;
  property_origin_type?: InputMaybe<Property_Origin_Type_Enum>;
  property_price_histories?: InputMaybe<Property_Price_History_Arr_Rel_Insert_Input>;
  property_source?: InputMaybe<Property_Source_Obj_Rel_Insert_Input>;
  property_status?: InputMaybe<Property_Status_Enum>;
  property_type?: InputMaybe<Property_Type_Enum>;
  rental_terms?: InputMaybe<Scalars['String']['input']>;
  sale_terms?: InputMaybe<Scalars['String']['input']>;
  search_vector?: InputMaybe<Scalars['String']['input']>;
  smoking_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  source_id?: InputMaybe<Scalars['uuid']['input']>;
  source_url?: InputMaybe<Scalars['String']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  title_text_content?: InputMaybe<Text_Content_Obj_Rel_Insert_Input>;
  total_area?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Property_List_Max_Fields = {
  __typename?: 'property_list_max_fields';
  building_floors?: Maybe<Scalars['Int']['output']>;
  canonical_url?: Maybe<Scalars['String']['output']>;
  construction_year?: Maybe<Scalars['Int']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['uuid']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_1?: Maybe<Scalars['String']['output']>;
  contact_phone_2?: Maybe<Scalars['String']['output']>;
  content_location?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id?: Maybe<Scalars['uuid']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  heating_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_renovation_year?: Maybe<Scalars['Int']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  living_area?: Maybe<Scalars['float8']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  lot_size?: Maybe<Scalars['String']['output']>;
  main_image_id?: Maybe<Scalars['uuid']['output']>;
  parent_property?: Maybe<Scalars['uuid']['output']>;
  parking_spaces?: Maybe<Scalars['Int']['output']>;
  plot_area?: Maybe<Scalars['float8']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  rental_terms?: Maybe<Scalars['String']['output']>;
  sale_terms?: Maybe<Scalars['String']['output']>;
  search_vector?: Maybe<Scalars['String']['output']>;
  source_id?: Maybe<Scalars['uuid']['output']>;
  source_url?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  total_area?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "property_list" */
export type Property_List_Max_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  canonical_url?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  contact_phone_1?: InputMaybe<Order_By>;
  contact_phone_2?: InputMaybe<Order_By>;
  content_location?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description_id?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  heating_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  lot_size?: InputMaybe<Order_By>;
  main_image_id?: InputMaybe<Order_By>;
  parent_property?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  rental_terms?: InputMaybe<Order_By>;
  sale_terms?: InputMaybe<Order_By>;
  search_vector?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_List_Min_Fields = {
  __typename?: 'property_list_min_fields';
  building_floors?: Maybe<Scalars['Int']['output']>;
  canonical_url?: Maybe<Scalars['String']['output']>;
  construction_year?: Maybe<Scalars['Int']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['uuid']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone_1?: Maybe<Scalars['String']['output']>;
  contact_phone_2?: Maybe<Scalars['String']['output']>;
  content_location?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id?: Maybe<Scalars['uuid']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  heating_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_renovation_year?: Maybe<Scalars['Int']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  living_area?: Maybe<Scalars['float8']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  lot_size?: Maybe<Scalars['String']['output']>;
  main_image_id?: Maybe<Scalars['uuid']['output']>;
  parent_property?: Maybe<Scalars['uuid']['output']>;
  parking_spaces?: Maybe<Scalars['Int']['output']>;
  plot_area?: Maybe<Scalars['float8']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  rental_terms?: Maybe<Scalars['String']['output']>;
  sale_terms?: Maybe<Scalars['String']['output']>;
  search_vector?: Maybe<Scalars['String']['output']>;
  source_id?: Maybe<Scalars['uuid']['output']>;
  source_url?: Maybe<Scalars['String']['output']>;
  title_id?: Maybe<Scalars['uuid']['output']>;
  total_area?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "property_list" */
export type Property_List_Min_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  canonical_url?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  contact_phone_1?: InputMaybe<Order_By>;
  contact_phone_2?: InputMaybe<Order_By>;
  content_location?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description_id?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  heating_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  lot_size?: InputMaybe<Order_By>;
  main_image_id?: InputMaybe<Order_By>;
  parent_property?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  rental_terms?: InputMaybe<Order_By>;
  sale_terms?: InputMaybe<Order_By>;
  search_vector?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_list" */
export type Property_List_Mutation_Response = {
  __typename?: 'property_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_List>;
};

/** input type for inserting object relation for remote table "property_list" */
export type Property_List_Obj_Rel_Insert_Input = {
  data: Property_List_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_List_On_Conflict>;
};

/** on_conflict condition type for table "property_list" */
export type Property_List_On_Conflict = {
  constraint: Property_List_Constraint;
  update_columns?: Array<Property_List_Update_Column>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};

/** Ordering options when selecting data from "property_list". */
export type Property_List_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  canonical_url?: InputMaybe<Order_By>;
  construction_status?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  contact_phone_1?: InputMaybe<Order_By>;
  contact_phone_2?: InputMaybe<Order_By>;
  content_location?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description_id?: InputMaybe<Order_By>;
  description_text_content?: InputMaybe<Text_Content_Order_By>;
  features_json?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  furnishing_status?: InputMaybe<Order_By>;
  has_balcony?: InputMaybe<Order_By>;
  has_carport?: InputMaybe<Order_By>;
  has_elevator?: InputMaybe<Order_By>;
  has_garage?: InputMaybe<Order_By>;
  has_garden?: InputMaybe<Order_By>;
  has_parking_space?: InputMaybe<Order_By>;
  has_terrace?: InputMaybe<Order_By>;
  heating_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_barrier_free?: InputMaybe<Order_By>;
  is_main_listing?: InputMaybe<Order_By>;
  json_ld?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  lot_size?: InputMaybe<Order_By>;
  main_image?: InputMaybe<Property_Image_Order_By>;
  main_image_id?: InputMaybe<Order_By>;
  parent_property?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  parking_type?: InputMaybe<Order_By>;
  pets_allowed?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_currency?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_amenities_aggregate?: InputMaybe<Property_Amenities_Aggregate_Order_By>;
  property_composition?: InputMaybe<Property_Composition_Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Order_By>;
  property_images_aggregate?: InputMaybe<Property_Image_Aggregate_Order_By>;
  property_origin_type?: InputMaybe<Order_By>;
  property_price_histories_aggregate?: InputMaybe<Property_Price_History_Aggregate_Order_By>;
  property_source?: InputMaybe<Property_Source_Order_By>;
  property_status?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  rental_terms?: InputMaybe<Order_By>;
  sale_terms?: InputMaybe<Order_By>;
  search_vector?: InputMaybe<Order_By>;
  smoking_allowed?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  title_id?: InputMaybe<Order_By>;
  title_text_content?: InputMaybe<Text_Content_Order_By>;
  total_area?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_list */
export type Property_List_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Property_List_Prepend_Input = {
  features_json?: InputMaybe<Scalars['jsonb']['input']>;
  json_ld?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "property_list" */
export enum Property_List_Select_Column {
  /** column name */
  BuildingFloors = 'building_floors',
  /** column name */
  CanonicalUrl = 'canonical_url',
  /** column name */
  ConstructionStatus = 'construction_status',
  /** column name */
  ConstructionYear = 'construction_year',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  ContactPhone_1 = 'contact_phone_1',
  /** column name */
  ContactPhone_2 = 'contact_phone_2',
  /** column name */
  ContentLocation = 'content_location',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionId = 'description_id',
  /** column name */
  FeaturesJson = 'features_json',
  /** column name */
  Floor = 'floor',
  /** column name */
  FurnishingStatus = 'furnishing_status',
  /** column name */
  HasBalcony = 'has_balcony',
  /** column name */
  HasCarport = 'has_carport',
  /** column name */
  HasElevator = 'has_elevator',
  /** column name */
  HasGarage = 'has_garage',
  /** column name */
  HasGarden = 'has_garden',
  /** column name */
  HasParkingSpace = 'has_parking_space',
  /** column name */
  HasTerrace = 'has_terrace',
  /** column name */
  HeatingType = 'heating_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsBarrierFree = 'is_barrier_free',
  /** column name */
  IsMainListing = 'is_main_listing',
  /** column name */
  JsonLd = 'json_ld',
  /** column name */
  LastRenovationYear = 'last_renovation_year',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LotSize = 'lot_size',
  /** column name */
  MainImageId = 'main_image_id',
  /** column name */
  ParentProperty = 'parent_property',
  /** column name */
  ParkingSpaces = 'parking_spaces',
  /** column name */
  ParkingType = 'parking_type',
  /** column name */
  PetsAllowed = 'pets_allowed',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  PriceCurrency = 'price_currency',
  /** column name */
  PropertyCompositionId = 'property_composition_id',
  /** column name */
  PropertyOriginType = 'property_origin_type',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  RentalTerms = 'rental_terms',
  /** column name */
  SaleTerms = 'sale_terms',
  /** column name */
  SearchVector = 'search_vector',
  /** column name */
  SmokingAllowed = 'smoking_allowed',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  TotalArea = 'total_area',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "property_list_aggregate_bool_exp_avg_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_bool_and_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasBalcony = 'has_balcony',
  /** column name */
  HasCarport = 'has_carport',
  /** column name */
  HasElevator = 'has_elevator',
  /** column name */
  HasGarage = 'has_garage',
  /** column name */
  HasGarden = 'has_garden',
  /** column name */
  HasParkingSpace = 'has_parking_space',
  /** column name */
  HasTerrace = 'has_terrace',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsBarrierFree = 'is_barrier_free',
  /** column name */
  IsMainListing = 'is_main_listing',
  /** column name */
  PetsAllowed = 'pets_allowed',
  /** column name */
  SmokingAllowed = 'smoking_allowed'
}

/** select "property_list_aggregate_bool_exp_bool_or_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasBalcony = 'has_balcony',
  /** column name */
  HasCarport = 'has_carport',
  /** column name */
  HasElevator = 'has_elevator',
  /** column name */
  HasGarage = 'has_garage',
  /** column name */
  HasGarden = 'has_garden',
  /** column name */
  HasParkingSpace = 'has_parking_space',
  /** column name */
  HasTerrace = 'has_terrace',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsBarrierFree = 'is_barrier_free',
  /** column name */
  IsMainListing = 'is_main_listing',
  /** column name */
  PetsAllowed = 'pets_allowed',
  /** column name */
  SmokingAllowed = 'smoking_allowed'
}

/** select "property_list_aggregate_bool_exp_corr_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_max_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_min_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_sum_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** select "property_list_aggregate_bool_exp_var_samp_arguments_columns" columns of table "property_list" */
export enum Property_List_Select_Column_Property_List_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  TotalArea = 'total_area'
}

/** input type for updating data in table "property_list" */
export type Property_List_Set_Input = {
  building_floors?: InputMaybe<Scalars['Int']['input']>;
  canonical_url?: InputMaybe<Scalars['String']['input']>;
  construction_status?: InputMaybe<Construction_Status_Enum>;
  construction_year?: InputMaybe<Scalars['Int']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_phone_1?: InputMaybe<Scalars['String']['input']>;
  contact_phone_2?: InputMaybe<Scalars['String']['input']>;
  content_location?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['uuid']['input']>;
  features_json?: InputMaybe<Scalars['jsonb']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  furnishing_status?: InputMaybe<Furnishing_Status_Enum>;
  has_balcony?: InputMaybe<Scalars['Boolean']['input']>;
  has_carport?: InputMaybe<Scalars['Boolean']['input']>;
  has_elevator?: InputMaybe<Scalars['Boolean']['input']>;
  has_garage?: InputMaybe<Scalars['Boolean']['input']>;
  has_garden?: InputMaybe<Scalars['Boolean']['input']>;
  has_parking_space?: InputMaybe<Scalars['Boolean']['input']>;
  has_terrace?: InputMaybe<Scalars['Boolean']['input']>;
  heating_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_barrier_free?: InputMaybe<Scalars['Boolean']['input']>;
  is_main_listing?: InputMaybe<Scalars['Boolean']['input']>;
  json_ld?: InputMaybe<Scalars['jsonb']['input']>;
  last_renovation_year?: InputMaybe<Scalars['Int']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  living_area?: InputMaybe<Scalars['float8']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  lot_size?: InputMaybe<Scalars['String']['input']>;
  main_image_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_property?: InputMaybe<Scalars['uuid']['input']>;
  parking_spaces?: InputMaybe<Scalars['Int']['input']>;
  parking_type?: InputMaybe<Parking_Type_Enum>;
  pets_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  plot_area?: InputMaybe<Scalars['float8']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  price_currency?: InputMaybe<Currency_Enum>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Property_Origin_Type_Enum>;
  property_status?: InputMaybe<Property_Status_Enum>;
  property_type?: InputMaybe<Property_Type_Enum>;
  rental_terms?: InputMaybe<Scalars['String']['input']>;
  sale_terms?: InputMaybe<Scalars['String']['input']>;
  search_vector?: InputMaybe<Scalars['String']['input']>;
  smoking_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  source_id?: InputMaybe<Scalars['uuid']['input']>;
  source_url?: InputMaybe<Scalars['String']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  total_area?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Property_List_Stddev_Fields = {
  __typename?: 'property_list_stddev_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "property_list" */
export type Property_List_Stddev_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Property_List_Stddev_Pop_Fields = {
  __typename?: 'property_list_stddev_pop_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "property_list" */
export type Property_List_Stddev_Pop_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Property_List_Stddev_Samp_Fields = {
  __typename?: 'property_list_stddev_samp_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "property_list" */
export type Property_List_Stddev_Samp_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_list" */
export type Property_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_List_Stream_Cursor_Value_Input = {
  building_floors?: InputMaybe<Scalars['Int']['input']>;
  canonical_url?: InputMaybe<Scalars['String']['input']>;
  construction_status?: InputMaybe<Construction_Status_Enum>;
  construction_year?: InputMaybe<Scalars['Int']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_phone_1?: InputMaybe<Scalars['String']['input']>;
  contact_phone_2?: InputMaybe<Scalars['String']['input']>;
  content_location?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['uuid']['input']>;
  features_json?: InputMaybe<Scalars['jsonb']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  furnishing_status?: InputMaybe<Furnishing_Status_Enum>;
  has_balcony?: InputMaybe<Scalars['Boolean']['input']>;
  has_carport?: InputMaybe<Scalars['Boolean']['input']>;
  has_elevator?: InputMaybe<Scalars['Boolean']['input']>;
  has_garage?: InputMaybe<Scalars['Boolean']['input']>;
  has_garden?: InputMaybe<Scalars['Boolean']['input']>;
  has_parking_space?: InputMaybe<Scalars['Boolean']['input']>;
  has_terrace?: InputMaybe<Scalars['Boolean']['input']>;
  heating_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_barrier_free?: InputMaybe<Scalars['Boolean']['input']>;
  is_main_listing?: InputMaybe<Scalars['Boolean']['input']>;
  json_ld?: InputMaybe<Scalars['jsonb']['input']>;
  last_renovation_year?: InputMaybe<Scalars['Int']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  living_area?: InputMaybe<Scalars['float8']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  lot_size?: InputMaybe<Scalars['String']['input']>;
  main_image_id?: InputMaybe<Scalars['uuid']['input']>;
  parent_property?: InputMaybe<Scalars['uuid']['input']>;
  parking_spaces?: InputMaybe<Scalars['Int']['input']>;
  parking_type?: InputMaybe<Parking_Type_Enum>;
  pets_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  plot_area?: InputMaybe<Scalars['float8']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  price_currency?: InputMaybe<Currency_Enum>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Property_Origin_Type_Enum>;
  property_status?: InputMaybe<Property_Status_Enum>;
  property_type?: InputMaybe<Property_Type_Enum>;
  rental_terms?: InputMaybe<Scalars['String']['input']>;
  sale_terms?: InputMaybe<Scalars['String']['input']>;
  search_vector?: InputMaybe<Scalars['String']['input']>;
  smoking_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  source_id?: InputMaybe<Scalars['uuid']['input']>;
  source_url?: InputMaybe<Scalars['String']['input']>;
  title_id?: InputMaybe<Scalars['uuid']['input']>;
  total_area?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Property_List_Sum_Fields = {
  __typename?: 'property_list_sum_fields';
  building_floors?: Maybe<Scalars['Int']['output']>;
  construction_year?: Maybe<Scalars['Int']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  last_renovation_year?: Maybe<Scalars['Int']['output']>;
  living_area?: Maybe<Scalars['float8']['output']>;
  parking_spaces?: Maybe<Scalars['Int']['output']>;
  plot_area?: Maybe<Scalars['float8']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  total_area?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "property_list" */
export type Property_List_Sum_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** update columns of table "property_list" */
export enum Property_List_Update_Column {
  /** column name */
  BuildingFloors = 'building_floors',
  /** column name */
  CanonicalUrl = 'canonical_url',
  /** column name */
  ConstructionStatus = 'construction_status',
  /** column name */
  ConstructionYear = 'construction_year',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  ContactPhone_1 = 'contact_phone_1',
  /** column name */
  ContactPhone_2 = 'contact_phone_2',
  /** column name */
  ContentLocation = 'content_location',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionId = 'description_id',
  /** column name */
  FeaturesJson = 'features_json',
  /** column name */
  Floor = 'floor',
  /** column name */
  FurnishingStatus = 'furnishing_status',
  /** column name */
  HasBalcony = 'has_balcony',
  /** column name */
  HasCarport = 'has_carport',
  /** column name */
  HasElevator = 'has_elevator',
  /** column name */
  HasGarage = 'has_garage',
  /** column name */
  HasGarden = 'has_garden',
  /** column name */
  HasParkingSpace = 'has_parking_space',
  /** column name */
  HasTerrace = 'has_terrace',
  /** column name */
  HeatingType = 'heating_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsBarrierFree = 'is_barrier_free',
  /** column name */
  IsMainListing = 'is_main_listing',
  /** column name */
  JsonLd = 'json_ld',
  /** column name */
  LastRenovationYear = 'last_renovation_year',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  LivingArea = 'living_area',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LotSize = 'lot_size',
  /** column name */
  MainImageId = 'main_image_id',
  /** column name */
  ParentProperty = 'parent_property',
  /** column name */
  ParkingSpaces = 'parking_spaces',
  /** column name */
  ParkingType = 'parking_type',
  /** column name */
  PetsAllowed = 'pets_allowed',
  /** column name */
  PlotArea = 'plot_area',
  /** column name */
  Price = 'price',
  /** column name */
  PriceCurrency = 'price_currency',
  /** column name */
  PropertyCompositionId = 'property_composition_id',
  /** column name */
  PropertyOriginType = 'property_origin_type',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  RentalTerms = 'rental_terms',
  /** column name */
  SaleTerms = 'sale_terms',
  /** column name */
  SearchVector = 'search_vector',
  /** column name */
  SmokingAllowed = 'smoking_allowed',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  TotalArea = 'total_area',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Property_List_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Property_List_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Property_List_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Property_List_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Property_List_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_List_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Property_List_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_List_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_List_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Property_List_Var_Pop_Fields = {
  __typename?: 'property_list_var_pop_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "property_list" */
export type Property_List_Var_Pop_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Property_List_Var_Samp_Fields = {
  __typename?: 'property_list_var_samp_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "property_list" */
export type Property_List_Var_Samp_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Property_List_Variance_Fields = {
  __typename?: 'property_list_variance_fields';
  building_floors?: Maybe<Scalars['Float']['output']>;
  construction_year?: Maybe<Scalars['Float']['output']>;
  floor?: Maybe<Scalars['Float']['output']>;
  last_renovation_year?: Maybe<Scalars['Float']['output']>;
  living_area?: Maybe<Scalars['Float']['output']>;
  parking_spaces?: Maybe<Scalars['Float']['output']>;
  plot_area?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  total_area?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "property_list" */
export type Property_List_Variance_Order_By = {
  building_floors?: InputMaybe<Order_By>;
  construction_year?: InputMaybe<Order_By>;
  floor?: InputMaybe<Order_By>;
  last_renovation_year?: InputMaybe<Order_By>;
  living_area?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  plot_area?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Property_Max_Fields = {
  __typename?: 'property_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  json_ld?: Maybe<Scalars['String']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  main_listing_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Property_Min_Fields = {
  __typename?: 'property_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  json_ld?: Maybe<Scalars['String']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  main_listing_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "property" */
export type Property_Mutation_Response = {
  __typename?: 'property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property>;
};

/** input type for inserting object relation for remote table "property" */
export type Property_Obj_Rel_Insert_Input = {
  data: Property_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

/** on_conflict condition type for table "property" */
export type Property_On_Conflict = {
  constraint: Property_Constraint;
  update_columns?: Array<Property_Update_Column>;
  where?: InputMaybe<Property_Bool_Exp>;
};

/** Ordering options when selecting data from "property". */
export type Property_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json_ld?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  main_listing_id?: InputMaybe<Order_By>;
  main_property?: InputMaybe<Property_List_Order_By>;
  property_features_aggregate?: InputMaybe<Property_Feature_Aggregate_Order_By>;
  property_images_aggregate?: InputMaybe<Property_Image_Aggregate_Order_By>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Order_By>;
  property_price_histories_aggregate?: InputMaybe<Property_Price_History_Aggregate_Order_By>;
  property_views_aggregate?: InputMaybe<Property_Views_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url_slugs_aggregate?: InputMaybe<Url_Slug_Aggregate_Order_By>;
  user_saved_properties_aggregate?: InputMaybe<User_Saved_Properties_Aggregate_Order_By>;
  user_seen_properties_aggregate?: InputMaybe<User_Seen_Property_Aggregate_Order_By>;
};

/** Enum per te ruajtur emertimin e origjines nga jane marre pronat */
export type Property_Origin = {
  __typename?: 'property_origin';
  origin: Scalars['String']['output'];
  ranking?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "property_origin" */
export type Property_Origin_Aggregate = {
  __typename?: 'property_origin_aggregate';
  aggregate?: Maybe<Property_Origin_Aggregate_Fields>;
  nodes: Array<Property_Origin>;
};

/** aggregate fields of "property_origin" */
export type Property_Origin_Aggregate_Fields = {
  __typename?: 'property_origin_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Origin_Max_Fields>;
  min?: Maybe<Property_Origin_Min_Fields>;
};


/** aggregate fields of "property_origin" */
export type Property_Origin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Origin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_origin". All fields are combined with a logical 'AND'. */
export type Property_Origin_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Origin_Bool_Exp>>;
  _not?: InputMaybe<Property_Origin_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Origin_Bool_Exp>>;
  origin?: InputMaybe<String_Comparison_Exp>;
  ranking?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_origin" */
export enum Property_Origin_Constraint {
  /** unique or primary key constraint on columns "origin" */
  PropertyOriginPkey = 'property_origin_pkey'
}

export enum Property_Origin_Enum {
  Century_21 = 'CENTURY_21',
  Dupron = 'DUPRON',
  MerrJep = 'MERR_JEP',
  NjoftimeAl = 'NJOFTIME_AL',
  NjoftimeCom = 'NJOFTIME_COM',
  NjoftimeFalas = 'NJOFTIME_FALAS',
  RealEstateAl = 'REAL_ESTATE_AL',
  Remax = 'REMAX',
  ThePoint = 'THE_POINT',
}

/** Boolean expression to compare columns of type "property_origin_enum". All fields are combined with logical 'AND'. */
export type Property_Origin_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Origin_Enum>;
  _in?: InputMaybe<Array<Property_Origin_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Property_Origin_Enum>;
  _nin?: InputMaybe<Array<Property_Origin_Enum>>;
};

/** input type for inserting data into table "property_origin" */
export type Property_Origin_Insert_Input = {
  origin?: InputMaybe<Scalars['String']['input']>;
  ranking?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Property_Origin_Max_Fields = {
  __typename?: 'property_origin_max_fields';
  origin?: Maybe<Scalars['String']['output']>;
  ranking?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Property_Origin_Min_Fields = {
  __typename?: 'property_origin_min_fields';
  origin?: Maybe<Scalars['String']['output']>;
  ranking?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_origin" */
export type Property_Origin_Mutation_Response = {
  __typename?: 'property_origin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Origin>;
};

/** on_conflict condition type for table "property_origin" */
export type Property_Origin_On_Conflict = {
  constraint: Property_Origin_Constraint;
  update_columns?: Array<Property_Origin_Update_Column>;
  where?: InputMaybe<Property_Origin_Bool_Exp>;
};

/** Ordering options when selecting data from "property_origin". */
export type Property_Origin_Order_By = {
  origin?: InputMaybe<Order_By>;
  ranking?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_origin */
export type Property_Origin_Pk_Columns_Input = {
  origin: Scalars['String']['input'];
};

/** select columns of table "property_origin" */
export enum Property_Origin_Select_Column {
  /** column name */
  Origin = 'origin',
  /** column name */
  Ranking = 'ranking'
}

/** input type for updating data in table "property_origin" */
export type Property_Origin_Set_Input = {
  origin?: InputMaybe<Scalars['String']['input']>;
  ranking?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_origin" */
export type Property_Origin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Origin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Origin_Stream_Cursor_Value_Input = {
  origin?: InputMaybe<Scalars['String']['input']>;
  ranking?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "property_origin_type" */
export type Property_Origin_Type = {
  __typename?: 'property_origin_type';
  type: Scalars['String']['output'];
};

export type Property_Origin_Type_ActionType = {
  __typename?: 'property_origin_type_ActionType';
  type: Scalars['String']['output'];
};

/** aggregated selection of "property_origin_type" */
export type Property_Origin_Type_Aggregate = {
  __typename?: 'property_origin_type_aggregate';
  aggregate?: Maybe<Property_Origin_Type_Aggregate_Fields>;
  nodes: Array<Property_Origin_Type>;
};

/** aggregate fields of "property_origin_type" */
export type Property_Origin_Type_Aggregate_Fields = {
  __typename?: 'property_origin_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Origin_Type_Max_Fields>;
  min?: Maybe<Property_Origin_Type_Min_Fields>;
};


/** aggregate fields of "property_origin_type" */
export type Property_Origin_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_origin_type". All fields are combined with a logical 'AND'. */
export type Property_Origin_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Origin_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Origin_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Origin_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_origin_type". All fields are combined with a logical 'AND'. */
export type Property_Origin_Type_Bool_Exp_Remote_Rel_Notificationtype_Property_Origin = {
  _and?: InputMaybe<Array<Property_Origin_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Origin_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Origin_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp_Remote_Rel_Notificationtype_Property_Origin>;
};

/** Boolean expression to filter rows from the table "property_origin_type". All fields are combined with a logical 'AND'. */
export type Property_Origin_Type_Bool_Exp_Remote_Rel_User_Filtersproperty_Origin = {
  _and?: InputMaybe<Array<Property_Origin_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Origin_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Origin_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp_Remote_Rel_User_Filtersproperty_Origin>;
};

/** unique or primary key constraints on table "property_origin_type" */
export enum Property_Origin_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  PropertyOriginTypePkey = 'property_origin_type_pkey'
}

export enum Property_Origin_Type_Enum {
  Agency = 'agency',
  Aggregator = 'aggregator'
}

/** Boolean expression to compare columns of type "property_origin_type_enum". All fields are combined with logical 'AND'. */
export type Property_Origin_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Origin_Type_Enum>;
  _in?: InputMaybe<Array<Property_Origin_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Property_Origin_Type_Enum>;
  _nin?: InputMaybe<Array<Property_Origin_Type_Enum>>;
};

/** input type for inserting data into table "property_origin_type" */
export type Property_Origin_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Property_Origin_Type_Max_Fields = {
  __typename?: 'property_origin_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Property_Origin_Type_Min_Fields = {
  __typename?: 'property_origin_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_origin_type" */
export type Property_Origin_Type_Mutation_Response = {
  __typename?: 'property_origin_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Origin_Type>;
};

/** on_conflict condition type for table "property_origin_type" */
export type Property_Origin_Type_On_Conflict = {
  constraint: Property_Origin_Type_Constraint;
  update_columns?: Array<Property_Origin_Type_Update_Column>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "property_origin_type". */
export type Property_Origin_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_origin_type */
export type Property_Origin_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "property_origin_type" */
export enum Property_Origin_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "property_origin_type" */
export type Property_Origin_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_origin_type" */
export type Property_Origin_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Origin_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Origin_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "property_origin_type" */
export enum Property_Origin_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Property_Origin_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Origin_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Origin_Type_Bool_Exp;
};

/** update columns of table "property_origin" */
export enum Property_Origin_Update_Column {
  /** column name */
  Origin = 'origin',
  /** column name */
  Ranking = 'ranking'
}

export type Property_Origin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Origin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Origin_Bool_Exp;
};

/** primary key columns input for table: property */
export type Property_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "property_price_history" */
export type Property_Price_History = {
  __typename?: 'property_price_history';
  created_at: Scalars['timestamptz']['output'];
  date_recorded: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  price: Scalars['float8']['output'];
  price_currency: Currency_Enum;
  /** An object relationship */
  property?: Maybe<Property>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  property_list: Property_List;
  property_list_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "property_price_history" */
export type Property_Price_History_Aggregate = {
  __typename?: 'property_price_history_aggregate';
  aggregate?: Maybe<Property_Price_History_Aggregate_Fields>;
  nodes: Array<Property_Price_History>;
};

export type Property_Price_History_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Property_Price_History_Aggregate_Bool_Exp_Var_Samp>;
};

export type Property_Price_History_Aggregate_Bool_Exp_Avg = {
  arguments: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Corr = {
  arguments: Property_Price_History_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Property_Price_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Property_Price_History_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Property_Price_History_Aggregate_Bool_Exp_Max = {
  arguments: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Min = {
  arguments: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Sum = {
  arguments: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Property_Price_History_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Price_History_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "property_price_history" */
export type Property_Price_History_Aggregate_Fields = {
  __typename?: 'property_price_history_aggregate_fields';
  avg?: Maybe<Property_Price_History_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Price_History_Max_Fields>;
  min?: Maybe<Property_Price_History_Min_Fields>;
  stddev?: Maybe<Property_Price_History_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Price_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Price_History_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Price_History_Sum_Fields>;
  var_pop?: Maybe<Property_Price_History_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Price_History_Var_Samp_Fields>;
  variance?: Maybe<Property_Price_History_Variance_Fields>;
};


/** aggregate fields of "property_price_history" */
export type Property_Price_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_price_history" */
export type Property_Price_History_Aggregate_Order_By = {
  avg?: InputMaybe<Property_Price_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Price_History_Max_Order_By>;
  min?: InputMaybe<Property_Price_History_Min_Order_By>;
  stddev?: InputMaybe<Property_Price_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Property_Price_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Property_Price_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Property_Price_History_Sum_Order_By>;
  var_pop?: InputMaybe<Property_Price_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Property_Price_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Property_Price_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "property_price_history" */
export type Property_Price_History_Arr_Rel_Insert_Input = {
  data: Array<Property_Price_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Price_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Property_Price_History_Avg_Fields = {
  __typename?: 'property_price_history_avg_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "property_price_history" */
export type Property_Price_History_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property_price_history". All fields are combined with a logical 'AND'. */
export type Property_Price_History_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Price_History_Bool_Exp>>;
  _not?: InputMaybe<Property_Price_History_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Price_History_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_recorded?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  price_currency?: InputMaybe<Currency_Enum_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_list?: InputMaybe<Property_List_Bool_Exp>;
  property_list_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_price_history" */
export enum Property_Price_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyPriceHistoryPkey = 'property_price_history_pkey',
  /** unique or primary key constraint on columns "price", "property_list_id" */
  PropertyPriceHistoryPropertyListIdPriceKey = 'property_price_history_property_list_id_price_key'
}

/** input type for incrementing numeric columns in table "property_price_history" */
export type Property_Price_History_Inc_Input = {
  price?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "property_price_history" */
export type Property_Price_History_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_recorded?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  price_currency?: InputMaybe<Currency_Enum>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list?: InputMaybe<Property_List_Obj_Rel_Insert_Input>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Property_Price_History_Max_Fields = {
  __typename?: 'property_price_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_recorded?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "property_price_history" */
export type Property_Price_History_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date_recorded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Price_History_Min_Fields = {
  __typename?: 'property_price_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_recorded?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  property_list_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "property_price_history" */
export type Property_Price_History_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date_recorded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_price_history" */
export type Property_Price_History_Mutation_Response = {
  __typename?: 'property_price_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Price_History>;
};

/** on_conflict condition type for table "property_price_history" */
export type Property_Price_History_On_Conflict = {
  constraint: Property_Price_History_Constraint;
  update_columns?: Array<Property_Price_History_Update_Column>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};

/** Ordering options when selecting data from "property_price_history". */
export type Property_Price_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date_recorded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_currency?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_list?: InputMaybe<Property_List_Order_By>;
  property_list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_price_history */
export type Property_Price_History_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_price_history" */
export enum Property_Price_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRecorded = 'date_recorded',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  PriceCurrency = 'price_currency',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  PropertyListId = 'property_list_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "property_price_history_aggregate_bool_exp_avg_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_corr_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_max_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_min_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_sum_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** select "property_price_history_aggregate_bool_exp_var_samp_arguments_columns" columns of table "property_price_history" */
export enum Property_Price_History_Select_Column_Property_Price_History_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "property_price_history" */
export type Property_Price_History_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_recorded?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  price_currency?: InputMaybe<Currency_Enum>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Property_Price_History_Stddev_Fields = {
  __typename?: 'property_price_history_stddev_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "property_price_history" */
export type Property_Price_History_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Property_Price_History_Stddev_Pop_Fields = {
  __typename?: 'property_price_history_stddev_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "property_price_history" */
export type Property_Price_History_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Property_Price_History_Stddev_Samp_Fields = {
  __typename?: 'property_price_history_stddev_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "property_price_history" */
export type Property_Price_History_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_price_history" */
export type Property_Price_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Price_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Price_History_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_recorded?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  price_currency?: InputMaybe<Currency_Enum>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  property_list_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Property_Price_History_Sum_Fields = {
  __typename?: 'property_price_history_sum_fields';
  price?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "property_price_history" */
export type Property_Price_History_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "property_price_history" */
export enum Property_Price_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRecorded = 'date_recorded',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  PriceCurrency = 'price_currency',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  PropertyListId = 'property_list_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Property_Price_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Price_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Price_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Price_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Property_Price_History_Var_Pop_Fields = {
  __typename?: 'property_price_history_var_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "property_price_history" */
export type Property_Price_History_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Property_Price_History_Var_Samp_Fields = {
  __typename?: 'property_price_history_var_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "property_price_history" */
export type Property_Price_History_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Property_Price_History_Variance_Fields = {
  __typename?: 'property_price_history_variance_fields';
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "property_price_history" */
export type Property_Price_History_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** select columns of table "property" */
export enum Property_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JsonLd = 'json_ld',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  MainListingId = 'main_listing_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "property" */
export type Property_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  json_ld?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  main_listing_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "property_source" */
export type Property_Source = {
  __typename?: 'property_source';
  /** An object relationship */
  agency?: Maybe<Agency>;
  agency_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  origin?: Maybe<Property_Origin_Enum>;
  /** An array relationship */
  property_lists: Array<Property_List>;
  /** An aggregate relationship */
  property_lists_aggregate: Property_List_Aggregate;
  type: Property_Origin_Type_Enum;
};


/** columns and relationships of "property_source" */
export type Property_SourceProperty_ListsArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


/** columns and relationships of "property_source" */
export type Property_SourceProperty_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};

/** aggregated selection of "property_source" */
export type Property_Source_Aggregate = {
  __typename?: 'property_source_aggregate';
  aggregate?: Maybe<Property_Source_Aggregate_Fields>;
  nodes: Array<Property_Source>;
};

export type Property_Source_Aggregate_Bool_Exp = {
  count?: InputMaybe<Property_Source_Aggregate_Bool_Exp_Count>;
};

export type Property_Source_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Source_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "property_source" */
export type Property_Source_Aggregate_Fields = {
  __typename?: 'property_source_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Source_Max_Fields>;
  min?: Maybe<Property_Source_Min_Fields>;
};


/** aggregate fields of "property_source" */
export type Property_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_source" */
export type Property_Source_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Source_Max_Order_By>;
  min?: InputMaybe<Property_Source_Min_Order_By>;
};

/** input type for inserting array relation for remote table "property_source" */
export type Property_Source_Arr_Rel_Insert_Input = {
  data: Array<Property_Source_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Source_On_Conflict>;
};

/** Boolean expression to filter rows from the table "property_source". All fields are combined with a logical 'AND'. */
export type Property_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Source_Bool_Exp>>;
  _not?: InputMaybe<Property_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Source_Bool_Exp>>;
  agency?: InputMaybe<Agency_Bool_Exp>;
  agency_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  origin?: InputMaybe<Property_Origin_Enum_Comparison_Exp>;
  property_lists?: InputMaybe<Property_List_Bool_Exp>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Bool_Exp>;
  type?: InputMaybe<Property_Origin_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_source" */
export enum Property_Source_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertySourcePkey = 'property_source_pkey'
}

/** input type for inserting data into table "property_source" */
export type Property_Source_Insert_Input = {
  agency?: InputMaybe<Agency_Obj_Rel_Insert_Input>;
  agency_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  origin?: InputMaybe<Property_Origin_Enum>;
  property_lists?: InputMaybe<Property_List_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Property_Origin_Type_Enum>;
};

/** aggregate max on columns */
export type Property_Source_Max_Fields = {
  __typename?: 'property_source_max_fields';
  agency_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "property_source" */
export type Property_Source_Max_Order_By = {
  agency_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Source_Min_Fields = {
  __typename?: 'property_source_min_fields';
  agency_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "property_source" */
export type Property_Source_Min_Order_By = {
  agency_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_source" */
export type Property_Source_Mutation_Response = {
  __typename?: 'property_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Source>;
};

/** input type for inserting object relation for remote table "property_source" */
export type Property_Source_Obj_Rel_Insert_Input = {
  data: Property_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Source_On_Conflict>;
};

/** on_conflict condition type for table "property_source" */
export type Property_Source_On_Conflict = {
  constraint: Property_Source_Constraint;
  update_columns?: Array<Property_Source_Update_Column>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "property_source". */
export type Property_Source_Order_By = {
  agency?: InputMaybe<Agency_Order_By>;
  agency_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  property_lists_aggregate?: InputMaybe<Property_List_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_source */
export type Property_Source_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_source" */
export enum Property_Source_Select_Column {
  /** column name */
  AgencyId = 'agency_id',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "property_source" */
export type Property_Source_Set_Input = {
  agency_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  origin?: InputMaybe<Property_Origin_Enum>;
  type?: InputMaybe<Property_Origin_Type_Enum>;
};

/** Streaming cursor of the table "property_source" */
export type Property_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Source_Stream_Cursor_Value_Input = {
  agency_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  origin?: InputMaybe<Property_Origin_Enum>;
  type?: InputMaybe<Property_Origin_Type_Enum>;
};

/** update columns of table "property_source" */
export enum Property_Source_Update_Column {
  /** column name */
  AgencyId = 'agency_id',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  Type = 'type'
}

export type Property_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Source_Bool_Exp;
};

/** columns and relationships of "property_status" */
export type Property_Status = {
  __typename?: 'property_status';
  status: Scalars['String']['output'];
};

export type Property_Status_ActionType = {
  __typename?: 'property_status_ActionType';
  status: Scalars['String']['output'];
};

/** aggregated selection of "property_status" */
export type Property_Status_Aggregate = {
  __typename?: 'property_status_aggregate';
  aggregate?: Maybe<Property_Status_Aggregate_Fields>;
  nodes: Array<Property_Status>;
};

/** aggregate fields of "property_status" */
export type Property_Status_Aggregate_Fields = {
  __typename?: 'property_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Status_Max_Fields>;
  min?: Maybe<Property_Status_Min_Fields>;
};


/** aggregate fields of "property_status" */
export type Property_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_status". All fields are combined with a logical 'AND'. */
export type Property_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Status_Bool_Exp>>;
  _not?: InputMaybe<Property_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_status". All fields are combined with a logical 'AND'. */
export type Property_Status_Bool_Exp_Remote_Rel_Notificationstatus_Property = {
  _and?: InputMaybe<Array<Property_Status_Bool_Exp>>;
  _not?: InputMaybe<Property_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp_Remote_Rel_Notificationstatus_Property>;
};

/** Boolean expression to filter rows from the table "property_status". All fields are combined with a logical 'AND'. */
export type Property_Status_Bool_Exp_Remote_Rel_User_Filtersstatus_Property = {
  _and?: InputMaybe<Array<Property_Status_Bool_Exp>>;
  _not?: InputMaybe<Property_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp_Remote_Rel_User_Filtersstatus_Property>;
};

/** unique or primary key constraints on table "property_status" */
export enum Property_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  PropertyStatusPkey = 'property_status_pkey'
}

export enum Property_Status_Enum {
  ForRent = 'for_rent',
  ForSale = 'for_sale',
  Rented = 'rented',
  Sold = 'sold',
  UnderOffer = 'under_offer'
}

/** Boolean expression to compare columns of type "property_status_enum". All fields are combined with logical 'AND'. */
export type Property_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Status_Enum>;
  _in?: InputMaybe<Array<Property_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Property_Status_Enum>;
  _nin?: InputMaybe<Array<Property_Status_Enum>>;
};

/** input type for inserting data into table "property_status" */
export type Property_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Property_Status_Max_Fields = {
  __typename?: 'property_status_max_fields';
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Property_Status_Min_Fields = {
  __typename?: 'property_status_min_fields';
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_status" */
export type Property_Status_Mutation_Response = {
  __typename?: 'property_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Status>;
};

/** on_conflict condition type for table "property_status" */
export type Property_Status_On_Conflict = {
  constraint: Property_Status_Constraint;
  update_columns?: Array<Property_Status_Update_Column>;
  where?: InputMaybe<Property_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "property_status". */
export type Property_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_status */
export type Property_Status_Pk_Columns_Input = {
  status: Scalars['String']['input'];
};

/** select columns of table "property_status" */
export enum Property_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "property_status" */
export type Property_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_status" */
export type Property_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "property_status" */
export enum Property_Status_Update_Column {
  /** column name */
  Status = 'status'
}

export type Property_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Status_Bool_Exp;
};

/** Streaming cursor of the table "property" */
export type Property_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  json_ld?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  main_listing_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "property_type" */
export type Property_Type = {
  __typename?: 'property_type';
  cluster?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Property_Type_ActionType = {
  __typename?: 'property_type_ActionType';
  type: Scalars['String']['output'];
};

/** aggregated selection of "property_type" */
export type Property_Type_Aggregate = {
  __typename?: 'property_type_aggregate';
  aggregate?: Maybe<Property_Type_Aggregate_Fields>;
  nodes: Array<Property_Type>;
};

/** aggregate fields of "property_type" */
export type Property_Type_Aggregate_Fields = {
  __typename?: 'property_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Type_Max_Fields>;
  min?: Maybe<Property_Type_Min_Fields>;
};


/** aggregate fields of "property_type" */
export type Property_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
export type Property_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  cluster?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
export type Property_Type_Bool_Exp_Remote_Rel_Notification_Property_Typetype = {
  _and?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  cluster?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp_Remote_Rel_Notification_Property_Typetype>;
};

/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
export type Property_Type_Bool_Exp_Remote_Rel_Search_Contexttype = {
  _and?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  cluster?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp_Remote_Rel_Search_Contexttype>;
};

/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
export type Property_Type_Bool_Exp_Remote_Rel_User_Filterstype_Property = {
  _and?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  cluster?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp_Remote_Rel_User_Filterstype_Property>;
};

/** unique or primary key constraints on table "property_type" */
export enum Property_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  PropertyTypePkey = 'property_type_pkey'
}

export enum Property_Type_Enum {
  Apartment = 'apartment',
  Building = 'building',
  Bungalow = 'bungalow',
  Duplex = 'duplex',
  Farmhouse = 'farmhouse',
  Garage = 'garage',
  Home = 'home',
  Industrial = 'industrial',
  Land = 'land',
  MultipurposeSpace = 'multipurpose_space',
  Office = 'office',
  Parking = 'parking',
  Room = 'room',
  Villa = 'villa'
}

/** Boolean expression to compare columns of type "property_type_enum". All fields are combined with logical 'AND'. */
export type Property_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Type_Enum>;
  _in?: InputMaybe<Array<Property_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Property_Type_Enum>;
  _nin?: InputMaybe<Array<Property_Type_Enum>>;
};

/** input type for inserting data into table "property_type" */
export type Property_Type_Insert_Input = {
  cluster?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Property_Type_Max_Fields = {
  __typename?: 'property_type_max_fields';
  cluster?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Property_Type_Min_Fields = {
  __typename?: 'property_type_min_fields';
  cluster?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_type" */
export type Property_Type_Mutation_Response = {
  __typename?: 'property_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Type>;
};

/** on_conflict condition type for table "property_type" */
export type Property_Type_On_Conflict = {
  constraint: Property_Type_Constraint;
  update_columns?: Array<Property_Type_Update_Column>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "property_type". */
export type Property_Type_Order_By = {
  cluster?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_type */
export type Property_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "property_type" */
export enum Property_Type_Select_Column {
  /** column name */
  Cluster = 'cluster',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "property_type" */
export type Property_Type_Set_Input = {
  cluster?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_type" */
export type Property_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Type_Stream_Cursor_Value_Input = {
  cluster?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "property_type" */
export enum Property_Type_Update_Column {
  /** column name */
  Cluster = 'cluster',
  /** column name */
  Type = 'type'
}

export type Property_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Type_Bool_Exp;
};

/** update columns of table "property" */
export enum Property_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JsonLd = 'json_ld',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  MainListingId = 'main_listing_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Property_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Bool_Exp;
};

/** columns and relationships of "property_views" */
export type Property_Views = {
  __typename?: 'property_views';
  created_at: Scalars['timestamptz']['output'];
  date: Scalars['date']['output'];
  id: Scalars['uuid']['output'];
  property_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  view_count: Scalars['Int']['output'];
};

/** aggregated selection of "property_views" */
export type Property_Views_Aggregate = {
  __typename?: 'property_views_aggregate';
  aggregate?: Maybe<Property_Views_Aggregate_Fields>;
  nodes: Array<Property_Views>;
};

export type Property_Views_Aggregate_Bool_Exp = {
  count?: InputMaybe<Property_Views_Aggregate_Bool_Exp_Count>;
};

export type Property_Views_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Property_Views_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "property_views" */
export type Property_Views_Aggregate_Fields = {
  __typename?: 'property_views_aggregate_fields';
  avg?: Maybe<Property_Views_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Property_Views_Max_Fields>;
  min?: Maybe<Property_Views_Min_Fields>;
  stddev?: Maybe<Property_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Views_Sum_Fields>;
  var_pop?: Maybe<Property_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Views_Var_Samp_Fields>;
  variance?: Maybe<Property_Views_Variance_Fields>;
};


/** aggregate fields of "property_views" */
export type Property_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property_views" */
export type Property_Views_Aggregate_Order_By = {
  avg?: InputMaybe<Property_Views_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Views_Max_Order_By>;
  min?: InputMaybe<Property_Views_Min_Order_By>;
  stddev?: InputMaybe<Property_Views_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Property_Views_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Property_Views_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Property_Views_Sum_Order_By>;
  var_pop?: InputMaybe<Property_Views_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Property_Views_Var_Samp_Order_By>;
  variance?: InputMaybe<Property_Views_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "property_views" */
export type Property_Views_Arr_Rel_Insert_Input = {
  data: Array<Property_Views_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Views_On_Conflict>;
};

/** aggregate avg on columns */
export type Property_Views_Avg_Fields = {
  __typename?: 'property_views_avg_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "property_views" */
export type Property_Views_Avg_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property_views". All fields are combined with a logical 'AND'. */
export type Property_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Views_Bool_Exp>>;
  _not?: InputMaybe<Property_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Views_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  view_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "property_views" */
export enum Property_Views_Constraint {
  /** unique or primary key constraint on columns "id" */
  PropertyViewsPkey = 'property_views_pkey'
}

/** input type for incrementing numeric columns in table "property_views" */
export type Property_Views_Inc_Input = {
  view_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "property_views" */
export type Property_Views_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Property_Views_Max_Fields = {
  __typename?: 'property_views_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  view_count?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "property_views" */
export type Property_Views_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_count?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Property_Views_Min_Fields = {
  __typename?: 'property_views_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  view_count?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "property_views" */
export type Property_Views_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_count?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "property_views" */
export type Property_Views_Mutation_Response = {
  __typename?: 'property_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Views>;
};

/** on_conflict condition type for table "property_views" */
export type Property_Views_On_Conflict = {
  constraint: Property_Views_Constraint;
  update_columns?: Array<Property_Views_Update_Column>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "property_views". */
export type Property_Views_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_views */
export type Property_Views_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "property_views" */
export enum Property_Views_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewCount = 'view_count'
}

/** input type for updating data in table "property_views" */
export type Property_Views_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Property_Views_Stddev_Fields = {
  __typename?: 'property_views_stddev_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "property_views" */
export type Property_Views_Stddev_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Property_Views_Stddev_Pop_Fields = {
  __typename?: 'property_views_stddev_pop_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "property_views" */
export type Property_Views_Stddev_Pop_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Property_Views_Stddev_Samp_Fields = {
  __typename?: 'property_views_stddev_samp_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "property_views" */
export type Property_Views_Stddev_Samp_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_views" */
export type Property_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Property_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Views_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  view_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Property_Views_Sum_Fields = {
  __typename?: 'property_views_sum_fields';
  view_count?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "property_views" */
export type Property_Views_Sum_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** update columns of table "property_views" */
export enum Property_Views_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewCount = 'view_count'
}

export type Property_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Property_Views_Var_Pop_Fields = {
  __typename?: 'property_views_var_pop_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "property_views" */
export type Property_Views_Var_Pop_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Property_Views_Var_Samp_Fields = {
  __typename?: 'property_views_var_samp_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "property_views" */
export type Property_Views_Var_Samp_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Property_Views_Variance_Fields = {
  __typename?: 'property_views_variance_fields';
  view_count?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "property_views" */
export type Property_Views_Variance_Order_By = {
  view_count?: InputMaybe<Order_By>;
};

/** columns and relationships of "queries_property_result" */
export type Queries_Property_Result = {
  __typename?: 'queries_property_result';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  natural_language_query_id: Scalars['uuid']['output'];
  property: Array<Property>;
  property_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "queries_property_result" */
export type Queries_Property_ResultPropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp_Remote_Rel_Queries_Property_Resultproperty>;
};

/** aggregated selection of "queries_property_result" */
export type Queries_Property_Result_Aggregate = {
  __typename?: 'queries_property_result_aggregate';
  aggregate?: Maybe<Queries_Property_Result_Aggregate_Fields>;
  nodes: Array<Queries_Property_Result>;
};

export type Queries_Property_Result_Aggregate_Bool_Exp = {
  count?: InputMaybe<Queries_Property_Result_Aggregate_Bool_Exp_Count>;
};

export type Queries_Property_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Queries_Property_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "queries_property_result" */
export type Queries_Property_Result_Aggregate_Fields = {
  __typename?: 'queries_property_result_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Queries_Property_Result_Max_Fields>;
  min?: Maybe<Queries_Property_Result_Min_Fields>;
};


/** aggregate fields of "queries_property_result" */
export type Queries_Property_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "queries_property_result" */
export type Queries_Property_Result_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Queries_Property_Result_Max_Order_By>;
  min?: InputMaybe<Queries_Property_Result_Min_Order_By>;
};

/** input type for inserting array relation for remote table "queries_property_result" */
export type Queries_Property_Result_Arr_Rel_Insert_Input = {
  data: Array<Queries_Property_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Queries_Property_Result_On_Conflict>;
};

/** Boolean expression to filter rows from the table "queries_property_result". All fields are combined with a logical 'AND'. */
export type Queries_Property_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Queries_Property_Result_Bool_Exp>>;
  _not?: InputMaybe<Queries_Property_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Queries_Property_Result_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  natural_language_query_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "queries_property_result" */
export enum Queries_Property_Result_Constraint {
  /** unique or primary key constraint on columns "natural_language_query_id", "property_id" */
  QueriesPropertyResultNaturalLanguageQueryIdPropertyKey = 'queries_property_result_natural_language_query_id_property__key',
  /** unique or primary key constraint on columns "id" */
  QueriesPropertyResultPkey = 'queries_property_result_pkey'
}

/** input type for inserting data into table "queries_property_result" */
export type Queries_Property_Result_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  natural_language_query_id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Queries_Property_Result_Max_Fields = {
  __typename?: 'queries_property_result_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  natural_language_query_id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "queries_property_result" */
export type Queries_Property_Result_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  natural_language_query_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Queries_Property_Result_Min_Fields = {
  __typename?: 'queries_property_result_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  natural_language_query_id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "queries_property_result" */
export type Queries_Property_Result_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  natural_language_query_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "queries_property_result" */
export type Queries_Property_Result_Mutation_Response = {
  __typename?: 'queries_property_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Queries_Property_Result>;
};

/** on_conflict condition type for table "queries_property_result" */
export type Queries_Property_Result_On_Conflict = {
  constraint: Queries_Property_Result_Constraint;
  update_columns?: Array<Queries_Property_Result_Update_Column>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "queries_property_result". */
export type Queries_Property_Result_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  natural_language_query_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: queries_property_result */
export type Queries_Property_Result_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "queries_property_result" */
export enum Queries_Property_Result_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NaturalLanguageQueryId = 'natural_language_query_id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "queries_property_result" */
export type Queries_Property_Result_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  natural_language_query_id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "queries_property_result" */
export type Queries_Property_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Queries_Property_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Queries_Property_Result_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  natural_language_query_id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "queries_property_result" */
export enum Queries_Property_Result_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NaturalLanguageQueryId = 'natural_language_query_id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Queries_Property_Result_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Queries_Property_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Queries_Property_Result_Bool_Exp;
};

/** Enum about the intention of the query */
export type Query_Classification = {
  __typename?: 'query_classification';
  class: Scalars['String']['output'];
};

/** aggregated selection of "query_classification" */
export type Query_Classification_Aggregate = {
  __typename?: 'query_classification_aggregate';
  aggregate?: Maybe<Query_Classification_Aggregate_Fields>;
  nodes: Array<Query_Classification>;
};

/** aggregate fields of "query_classification" */
export type Query_Classification_Aggregate_Fields = {
  __typename?: 'query_classification_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Query_Classification_Max_Fields>;
  min?: Maybe<Query_Classification_Min_Fields>;
};


/** aggregate fields of "query_classification" */
export type Query_Classification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Query_Classification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "query_classification". All fields are combined with a logical 'AND'. */
export type Query_Classification_Bool_Exp = {
  _and?: InputMaybe<Array<Query_Classification_Bool_Exp>>;
  _not?: InputMaybe<Query_Classification_Bool_Exp>;
  _or?: InputMaybe<Array<Query_Classification_Bool_Exp>>;
  class?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "query_classification" */
export enum Query_Classification_Constraint {
  /** unique or primary key constraint on columns "class" */
  QueryClassificationPkey = 'query_classification_pkey'
}

export enum Query_Classification_Enum {
  NotAboutRealEstate = 'NOT_ABOUT_REAL_ESTATE',
  RealEstateFiltering = 'REAL_ESTATE_FILTERING',
  RealEstateStatistics = 'REAL_ESTATE_STATISTICS'
}

/** Boolean expression to compare columns of type "query_classification_enum". All fields are combined with logical 'AND'. */
export type Query_Classification_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Query_Classification_Enum>;
  _in?: InputMaybe<Array<Query_Classification_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Query_Classification_Enum>;
  _nin?: InputMaybe<Array<Query_Classification_Enum>>;
};

/** input type for inserting data into table "query_classification" */
export type Query_Classification_Insert_Input = {
  class?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Query_Classification_Max_Fields = {
  __typename?: 'query_classification_max_fields';
  class?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Query_Classification_Min_Fields = {
  __typename?: 'query_classification_min_fields';
  class?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "query_classification" */
export type Query_Classification_Mutation_Response = {
  __typename?: 'query_classification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Query_Classification>;
};

/** on_conflict condition type for table "query_classification" */
export type Query_Classification_On_Conflict = {
  constraint: Query_Classification_Constraint;
  update_columns?: Array<Query_Classification_Update_Column>;
  where?: InputMaybe<Query_Classification_Bool_Exp>;
};

/** Ordering options when selecting data from "query_classification". */
export type Query_Classification_Order_By = {
  class?: InputMaybe<Order_By>;
};

/** primary key columns input for table: query_classification */
export type Query_Classification_Pk_Columns_Input = {
  class: Scalars['String']['input'];
};

/** select columns of table "query_classification" */
export enum Query_Classification_Select_Column {
  /** column name */
  Class = 'class'
}

/** input type for updating data in table "query_classification" */
export type Query_Classification_Set_Input = {
  class?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "query_classification" */
export type Query_Classification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Query_Classification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Query_Classification_Stream_Cursor_Value_Input = {
  class?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "query_classification" */
export enum Query_Classification_Update_Column {
  /** column name */
  Class = 'class'
}

export type Query_Classification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Query_Classification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Query_Classification_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address_types" */
  address_types: Array<Address_Types>;
  /** fetch aggregated fields from the table: "address_types" */
  address_types_aggregate: Address_Types_Aggregate;
  /** fetch data from the table: "address_types" using primary key columns */
  address_types_by_pk?: Maybe<Address_Types>;
  /** fetch data from the table: "agency" */
  agency: Array<Agency>;
  /** fetch aggregated fields from the table: "agency" */
  agency_aggregate: Agency_Aggregate;
  /** fetch data from the table: "agency" using primary key columns */
  agency_by_pk?: Maybe<Agency>;
  /** fetch data from the table: "amenities" */
  amenities: Array<Amenities>;
  /** fetch aggregated fields from the table: "amenities" */
  amenities_aggregate: Amenities_Aggregate;
  /** fetch data from the table: "amenities" using primary key columns */
  amenities_by_pk?: Maybe<Amenities>;
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequests_Aggregate;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProviders_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypes_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokens_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRoles_Aggregate;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProviders_Aggregate;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRoles_Aggregate;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeys_Aggregate;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table: "chat_response_type" */
  chat_response_type: Array<Chat_Response_Type>;
  /** fetch aggregated fields from the table: "chat_response_type" */
  chat_response_type_aggregate: Chat_Response_Type_Aggregate;
  /** fetch data from the table: "chat_response_type" using primary key columns */
  chat_response_type_by_pk?: Maybe<Chat_Response_Type>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  city_aggregate: City_Aggregate;
  /** fetch data from the table: "city" using primary key columns */
  city_by_pk?: Maybe<City>;
  /** fetch data from the table: "city_zone" */
  city_zone: Array<City_Zone>;
  /** fetch aggregated fields from the table: "city_zone" */
  city_zone_aggregate: City_Zone_Aggregate;
  /** fetch data from the table: "city_zone" using primary key columns */
  city_zone_by_pk?: Maybe<City_Zone>;
  /** fetch data from the table: "city_zone_origin" */
  city_zone_origin: Array<City_Zone_Origin>;
  /** fetch aggregated fields from the table: "city_zone_origin" */
  city_zone_origin_aggregate: City_Zone_Origin_Aggregate;
  /** fetch data from the table: "city_zone_origin" using primary key columns */
  city_zone_origin_by_pk?: Maybe<City_Zone_Origin>;
  /** fetch data from the table: "construction_status" */
  construction_status: Array<Construction_Status>;
  /** fetch aggregated fields from the table: "construction_status" */
  construction_status_aggregate: Construction_Status_Aggregate;
  /** fetch data from the table: "construction_status" using primary key columns */
  construction_status_by_pk?: Maybe<Construction_Status>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** fetch data from the table: "contact_phone_number" */
  contact_phone_number: Array<Contact_Phone_Number>;
  /** fetch aggregated fields from the table: "contact_phone_number" */
  contact_phone_number_aggregate: Contact_Phone_Number_Aggregate;
  /** fetch data from the table: "contact_phone_number" using primary key columns */
  contact_phone_number_by_pk?: Maybe<Contact_Phone_Number>;
  /** fetch data from the table: "contact_type" */
  contact_type: Array<Contact_Type>;
  /** fetch aggregated fields from the table: "contact_type" */
  contact_type_aggregate: Contact_Type_Aggregate;
  /** fetch data from the table: "contact_type" using primary key columns */
  contact_type_by_pk?: Maybe<Contact_Type>;
  /** fetch data from the table: "content_type" */
  content_type: Array<Content_Type>;
  /** fetch aggregated fields from the table: "content_type" */
  content_type_aggregate: Content_Type_Aggregate;
  /** fetch data from the table: "content_type" using primary key columns */
  content_type_by_pk?: Maybe<Content_Type>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>;
  /** fetch data from the table: "currency_exchange_rates" */
  currency_exchange_rates: Array<Currency_Exchange_Rates>;
  /** fetch aggregated fields from the table: "currency_exchange_rates" */
  currency_exchange_rates_aggregate: Currency_Exchange_Rates_Aggregate;
  /** fetch data from the table: "currency_exchange_rates" using primary key columns */
  currency_exchange_rates_by_pk?: Maybe<Currency_Exchange_Rates>;
  /** fetch data from the table: "entities" */
  entities: Array<Entities>;
  /** fetch aggregated fields from the table: "entities" */
  entities_aggregate: Entities_Aggregate;
  /** fetch data from the table: "entities" using primary key columns */
  entities_by_pk?: Maybe<Entities>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk?: Maybe<Feature>;
  /** fetch data from the table: "feature_type" */
  feature_type: Array<Feature_Type>;
  /** fetch aggregated fields from the table: "feature_type" */
  feature_type_aggregate: Feature_Type_Aggregate;
  /** fetch data from the table: "feature_type" using primary key columns */
  feature_type_by_pk?: Maybe<Feature_Type>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table: "furnishing_status" */
  furnishing_status: Array<Furnishing_Status>;
  /** fetch aggregated fields from the table: "furnishing_status" */
  furnishing_status_aggregate: Furnishing_Status_Aggregate;
  /** fetch data from the table: "furnishing_status" using primary key columns */
  furnishing_status_by_pk?: Maybe<Furnishing_Status>;
  /** fetch data from the table: "heatingType" */
  heatingType: Array<HeatingType>;
  /** fetch aggregated fields from the table: "heatingType" */
  heatingType_aggregate: HeatingType_Aggregate;
  /** fetch data from the table: "heatingType" using primary key columns */
  heatingType_by_pk?: Maybe<HeatingType>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch aggregated fields from the table: "languages" */
  languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "languages" using primary key columns */
  languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "membership_package" */
  membership_package: Array<Membership_Package>;
  /** fetch aggregated fields from the table: "membership_package" */
  membership_package_aggregate: Membership_Package_Aggregate;
  /** fetch data from the table: "membership_package" using primary key columns */
  membership_package_by_pk?: Maybe<Membership_Package>;
  /** An array relationship */
  natural_language_queries: Array<Natural_Language_Queries>;
  /** An aggregate relationship */
  natural_language_queries_aggregate: Natural_Language_Queries_Aggregate;
  /** fetch data from the table: "natural_language_queries" using primary key columns */
  natural_language_queries_by_pk?: Maybe<Natural_Language_Queries>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** An array relationship */
  notification_amenities: Array<Notification_Amenities>;
  /** An aggregate relationship */
  notification_amenities_aggregate: Notification_Amenities_Aggregate;
  /** fetch data from the table: "notification_amenities" using primary key columns */
  notification_amenities_by_pk?: Maybe<Notification_Amenities>;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_feature" */
  notification_feature: Array<Notification_Feature>;
  /** fetch aggregated fields from the table: "notification_feature" */
  notification_feature_aggregate: Notification_Feature_Aggregate;
  /** fetch data from the table: "notification_feature" using primary key columns */
  notification_feature_by_pk?: Maybe<Notification_Feature>;
  /** fetch data from the table: "notification_property_type" */
  notification_property_type: Array<Notification_Property_Type>;
  /** fetch aggregated fields from the table: "notification_property_type" */
  notification_property_type_aggregate: Notification_Property_Type_Aggregate;
  /** fetch data from the table: "notification_property_type" using primary key columns */
  notification_property_type_by_pk?: Maybe<Notification_Property_Type>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "parking_type" */
  parking_type: Array<Parking_Type>;
  /** fetch aggregated fields from the table: "parking_type" */
  parking_type_aggregate: Parking_Type_Aggregate;
  /** fetch data from the table: "parking_type" using primary key columns */
  parking_type_by_pk?: Maybe<Parking_Type>;
  /** fetch data from the table: "property" */
  property: Array<Property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: Property_Aggregate;
  /** An array relationship */
  property_amenities: Array<Property_Amenities>;
  /** An aggregate relationship */
  property_amenities_aggregate: Property_Amenities_Aggregate;
  /** fetch data from the table: "property_amenities" using primary key columns */
  property_amenities_by_pk?: Maybe<Property_Amenities>;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<Property>;
  /** fetch data from the table: "property_cluster" */
  property_cluster: Array<Property_Cluster>;
  /** fetch aggregated fields from the table: "property_cluster" */
  property_cluster_aggregate: Property_Cluster_Aggregate;
  /** fetch data from the table: "property_cluster" using primary key columns */
  property_cluster_by_pk?: Maybe<Property_Cluster>;
  /** fetch data from the table: "property_composition" */
  property_composition: Array<Property_Composition>;
  /** fetch aggregated fields from the table: "property_composition" */
  property_composition_aggregate: Property_Composition_Aggregate;
  /** fetch data from the table: "property_composition" using primary key columns */
  property_composition_by_pk?: Maybe<Property_Composition>;
  /** fetch data from the table: "property_feature" */
  property_feature: Array<Property_Feature>;
  /** fetch aggregated fields from the table: "property_feature" */
  property_feature_aggregate: Property_Feature_Aggregate;
  /** fetch data from the table: "property_feature" using primary key columns */
  property_feature_by_pk?: Maybe<Property_Feature>;
  /** fetch data from the table: "property_image" */
  property_image: Array<Property_Image>;
  /** fetch aggregated fields from the table: "property_image" */
  property_image_aggregate: Property_Image_Aggregate;
  /** fetch data from the table: "property_image" using primary key columns */
  property_image_by_pk?: Maybe<Property_Image>;
  /** fetch data from the table: "property_list" */
  property_list: Array<Property_List>;
  /** fetch aggregated fields from the table: "property_list" */
  property_list_aggregate: Property_List_Aggregate;
  /** fetch data from the table: "property_list" using primary key columns */
  property_list_by_pk?: Maybe<Property_List>;
  /** fetch data from the table: "property_origin" */
  property_origin: Array<Property_Origin>;
  /** fetch aggregated fields from the table: "property_origin" */
  property_origin_aggregate: Property_Origin_Aggregate;
  /** fetch data from the table: "property_origin" using primary key columns */
  property_origin_by_pk?: Maybe<Property_Origin>;
  /** fetch data from the table: "property_origin_type" */
  property_origin_type: Array<Property_Origin_Type>;
  /** fetch aggregated fields from the table: "property_origin_type" */
  property_origin_type_aggregate: Property_Origin_Type_Aggregate;
  /** fetch data from the table: "property_origin_type" using primary key columns */
  property_origin_type_by_pk?: Maybe<Property_Origin_Type>;
  /** fetch data from the table: "property_price_history" */
  property_price_history: Array<Property_Price_History>;
  /** fetch aggregated fields from the table: "property_price_history" */
  property_price_history_aggregate: Property_Price_History_Aggregate;
  /** fetch data from the table: "property_price_history" using primary key columns */
  property_price_history_by_pk?: Maybe<Property_Price_History>;
  /** fetch data from the table: "property_source" */
  property_source: Array<Property_Source>;
  /** fetch aggregated fields from the table: "property_source" */
  property_source_aggregate: Property_Source_Aggregate;
  /** fetch data from the table: "property_source" using primary key columns */
  property_source_by_pk?: Maybe<Property_Source>;
  /** fetch data from the table: "property_status" */
  property_status: Array<Property_Status>;
  /** fetch aggregated fields from the table: "property_status" */
  property_status_aggregate: Property_Status_Aggregate;
  /** fetch data from the table: "property_status" using primary key columns */
  property_status_by_pk?: Maybe<Property_Status>;
  /** fetch data from the table: "property_type" */
  property_type: Array<Property_Type>;
  /** fetch aggregated fields from the table: "property_type" */
  property_type_aggregate: Property_Type_Aggregate;
  /** fetch data from the table: "property_type" using primary key columns */
  property_type_by_pk?: Maybe<Property_Type>;
  /** An array relationship */
  property_views: Array<Property_Views>;
  /** An aggregate relationship */
  property_views_aggregate: Property_Views_Aggregate;
  /** fetch data from the table: "property_views" using primary key columns */
  property_views_by_pk?: Maybe<Property_Views>;
  /** fetch data from the table: "queries_property_result" */
  queries_property_result: Array<Queries_Property_Result>;
  /** fetch aggregated fields from the table: "queries_property_result" */
  queries_property_result_aggregate: Queries_Property_Result_Aggregate;
  /** fetch data from the table: "queries_property_result" using primary key columns */
  queries_property_result_by_pk?: Maybe<Queries_Property_Result>;
  /** fetch data from the table: "query_classification" */
  query_classification: Array<Query_Classification>;
  /** fetch aggregated fields from the table: "query_classification" */
  query_classification_aggregate: Query_Classification_Aggregate;
  /** fetch data from the table: "query_classification" using primary key columns */
  query_classification_by_pk?: Maybe<Query_Classification>;
  /** fetch data from the table: "raw_location" */
  raw_location: Array<Raw_Location>;
  /** fetch aggregated fields from the table: "raw_location" */
  raw_location_aggregate: Raw_Location_Aggregate;
  /** fetch data from the table: "raw_location" using primary key columns */
  raw_location_by_pk?: Maybe<Raw_Location>;
  /** fetch data from the table: "redirect" */
  redirect: Array<Redirect>;
  /** fetch aggregated fields from the table: "redirect" */
  redirect_aggregate: Redirect_Aggregate;
  /** fetch data from the table: "redirect" using primary key columns */
  redirect_by_pk?: Maybe<Redirect>;
  /** fetch data from the table: "search_context" */
  search_context: Array<Search_Context>;
  /** fetch aggregated fields from the table: "search_context" */
  search_context_aggregate: Search_Context_Aggregate;
  /** fetch data from the table: "search_context" using primary key columns */
  search_context_by_pk?: Maybe<Search_Context>;
  /** fetch data from the table: "search_domain" */
  search_domain: Array<Search_Domain>;
  /** fetch aggregated fields from the table: "search_domain" */
  search_domain_aggregate: Search_Domain_Aggregate;
  /** fetch data from the table: "search_domain" using primary key columns */
  search_domain_by_pk?: Maybe<Search_Domain>;
  /** An array relationship */
  search_sessions: Array<Search_Sessions>;
  /** An aggregate relationship */
  search_sessions_aggregate: Search_Sessions_Aggregate;
  /** fetch data from the table: "search_sessions" using primary key columns */
  search_sessions_by_pk?: Maybe<Search_Sessions>;
  /** fetch data from the table: "text_content" */
  text_content: Array<Text_Content>;
  /** fetch aggregated fields from the table: "text_content" */
  text_content_aggregate: Text_Content_Aggregate;
  /** fetch data from the table: "text_content" using primary key columns */
  text_content_by_pk?: Maybe<Text_Content>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  /** fetch data from the table: "translations" using primary key columns */
  translations_by_pk?: Maybe<Translations>;
  /** fetch data from the table: "url_slug" */
  url_slug: Array<Url_Slug>;
  /** fetch aggregated fields from the table: "url_slug" */
  url_slug_aggregate: Url_Slug_Aggregate;
  /** fetch data from the table: "url_slug" using primary key columns */
  url_slug_by_pk?: Maybe<Url_Slug>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_card_information" */
  user_card_information: Array<User_Card_Information>;
  /** fetch aggregated fields from the table: "user_card_information" */
  user_card_information_aggregate: User_Card_Information_Aggregate;
  /** fetch data from the table: "user_card_information" using primary key columns */
  user_card_information_by_pk?: Maybe<User_Card_Information>;
  /** An array relationship */
  user_filters: Array<User_Filters>;
  /** An aggregate relationship */
  user_filters_aggregate: User_Filters_Aggregate;
  /** fetch data from the table: "user_filters" using primary key columns */
  user_filters_by_pk?: Maybe<User_Filters>;
  /** fetch data from the table: "user_filters_features" */
  user_filters_features: Array<User_Filters_Features>;
  /** fetch aggregated fields from the table: "user_filters_features" */
  user_filters_features_aggregate: User_Filters_Features_Aggregate;
  /** fetch data from the table: "user_filters_features" using primary key columns */
  user_filters_features_by_pk?: Maybe<User_Filters_Features>;
  /** fetch data from the table: "user_membership" */
  user_membership: Array<User_Membership>;
  /** fetch aggregated fields from the table: "user_membership" */
  user_membership_aggregate: User_Membership_Aggregate;
  /** fetch data from the table: "user_membership" using primary key columns */
  user_membership_by_pk?: Maybe<User_Membership>;
  /** An array relationship */
  user_preferences: Array<User_Preferences>;
  /** An aggregate relationship */
  user_preferences_aggregate: User_Preferences_Aggregate;
  /** fetch data from the table: "user_preferences" using primary key columns */
  user_preferences_by_pk?: Maybe<User_Preferences>;
  /** fetch data from the table: "user_property_notifiation" */
  user_property_notifiation: Array<User_Property_Notifiation>;
  /** fetch aggregated fields from the table: "user_property_notifiation" */
  user_property_notifiation_aggregate: User_Property_Notifiation_Aggregate;
  /** fetch data from the table: "user_property_notifiation" using primary key columns */
  user_property_notifiation_by_pk?: Maybe<User_Property_Notifiation>;
  /** An array relationship */
  user_saved_properties: Array<User_Saved_Properties>;
  /** An aggregate relationship */
  user_saved_properties_aggregate: User_Saved_Properties_Aggregate;
  /** fetch data from the table: "user_saved_properties" using primary key columns */
  user_saved_properties_by_pk?: Maybe<User_Saved_Properties>;
  /** fetch data from the table: "user_seen_property" */
  user_seen_property: Array<User_Seen_Property>;
  /** fetch aggregated fields from the table: "user_seen_property" */
  user_seen_property_aggregate: User_Seen_Property_Aggregate;
  /** fetch data from the table: "user_seen_property" using primary key columns */
  user_seen_property_by_pk?: Maybe<User_Seen_Property>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table: "storage.virus" using primary key columns */
  virus?: Maybe<Virus>;
  /** fetch data from the table: "storage.virus" */
  viruses: Array<Virus>;
  /** fetch aggregated fields from the table: "storage.virus" */
  virusesAggregate: Virus_Aggregate;
  /** fetch data from the table: "zone_data_method" */
  zone_data_method: Array<Zone_Data_Method>;
  /** fetch aggregated fields from the table: "zone_data_method" */
  zone_data_method_aggregate: Zone_Data_Method_Aggregate;
  /** fetch data from the table: "zone_data_method" using primary key columns */
  zone_data_method_by_pk?: Maybe<Zone_Data_Method>;
};


export type Query_RootAddress_TypesArgs = {
  distinct_on?: InputMaybe<Array<Address_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Types_Order_By>>;
  where?: InputMaybe<Address_Types_Bool_Exp>;
};


export type Query_RootAddress_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Types_Order_By>>;
  where?: InputMaybe<Address_Types_Bool_Exp>;
};


export type Query_RootAddress_Types_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootAgencyArgs = {
  distinct_on?: InputMaybe<Array<Agency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Agency_Order_By>>;
  where?: InputMaybe<Agency_Bool_Exp>;
};


export type Query_RootAgency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Agency_Order_By>>;
  where?: InputMaybe<Agency_Bool_Exp>;
};


export type Query_RootAgency_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Amenities_Order_By>>;
  where?: InputMaybe<Amenities_Bool_Exp>;
};


export type Query_RootAmenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Amenities_Order_By>>;
  where?: InputMaybe<Amenities_Bool_Exp>;
};


export type Query_RootAmenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthProviderArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Query_RootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Query_RootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Query_RootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Query_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Query_RootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Query_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Query_RootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Query_RootAuthRoleArgs = {
  role: Scalars['String']['input'];
};


export type Query_RootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Query_RootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Query_RootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Query_RootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Query_RootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Query_RootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Query_RootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Query_RootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Query_RootBucketArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Query_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Query_RootChat_Response_TypeArgs = {
  distinct_on?: InputMaybe<Array<Chat_Response_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Response_Type_Order_By>>;
  where?: InputMaybe<Chat_Response_Type_Bool_Exp>;
};


export type Query_RootChat_Response_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Response_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Response_Type_Order_By>>;
  where?: InputMaybe<Chat_Response_Type_Bool_Exp>;
};


export type Query_RootChat_Response_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootCityArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};


export type Query_RootCity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};


export type Query_RootCity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCity_ZoneArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Order_By>>;
  where?: InputMaybe<City_Zone_Bool_Exp>;
};


export type Query_RootCity_Zone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Order_By>>;
  where?: InputMaybe<City_Zone_Bool_Exp>;
};


export type Query_RootCity_Zone_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCity_Zone_OriginArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Origin_Order_By>>;
  where?: InputMaybe<City_Zone_Origin_Bool_Exp>;
};


export type Query_RootCity_Zone_Origin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Origin_Order_By>>;
  where?: InputMaybe<City_Zone_Origin_Bool_Exp>;
};


export type Query_RootCity_Zone_Origin_By_PkArgs = {
  origin: Scalars['String']['input'];
};


export type Query_RootConstruction_StatusArgs = {
  distinct_on?: InputMaybe<Array<Construction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Construction_Status_Order_By>>;
  where?: InputMaybe<Construction_Status_Bool_Exp>;
};


export type Query_RootConstruction_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Construction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Construction_Status_Order_By>>;
  where?: InputMaybe<Construction_Status_Bool_Exp>;
};


export type Query_RootConstruction_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Query_RootContactArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Query_RootContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Query_RootContact_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContact_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Phone_Number_Order_By>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


export type Query_RootContact_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Phone_Number_Order_By>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


export type Query_RootContact_Phone_Number_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContact_TypeArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Query_RootContact_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Query_RootContact_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootContent_TypeArgs = {
  distinct_on?: InputMaybe<Array<Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Type_Order_By>>;
  where?: InputMaybe<Content_Type_Bool_Exp>;
};


export type Query_RootContent_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Type_Order_By>>;
  where?: InputMaybe<Content_Type_Bool_Exp>;
};


export type Query_RootContent_Type_By_PkArgs = {
  content_type: Scalars['String']['input'];
};


export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Query_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Query_RootCurrency_By_PkArgs = {
  currency: Scalars['String']['input'];
};


export type Query_RootCurrency_Exchange_RatesArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rates_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
};


export type Query_RootCurrency_Exchange_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rates_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
};


export type Query_RootCurrency_Exchange_Rates_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootEntitiesArgs = {
  distinct_on?: InputMaybe<Array<Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entities_Order_By>>;
  where?: InputMaybe<Entities_Bool_Exp>;
};


export type Query_RootEntities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entities_Order_By>>;
  where?: InputMaybe<Entities_Bool_Exp>;
};


export type Query_RootEntities_By_PkArgs = {
  entity_type: Scalars['String']['input'];
};


export type Query_RootFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};


export type Query_RootFeature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};


export type Query_RootFeature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFeature_TypeArgs = {
  distinct_on?: InputMaybe<Array<Feature_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Type_Order_By>>;
  where?: InputMaybe<Feature_Type_Bool_Exp>;
};


export type Query_RootFeature_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Type_Order_By>>;
  where?: InputMaybe<Feature_Type_Bool_Exp>;
};


export type Query_RootFeature_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFurnishing_StatusArgs = {
  distinct_on?: InputMaybe<Array<Furnishing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Furnishing_Status_Order_By>>;
  where?: InputMaybe<Furnishing_Status_Bool_Exp>;
};


export type Query_RootFurnishing_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Furnishing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Furnishing_Status_Order_By>>;
  where?: InputMaybe<Furnishing_Status_Bool_Exp>;
};


export type Query_RootFurnishing_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Query_RootHeatingTypeArgs = {
  distinct_on?: InputMaybe<Array<HeatingType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatingType_Order_By>>;
  where?: InputMaybe<HeatingType_Bool_Exp>;
};


export type Query_RootHeatingType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatingType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatingType_Order_By>>;
  where?: InputMaybe<HeatingType_Bool_Exp>;
};


export type Query_RootHeatingType_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_By_PkArgs = {
  language_id: Scalars['Int']['input'];
};


export type Query_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Query_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Query_RootLocation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMembership_PackageArgs = {
  distinct_on?: InputMaybe<Array<Membership_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Membership_Package_Order_By>>;
  where?: InputMaybe<Membership_Package_Bool_Exp>;
};


export type Query_RootMembership_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Membership_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Membership_Package_Order_By>>;
  where?: InputMaybe<Membership_Package_Bool_Exp>;
};


export type Query_RootMembership_Package_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNatural_Language_QueriesArgs = {
  distinct_on?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Natural_Language_Queries_Order_By>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


export type Query_RootNatural_Language_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Natural_Language_Queries_Order_By>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


export type Query_RootNatural_Language_Queries_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_AmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Amenities_Order_By>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


export type Query_RootNotification_Amenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Amenities_Order_By>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


export type Query_RootNotification_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotification_FeatureArgs = {
  distinct_on?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Feature_Order_By>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


export type Query_RootNotification_Feature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Feature_Order_By>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


export type Query_RootNotification_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotification_Property_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Property_Type_Order_By>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


export type Query_RootNotification_Property_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Property_Type_Order_By>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


export type Query_RootNotification_Property_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNotification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Query_RootNotification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Query_RootNotification_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootParking_TypeArgs = {
  distinct_on?: InputMaybe<Array<Parking_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parking_Type_Order_By>>;
  where?: InputMaybe<Parking_Type_Bool_Exp>;
};


export type Query_RootParking_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parking_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parking_Type_Order_By>>;
  where?: InputMaybe<Parking_Type_Bool_Exp>;
};


export type Query_RootParking_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootPropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Query_RootProperty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Query_RootProperty_AmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


export type Query_RootProperty_Amenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


export type Query_RootProperty_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Property_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Cluster_Order_By>>;
  where?: InputMaybe<Property_Cluster_Bool_Exp>;
};


export type Query_RootProperty_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Cluster_Order_By>>;
  where?: InputMaybe<Property_Cluster_Bool_Exp>;
};


export type Query_RootProperty_Cluster_By_PkArgs = {
  cluster: Scalars['String']['input'];
};


export type Query_RootProperty_CompositionArgs = {
  distinct_on?: InputMaybe<Array<Property_Composition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Composition_Order_By>>;
  where?: InputMaybe<Property_Composition_Bool_Exp>;
};


export type Query_RootProperty_Composition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Composition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Composition_Order_By>>;
  where?: InputMaybe<Property_Composition_Bool_Exp>;
};


export type Query_RootProperty_Composition_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_FeatureArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


export type Query_RootProperty_Feature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


export type Query_RootProperty_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_ImageArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


export type Query_RootProperty_Image_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


export type Query_RootProperty_Image_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_ListArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


export type Query_RootProperty_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


export type Query_RootProperty_List_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_OriginArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Order_By>>;
  where?: InputMaybe<Property_Origin_Bool_Exp>;
};


export type Query_RootProperty_Origin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Order_By>>;
  where?: InputMaybe<Property_Origin_Bool_Exp>;
};


export type Query_RootProperty_Origin_By_PkArgs = {
  origin: Scalars['String']['input'];
};


export type Query_RootProperty_Origin_TypeArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Type_Order_By>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp>;
};


export type Query_RootProperty_Origin_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Type_Order_By>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp>;
};


export type Query_RootProperty_Origin_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootProperty_Price_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


export type Query_RootProperty_Price_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


export type Query_RootProperty_Price_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_SourceArgs = {
  distinct_on?: InputMaybe<Array<Property_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Source_Order_By>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};


export type Query_RootProperty_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Source_Order_By>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};


export type Query_RootProperty_Source_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProperty_StatusArgs = {
  distinct_on?: InputMaybe<Array<Property_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Status_Order_By>>;
  where?: InputMaybe<Property_Status_Bool_Exp>;
};


export type Query_RootProperty_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Status_Order_By>>;
  where?: InputMaybe<Property_Status_Bool_Exp>;
};


export type Query_RootProperty_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Query_RootProperty_TypeArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};


export type Query_RootProperty_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};


export type Query_RootProperty_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Query_RootProperty_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Property_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Views_Order_By>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


export type Query_RootProperty_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Views_Order_By>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


export type Query_RootProperty_Views_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQueries_Property_ResultArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


export type Query_RootQueries_Property_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


export type Query_RootQueries_Property_Result_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQuery_ClassificationArgs = {
  distinct_on?: InputMaybe<Array<Query_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Query_Classification_Order_By>>;
  where?: InputMaybe<Query_Classification_Bool_Exp>;
};


export type Query_RootQuery_Classification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Query_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Query_Classification_Order_By>>;
  where?: InputMaybe<Query_Classification_Bool_Exp>;
};


export type Query_RootQuery_Classification_By_PkArgs = {
  class: Scalars['String']['input'];
};


export type Query_RootRaw_LocationArgs = {
  distinct_on?: InputMaybe<Array<Raw_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Raw_Location_Order_By>>;
  where?: InputMaybe<Raw_Location_Bool_Exp>;
};


export type Query_RootRaw_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Raw_Location_Order_By>>;
  where?: InputMaybe<Raw_Location_Bool_Exp>;
};


export type Query_RootRaw_Location_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRedirectArgs = {
  distinct_on?: InputMaybe<Array<Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Redirect_Order_By>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


export type Query_RootRedirect_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Redirect_Order_By>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


export type Query_RootRedirect_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSearch_ContextArgs = {
  distinct_on?: InputMaybe<Array<Search_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Context_Order_By>>;
  where?: InputMaybe<Search_Context_Bool_Exp>;
};


export type Query_RootSearch_Context_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Context_Order_By>>;
  where?: InputMaybe<Search_Context_Bool_Exp>;
};


export type Query_RootSearch_Context_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSearch_DomainArgs = {
  distinct_on?: InputMaybe<Array<Search_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Domain_Order_By>>;
  where?: InputMaybe<Search_Domain_Bool_Exp>;
};


export type Query_RootSearch_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Domain_Order_By>>;
  where?: InputMaybe<Search_Domain_Bool_Exp>;
};


export type Query_RootSearch_Domain_By_PkArgs = {
  domain: Scalars['String']['input'];
};


export type Query_RootSearch_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Sessions_Order_By>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


export type Query_RootSearch_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Sessions_Order_By>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


export type Query_RootSearch_Sessions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootText_ContentArgs = {
  distinct_on?: InputMaybe<Array<Text_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Content_Order_By>>;
  where?: InputMaybe<Text_Content_Bool_Exp>;
};


export type Query_RootText_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Content_Order_By>>;
  where?: InputMaybe<Text_Content_Bool_Exp>;
};


export type Query_RootText_Content_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Query_RootTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Query_RootTranslations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUrl_SlugArgs = {
  distinct_on?: InputMaybe<Array<Url_Slug_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Slug_Order_By>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


export type Query_RootUrl_Slug_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Slug_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Slug_Order_By>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


export type Query_RootUrl_Slug_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_Card_InformationArgs = {
  distinct_on?: InputMaybe<Array<User_Card_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Card_Information_Order_By>>;
  where?: InputMaybe<User_Card_Information_Bool_Exp>;
};


export type Query_RootUser_Card_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Card_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Card_Information_Order_By>>;
  where?: InputMaybe<User_Card_Information_Bool_Exp>;
};


export type Query_RootUser_Card_Information_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_FiltersArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Order_By>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};


export type Query_RootUser_Filters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Order_By>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};


export type Query_RootUser_Filters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_Filters_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Features_Order_By>>;
  where?: InputMaybe<User_Filters_Features_Bool_Exp>;
};


export type Query_RootUser_Filters_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Features_Order_By>>;
  where?: InputMaybe<User_Filters_Features_Bool_Exp>;
};


export type Query_RootUser_Filters_Features_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_MembershipArgs = {
  distinct_on?: InputMaybe<Array<User_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Membership_Order_By>>;
  where?: InputMaybe<User_Membership_Bool_Exp>;
};


export type Query_RootUser_Membership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Membership_Order_By>>;
  where?: InputMaybe<User_Membership_Bool_Exp>;
};


export type Query_RootUser_Membership_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Query_RootUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Query_RootUser_Preferences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_Property_NotifiationArgs = {
  distinct_on?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Property_Notifiation_Order_By>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


export type Query_RootUser_Property_Notifiation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Property_Notifiation_Order_By>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


export type Query_RootUser_Property_Notifiation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_Saved_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Saved_Properties_Order_By>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


export type Query_RootUser_Saved_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Saved_Properties_Order_By>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


export type Query_RootUser_Saved_Properties_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_Seen_PropertyArgs = {
  distinct_on?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Seen_Property_Order_By>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};


export type Query_RootUser_Seen_Property_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Seen_Property_Order_By>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};


export type Query_RootUser_Seen_Property_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootVirusArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVirusesArgs = {
  distinct_on?: InputMaybe<Array<Virus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Virus_Order_By>>;
  where?: InputMaybe<Virus_Bool_Exp>;
};


export type Query_RootVirusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Virus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Virus_Order_By>>;
  where?: InputMaybe<Virus_Bool_Exp>;
};


export type Query_RootZone_Data_MethodArgs = {
  distinct_on?: InputMaybe<Array<Zone_Data_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Zone_Data_Method_Order_By>>;
  where?: InputMaybe<Zone_Data_Method_Bool_Exp>;
};


export type Query_RootZone_Data_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zone_Data_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Zone_Data_Method_Order_By>>;
  where?: InputMaybe<Zone_Data_Method_Bool_Exp>;
};


export type Query_RootZone_Data_Method_By_PkArgs = {
  method: Scalars['String']['input'];
};

/** location as it is published,raw */
export type Raw_Location = {
  __typename?: 'raw_location';
  created_at: Scalars['timestamptz']['output'];
  extracted_locations?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  raw_property_id?: Maybe<Scalars['String']['output']>;
  task_id?: Maybe<Scalars['String']['output']>;
};


/** location as it is published,raw */
export type Raw_LocationExtracted_LocationsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "raw_location" */
export type Raw_Location_Aggregate = {
  __typename?: 'raw_location_aggregate';
  aggregate?: Maybe<Raw_Location_Aggregate_Fields>;
  nodes: Array<Raw_Location>;
};

/** aggregate fields of "raw_location" */
export type Raw_Location_Aggregate_Fields = {
  __typename?: 'raw_location_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Raw_Location_Max_Fields>;
  min?: Maybe<Raw_Location_Min_Fields>;
};


/** aggregate fields of "raw_location" */
export type Raw_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Raw_Location_Append_Input = {
  extracted_locations?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "raw_location". All fields are combined with a logical 'AND'. */
export type Raw_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Location_Bool_Exp>>;
  _not?: InputMaybe<Raw_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Location_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  extracted_locations?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  raw_property_id?: InputMaybe<String_Comparison_Exp>;
  task_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_location" */
export enum Raw_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  RawLocationPkey = 'raw_location_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Raw_Location_Delete_At_Path_Input = {
  extracted_locations?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Raw_Location_Delete_Elem_Input = {
  extracted_locations?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Raw_Location_Delete_Key_Input = {
  extracted_locations?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "raw_location" */
export type Raw_Location_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  extracted_locations?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  raw_property_id?: InputMaybe<Scalars['String']['input']>;
  task_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Raw_Location_Max_Fields = {
  __typename?: 'raw_location_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  raw_property_id?: Maybe<Scalars['String']['output']>;
  task_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Raw_Location_Min_Fields = {
  __typename?: 'raw_location_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  raw_property_id?: Maybe<Scalars['String']['output']>;
  task_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "raw_location" */
export type Raw_Location_Mutation_Response = {
  __typename?: 'raw_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Location>;
};

/** on_conflict condition type for table "raw_location" */
export type Raw_Location_On_Conflict = {
  constraint: Raw_Location_Constraint;
  update_columns?: Array<Raw_Location_Update_Column>;
  where?: InputMaybe<Raw_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_location". */
export type Raw_Location_Order_By = {
  created_at?: InputMaybe<Order_By>;
  extracted_locations?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  raw_property_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_location */
export type Raw_Location_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Raw_Location_Prepend_Input = {
  extracted_locations?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "raw_location" */
export enum Raw_Location_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtractedLocations = 'extracted_locations',
  /** column name */
  Id = 'id',
  /** column name */
  RawPropertyId = 'raw_property_id',
  /** column name */
  TaskId = 'task_id'
}

/** input type for updating data in table "raw_location" */
export type Raw_Location_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  extracted_locations?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  raw_property_id?: InputMaybe<Scalars['String']['input']>;
  task_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "raw_location" */
export type Raw_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Location_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  extracted_locations?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  raw_property_id?: InputMaybe<Scalars['String']['input']>;
  task_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "raw_location" */
export enum Raw_Location_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtractedLocations = 'extracted_locations',
  /** column name */
  Id = 'id',
  /** column name */
  RawPropertyId = 'raw_property_id',
  /** column name */
  TaskId = 'task_id'
}

export type Raw_Location_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Raw_Location_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Raw_Location_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Raw_Location_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Raw_Location_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Raw_Location_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Location_Bool_Exp;
};

/** columns and relationships of "redirect" */
export type Redirect = {
  __typename?: 'redirect';
  from_url: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  is_active: Scalars['Boolean']['output'];
  redirect_type?: Maybe<Scalars['Int']['output']>;
  to_url: Scalars['String']['output'];
};

/** aggregated selection of "redirect" */
export type Redirect_Aggregate = {
  __typename?: 'redirect_aggregate';
  aggregate?: Maybe<Redirect_Aggregate_Fields>;
  nodes: Array<Redirect>;
};

/** aggregate fields of "redirect" */
export type Redirect_Aggregate_Fields = {
  __typename?: 'redirect_aggregate_fields';
  avg?: Maybe<Redirect_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Redirect_Max_Fields>;
  min?: Maybe<Redirect_Min_Fields>;
  stddev?: Maybe<Redirect_Stddev_Fields>;
  stddev_pop?: Maybe<Redirect_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Redirect_Stddev_Samp_Fields>;
  sum?: Maybe<Redirect_Sum_Fields>;
  var_pop?: Maybe<Redirect_Var_Pop_Fields>;
  var_samp?: Maybe<Redirect_Var_Samp_Fields>;
  variance?: Maybe<Redirect_Variance_Fields>;
};


/** aggregate fields of "redirect" */
export type Redirect_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Redirect_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Redirect_Avg_Fields = {
  __typename?: 'redirect_avg_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "redirect". All fields are combined with a logical 'AND'. */
export type Redirect_Bool_Exp = {
  _and?: InputMaybe<Array<Redirect_Bool_Exp>>;
  _not?: InputMaybe<Redirect_Bool_Exp>;
  _or?: InputMaybe<Array<Redirect_Bool_Exp>>;
  from_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  redirect_type?: InputMaybe<Int_Comparison_Exp>;
  to_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "redirect" */
export enum Redirect_Constraint {
  /** unique or primary key constraint on columns "id" */
  RedirectPkey = 'redirect_pkey'
}

/** input type for incrementing numeric columns in table "redirect" */
export type Redirect_Inc_Input = {
  redirect_type?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "redirect" */
export type Redirect_Insert_Input = {
  from_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  redirect_type?: InputMaybe<Scalars['Int']['input']>;
  to_url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Redirect_Max_Fields = {
  __typename?: 'redirect_max_fields';
  from_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  redirect_type?: Maybe<Scalars['Int']['output']>;
  to_url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Redirect_Min_Fields = {
  __typename?: 'redirect_min_fields';
  from_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  redirect_type?: Maybe<Scalars['Int']['output']>;
  to_url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "redirect" */
export type Redirect_Mutation_Response = {
  __typename?: 'redirect_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Redirect>;
};

/** on_conflict condition type for table "redirect" */
export type Redirect_On_Conflict = {
  constraint: Redirect_Constraint;
  update_columns?: Array<Redirect_Update_Column>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};

/** Ordering options when selecting data from "redirect". */
export type Redirect_Order_By = {
  from_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  redirect_type?: InputMaybe<Order_By>;
  to_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: redirect */
export type Redirect_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "redirect" */
export enum Redirect_Select_Column {
  /** column name */
  FromUrl = 'from_url',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  RedirectType = 'redirect_type',
  /** column name */
  ToUrl = 'to_url'
}

/** input type for updating data in table "redirect" */
export type Redirect_Set_Input = {
  from_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  redirect_type?: InputMaybe<Scalars['Int']['input']>;
  to_url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Redirect_Stddev_Fields = {
  __typename?: 'redirect_stddev_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Redirect_Stddev_Pop_Fields = {
  __typename?: 'redirect_stddev_pop_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Redirect_Stddev_Samp_Fields = {
  __typename?: 'redirect_stddev_samp_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "redirect" */
export type Redirect_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Redirect_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Redirect_Stream_Cursor_Value_Input = {
  from_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  redirect_type?: InputMaybe<Scalars['Int']['input']>;
  to_url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Redirect_Sum_Fields = {
  __typename?: 'redirect_sum_fields';
  redirect_type?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "redirect" */
export enum Redirect_Update_Column {
  /** column name */
  FromUrl = 'from_url',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  RedirectType = 'redirect_type',
  /** column name */
  ToUrl = 'to_url'
}

export type Redirect_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Redirect_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Redirect_Set_Input>;
  /** filter the rows which have to be updated */
  where: Redirect_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Redirect_Var_Pop_Fields = {
  __typename?: 'redirect_var_pop_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Redirect_Var_Samp_Fields = {
  __typename?: 'redirect_var_samp_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Redirect_Variance_Fields = {
  __typename?: 'redirect_variance_fields';
  redirect_type?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "search_context" */
export type Search_Context = {
  __typename?: 'search_context';
  area_range: Scalars['jsonb']['output'];
  cluster: Array<Property_Cluster>;
  created_at: Scalars['timestamptz']['output'];
  extracted_filters: Scalars['jsonb']['output'];
  geo_location_draw: Scalars['jsonb']['output'];
  id: Scalars['uuid']['output'];
  location_zone_id: Scalars['uuid']['output'];
  price_range: Scalars['jsonb']['output'];
  property_cluster?: Maybe<Scalars['String']['output']>;
  property_status: Scalars['String']['output'];
  property_type?: Maybe<Scalars['String']['output']>;
  query_id: Scalars['uuid']['output'];
  type: Array<Property_Type>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "search_context" */
export type Search_ContextArea_RangeArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "search_context" */
export type Search_ContextClusterArgs = {
  distinct_on?: InputMaybe<Array<Property_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Cluster_Order_By>>;
  where?: InputMaybe<Property_Cluster_Bool_Exp_Remote_Rel_Search_Contextcluster>;
};


/** columns and relationships of "search_context" */
export type Search_ContextExtracted_FiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "search_context" */
export type Search_ContextGeo_Location_DrawArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "search_context" */
export type Search_ContextPrice_RangeArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "search_context" */
export type Search_ContextTypeArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp_Remote_Rel_Search_Contexttype>;
};

/** aggregated selection of "search_context" */
export type Search_Context_Aggregate = {
  __typename?: 'search_context_aggregate';
  aggregate?: Maybe<Search_Context_Aggregate_Fields>;
  nodes: Array<Search_Context>;
};

/** aggregate fields of "search_context" */
export type Search_Context_Aggregate_Fields = {
  __typename?: 'search_context_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Search_Context_Max_Fields>;
  min?: Maybe<Search_Context_Min_Fields>;
};


/** aggregate fields of "search_context" */
export type Search_Context_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Context_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Search_Context_Append_Input = {
  area_range?: InputMaybe<Scalars['jsonb']['input']>;
  extracted_filters?: InputMaybe<Scalars['jsonb']['input']>;
  geo_location_draw?: InputMaybe<Scalars['jsonb']['input']>;
  price_range?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "search_context". All fields are combined with a logical 'AND'. */
export type Search_Context_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Context_Bool_Exp>>;
  _not?: InputMaybe<Search_Context_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Context_Bool_Exp>>;
  area_range?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  extracted_filters?: InputMaybe<Jsonb_Comparison_Exp>;
  geo_location_draw?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location_zone_id?: InputMaybe<Uuid_Comparison_Exp>;
  price_range?: InputMaybe<Jsonb_Comparison_Exp>;
  property_cluster?: InputMaybe<String_Comparison_Exp>;
  property_status?: InputMaybe<String_Comparison_Exp>;
  property_type?: InputMaybe<String_Comparison_Exp>;
  query_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "search_context" */
export enum Search_Context_Constraint {
  /** unique or primary key constraint on columns "id" */
  SearchContextPkey = 'search_context_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Search_Context_Delete_At_Path_Input = {
  area_range?: InputMaybe<Array<Scalars['String']['input']>>;
  extracted_filters?: InputMaybe<Array<Scalars['String']['input']>>;
  geo_location_draw?: InputMaybe<Array<Scalars['String']['input']>>;
  price_range?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Search_Context_Delete_Elem_Input = {
  area_range?: InputMaybe<Scalars['Int']['input']>;
  extracted_filters?: InputMaybe<Scalars['Int']['input']>;
  geo_location_draw?: InputMaybe<Scalars['Int']['input']>;
  price_range?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Search_Context_Delete_Key_Input = {
  area_range?: InputMaybe<Scalars['String']['input']>;
  extracted_filters?: InputMaybe<Scalars['String']['input']>;
  geo_location_draw?: InputMaybe<Scalars['String']['input']>;
  price_range?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "search_context" */
export type Search_Context_Insert_Input = {
  area_range?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  extracted_filters?: InputMaybe<Scalars['jsonb']['input']>;
  geo_location_draw?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  price_range?: InputMaybe<Scalars['jsonb']['input']>;
  property_cluster?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
  query_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Search_Context_Max_Fields = {
  __typename?: 'search_context_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_zone_id?: Maybe<Scalars['uuid']['output']>;
  property_cluster?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
  query_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Search_Context_Min_Fields = {
  __typename?: 'search_context_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  location_zone_id?: Maybe<Scalars['uuid']['output']>;
  property_cluster?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
  query_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "search_context" */
export type Search_Context_Mutation_Response = {
  __typename?: 'search_context_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Search_Context>;
};

/** input type for inserting object relation for remote table "search_context" */
export type Search_Context_Obj_Rel_Insert_Input = {
  data: Search_Context_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Search_Context_On_Conflict>;
};

/** on_conflict condition type for table "search_context" */
export type Search_Context_On_Conflict = {
  constraint: Search_Context_Constraint;
  update_columns?: Array<Search_Context_Update_Column>;
  where?: InputMaybe<Search_Context_Bool_Exp>;
};

/** Ordering options when selecting data from "search_context". */
export type Search_Context_Order_By = {
  area_range?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  extracted_filters?: InputMaybe<Order_By>;
  geo_location_draw?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_zone_id?: InputMaybe<Order_By>;
  price_range?: InputMaybe<Order_By>;
  property_cluster?: InputMaybe<Order_By>;
  property_status?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  query_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: search_context */
export type Search_Context_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Search_Context_Prepend_Input = {
  area_range?: InputMaybe<Scalars['jsonb']['input']>;
  extracted_filters?: InputMaybe<Scalars['jsonb']['input']>;
  geo_location_draw?: InputMaybe<Scalars['jsonb']['input']>;
  price_range?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "search_context" */
export enum Search_Context_Select_Column {
  /** column name */
  AreaRange = 'area_range',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtractedFilters = 'extracted_filters',
  /** column name */
  GeoLocationDraw = 'geo_location_draw',
  /** column name */
  Id = 'id',
  /** column name */
  LocationZoneId = 'location_zone_id',
  /** column name */
  PriceRange = 'price_range',
  /** column name */
  PropertyCluster = 'property_cluster',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  QueryId = 'query_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "search_context" */
export type Search_Context_Set_Input = {
  area_range?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  extracted_filters?: InputMaybe<Scalars['jsonb']['input']>;
  geo_location_draw?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  price_range?: InputMaybe<Scalars['jsonb']['input']>;
  property_cluster?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
  query_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "search_context" */
export type Search_Context_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Context_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Context_Stream_Cursor_Value_Input = {
  area_range?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  extracted_filters?: InputMaybe<Scalars['jsonb']['input']>;
  geo_location_draw?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  price_range?: InputMaybe<Scalars['jsonb']['input']>;
  property_cluster?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
  query_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "search_context" */
export enum Search_Context_Update_Column {
  /** column name */
  AreaRange = 'area_range',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtractedFilters = 'extracted_filters',
  /** column name */
  GeoLocationDraw = 'geo_location_draw',
  /** column name */
  Id = 'id',
  /** column name */
  LocationZoneId = 'location_zone_id',
  /** column name */
  PriceRange = 'price_range',
  /** column name */
  PropertyCluster = 'property_cluster',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  QueryId = 'query_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Search_Context_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Search_Context_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Search_Context_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Search_Context_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Search_Context_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Search_Context_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Search_Context_Set_Input>;
  /** filter the rows which have to be updated */
  where: Search_Context_Bool_Exp;
};

/** Contains the type of tailored search offered by the platform */
export type Search_Domain = {
  __typename?: 'search_domain';
  domain: Scalars['String']['output'];
};

/** aggregated selection of "search_domain" */
export type Search_Domain_Aggregate = {
  __typename?: 'search_domain_aggregate';
  aggregate?: Maybe<Search_Domain_Aggregate_Fields>;
  nodes: Array<Search_Domain>;
};

/** aggregate fields of "search_domain" */
export type Search_Domain_Aggregate_Fields = {
  __typename?: 'search_domain_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Search_Domain_Max_Fields>;
  min?: Maybe<Search_Domain_Min_Fields>;
};


/** aggregate fields of "search_domain" */
export type Search_Domain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "search_domain". All fields are combined with a logical 'AND'. */
export type Search_Domain_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Domain_Bool_Exp>>;
  _not?: InputMaybe<Search_Domain_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Domain_Bool_Exp>>;
  domain?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "search_domain" */
export enum Search_Domain_Constraint {
  /** unique or primary key constraint on columns "domain" */
  SearchDomainPkey = 'search_domain_pkey'
}

export enum Search_Domain_Enum {
  RealEstate = 'REAL_ESTATE'
}

/** Boolean expression to compare columns of type "search_domain_enum". All fields are combined with logical 'AND'. */
export type Search_Domain_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Search_Domain_Enum>;
  _in?: InputMaybe<Array<Search_Domain_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Search_Domain_Enum>;
  _nin?: InputMaybe<Array<Search_Domain_Enum>>;
};

/** input type for inserting data into table "search_domain" */
export type Search_Domain_Insert_Input = {
  domain?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Search_Domain_Max_Fields = {
  __typename?: 'search_domain_max_fields';
  domain?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Search_Domain_Min_Fields = {
  __typename?: 'search_domain_min_fields';
  domain?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "search_domain" */
export type Search_Domain_Mutation_Response = {
  __typename?: 'search_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Search_Domain>;
};

/** input type for inserting object relation for remote table "search_domain" */
export type Search_Domain_Obj_Rel_Insert_Input = {
  data: Search_Domain_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Search_Domain_On_Conflict>;
};

/** on_conflict condition type for table "search_domain" */
export type Search_Domain_On_Conflict = {
  constraint: Search_Domain_Constraint;
  update_columns?: Array<Search_Domain_Update_Column>;
  where?: InputMaybe<Search_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "search_domain". */
export type Search_Domain_Order_By = {
  domain?: InputMaybe<Order_By>;
};

/** primary key columns input for table: search_domain */
export type Search_Domain_Pk_Columns_Input = {
  domain: Scalars['String']['input'];
};

/** select columns of table "search_domain" */
export enum Search_Domain_Select_Column {
  /** column name */
  Domain = 'domain'
}

/** input type for updating data in table "search_domain" */
export type Search_Domain_Set_Input = {
  domain?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "search_domain" */
export type Search_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Domain_Stream_Cursor_Value_Input = {
  domain?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "search_domain" */
export enum Search_Domain_Update_Column {
  /** column name */
  Domain = 'domain'
}

export type Search_Domain_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Search_Domain_Set_Input>;
  /** filter the rows which have to be updated */
  where: Search_Domain_Bool_Exp;
};

/** one for each conversation session of the user */
export type Search_Sessions = {
  __typename?: 'search_sessions';
  created_at: Scalars['timestamptz']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  domain: Search_Domain_Enum;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  natural_language_queries: Array<Natural_Language_Queries>;
  /** An aggregate relationship */
  natural_language_queries_aggregate: Natural_Language_Queries_Aggregate;
  real_time_alert: Scalars['Boolean']['output'];
  /** An object relationship */
  search_domain: Search_Domain;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An array relationship */
  user_filters: Array<User_Filters>;
  /** An aggregate relationship */
  user_filters_aggregate: User_Filters_Aggregate;
  user_id?: Maybe<Scalars['uuid']['output']>;
};


/** one for each conversation session of the user */
export type Search_SessionsNatural_Language_QueriesArgs = {
  distinct_on?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Natural_Language_Queries_Order_By>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


/** one for each conversation session of the user */
export type Search_SessionsNatural_Language_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Natural_Language_Queries_Order_By>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


/** one for each conversation session of the user */
export type Search_SessionsUser_FiltersArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Order_By>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};


/** one for each conversation session of the user */
export type Search_SessionsUser_Filters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Order_By>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};

/** aggregated selection of "search_sessions" */
export type Search_Sessions_Aggregate = {
  __typename?: 'search_sessions_aggregate';
  aggregate?: Maybe<Search_Sessions_Aggregate_Fields>;
  nodes: Array<Search_Sessions>;
};

export type Search_Sessions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Search_Sessions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Search_Sessions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Search_Sessions_Aggregate_Bool_Exp_Count>;
};

export type Search_Sessions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Search_Sessions_Select_Column_Search_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Search_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Search_Sessions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Search_Sessions_Select_Column_Search_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Search_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Search_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Search_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "search_sessions" */
export type Search_Sessions_Aggregate_Fields = {
  __typename?: 'search_sessions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Search_Sessions_Max_Fields>;
  min?: Maybe<Search_Sessions_Min_Fields>;
};


/** aggregate fields of "search_sessions" */
export type Search_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "search_sessions" */
export type Search_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Search_Sessions_Max_Order_By>;
  min?: InputMaybe<Search_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "search_sessions" */
export type Search_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Search_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Search_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "search_sessions". All fields are combined with a logical 'AND'. */
export type Search_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Search_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Sessions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  domain?: InputMaybe<Search_Domain_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  natural_language_queries?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
  natural_language_queries_aggregate?: InputMaybe<Natural_Language_Queries_Aggregate_Bool_Exp>;
  real_time_alert?: InputMaybe<Boolean_Comparison_Exp>;
  search_domain?: InputMaybe<Search_Domain_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_filters?: InputMaybe<User_Filters_Bool_Exp>;
  user_filters_aggregate?: InputMaybe<User_Filters_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "search_sessions" */
export enum Search_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SearchSessionsPkey = 'search_sessions_pkey'
}

/** input type for inserting data into table "search_sessions" */
export type Search_Sessions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  domain?: InputMaybe<Search_Domain_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  natural_language_queries?: InputMaybe<Natural_Language_Queries_Arr_Rel_Insert_Input>;
  real_time_alert?: InputMaybe<Scalars['Boolean']['input']>;
  search_domain?: InputMaybe<Search_Domain_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_filters?: InputMaybe<User_Filters_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Search_Sessions_Max_Fields = {
  __typename?: 'search_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "search_sessions" */
export type Search_Sessions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Search_Sessions_Min_Fields = {
  __typename?: 'search_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "search_sessions" */
export type Search_Sessions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "search_sessions" */
export type Search_Sessions_Mutation_Response = {
  __typename?: 'search_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Search_Sessions>;
};

/** input type for inserting object relation for remote table "search_sessions" */
export type Search_Sessions_Obj_Rel_Insert_Input = {
  data: Search_Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Search_Sessions_On_Conflict>;
};

/** on_conflict condition type for table "search_sessions" */
export type Search_Sessions_On_Conflict = {
  constraint: Search_Sessions_Constraint;
  update_columns?: Array<Search_Sessions_Update_Column>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "search_sessions". */
export type Search_Sessions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  natural_language_queries_aggregate?: InputMaybe<Natural_Language_Queries_Aggregate_Order_By>;
  real_time_alert?: InputMaybe<Order_By>;
  search_domain?: InputMaybe<Search_Domain_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_filters_aggregate?: InputMaybe<User_Filters_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: search_sessions */
export type Search_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "search_sessions" */
export enum Search_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  RealTimeAlert = 'real_time_alert',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "search_sessions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "search_sessions" */
export enum Search_Sessions_Select_Column_Search_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deleted = 'deleted',
  /** column name */
  RealTimeAlert = 'real_time_alert'
}

/** select "search_sessions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "search_sessions" */
export enum Search_Sessions_Select_Column_Search_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deleted = 'deleted',
  /** column name */
  RealTimeAlert = 'real_time_alert'
}

/** input type for updating data in table "search_sessions" */
export type Search_Sessions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  domain?: InputMaybe<Search_Domain_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  real_time_alert?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "search_sessions" */
export type Search_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Sessions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  domain?: InputMaybe<Search_Domain_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  real_time_alert?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "search_sessions" */
export enum Search_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  RealTimeAlert = 'real_time_alert',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Search_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Search_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Search_Sessions_Bool_Exp;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float']['input'];
  from: Scalars['geography']['input'];
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float']['input'];
  from: Scalars['geometry']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address_types" */
  address_types: Array<Address_Types>;
  /** fetch aggregated fields from the table: "address_types" */
  address_types_aggregate: Address_Types_Aggregate;
  /** fetch data from the table: "address_types" using primary key columns */
  address_types_by_pk?: Maybe<Address_Types>;
  /** fetch data from the table in a streaming manner: "address_types" */
  address_types_stream: Array<Address_Types>;
  /** fetch data from the table: "agency" */
  agency: Array<Agency>;
  /** fetch aggregated fields from the table: "agency" */
  agency_aggregate: Agency_Aggregate;
  /** fetch data from the table: "agency" using primary key columns */
  agency_by_pk?: Maybe<Agency>;
  /** fetch data from the table in a streaming manner: "agency" */
  agency_stream: Array<Agency>;
  /** fetch data from the table: "amenities" */
  amenities: Array<Amenities>;
  /** fetch aggregated fields from the table: "amenities" */
  amenities_aggregate: Amenities_Aggregate;
  /** fetch data from the table: "amenities" using primary key columns */
  amenities_by_pk?: Maybe<Amenities>;
  /** fetch data from the table in a streaming manner: "amenities" */
  amenities_stream: Array<Amenities>;
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequests_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.provider_requests" */
  authProviderRequests_stream: Array<AuthProviderRequests>;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProviders_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.providers" */
  authProviders_stream: Array<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypes_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
  authRefreshTokenTypes_stream: Array<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokens_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.roles" */
  authRoles_stream: Array<AuthRoles>;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProviders_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_providers" */
  authUserProviders_stream: Array<AuthUserProviders>;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_roles" */
  authUserRoles_stream: Array<AuthUserRoles>;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeys_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_security_keys" */
  authUserSecurityKeys_stream: Array<AuthUserSecurityKeys>;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table in a streaming manner: "storage.buckets" */
  buckets_stream: Array<Buckets>;
  /** fetch data from the table: "chat_response_type" */
  chat_response_type: Array<Chat_Response_Type>;
  /** fetch aggregated fields from the table: "chat_response_type" */
  chat_response_type_aggregate: Chat_Response_Type_Aggregate;
  /** fetch data from the table: "chat_response_type" using primary key columns */
  chat_response_type_by_pk?: Maybe<Chat_Response_Type>;
  /** fetch data from the table in a streaming manner: "chat_response_type" */
  chat_response_type_stream: Array<Chat_Response_Type>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  city_aggregate: City_Aggregate;
  /** fetch data from the table: "city" using primary key columns */
  city_by_pk?: Maybe<City>;
  /** fetch data from the table in a streaming manner: "city" */
  city_stream: Array<City>;
  /** fetch data from the table: "city_zone" */
  city_zone: Array<City_Zone>;
  /** fetch aggregated fields from the table: "city_zone" */
  city_zone_aggregate: City_Zone_Aggregate;
  /** fetch data from the table: "city_zone" using primary key columns */
  city_zone_by_pk?: Maybe<City_Zone>;
  /** fetch data from the table: "city_zone_origin" */
  city_zone_origin: Array<City_Zone_Origin>;
  /** fetch aggregated fields from the table: "city_zone_origin" */
  city_zone_origin_aggregate: City_Zone_Origin_Aggregate;
  /** fetch data from the table: "city_zone_origin" using primary key columns */
  city_zone_origin_by_pk?: Maybe<City_Zone_Origin>;
  /** fetch data from the table in a streaming manner: "city_zone_origin" */
  city_zone_origin_stream: Array<City_Zone_Origin>;
  /** fetch data from the table in a streaming manner: "city_zone" */
  city_zone_stream: Array<City_Zone>;
  /** fetch data from the table: "construction_status" */
  construction_status: Array<Construction_Status>;
  /** fetch aggregated fields from the table: "construction_status" */
  construction_status_aggregate: Construction_Status_Aggregate;
  /** fetch data from the table: "construction_status" using primary key columns */
  construction_status_by_pk?: Maybe<Construction_Status>;
  /** fetch data from the table in a streaming manner: "construction_status" */
  construction_status_stream: Array<Construction_Status>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** fetch data from the table: "contact_phone_number" */
  contact_phone_number: Array<Contact_Phone_Number>;
  /** fetch aggregated fields from the table: "contact_phone_number" */
  contact_phone_number_aggregate: Contact_Phone_Number_Aggregate;
  /** fetch data from the table: "contact_phone_number" using primary key columns */
  contact_phone_number_by_pk?: Maybe<Contact_Phone_Number>;
  /** fetch data from the table in a streaming manner: "contact_phone_number" */
  contact_phone_number_stream: Array<Contact_Phone_Number>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<Contact>;
  /** fetch data from the table: "contact_type" */
  contact_type: Array<Contact_Type>;
  /** fetch aggregated fields from the table: "contact_type" */
  contact_type_aggregate: Contact_Type_Aggregate;
  /** fetch data from the table: "contact_type" using primary key columns */
  contact_type_by_pk?: Maybe<Contact_Type>;
  /** fetch data from the table in a streaming manner: "contact_type" */
  contact_type_stream: Array<Contact_Type>;
  /** fetch data from the table: "content_type" */
  content_type: Array<Content_Type>;
  /** fetch aggregated fields from the table: "content_type" */
  content_type_aggregate: Content_Type_Aggregate;
  /** fetch data from the table: "content_type" using primary key columns */
  content_type_by_pk?: Maybe<Content_Type>;
  /** fetch data from the table in a streaming manner: "content_type" */
  content_type_stream: Array<Content_Type>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countries_stream: Array<Countries>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>;
  /** fetch data from the table: "currency_exchange_rates" */
  currency_exchange_rates: Array<Currency_Exchange_Rates>;
  /** fetch aggregated fields from the table: "currency_exchange_rates" */
  currency_exchange_rates_aggregate: Currency_Exchange_Rates_Aggregate;
  /** fetch data from the table: "currency_exchange_rates" using primary key columns */
  currency_exchange_rates_by_pk?: Maybe<Currency_Exchange_Rates>;
  /** fetch data from the table in a streaming manner: "currency_exchange_rates" */
  currency_exchange_rates_stream: Array<Currency_Exchange_Rates>;
  /** fetch data from the table in a streaming manner: "currency" */
  currency_stream: Array<Currency>;
  /** fetch data from the table: "entities" */
  entities: Array<Entities>;
  /** fetch aggregated fields from the table: "entities" */
  entities_aggregate: Entities_Aggregate;
  /** fetch data from the table: "entities" using primary key columns */
  entities_by_pk?: Maybe<Entities>;
  /** fetch data from the table in a streaming manner: "entities" */
  entities_stream: Array<Entities>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk?: Maybe<Feature>;
  /** fetch data from the table in a streaming manner: "feature" */
  feature_stream: Array<Feature>;
  /** fetch data from the table: "feature_type" */
  feature_type: Array<Feature_Type>;
  /** fetch aggregated fields from the table: "feature_type" */
  feature_type_aggregate: Feature_Type_Aggregate;
  /** fetch data from the table: "feature_type" using primary key columns */
  feature_type_by_pk?: Maybe<Feature_Type>;
  /** fetch data from the table in a streaming manner: "feature_type" */
  feature_type_stream: Array<Feature_Type>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table in a streaming manner: "storage.files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "furnishing_status" */
  furnishing_status: Array<Furnishing_Status>;
  /** fetch aggregated fields from the table: "furnishing_status" */
  furnishing_status_aggregate: Furnishing_Status_Aggregate;
  /** fetch data from the table: "furnishing_status" using primary key columns */
  furnishing_status_by_pk?: Maybe<Furnishing_Status>;
  /** fetch data from the table in a streaming manner: "furnishing_status" */
  furnishing_status_stream: Array<Furnishing_Status>;
  /** fetch data from the table: "heatingType" */
  heatingType: Array<HeatingType>;
  /** fetch aggregated fields from the table: "heatingType" */
  heatingType_aggregate: HeatingType_Aggregate;
  /** fetch data from the table: "heatingType" using primary key columns */
  heatingType_by_pk?: Maybe<HeatingType>;
  /** fetch data from the table in a streaming manner: "heatingType" */
  heatingType_stream: Array<HeatingType>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch aggregated fields from the table: "languages" */
  languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "languages" using primary key columns */
  languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table in a streaming manner: "languages" */
  languages_stream: Array<Languages>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>;
  /** fetch data from the table: "membership_package" */
  membership_package: Array<Membership_Package>;
  /** fetch aggregated fields from the table: "membership_package" */
  membership_package_aggregate: Membership_Package_Aggregate;
  /** fetch data from the table: "membership_package" using primary key columns */
  membership_package_by_pk?: Maybe<Membership_Package>;
  /** fetch data from the table in a streaming manner: "membership_package" */
  membership_package_stream: Array<Membership_Package>;
  /** An array relationship */
  natural_language_queries: Array<Natural_Language_Queries>;
  /** An aggregate relationship */
  natural_language_queries_aggregate: Natural_Language_Queries_Aggregate;
  /** fetch data from the table: "natural_language_queries" using primary key columns */
  natural_language_queries_by_pk?: Maybe<Natural_Language_Queries>;
  /** fetch data from the table in a streaming manner: "natural_language_queries" */
  natural_language_queries_stream: Array<Natural_Language_Queries>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** An array relationship */
  notification_amenities: Array<Notification_Amenities>;
  /** An aggregate relationship */
  notification_amenities_aggregate: Notification_Amenities_Aggregate;
  /** fetch data from the table: "notification_amenities" using primary key columns */
  notification_amenities_by_pk?: Maybe<Notification_Amenities>;
  /** fetch data from the table in a streaming manner: "notification_amenities" */
  notification_amenities_stream: Array<Notification_Amenities>;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_feature" */
  notification_feature: Array<Notification_Feature>;
  /** fetch aggregated fields from the table: "notification_feature" */
  notification_feature_aggregate: Notification_Feature_Aggregate;
  /** fetch data from the table: "notification_feature" using primary key columns */
  notification_feature_by_pk?: Maybe<Notification_Feature>;
  /** fetch data from the table in a streaming manner: "notification_feature" */
  notification_feature_stream: Array<Notification_Feature>;
  /** fetch data from the table: "notification_property_type" */
  notification_property_type: Array<Notification_Property_Type>;
  /** fetch aggregated fields from the table: "notification_property_type" */
  notification_property_type_aggregate: Notification_Property_Type_Aggregate;
  /** fetch data from the table: "notification_property_type" using primary key columns */
  notification_property_type_by_pk?: Maybe<Notification_Property_Type>;
  /** fetch data from the table in a streaming manner: "notification_property_type" */
  notification_property_type_stream: Array<Notification_Property_Type>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<Notification_Type>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table in a streaming manner: "order" */
  order_stream: Array<Order>;
  /** fetch data from the table: "parking_type" */
  parking_type: Array<Parking_Type>;
  /** fetch aggregated fields from the table: "parking_type" */
  parking_type_aggregate: Parking_Type_Aggregate;
  /** fetch data from the table: "parking_type" using primary key columns */
  parking_type_by_pk?: Maybe<Parking_Type>;
  /** fetch data from the table in a streaming manner: "parking_type" */
  parking_type_stream: Array<Parking_Type>;
  /** fetch data from the table: "property" */
  property: Array<Property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: Property_Aggregate;
  /** An array relationship */
  property_amenities: Array<Property_Amenities>;
  /** An aggregate relationship */
  property_amenities_aggregate: Property_Amenities_Aggregate;
  /** fetch data from the table: "property_amenities" using primary key columns */
  property_amenities_by_pk?: Maybe<Property_Amenities>;
  /** fetch data from the table in a streaming manner: "property_amenities" */
  property_amenities_stream: Array<Property_Amenities>;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<Property>;
  /** fetch data from the table: "property_cluster" */
  property_cluster: Array<Property_Cluster>;
  /** fetch aggregated fields from the table: "property_cluster" */
  property_cluster_aggregate: Property_Cluster_Aggregate;
  /** fetch data from the table: "property_cluster" using primary key columns */
  property_cluster_by_pk?: Maybe<Property_Cluster>;
  /** fetch data from the table in a streaming manner: "property_cluster" */
  property_cluster_stream: Array<Property_Cluster>;
  /** fetch data from the table: "property_composition" */
  property_composition: Array<Property_Composition>;
  /** fetch aggregated fields from the table: "property_composition" */
  property_composition_aggregate: Property_Composition_Aggregate;
  /** fetch data from the table: "property_composition" using primary key columns */
  property_composition_by_pk?: Maybe<Property_Composition>;
  /** fetch data from the table in a streaming manner: "property_composition" */
  property_composition_stream: Array<Property_Composition>;
  /** fetch data from the table: "property_feature" */
  property_feature: Array<Property_Feature>;
  /** fetch aggregated fields from the table: "property_feature" */
  property_feature_aggregate: Property_Feature_Aggregate;
  /** fetch data from the table: "property_feature" using primary key columns */
  property_feature_by_pk?: Maybe<Property_Feature>;
  /** fetch data from the table in a streaming manner: "property_feature" */
  property_feature_stream: Array<Property_Feature>;
  /** fetch data from the table: "property_image" */
  property_image: Array<Property_Image>;
  /** fetch aggregated fields from the table: "property_image" */
  property_image_aggregate: Property_Image_Aggregate;
  /** fetch data from the table: "property_image" using primary key columns */
  property_image_by_pk?: Maybe<Property_Image>;
  /** fetch data from the table in a streaming manner: "property_image" */
  property_image_stream: Array<Property_Image>;
  /** fetch data from the table: "property_list" */
  property_list: Array<Property_List>;
  /** fetch aggregated fields from the table: "property_list" */
  property_list_aggregate: Property_List_Aggregate;
  /** fetch data from the table: "property_list" using primary key columns */
  property_list_by_pk?: Maybe<Property_List>;
  /** fetch data from the table in a streaming manner: "property_list" */
  property_list_stream: Array<Property_List>;
  /** fetch data from the table: "property_origin" */
  property_origin: Array<Property_Origin>;
  /** fetch aggregated fields from the table: "property_origin" */
  property_origin_aggregate: Property_Origin_Aggregate;
  /** fetch data from the table: "property_origin" using primary key columns */
  property_origin_by_pk?: Maybe<Property_Origin>;
  /** fetch data from the table in a streaming manner: "property_origin" */
  property_origin_stream: Array<Property_Origin>;
  /** fetch data from the table: "property_origin_type" */
  property_origin_type: Array<Property_Origin_Type>;
  /** fetch aggregated fields from the table: "property_origin_type" */
  property_origin_type_aggregate: Property_Origin_Type_Aggregate;
  /** fetch data from the table: "property_origin_type" using primary key columns */
  property_origin_type_by_pk?: Maybe<Property_Origin_Type>;
  /** fetch data from the table in a streaming manner: "property_origin_type" */
  property_origin_type_stream: Array<Property_Origin_Type>;
  /** fetch data from the table: "property_price_history" */
  property_price_history: Array<Property_Price_History>;
  /** fetch aggregated fields from the table: "property_price_history" */
  property_price_history_aggregate: Property_Price_History_Aggregate;
  /** fetch data from the table: "property_price_history" using primary key columns */
  property_price_history_by_pk?: Maybe<Property_Price_History>;
  /** fetch data from the table in a streaming manner: "property_price_history" */
  property_price_history_stream: Array<Property_Price_History>;
  /** fetch data from the table: "property_source" */
  property_source: Array<Property_Source>;
  /** fetch aggregated fields from the table: "property_source" */
  property_source_aggregate: Property_Source_Aggregate;
  /** fetch data from the table: "property_source" using primary key columns */
  property_source_by_pk?: Maybe<Property_Source>;
  /** fetch data from the table in a streaming manner: "property_source" */
  property_source_stream: Array<Property_Source>;
  /** fetch data from the table: "property_status" */
  property_status: Array<Property_Status>;
  /** fetch aggregated fields from the table: "property_status" */
  property_status_aggregate: Property_Status_Aggregate;
  /** fetch data from the table: "property_status" using primary key columns */
  property_status_by_pk?: Maybe<Property_Status>;
  /** fetch data from the table in a streaming manner: "property_status" */
  property_status_stream: Array<Property_Status>;
  /** fetch data from the table in a streaming manner: "property" */
  property_stream: Array<Property>;
  /** fetch data from the table: "property_type" */
  property_type: Array<Property_Type>;
  /** fetch aggregated fields from the table: "property_type" */
  property_type_aggregate: Property_Type_Aggregate;
  /** fetch data from the table: "property_type" using primary key columns */
  property_type_by_pk?: Maybe<Property_Type>;
  /** fetch data from the table in a streaming manner: "property_type" */
  property_type_stream: Array<Property_Type>;
  /** An array relationship */
  property_views: Array<Property_Views>;
  /** An aggregate relationship */
  property_views_aggregate: Property_Views_Aggregate;
  /** fetch data from the table: "property_views" using primary key columns */
  property_views_by_pk?: Maybe<Property_Views>;
  /** fetch data from the table in a streaming manner: "property_views" */
  property_views_stream: Array<Property_Views>;
  /** fetch data from the table: "queries_property_result" */
  queries_property_result: Array<Queries_Property_Result>;
  /** fetch aggregated fields from the table: "queries_property_result" */
  queries_property_result_aggregate: Queries_Property_Result_Aggregate;
  /** fetch data from the table: "queries_property_result" using primary key columns */
  queries_property_result_by_pk?: Maybe<Queries_Property_Result>;
  /** fetch data from the table in a streaming manner: "queries_property_result" */
  queries_property_result_stream: Array<Queries_Property_Result>;
  /** fetch data from the table: "query_classification" */
  query_classification: Array<Query_Classification>;
  /** fetch aggregated fields from the table: "query_classification" */
  query_classification_aggregate: Query_Classification_Aggregate;
  /** fetch data from the table: "query_classification" using primary key columns */
  query_classification_by_pk?: Maybe<Query_Classification>;
  /** fetch data from the table in a streaming manner: "query_classification" */
  query_classification_stream: Array<Query_Classification>;
  /** fetch data from the table: "raw_location" */
  raw_location: Array<Raw_Location>;
  /** fetch aggregated fields from the table: "raw_location" */
  raw_location_aggregate: Raw_Location_Aggregate;
  /** fetch data from the table: "raw_location" using primary key columns */
  raw_location_by_pk?: Maybe<Raw_Location>;
  /** fetch data from the table in a streaming manner: "raw_location" */
  raw_location_stream: Array<Raw_Location>;
  /** fetch data from the table: "redirect" */
  redirect: Array<Redirect>;
  /** fetch aggregated fields from the table: "redirect" */
  redirect_aggregate: Redirect_Aggregate;
  /** fetch data from the table: "redirect" using primary key columns */
  redirect_by_pk?: Maybe<Redirect>;
  /** fetch data from the table in a streaming manner: "redirect" */
  redirect_stream: Array<Redirect>;
  /** fetch data from the table: "search_context" */
  search_context: Array<Search_Context>;
  /** fetch aggregated fields from the table: "search_context" */
  search_context_aggregate: Search_Context_Aggregate;
  /** fetch data from the table: "search_context" using primary key columns */
  search_context_by_pk?: Maybe<Search_Context>;
  /** fetch data from the table in a streaming manner: "search_context" */
  search_context_stream: Array<Search_Context>;
  /** fetch data from the table: "search_domain" */
  search_domain: Array<Search_Domain>;
  /** fetch aggregated fields from the table: "search_domain" */
  search_domain_aggregate: Search_Domain_Aggregate;
  /** fetch data from the table: "search_domain" using primary key columns */
  search_domain_by_pk?: Maybe<Search_Domain>;
  /** fetch data from the table in a streaming manner: "search_domain" */
  search_domain_stream: Array<Search_Domain>;
  /** An array relationship */
  search_sessions: Array<Search_Sessions>;
  /** An aggregate relationship */
  search_sessions_aggregate: Search_Sessions_Aggregate;
  /** fetch data from the table: "search_sessions" using primary key columns */
  search_sessions_by_pk?: Maybe<Search_Sessions>;
  /** fetch data from the table in a streaming manner: "search_sessions" */
  search_sessions_stream: Array<Search_Sessions>;
  /** fetch data from the table: "text_content" */
  text_content: Array<Text_Content>;
  /** fetch aggregated fields from the table: "text_content" */
  text_content_aggregate: Text_Content_Aggregate;
  /** fetch data from the table: "text_content" using primary key columns */
  text_content_by_pk?: Maybe<Text_Content>;
  /** fetch data from the table in a streaming manner: "text_content" */
  text_content_stream: Array<Text_Content>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  /** fetch data from the table: "translations" using primary key columns */
  translations_by_pk?: Maybe<Translations>;
  /** fetch data from the table in a streaming manner: "translations" */
  translations_stream: Array<Translations>;
  /** fetch data from the table: "url_slug" */
  url_slug: Array<Url_Slug>;
  /** fetch aggregated fields from the table: "url_slug" */
  url_slug_aggregate: Url_Slug_Aggregate;
  /** fetch data from the table: "url_slug" using primary key columns */
  url_slug_by_pk?: Maybe<Url_Slug>;
  /** fetch data from the table in a streaming manner: "url_slug" */
  url_slug_stream: Array<Url_Slug>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_card_information" */
  user_card_information: Array<User_Card_Information>;
  /** fetch aggregated fields from the table: "user_card_information" */
  user_card_information_aggregate: User_Card_Information_Aggregate;
  /** fetch data from the table: "user_card_information" using primary key columns */
  user_card_information_by_pk?: Maybe<User_Card_Information>;
  /** fetch data from the table in a streaming manner: "user_card_information" */
  user_card_information_stream: Array<User_Card_Information>;
  /** An array relationship */
  user_filters: Array<User_Filters>;
  /** An aggregate relationship */
  user_filters_aggregate: User_Filters_Aggregate;
  /** fetch data from the table: "user_filters" using primary key columns */
  user_filters_by_pk?: Maybe<User_Filters>;
  /** fetch data from the table: "user_filters_features" */
  user_filters_features: Array<User_Filters_Features>;
  /** fetch aggregated fields from the table: "user_filters_features" */
  user_filters_features_aggregate: User_Filters_Features_Aggregate;
  /** fetch data from the table: "user_filters_features" using primary key columns */
  user_filters_features_by_pk?: Maybe<User_Filters_Features>;
  /** fetch data from the table in a streaming manner: "user_filters_features" */
  user_filters_features_stream: Array<User_Filters_Features>;
  /** fetch data from the table in a streaming manner: "user_filters" */
  user_filters_stream: Array<User_Filters>;
  /** fetch data from the table: "user_membership" */
  user_membership: Array<User_Membership>;
  /** fetch aggregated fields from the table: "user_membership" */
  user_membership_aggregate: User_Membership_Aggregate;
  /** fetch data from the table: "user_membership" using primary key columns */
  user_membership_by_pk?: Maybe<User_Membership>;
  /** fetch data from the table in a streaming manner: "user_membership" */
  user_membership_stream: Array<User_Membership>;
  /** An array relationship */
  user_preferences: Array<User_Preferences>;
  /** An aggregate relationship */
  user_preferences_aggregate: User_Preferences_Aggregate;
  /** fetch data from the table: "user_preferences" using primary key columns */
  user_preferences_by_pk?: Maybe<User_Preferences>;
  /** fetch data from the table in a streaming manner: "user_preferences" */
  user_preferences_stream: Array<User_Preferences>;
  /** fetch data from the table: "user_property_notifiation" */
  user_property_notifiation: Array<User_Property_Notifiation>;
  /** fetch aggregated fields from the table: "user_property_notifiation" */
  user_property_notifiation_aggregate: User_Property_Notifiation_Aggregate;
  /** fetch data from the table: "user_property_notifiation" using primary key columns */
  user_property_notifiation_by_pk?: Maybe<User_Property_Notifiation>;
  /** fetch data from the table in a streaming manner: "user_property_notifiation" */
  user_property_notifiation_stream: Array<User_Property_Notifiation>;
  /** An array relationship */
  user_saved_properties: Array<User_Saved_Properties>;
  /** An aggregate relationship */
  user_saved_properties_aggregate: User_Saved_Properties_Aggregate;
  /** fetch data from the table: "user_saved_properties" using primary key columns */
  user_saved_properties_by_pk?: Maybe<User_Saved_Properties>;
  /** fetch data from the table in a streaming manner: "user_saved_properties" */
  user_saved_properties_stream: Array<User_Saved_Properties>;
  /** fetch data from the table: "user_seen_property" */
  user_seen_property: Array<User_Seen_Property>;
  /** fetch aggregated fields from the table: "user_seen_property" */
  user_seen_property_aggregate: User_Seen_Property_Aggregate;
  /** fetch data from the table: "user_seen_property" using primary key columns */
  user_seen_property_by_pk?: Maybe<User_Seen_Property>;
  /** fetch data from the table in a streaming manner: "user_seen_property" */
  user_seen_property_stream: Array<User_Seen_Property>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "storage.virus" using primary key columns */
  virus?: Maybe<Virus>;
  /** fetch data from the table in a streaming manner: "storage.virus" */
  virus_stream: Array<Virus>;
  /** fetch data from the table: "storage.virus" */
  viruses: Array<Virus>;
  /** fetch aggregated fields from the table: "storage.virus" */
  virusesAggregate: Virus_Aggregate;
  /** fetch data from the table: "zone_data_method" */
  zone_data_method: Array<Zone_Data_Method>;
  /** fetch aggregated fields from the table: "zone_data_method" */
  zone_data_method_aggregate: Zone_Data_Method_Aggregate;
  /** fetch data from the table: "zone_data_method" using primary key columns */
  zone_data_method_by_pk?: Maybe<Zone_Data_Method>;
  /** fetch data from the table in a streaming manner: "zone_data_method" */
  zone_data_method_stream: Array<Zone_Data_Method>;
};


export type Subscription_RootAddress_TypesArgs = {
  distinct_on?: InputMaybe<Array<Address_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Types_Order_By>>;
  where?: InputMaybe<Address_Types_Bool_Exp>;
};


export type Subscription_RootAddress_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Types_Order_By>>;
  where?: InputMaybe<Address_Types_Bool_Exp>;
};


export type Subscription_RootAddress_Types_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootAddress_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Address_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Types_Bool_Exp>;
};


export type Subscription_RootAgencyArgs = {
  distinct_on?: InputMaybe<Array<Agency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Agency_Order_By>>;
  where?: InputMaybe<Agency_Bool_Exp>;
};


export type Subscription_RootAgency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Agency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Agency_Order_By>>;
  where?: InputMaybe<Agency_Bool_Exp>;
};


export type Subscription_RootAgency_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAgency_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Agency_Stream_Cursor_Input>>;
  where?: InputMaybe<Agency_Bool_Exp>;
};


export type Subscription_RootAmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Amenities_Order_By>>;
  where?: InputMaybe<Amenities_Bool_Exp>;
};


export type Subscription_RootAmenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Amenities_Order_By>>;
  where?: InputMaybe<Amenities_Bool_Exp>;
};


export type Subscription_RootAmenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAmenities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Amenities_Stream_Cursor_Input>>;
  where?: InputMaybe<Amenities_Bool_Exp>;
};


export type Subscription_RootAuthProviderArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Subscription_RootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Subscription_RootAuthProviderRequests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviderRequests_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Subscription_RootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Subscription_RootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Subscription_RootAuthProviders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviders_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokenTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokenTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokens_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Subscription_RootAuthRoleArgs = {
  role: Scalars['String']['input'];
};


export type Subscription_RootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Subscription_RootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Subscription_RootAuthRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Subscription_RootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Subscription_RootAuthUserProviders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserProviders_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Subscription_RootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Subscription_RootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Subscription_RootAuthUserSecurityKeys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserSecurityKeys_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Subscription_RootBucketArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootBuckets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Buckets_Stream_Cursor_Input>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootChat_Response_TypeArgs = {
  distinct_on?: InputMaybe<Array<Chat_Response_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Response_Type_Order_By>>;
  where?: InputMaybe<Chat_Response_Type_Bool_Exp>;
};


export type Subscription_RootChat_Response_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Response_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Chat_Response_Type_Order_By>>;
  where?: InputMaybe<Chat_Response_Type_Bool_Exp>;
};


export type Subscription_RootChat_Response_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootChat_Response_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Chat_Response_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Response_Type_Bool_Exp>;
};


export type Subscription_RootCityArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};


export type Subscription_RootCity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};


export type Subscription_RootCity_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCity_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<City_Stream_Cursor_Input>>;
  where?: InputMaybe<City_Bool_Exp>;
};


export type Subscription_RootCity_ZoneArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Order_By>>;
  where?: InputMaybe<City_Zone_Bool_Exp>;
};


export type Subscription_RootCity_Zone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Order_By>>;
  where?: InputMaybe<City_Zone_Bool_Exp>;
};


export type Subscription_RootCity_Zone_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCity_Zone_OriginArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Origin_Order_By>>;
  where?: InputMaybe<City_Zone_Origin_Bool_Exp>;
};


export type Subscription_RootCity_Zone_Origin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Origin_Order_By>>;
  where?: InputMaybe<City_Zone_Origin_Bool_Exp>;
};


export type Subscription_RootCity_Zone_Origin_By_PkArgs = {
  origin: Scalars['String']['input'];
};


export type Subscription_RootCity_Zone_Origin_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<City_Zone_Origin_Stream_Cursor_Input>>;
  where?: InputMaybe<City_Zone_Origin_Bool_Exp>;
};


export type Subscription_RootCity_Zone_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<City_Zone_Stream_Cursor_Input>>;
  where?: InputMaybe<City_Zone_Bool_Exp>;
};


export type Subscription_RootConstruction_StatusArgs = {
  distinct_on?: InputMaybe<Array<Construction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Construction_Status_Order_By>>;
  where?: InputMaybe<Construction_Status_Bool_Exp>;
};


export type Subscription_RootConstruction_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Construction_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Construction_Status_Order_By>>;
  where?: InputMaybe<Construction_Status_Bool_Exp>;
};


export type Subscription_RootConstruction_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Subscription_RootConstruction_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Construction_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Construction_Status_Bool_Exp>;
};


export type Subscription_RootContactArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContact_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Phone_Number_Order_By>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


export type Subscription_RootContact_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Phone_Number_Order_By>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


export type Subscription_RootContact_Phone_Number_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContact_Phone_Number_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Phone_Number_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Phone_Number_Bool_Exp>;
};


export type Subscription_RootContact_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_TypeArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Subscription_RootContact_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Subscription_RootContact_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootContact_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Subscription_RootContent_TypeArgs = {
  distinct_on?: InputMaybe<Array<Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Type_Order_By>>;
  where?: InputMaybe<Content_Type_Bool_Exp>;
};


export type Subscription_RootContent_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Type_Order_By>>;
  where?: InputMaybe<Content_Type_Bool_Exp>;
};


export type Subscription_RootContent_Type_By_PkArgs = {
  content_type: Scalars['String']['input'];
};


export type Subscription_RootContent_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Content_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Type_Bool_Exp>;
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Countries_Stream_Cursor_Input>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Subscription_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Subscription_RootCurrency_By_PkArgs = {
  currency: Scalars['String']['input'];
};


export type Subscription_RootCurrency_Exchange_RatesArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rates_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootCurrency_Exchange_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rates_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootCurrency_Exchange_Rates_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCurrency_Exchange_Rates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Currency_Exchange_Rates_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_Exchange_Rates_Bool_Exp>;
};


export type Subscription_RootCurrency_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Currency_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};


export type Subscription_RootEntitiesArgs = {
  distinct_on?: InputMaybe<Array<Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entities_Order_By>>;
  where?: InputMaybe<Entities_Bool_Exp>;
};


export type Subscription_RootEntities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entities_Order_By>>;
  where?: InputMaybe<Entities_Bool_Exp>;
};


export type Subscription_RootEntities_By_PkArgs = {
  entity_type: Scalars['String']['input'];
};


export type Subscription_RootEntities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entities_Stream_Cursor_Input>>;
  where?: InputMaybe<Entities_Bool_Exp>;
};


export type Subscription_RootFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};


export type Subscription_RootFeature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};


export type Subscription_RootFeature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeature_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Feature_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};


export type Subscription_RootFeature_TypeArgs = {
  distinct_on?: InputMaybe<Array<Feature_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Type_Order_By>>;
  where?: InputMaybe<Feature_Type_Bool_Exp>;
};


export type Subscription_RootFeature_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Type_Order_By>>;
  where?: InputMaybe<Feature_Type_Bool_Exp>;
};


export type Subscription_RootFeature_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootFeature_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Feature_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Type_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFurnishing_StatusArgs = {
  distinct_on?: InputMaybe<Array<Furnishing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Furnishing_Status_Order_By>>;
  where?: InputMaybe<Furnishing_Status_Bool_Exp>;
};


export type Subscription_RootFurnishing_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Furnishing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Furnishing_Status_Order_By>>;
  where?: InputMaybe<Furnishing_Status_Bool_Exp>;
};


export type Subscription_RootFurnishing_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Subscription_RootFurnishing_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Furnishing_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Furnishing_Status_Bool_Exp>;
};


export type Subscription_RootHeatingTypeArgs = {
  distinct_on?: InputMaybe<Array<HeatingType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatingType_Order_By>>;
  where?: InputMaybe<HeatingType_Bool_Exp>;
};


export type Subscription_RootHeatingType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatingType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatingType_Order_By>>;
  where?: InputMaybe<HeatingType_Bool_Exp>;
};


export type Subscription_RootHeatingType_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootHeatingType_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<HeatingType_Stream_Cursor_Input>>;
  where?: InputMaybe<HeatingType_Bool_Exp>;
};


export type Subscription_RootLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_By_PkArgs = {
  language_id: Scalars['Int']['input'];
};


export type Subscription_RootLanguages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Languages_Stream_Cursor_Input>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLocation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Location_Bool_Exp>;
};


export type Subscription_RootMembership_PackageArgs = {
  distinct_on?: InputMaybe<Array<Membership_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Membership_Package_Order_By>>;
  where?: InputMaybe<Membership_Package_Bool_Exp>;
};


export type Subscription_RootMembership_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Membership_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Membership_Package_Order_By>>;
  where?: InputMaybe<Membership_Package_Bool_Exp>;
};


export type Subscription_RootMembership_Package_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMembership_Package_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Membership_Package_Stream_Cursor_Input>>;
  where?: InputMaybe<Membership_Package_Bool_Exp>;
};


export type Subscription_RootNatural_Language_QueriesArgs = {
  distinct_on?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Natural_Language_Queries_Order_By>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


export type Subscription_RootNatural_Language_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Natural_Language_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Natural_Language_Queries_Order_By>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


export type Subscription_RootNatural_Language_Queries_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNatural_Language_Queries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Natural_Language_Queries_Stream_Cursor_Input>>;
  where?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
};


export type Subscription_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_AmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Amenities_Order_By>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


export type Subscription_RootNotification_Amenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Amenities_Order_By>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


export type Subscription_RootNotification_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotification_Amenities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Amenities_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Amenities_Bool_Exp>;
};


export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotification_FeatureArgs = {
  distinct_on?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Feature_Order_By>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


export type Subscription_RootNotification_Feature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Feature_Order_By>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


export type Subscription_RootNotification_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotification_Feature_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Feature_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Feature_Bool_Exp>;
};


export type Subscription_RootNotification_Property_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Property_Type_Order_By>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


export type Subscription_RootNotification_Property_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Property_Type_Order_By>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


export type Subscription_RootNotification_Property_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotification_Property_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Property_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Property_Type_Bool_Exp>;
};


export type Subscription_RootNotification_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Subscription_RootNotification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Subscription_RootNotification_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootNotification_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Subscription_RootOrderArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Order_Order_By>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOrder_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Order_Bool_Exp>;
};


export type Subscription_RootParking_TypeArgs = {
  distinct_on?: InputMaybe<Array<Parking_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parking_Type_Order_By>>;
  where?: InputMaybe<Parking_Type_Bool_Exp>;
};


export type Subscription_RootParking_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parking_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parking_Type_Order_By>>;
  where?: InputMaybe<Parking_Type_Bool_Exp>;
};


export type Subscription_RootParking_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootParking_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Parking_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Parking_Type_Bool_Exp>;
};


export type Subscription_RootPropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Subscription_RootProperty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Subscription_RootProperty_AmenitiesArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


export type Subscription_RootProperty_Amenities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Amenities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Amenities_Order_By>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


export type Subscription_RootProperty_Amenities_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Amenities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Amenities_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Amenities_Bool_Exp>;
};


export type Subscription_RootProperty_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Property_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Cluster_Order_By>>;
  where?: InputMaybe<Property_Cluster_Bool_Exp>;
};


export type Subscription_RootProperty_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Cluster_Order_By>>;
  where?: InputMaybe<Property_Cluster_Bool_Exp>;
};


export type Subscription_RootProperty_Cluster_By_PkArgs = {
  cluster: Scalars['String']['input'];
};


export type Subscription_RootProperty_Cluster_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Cluster_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Cluster_Bool_Exp>;
};


export type Subscription_RootProperty_CompositionArgs = {
  distinct_on?: InputMaybe<Array<Property_Composition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Composition_Order_By>>;
  where?: InputMaybe<Property_Composition_Bool_Exp>;
};


export type Subscription_RootProperty_Composition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Composition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Composition_Order_By>>;
  where?: InputMaybe<Property_Composition_Bool_Exp>;
};


export type Subscription_RootProperty_Composition_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Composition_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Composition_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Composition_Bool_Exp>;
};


export type Subscription_RootProperty_FeatureArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


export type Subscription_RootProperty_Feature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Feature_Order_By>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


export type Subscription_RootProperty_Feature_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Feature_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Feature_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Feature_Bool_Exp>;
};


export type Subscription_RootProperty_ImageArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


export type Subscription_RootProperty_Image_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Image_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Image_Order_By>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


export type Subscription_RootProperty_Image_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Image_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Image_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Image_Bool_Exp>;
};


export type Subscription_RootProperty_ListArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


export type Subscription_RootProperty_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_List_Order_By>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


export type Subscription_RootProperty_List_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_List_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_List_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_List_Bool_Exp>;
};


export type Subscription_RootProperty_OriginArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Order_By>>;
  where?: InputMaybe<Property_Origin_Bool_Exp>;
};


export type Subscription_RootProperty_Origin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Order_By>>;
  where?: InputMaybe<Property_Origin_Bool_Exp>;
};


export type Subscription_RootProperty_Origin_By_PkArgs = {
  origin: Scalars['String']['input'];
};


export type Subscription_RootProperty_Origin_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Origin_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Origin_Bool_Exp>;
};


export type Subscription_RootProperty_Origin_TypeArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Type_Order_By>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp>;
};


export type Subscription_RootProperty_Origin_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Type_Order_By>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp>;
};


export type Subscription_RootProperty_Origin_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootProperty_Origin_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Origin_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp>;
};


export type Subscription_RootProperty_Price_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


export type Subscription_RootProperty_Price_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Price_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Price_History_Order_By>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


export type Subscription_RootProperty_Price_History_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Price_History_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Price_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Price_History_Bool_Exp>;
};


export type Subscription_RootProperty_SourceArgs = {
  distinct_on?: InputMaybe<Array<Property_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Source_Order_By>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};


export type Subscription_RootProperty_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Source_Order_By>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};


export type Subscription_RootProperty_Source_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Source_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Source_Bool_Exp>;
};


export type Subscription_RootProperty_StatusArgs = {
  distinct_on?: InputMaybe<Array<Property_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Status_Order_By>>;
  where?: InputMaybe<Property_Status_Bool_Exp>;
};


export type Subscription_RootProperty_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Status_Order_By>>;
  where?: InputMaybe<Property_Status_Bool_Exp>;
};


export type Subscription_RootProperty_Status_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Subscription_RootProperty_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Status_Bool_Exp>;
};


export type Subscription_RootProperty_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Bool_Exp>;
};


export type Subscription_RootProperty_TypeArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};


export type Subscription_RootProperty_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};


export type Subscription_RootProperty_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type Subscription_RootProperty_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};


export type Subscription_RootProperty_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Property_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Views_Order_By>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


export type Subscription_RootProperty_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Property_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Views_Order_By>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


export type Subscription_RootProperty_Views_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProperty_Views_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Property_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Property_Views_Bool_Exp>;
};


export type Subscription_RootQueries_Property_ResultArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


export type Subscription_RootQueries_Property_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Queries_Property_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Queries_Property_Result_Order_By>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


export type Subscription_RootQueries_Property_Result_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQueries_Property_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Queries_Property_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Queries_Property_Result_Bool_Exp>;
};


export type Subscription_RootQuery_ClassificationArgs = {
  distinct_on?: InputMaybe<Array<Query_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Query_Classification_Order_By>>;
  where?: InputMaybe<Query_Classification_Bool_Exp>;
};


export type Subscription_RootQuery_Classification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Query_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Query_Classification_Order_By>>;
  where?: InputMaybe<Query_Classification_Bool_Exp>;
};


export type Subscription_RootQuery_Classification_By_PkArgs = {
  class: Scalars['String']['input'];
};


export type Subscription_RootQuery_Classification_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Query_Classification_Stream_Cursor_Input>>;
  where?: InputMaybe<Query_Classification_Bool_Exp>;
};


export type Subscription_RootRaw_LocationArgs = {
  distinct_on?: InputMaybe<Array<Raw_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Raw_Location_Order_By>>;
  where?: InputMaybe<Raw_Location_Bool_Exp>;
};


export type Subscription_RootRaw_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Raw_Location_Order_By>>;
  where?: InputMaybe<Raw_Location_Bool_Exp>;
};


export type Subscription_RootRaw_Location_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRaw_Location_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Raw_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Location_Bool_Exp>;
};


export type Subscription_RootRedirectArgs = {
  distinct_on?: InputMaybe<Array<Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Redirect_Order_By>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


export type Subscription_RootRedirect_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Redirect_Order_By>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


export type Subscription_RootRedirect_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRedirect_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Redirect_Stream_Cursor_Input>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


export type Subscription_RootSearch_ContextArgs = {
  distinct_on?: InputMaybe<Array<Search_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Context_Order_By>>;
  where?: InputMaybe<Search_Context_Bool_Exp>;
};


export type Subscription_RootSearch_Context_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Context_Order_By>>;
  where?: InputMaybe<Search_Context_Bool_Exp>;
};


export type Subscription_RootSearch_Context_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSearch_Context_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Search_Context_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Context_Bool_Exp>;
};


export type Subscription_RootSearch_DomainArgs = {
  distinct_on?: InputMaybe<Array<Search_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Domain_Order_By>>;
  where?: InputMaybe<Search_Domain_Bool_Exp>;
};


export type Subscription_RootSearch_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Domain_Order_By>>;
  where?: InputMaybe<Search_Domain_Bool_Exp>;
};


export type Subscription_RootSearch_Domain_By_PkArgs = {
  domain: Scalars['String']['input'];
};


export type Subscription_RootSearch_Domain_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Search_Domain_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Domain_Bool_Exp>;
};


export type Subscription_RootSearch_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Sessions_Order_By>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


export type Subscription_RootSearch_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Sessions_Order_By>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


export type Subscription_RootSearch_Sessions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSearch_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Search_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


export type Subscription_RootText_ContentArgs = {
  distinct_on?: InputMaybe<Array<Text_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Content_Order_By>>;
  where?: InputMaybe<Text_Content_Bool_Exp>;
};


export type Subscription_RootText_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Content_Order_By>>;
  where?: InputMaybe<Text_Content_Bool_Exp>;
};


export type Subscription_RootText_Content_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootText_Content_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Text_Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Content_Bool_Exp>;
};


export type Subscription_RootTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootTranslations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTranslations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Translations_Stream_Cursor_Input>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootUrl_SlugArgs = {
  distinct_on?: InputMaybe<Array<Url_Slug_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Slug_Order_By>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


export type Subscription_RootUrl_Slug_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Slug_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Slug_Order_By>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


export type Subscription_RootUrl_Slug_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUrl_Slug_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Url_Slug_Stream_Cursor_Input>>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Card_InformationArgs = {
  distinct_on?: InputMaybe<Array<User_Card_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Card_Information_Order_By>>;
  where?: InputMaybe<User_Card_Information_Bool_Exp>;
};


export type Subscription_RootUser_Card_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Card_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Card_Information_Order_By>>;
  where?: InputMaybe<User_Card_Information_Bool_Exp>;
};


export type Subscription_RootUser_Card_Information_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Card_Information_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Card_Information_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Card_Information_Bool_Exp>;
};


export type Subscription_RootUser_FiltersArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Order_By>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};


export type Subscription_RootUser_Filters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Order_By>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};


export type Subscription_RootUser_Filters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Filters_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Features_Order_By>>;
  where?: InputMaybe<User_Filters_Features_Bool_Exp>;
};


export type Subscription_RootUser_Filters_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Filters_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Filters_Features_Order_By>>;
  where?: InputMaybe<User_Filters_Features_Bool_Exp>;
};


export type Subscription_RootUser_Filters_Features_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Filters_Features_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Filters_Features_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Filters_Features_Bool_Exp>;
};


export type Subscription_RootUser_Filters_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Filters_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};


export type Subscription_RootUser_MembershipArgs = {
  distinct_on?: InputMaybe<Array<User_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Membership_Order_By>>;
  where?: InputMaybe<User_Membership_Bool_Exp>;
};


export type Subscription_RootUser_Membership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Membership_Order_By>>;
  where?: InputMaybe<User_Membership_Bool_Exp>;
};


export type Subscription_RootUser_Membership_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Membership_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Membership_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Membership_Bool_Exp>;
};


export type Subscription_RootUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Preferences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Preferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Property_NotifiationArgs = {
  distinct_on?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Property_Notifiation_Order_By>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


export type Subscription_RootUser_Property_Notifiation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Property_Notifiation_Order_By>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


export type Subscription_RootUser_Property_Notifiation_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Property_Notifiation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Property_Notifiation_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};


export type Subscription_RootUser_Saved_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Saved_Properties_Order_By>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


export type Subscription_RootUser_Saved_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Saved_Properties_Order_By>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


export type Subscription_RootUser_Saved_Properties_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Saved_Properties_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Saved_Properties_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};


export type Subscription_RootUser_Seen_PropertyArgs = {
  distinct_on?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Seen_Property_Order_By>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};


export type Subscription_RootUser_Seen_Property_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Seen_Property_Order_By>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};


export type Subscription_RootUser_Seen_Property_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Seen_Property_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Seen_Property_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVirusArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVirus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Virus_Stream_Cursor_Input>>;
  where?: InputMaybe<Virus_Bool_Exp>;
};


export type Subscription_RootVirusesArgs = {
  distinct_on?: InputMaybe<Array<Virus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Virus_Order_By>>;
  where?: InputMaybe<Virus_Bool_Exp>;
};


export type Subscription_RootVirusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Virus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Virus_Order_By>>;
  where?: InputMaybe<Virus_Bool_Exp>;
};


export type Subscription_RootZone_Data_MethodArgs = {
  distinct_on?: InputMaybe<Array<Zone_Data_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Zone_Data_Method_Order_By>>;
  where?: InputMaybe<Zone_Data_Method_Bool_Exp>;
};


export type Subscription_RootZone_Data_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zone_Data_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Zone_Data_Method_Order_By>>;
  where?: InputMaybe<Zone_Data_Method_Bool_Exp>;
};


export type Subscription_RootZone_Data_Method_By_PkArgs = {
  method: Scalars['String']['input'];
};


export type Subscription_RootZone_Data_Method_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Zone_Data_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Zone_Data_Method_Bool_Exp>;
};

/** columns and relationships of "text_content" */
export type Text_Content = {
  __typename?: 'text_content';
  content_type?: Maybe<Content_Type_Enum>;
  entity_type: Entities_Enum;
  id: Scalars['uuid']['output'];
  original_language_id: Scalars['Int']['output'];
  original_text: Scalars['String']['output'];
  seo_meta?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
};


/** columns and relationships of "text_content" */
export type Text_ContentTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


/** columns and relationships of "text_content" */
export type Text_ContentTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** aggregated selection of "text_content" */
export type Text_Content_Aggregate = {
  __typename?: 'text_content_aggregate';
  aggregate?: Maybe<Text_Content_Aggregate_Fields>;
  nodes: Array<Text_Content>;
};

/** aggregate fields of "text_content" */
export type Text_Content_Aggregate_Fields = {
  __typename?: 'text_content_aggregate_fields';
  avg?: Maybe<Text_Content_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Text_Content_Max_Fields>;
  min?: Maybe<Text_Content_Min_Fields>;
  stddev?: Maybe<Text_Content_Stddev_Fields>;
  stddev_pop?: Maybe<Text_Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Text_Content_Stddev_Samp_Fields>;
  sum?: Maybe<Text_Content_Sum_Fields>;
  var_pop?: Maybe<Text_Content_Var_Pop_Fields>;
  var_samp?: Maybe<Text_Content_Var_Samp_Fields>;
  variance?: Maybe<Text_Content_Variance_Fields>;
};


/** aggregate fields of "text_content" */
export type Text_Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Text_Content_Avg_Fields = {
  __typename?: 'text_content_avg_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "text_content". All fields are combined with a logical 'AND'. */
export type Text_Content_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Content_Bool_Exp>>;
  _not?: InputMaybe<Text_Content_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Content_Bool_Exp>>;
  content_type?: InputMaybe<Content_Type_Enum_Comparison_Exp>;
  entity_type?: InputMaybe<Entities_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  original_language_id?: InputMaybe<Int_Comparison_Exp>;
  original_text?: InputMaybe<String_Comparison_Exp>;
  seo_meta?: InputMaybe<String_Comparison_Exp>;
  translations?: InputMaybe<Translations_Bool_Exp>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "text_content" */
export enum Text_Content_Constraint {
  /** unique or primary key constraint on columns "id" */
  TextContentPkey = 'text_content_pkey'
}

/** input type for incrementing numeric columns in table "text_content" */
export type Text_Content_Inc_Input = {
  original_language_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "text_content" */
export type Text_Content_Insert_Input = {
  content_type?: InputMaybe<Content_Type_Enum>;
  entity_type?: InputMaybe<Entities_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  original_language_id?: InputMaybe<Scalars['Int']['input']>;
  original_text?: InputMaybe<Scalars['String']['input']>;
  seo_meta?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Translations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Text_Content_Max_Fields = {
  __typename?: 'text_content_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  original_language_id?: Maybe<Scalars['Int']['output']>;
  original_text?: Maybe<Scalars['String']['output']>;
  seo_meta?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Text_Content_Min_Fields = {
  __typename?: 'text_content_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  original_language_id?: Maybe<Scalars['Int']['output']>;
  original_text?: Maybe<Scalars['String']['output']>;
  seo_meta?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "text_content" */
export type Text_Content_Mutation_Response = {
  __typename?: 'text_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Content>;
};

/** input type for inserting object relation for remote table "text_content" */
export type Text_Content_Obj_Rel_Insert_Input = {
  data: Text_Content_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Text_Content_On_Conflict>;
};

/** on_conflict condition type for table "text_content" */
export type Text_Content_On_Conflict = {
  constraint: Text_Content_Constraint;
  update_columns?: Array<Text_Content_Update_Column>;
  where?: InputMaybe<Text_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "text_content". */
export type Text_Content_Order_By = {
  content_type?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  original_language_id?: InputMaybe<Order_By>;
  original_text?: InputMaybe<Order_By>;
  seo_meta?: InputMaybe<Order_By>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Order_By>;
};

/** primary key columns input for table: text_content */
export type Text_Content_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "text_content" */
export enum Text_Content_Select_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalLanguageId = 'original_language_id',
  /** column name */
  OriginalText = 'original_text',
  /** column name */
  SeoMeta = 'seo_meta'
}

/** input type for updating data in table "text_content" */
export type Text_Content_Set_Input = {
  content_type?: InputMaybe<Content_Type_Enum>;
  entity_type?: InputMaybe<Entities_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  original_language_id?: InputMaybe<Scalars['Int']['input']>;
  original_text?: InputMaybe<Scalars['String']['input']>;
  seo_meta?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Text_Content_Stddev_Fields = {
  __typename?: 'text_content_stddev_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Text_Content_Stddev_Pop_Fields = {
  __typename?: 'text_content_stddev_pop_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Text_Content_Stddev_Samp_Fields = {
  __typename?: 'text_content_stddev_samp_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "text_content" */
export type Text_Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Content_Stream_Cursor_Value_Input = {
  content_type?: InputMaybe<Content_Type_Enum>;
  entity_type?: InputMaybe<Entities_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  original_language_id?: InputMaybe<Scalars['Int']['input']>;
  original_text?: InputMaybe<Scalars['String']['input']>;
  seo_meta?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Text_Content_Sum_Fields = {
  __typename?: 'text_content_sum_fields';
  original_language_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "text_content" */
export enum Text_Content_Update_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalLanguageId = 'original_language_id',
  /** column name */
  OriginalText = 'original_text',
  /** column name */
  SeoMeta = 'seo_meta'
}

export type Text_Content_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Text_Content_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Text_Content_Var_Pop_Fields = {
  __typename?: 'text_content_var_pop_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Text_Content_Var_Samp_Fields = {
  __typename?: 'text_content_var_samp_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Text_Content_Variance_Fields = {
  __typename?: 'text_content_variance_fields';
  original_language_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "translations" */
export type Translations = {
  __typename?: 'translations';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  language: Languages;
  language_id: Scalars['Int']['output'];
  seo_meta?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  text_content: Text_Content;
  text_content_id: Scalars['uuid']['output'];
  translation: Scalars['String']['output'];
};

/** aggregated selection of "translations" */
export type Translations_Aggregate = {
  __typename?: 'translations_aggregate';
  aggregate?: Maybe<Translations_Aggregate_Fields>;
  nodes: Array<Translations>;
};

export type Translations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Translations_Aggregate_Bool_Exp_Count>;
};

export type Translations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Translations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "translations" */
export type Translations_Aggregate_Fields = {
  __typename?: 'translations_aggregate_fields';
  avg?: Maybe<Translations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Translations_Max_Fields>;
  min?: Maybe<Translations_Min_Fields>;
  stddev?: Maybe<Translations_Stddev_Fields>;
  stddev_pop?: Maybe<Translations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Translations_Stddev_Samp_Fields>;
  sum?: Maybe<Translations_Sum_Fields>;
  var_pop?: Maybe<Translations_Var_Pop_Fields>;
  var_samp?: Maybe<Translations_Var_Samp_Fields>;
  variance?: Maybe<Translations_Variance_Fields>;
};


/** aggregate fields of "translations" */
export type Translations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "translations" */
export type Translations_Aggregate_Order_By = {
  avg?: InputMaybe<Translations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Translations_Max_Order_By>;
  min?: InputMaybe<Translations_Min_Order_By>;
  stddev?: InputMaybe<Translations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Translations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Translations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Translations_Sum_Order_By>;
  var_pop?: InputMaybe<Translations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Translations_Var_Samp_Order_By>;
  variance?: InputMaybe<Translations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "translations" */
export type Translations_Arr_Rel_Insert_Input = {
  data: Array<Translations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};

/** aggregate avg on columns */
export type Translations_Avg_Fields = {
  __typename?: 'translations_avg_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "translations" */
export type Translations_Avg_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "translations". All fields are combined with a logical 'AND'. */
export type Translations_Bool_Exp = {
  _and?: InputMaybe<Array<Translations_Bool_Exp>>;
  _not?: InputMaybe<Translations_Bool_Exp>;
  _or?: InputMaybe<Array<Translations_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<Languages_Bool_Exp>;
  language_id?: InputMaybe<Int_Comparison_Exp>;
  seo_meta?: InputMaybe<String_Comparison_Exp>;
  text_content?: InputMaybe<Text_Content_Bool_Exp>;
  text_content_id?: InputMaybe<Uuid_Comparison_Exp>;
  translation?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "translations" */
export enum Translations_Constraint {
  /** unique or primary key constraint on columns "text_content_id", "language_id" */
  TranslationsLanguageIdTextContentIdKey = 'translations_language_id_text_content_id_key',
  /** unique or primary key constraint on columns "id" */
  TranslationsPkey = 'translations_pkey'
}

/** input type for incrementing numeric columns in table "translations" */
export type Translations_Inc_Input = {
  language_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "translations" */
export type Translations_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Languages_Obj_Rel_Insert_Input>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  seo_meta?: InputMaybe<Scalars['String']['input']>;
  text_content?: InputMaybe<Text_Content_Obj_Rel_Insert_Input>;
  text_content_id?: InputMaybe<Scalars['uuid']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Translations_Max_Fields = {
  __typename?: 'translations_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  seo_meta?: Maybe<Scalars['String']['output']>;
  text_content_id?: Maybe<Scalars['uuid']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "translations" */
export type Translations_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  seo_meta?: InputMaybe<Order_By>;
  text_content_id?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Translations_Min_Fields = {
  __typename?: 'translations_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  seo_meta?: Maybe<Scalars['String']['output']>;
  text_content_id?: Maybe<Scalars['uuid']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "translations" */
export type Translations_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  seo_meta?: InputMaybe<Order_By>;
  text_content_id?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "translations" */
export type Translations_Mutation_Response = {
  __typename?: 'translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Translations>;
};

/** on_conflict condition type for table "translations" */
export type Translations_On_Conflict = {
  constraint: Translations_Constraint;
  update_columns?: Array<Translations_Update_Column>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** Ordering options when selecting data from "translations". */
export type Translations_Order_By = {
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Languages_Order_By>;
  language_id?: InputMaybe<Order_By>;
  seo_meta?: InputMaybe<Order_By>;
  text_content?: InputMaybe<Text_Content_Order_By>;
  text_content_id?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
};

/** primary key columns input for table: translations */
export type Translations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "translations" */
export enum Translations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  SeoMeta = 'seo_meta',
  /** column name */
  TextContentId = 'text_content_id',
  /** column name */
  Translation = 'translation'
}

/** input type for updating data in table "translations" */
export type Translations_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  seo_meta?: InputMaybe<Scalars['String']['input']>;
  text_content_id?: InputMaybe<Scalars['uuid']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Translations_Stddev_Fields = {
  __typename?: 'translations_stddev_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "translations" */
export type Translations_Stddev_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Translations_Stddev_Pop_Fields = {
  __typename?: 'translations_stddev_pop_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "translations" */
export type Translations_Stddev_Pop_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Translations_Stddev_Samp_Fields = {
  __typename?: 'translations_stddev_samp_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "translations" */
export type Translations_Stddev_Samp_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "translations" */
export type Translations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Translations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Translations_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  seo_meta?: InputMaybe<Scalars['String']['input']>;
  text_content_id?: InputMaybe<Scalars['uuid']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Translations_Sum_Fields = {
  __typename?: 'translations_sum_fields';
  language_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "translations" */
export type Translations_Sum_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** update columns of table "translations" */
export enum Translations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  SeoMeta = 'seo_meta',
  /** column name */
  TextContentId = 'text_content_id',
  /** column name */
  Translation = 'translation'
}

export type Translations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Translations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Translations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Translations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Translations_Var_Pop_Fields = {
  __typename?: 'translations_var_pop_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "translations" */
export type Translations_Var_Pop_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Translations_Var_Samp_Fields = {
  __typename?: 'translations_var_samp_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "translations" */
export type Translations_Var_Samp_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Translations_Variance_Fields = {
  __typename?: 'translations_variance_fields';
  language_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "translations" */
export type Translations_Variance_Order_By = {
  language_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "url_slug" */
export type Url_Slug = {
  __typename?: 'url_slug';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_active: Scalars['Boolean']['output'];
  property_id: Scalars['uuid']['output'];
  slug: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "url_slug" */
export type Url_Slug_Aggregate = {
  __typename?: 'url_slug_aggregate';
  aggregate?: Maybe<Url_Slug_Aggregate_Fields>;
  nodes: Array<Url_Slug>;
};

export type Url_Slug_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Url_Slug_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Url_Slug_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Url_Slug_Aggregate_Bool_Exp_Count>;
};

export type Url_Slug_Aggregate_Bool_Exp_Bool_And = {
  arguments: Url_Slug_Select_Column_Url_Slug_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Url_Slug_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Url_Slug_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Url_Slug_Select_Column_Url_Slug_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Url_Slug_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Url_Slug_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Url_Slug_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Url_Slug_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "url_slug" */
export type Url_Slug_Aggregate_Fields = {
  __typename?: 'url_slug_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Url_Slug_Max_Fields>;
  min?: Maybe<Url_Slug_Min_Fields>;
};


/** aggregate fields of "url_slug" */
export type Url_Slug_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Url_Slug_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "url_slug" */
export type Url_Slug_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Url_Slug_Max_Order_By>;
  min?: InputMaybe<Url_Slug_Min_Order_By>;
};

/** input type for inserting array relation for remote table "url_slug" */
export type Url_Slug_Arr_Rel_Insert_Input = {
  data: Array<Url_Slug_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Url_Slug_On_Conflict>;
};

/** Boolean expression to filter rows from the table "url_slug". All fields are combined with a logical 'AND'. */
export type Url_Slug_Bool_Exp = {
  _and?: InputMaybe<Array<Url_Slug_Bool_Exp>>;
  _not?: InputMaybe<Url_Slug_Bool_Exp>;
  _or?: InputMaybe<Array<Url_Slug_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "url_slug" */
export enum Url_Slug_Constraint {
  /** unique or primary key constraint on columns "id" */
  UrlSlugPkey = 'url_slug_pkey',
  /** unique or primary key constraint on columns "slug" */
  UrlSlugSlugKey = 'url_slug_slug_key'
}

/** input type for inserting data into table "url_slug" */
export type Url_Slug_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Url_Slug_Max_Fields = {
  __typename?: 'url_slug_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "url_slug" */
export type Url_Slug_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Url_Slug_Min_Fields = {
  __typename?: 'url_slug_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "url_slug" */
export type Url_Slug_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "url_slug" */
export type Url_Slug_Mutation_Response = {
  __typename?: 'url_slug_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Url_Slug>;
};

/** on_conflict condition type for table "url_slug" */
export type Url_Slug_On_Conflict = {
  constraint: Url_Slug_Constraint;
  update_columns?: Array<Url_Slug_Update_Column>;
  where?: InputMaybe<Url_Slug_Bool_Exp>;
};

/** Ordering options when selecting data from "url_slug". */
export type Url_Slug_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: url_slug */
export type Url_Slug_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "url_slug" */
export enum Url_Slug_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "url_slug_aggregate_bool_exp_bool_and_arguments_columns" columns of table "url_slug" */
export enum Url_Slug_Select_Column_Url_Slug_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** select "url_slug_aggregate_bool_exp_bool_or_arguments_columns" columns of table "url_slug" */
export enum Url_Slug_Select_Column_Url_Slug_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "url_slug" */
export type Url_Slug_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "url_slug" */
export type Url_Slug_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Url_Slug_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Url_Slug_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "url_slug" */
export enum Url_Slug_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Url_Slug_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Url_Slug_Set_Input>;
  /** filter the rows which have to be updated */
  where: Url_Slug_Bool_Exp;
};

/** columns and relationships of "user_card_information" */
export type User_Card_Information = {
  __typename?: 'user_card_information';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  administrative_area?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  credit_debit_card_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_card_number?: Maybe<Scalars['String']['output']>;
  expiration_month?: Maybe<Scalars['String']['output']>;
  expiration_year?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  hidden_card_number?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  key_id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numeric_card_type?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "user_card_information" */
export type User_Card_Information_Aggregate = {
  __typename?: 'user_card_information_aggregate';
  aggregate?: Maybe<User_Card_Information_Aggregate_Fields>;
  nodes: Array<User_Card_Information>;
};

/** aggregate fields of "user_card_information" */
export type User_Card_Information_Aggregate_Fields = {
  __typename?: 'user_card_information_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Card_Information_Max_Fields>;
  min?: Maybe<User_Card_Information_Min_Fields>;
};


/** aggregate fields of "user_card_information" */
export type User_Card_Information_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Card_Information_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_card_information". All fields are combined with a logical 'AND'. */
export type User_Card_Information_Bool_Exp = {
  _and?: InputMaybe<Array<User_Card_Information_Bool_Exp>>;
  _not?: InputMaybe<User_Card_Information_Bool_Exp>;
  _or?: InputMaybe<Array<User_Card_Information_Bool_Exp>>;
  address1?: InputMaybe<String_Comparison_Exp>;
  address2?: InputMaybe<String_Comparison_Exp>;
  administrative_area?: InputMaybe<String_Comparison_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  credit_debit_card_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_card_number?: InputMaybe<String_Comparison_Exp>;
  expiration_month?: InputMaybe<String_Comparison_Exp>;
  expiration_year?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  hidden_card_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  numeric_card_type?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_card_information" */
export enum User_Card_Information_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCardInformationPkey = 'user_card_information_pkey'
}

/** input type for inserting data into table "user_card_information" */
export type User_Card_Information_Insert_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  administrative_area?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  credit_debit_card_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_card_number?: InputMaybe<Scalars['String']['input']>;
  expiration_month?: InputMaybe<Scalars['String']['input']>;
  expiration_year?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hidden_card_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key_id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numeric_card_type?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Card_Information_Max_Fields = {
  __typename?: 'user_card_information_max_fields';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  administrative_area?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  credit_debit_card_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_card_number?: Maybe<Scalars['String']['output']>;
  expiration_month?: Maybe<Scalars['String']['output']>;
  expiration_year?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  hidden_card_number?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key_id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numeric_card_type?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Card_Information_Min_Fields = {
  __typename?: 'user_card_information_min_fields';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  administrative_area?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  credit_debit_card_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_card_number?: Maybe<Scalars['String']['output']>;
  expiration_month?: Maybe<Scalars['String']['output']>;
  expiration_year?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  hidden_card_number?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key_id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numeric_card_type?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_card_information" */
export type User_Card_Information_Mutation_Response = {
  __typename?: 'user_card_information_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Card_Information>;
};

/** on_conflict condition type for table "user_card_information" */
export type User_Card_Information_On_Conflict = {
  constraint: User_Card_Information_Constraint;
  update_columns?: Array<User_Card_Information_Update_Column>;
  where?: InputMaybe<User_Card_Information_Bool_Exp>;
};

/** Ordering options when selecting data from "user_card_information". */
export type User_Card_Information_Order_By = {
  address1?: InputMaybe<Order_By>;
  address2?: InputMaybe<Order_By>;
  administrative_area?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  credit_debit_card_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_card_number?: InputMaybe<Order_By>;
  expiration_month?: InputMaybe<Order_By>;
  expiration_year?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  hidden_card_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  numeric_card_type?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_card_information */
export type User_Card_Information_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_card_information" */
export enum User_Card_Information_Select_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  AdministrativeArea = 'administrative_area',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreditDebitCardId = 'credit_debit_card_id',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedCardNumber = 'encrypted_card_number',
  /** column name */
  ExpirationMonth = 'expiration_month',
  /** column name */
  ExpirationYear = 'expiration_year',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HiddenCardNumber = 'hidden_card_number',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locality = 'locality',
  /** column name */
  Name = 'name',
  /** column name */
  NumericCardType = 'numeric_card_type',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PostalCode = 'postal_code'
}

/** input type for updating data in table "user_card_information" */
export type User_Card_Information_Set_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  administrative_area?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  credit_debit_card_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_card_number?: InputMaybe<Scalars['String']['input']>;
  expiration_month?: InputMaybe<Scalars['String']['input']>;
  expiration_year?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hidden_card_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key_id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numeric_card_type?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_card_information" */
export type User_Card_Information_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Card_Information_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Card_Information_Stream_Cursor_Value_Input = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  administrative_area?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  credit_debit_card_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_card_number?: InputMaybe<Scalars['String']['input']>;
  expiration_month?: InputMaybe<Scalars['String']['input']>;
  expiration_year?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hidden_card_number?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key_id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numeric_card_type?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_card_information" */
export enum User_Card_Information_Update_Column {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  AdministrativeArea = 'administrative_area',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreditDebitCardId = 'credit_debit_card_id',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedCardNumber = 'encrypted_card_number',
  /** column name */
  ExpirationMonth = 'expiration_month',
  /** column name */
  ExpirationYear = 'expiration_year',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HiddenCardNumber = 'hidden_card_number',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locality = 'locality',
  /** column name */
  Name = 'name',
  /** column name */
  NumericCardType = 'numeric_card_type',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PostalCode = 'postal_code'
}

export type User_Card_Information_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Card_Information_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Card_Information_Bool_Exp;
};

/** columns and relationships of "user_filters" */
export type User_Filters = {
  __typename?: 'user_filters';
  city: Array<City>;
  city_id?: Maybe<Scalars['uuid']['output']>;
  city_zone: Array<City_Zone>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin: Array<Property_Origin_Type>;
  property_origin_type?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  search_session: Search_Sessions;
  search_session_id: Scalars['uuid']['output'];
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
  status_property: Array<Property_Status>;
  type_property: Array<Property_Type>;
};


/** columns and relationships of "user_filters" */
export type User_FiltersCityArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp_Remote_Rel_User_Filterscity>;
};


/** columns and relationships of "user_filters" */
export type User_FiltersCity_ZoneArgs = {
  distinct_on?: InputMaybe<Array<City_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<City_Zone_Order_By>>;
  where?: InputMaybe<City_Zone_Bool_Exp_Remote_Rel_User_Filterscity_Zone>;
};


/** columns and relationships of "user_filters" */
export type User_FiltersProperty_OriginArgs = {
  distinct_on?: InputMaybe<Array<Property_Origin_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Origin_Type_Order_By>>;
  where?: InputMaybe<Property_Origin_Type_Bool_Exp_Remote_Rel_User_Filtersproperty_Origin>;
};


/** columns and relationships of "user_filters" */
export type User_FiltersStatus_PropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Status_Order_By>>;
  where?: InputMaybe<Property_Status_Bool_Exp_Remote_Rel_User_Filtersstatus_Property>;
};


/** columns and relationships of "user_filters" */
export type User_FiltersType_PropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  where?: InputMaybe<Property_Type_Bool_Exp_Remote_Rel_User_Filterstype_Property>;
};

/** aggregated selection of "user_filters" */
export type User_Filters_Aggregate = {
  __typename?: 'user_filters_aggregate';
  aggregate?: Maybe<User_Filters_Aggregate_Fields>;
  nodes: Array<User_Filters>;
};

export type User_Filters_Aggregate_Bool_Exp = {
  avg?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<User_Filters_Aggregate_Bool_Exp_Var_Samp>;
};

export type User_Filters_Aggregate_Bool_Exp_Avg = {
  arguments: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Corr = {
  arguments: User_Filters_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Corr_Arguments = {
  X: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type User_Filters_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Filters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: User_Filters_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type User_Filters_Aggregate_Bool_Exp_Max = {
  arguments: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Min = {
  arguments: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Sum = {
  arguments: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Filters_Aggregate_Bool_Exp_Var_Samp = {
  arguments: User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Filters_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "user_filters" */
export type User_Filters_Aggregate_Fields = {
  __typename?: 'user_filters_aggregate_fields';
  avg?: Maybe<User_Filters_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Filters_Max_Fields>;
  min?: Maybe<User_Filters_Min_Fields>;
  stddev?: Maybe<User_Filters_Stddev_Fields>;
  stddev_pop?: Maybe<User_Filters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Filters_Stddev_Samp_Fields>;
  sum?: Maybe<User_Filters_Sum_Fields>;
  var_pop?: Maybe<User_Filters_Var_Pop_Fields>;
  var_samp?: Maybe<User_Filters_Var_Samp_Fields>;
  variance?: Maybe<User_Filters_Variance_Fields>;
};


/** aggregate fields of "user_filters" */
export type User_Filters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Filters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_filters" */
export type User_Filters_Aggregate_Order_By = {
  avg?: InputMaybe<User_Filters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Filters_Max_Order_By>;
  min?: InputMaybe<User_Filters_Min_Order_By>;
  stddev?: InputMaybe<User_Filters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Filters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Filters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Filters_Sum_Order_By>;
  var_pop?: InputMaybe<User_Filters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Filters_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Filters_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_filters" */
export type User_Filters_Arr_Rel_Insert_Input = {
  data: Array<User_Filters_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Filters_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Filters_Avg_Fields = {
  __typename?: 'user_filters_avg_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_filters" */
export type User_Filters_Avg_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_filters". All fields are combined with a logical 'AND'. */
export type User_Filters_Bool_Exp = {
  _and?: InputMaybe<Array<User_Filters_Bool_Exp>>;
  _not?: InputMaybe<User_Filters_Bool_Exp>;
  _or?: InputMaybe<Array<User_Filters_Bool_Exp>>;
  city_id?: InputMaybe<Uuid_Comparison_Exp>;
  city_zone_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  price_max?: InputMaybe<Float8_Comparison_Exp>;
  price_min?: InputMaybe<Float8_Comparison_Exp>;
  property_composition_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_origin_type?: InputMaybe<String_Comparison_Exp>;
  property_status?: InputMaybe<String_Comparison_Exp>;
  property_type?: InputMaybe<String_Comparison_Exp>;
  search_session?: InputMaybe<Search_Sessions_Bool_Exp>;
  search_session_id?: InputMaybe<Uuid_Comparison_Exp>;
  sq_max?: InputMaybe<Float8_Comparison_Exp>;
  sq_min?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_filters" */
export enum User_Filters_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFiltersPkey = 'user_filters_pkey'
}

/** columns and relationships of "user_filters_features" */
export type User_Filters_Features = {
  __typename?: 'user_filters_features';
  feature: Array<Feature>;
  feature_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  user_filter: User_Filters;
  user_filters_id: Scalars['uuid']['output'];
};


/** columns and relationships of "user_filters_features" */
export type User_Filters_FeaturesFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp_Remote_Rel_User_Filters_Featuresfeature>;
};

/** aggregated selection of "user_filters_features" */
export type User_Filters_Features_Aggregate = {
  __typename?: 'user_filters_features_aggregate';
  aggregate?: Maybe<User_Filters_Features_Aggregate_Fields>;
  nodes: Array<User_Filters_Features>;
};

/** aggregate fields of "user_filters_features" */
export type User_Filters_Features_Aggregate_Fields = {
  __typename?: 'user_filters_features_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Filters_Features_Max_Fields>;
  min?: Maybe<User_Filters_Features_Min_Fields>;
};


/** aggregate fields of "user_filters_features" */
export type User_Filters_Features_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Filters_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_filters_features". All fields are combined with a logical 'AND'. */
export type User_Filters_Features_Bool_Exp = {
  _and?: InputMaybe<Array<User_Filters_Features_Bool_Exp>>;
  _not?: InputMaybe<User_Filters_Features_Bool_Exp>;
  _or?: InputMaybe<Array<User_Filters_Features_Bool_Exp>>;
  feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_filter?: InputMaybe<User_Filters_Bool_Exp>;
  user_filters_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_filters_features" */
export enum User_Filters_Features_Constraint {
  /** unique or primary key constraint on columns "feature_id", "user_filters_id" */
  UserFiltersFeaturesFeatureIdUserFiltersIdKey = 'user_filters_features_feature_id_user_filters_id_key',
  /** unique or primary key constraint on columns "id" */
  UserFiltersFeaturesPkey = 'user_filters_features_pkey'
}

/** input type for inserting data into table "user_filters_features" */
export type User_Filters_Features_Insert_Input = {
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_filter?: InputMaybe<User_Filters_Obj_Rel_Insert_Input>;
  user_filters_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Filters_Features_Max_Fields = {
  __typename?: 'user_filters_features_max_fields';
  feature_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_filters_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type User_Filters_Features_Min_Fields = {
  __typename?: 'user_filters_features_min_fields';
  feature_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_filters_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "user_filters_features" */
export type User_Filters_Features_Mutation_Response = {
  __typename?: 'user_filters_features_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Filters_Features>;
};

/** on_conflict condition type for table "user_filters_features" */
export type User_Filters_Features_On_Conflict = {
  constraint: User_Filters_Features_Constraint;
  update_columns?: Array<User_Filters_Features_Update_Column>;
  where?: InputMaybe<User_Filters_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "user_filters_features". */
export type User_Filters_Features_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_filter?: InputMaybe<User_Filters_Order_By>;
  user_filters_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_filters_features */
export type User_Filters_Features_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_filters_features" */
export enum User_Filters_Features_Select_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserFiltersId = 'user_filters_id'
}

/** input type for updating data in table "user_filters_features" */
export type User_Filters_Features_Set_Input = {
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_filters_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_filters_features" */
export type User_Filters_Features_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Filters_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Filters_Features_Stream_Cursor_Value_Input = {
  feature_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_filters_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_filters_features" */
export enum User_Filters_Features_Update_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserFiltersId = 'user_filters_id'
}

export type User_Filters_Features_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Filters_Features_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Filters_Features_Bool_Exp;
};

/** input type for incrementing numeric columns in table "user_filters" */
export type User_Filters_Inc_Input = {
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "user_filters" */
export type User_Filters_Insert_Input = {
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
  search_session?: InputMaybe<Search_Sessions_Obj_Rel_Insert_Input>;
  search_session_id?: InputMaybe<Scalars['uuid']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type User_Filters_Max_Fields = {
  __typename?: 'user_filters_max_fields';
  city_id?: Maybe<Scalars['uuid']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin_type?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
  search_session_id?: Maybe<Scalars['uuid']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "user_filters" */
export type User_Filters_Max_Order_By = {
  city_id?: InputMaybe<Order_By>;
  city_zone_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  property_origin_type?: InputMaybe<Order_By>;
  property_status?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  search_session_id?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Filters_Min_Fields = {
  __typename?: 'user_filters_min_fields';
  city_id?: Maybe<Scalars['uuid']['output']>;
  city_zone_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  property_composition_id?: Maybe<Scalars['uuid']['output']>;
  property_origin_type?: Maybe<Scalars['String']['output']>;
  property_status?: Maybe<Scalars['String']['output']>;
  property_type?: Maybe<Scalars['String']['output']>;
  search_session_id?: Maybe<Scalars['uuid']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "user_filters" */
export type User_Filters_Min_Order_By = {
  city_id?: InputMaybe<Order_By>;
  city_zone_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  property_origin_type?: InputMaybe<Order_By>;
  property_status?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  search_session_id?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_filters" */
export type User_Filters_Mutation_Response = {
  __typename?: 'user_filters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Filters>;
};

/** input type for inserting object relation for remote table "user_filters" */
export type User_Filters_Obj_Rel_Insert_Input = {
  data: User_Filters_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Filters_On_Conflict>;
};

/** on_conflict condition type for table "user_filters" */
export type User_Filters_On_Conflict = {
  constraint: User_Filters_Constraint;
  update_columns?: Array<User_Filters_Update_Column>;
  where?: InputMaybe<User_Filters_Bool_Exp>;
};

/** Ordering options when selecting data from "user_filters". */
export type User_Filters_Order_By = {
  city_id?: InputMaybe<Order_By>;
  city_zone_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  property_composition_id?: InputMaybe<Order_By>;
  property_origin_type?: InputMaybe<Order_By>;
  property_status?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
  search_session?: InputMaybe<Search_Sessions_Order_By>;
  search_session_id?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_filters */
export type User_Filters_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_filters" */
export enum User_Filters_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CityZoneId = 'city_zone_id',
  /** column name */
  Id = 'id',
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  PropertyCompositionId = 'property_composition_id',
  /** column name */
  PropertyOriginType = 'property_origin_type',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  SearchSessionId = 'search_session_id',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_avg_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_corr_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_max_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_min_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_sum_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** select "user_filters_aggregate_bool_exp_var_samp_arguments_columns" columns of table "user_filters" */
export enum User_Filters_Select_Column_User_Filters_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

/** input type for updating data in table "user_filters" */
export type User_Filters_Set_Input = {
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
  search_session_id?: InputMaybe<Scalars['uuid']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type User_Filters_Stddev_Fields = {
  __typename?: 'user_filters_stddev_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_filters" */
export type User_Filters_Stddev_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Filters_Stddev_Pop_Fields = {
  __typename?: 'user_filters_stddev_pop_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_filters" */
export type User_Filters_Stddev_Pop_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Filters_Stddev_Samp_Fields = {
  __typename?: 'user_filters_stddev_samp_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_filters" */
export type User_Filters_Stddev_Samp_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_filters" */
export type User_Filters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Filters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Filters_Stream_Cursor_Value_Input = {
  city_id?: InputMaybe<Scalars['uuid']['input']>;
  city_zone_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price_max?: InputMaybe<Scalars['float8']['input']>;
  price_min?: InputMaybe<Scalars['float8']['input']>;
  property_composition_id?: InputMaybe<Scalars['uuid']['input']>;
  property_origin_type?: InputMaybe<Scalars['String']['input']>;
  property_status?: InputMaybe<Scalars['String']['input']>;
  property_type?: InputMaybe<Scalars['String']['input']>;
  search_session_id?: InputMaybe<Scalars['uuid']['input']>;
  sq_max?: InputMaybe<Scalars['float8']['input']>;
  sq_min?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type User_Filters_Sum_Fields = {
  __typename?: 'user_filters_sum_fields';
  price_max?: Maybe<Scalars['float8']['output']>;
  price_min?: Maybe<Scalars['float8']['output']>;
  sq_max?: Maybe<Scalars['float8']['output']>;
  sq_min?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "user_filters" */
export type User_Filters_Sum_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** update columns of table "user_filters" */
export enum User_Filters_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CityZoneId = 'city_zone_id',
  /** column name */
  Id = 'id',
  /** column name */
  PriceMax = 'price_max',
  /** column name */
  PriceMin = 'price_min',
  /** column name */
  PropertyCompositionId = 'property_composition_id',
  /** column name */
  PropertyOriginType = 'property_origin_type',
  /** column name */
  PropertyStatus = 'property_status',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  SearchSessionId = 'search_session_id',
  /** column name */
  SqMax = 'sq_max',
  /** column name */
  SqMin = 'sq_min'
}

export type User_Filters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Filters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Filters_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Filters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Filters_Var_Pop_Fields = {
  __typename?: 'user_filters_var_pop_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_filters" */
export type User_Filters_Var_Pop_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Filters_Var_Samp_Fields = {
  __typename?: 'user_filters_var_samp_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_filters" */
export type User_Filters_Var_Samp_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Filters_Variance_Fields = {
  __typename?: 'user_filters_variance_fields';
  price_max?: Maybe<Scalars['Float']['output']>;
  price_min?: Maybe<Scalars['Float']['output']>;
  sq_max?: Maybe<Scalars['Float']['output']>;
  sq_min?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_filters" */
export type User_Filters_Variance_Order_By = {
  price_max?: InputMaybe<Order_By>;
  price_min?: InputMaybe<Order_By>;
  sq_max?: InputMaybe<Order_By>;
  sq_min?: InputMaybe<Order_By>;
};

/** Save all membership packages paid by user */
export type User_Membership = {
  __typename?: 'user_membership';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  end_date?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  membership_package: Membership_Package;
  package_id: Scalars['uuid']['output'];
  start_date?: Maybe<Scalars['date']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_membership" */
export type User_Membership_Aggregate = {
  __typename?: 'user_membership_aggregate';
  aggregate?: Maybe<User_Membership_Aggregate_Fields>;
  nodes: Array<User_Membership>;
};

/** aggregate fields of "user_membership" */
export type User_Membership_Aggregate_Fields = {
  __typename?: 'user_membership_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Membership_Max_Fields>;
  min?: Maybe<User_Membership_Min_Fields>;
};


/** aggregate fields of "user_membership" */
export type User_Membership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Membership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_membership". All fields are combined with a logical 'AND'. */
export type User_Membership_Bool_Exp = {
  _and?: InputMaybe<Array<User_Membership_Bool_Exp>>;
  _not?: InputMaybe<User_Membership_Bool_Exp>;
  _or?: InputMaybe<Array<User_Membership_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership_package?: InputMaybe<Membership_Package_Bool_Exp>;
  package_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_membership" */
export enum User_Membership_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserMembershipPkey = 'user_membership_pkey'
}

/** input type for inserting data into table "user_membership" */
export type User_Membership_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  membership_package?: InputMaybe<Membership_Package_Obj_Rel_Insert_Input>;
  package_id?: InputMaybe<Scalars['uuid']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Membership_Max_Fields = {
  __typename?: 'user_membership_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  package_id?: Maybe<Scalars['uuid']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type User_Membership_Min_Fields = {
  __typename?: 'user_membership_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  package_id?: Maybe<Scalars['uuid']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "user_membership" */
export type User_Membership_Mutation_Response = {
  __typename?: 'user_membership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Membership>;
};

/** on_conflict condition type for table "user_membership" */
export type User_Membership_On_Conflict = {
  constraint: User_Membership_Constraint;
  update_columns?: Array<User_Membership_Update_Column>;
  where?: InputMaybe<User_Membership_Bool_Exp>;
};

/** Ordering options when selecting data from "user_membership". */
export type User_Membership_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership_package?: InputMaybe<Membership_Package_Order_By>;
  package_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_membership */
export type User_Membership_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_membership" */
export enum User_Membership_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_membership" */
export type User_Membership_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  package_id?: InputMaybe<Scalars['uuid']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_membership" */
export type User_Membership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Membership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Membership_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  package_id?: InputMaybe<Scalars['uuid']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_membership" */
export enum User_Membership_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  PackageId = 'package_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Membership_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Membership_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Membership_Bool_Exp;
};

/** general user platform preferences */
export type User_Preferences = {
  __typename?: 'user_preferences';
  currency?: Maybe<Scalars['String']['output']>;
  email_notifications: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  language?: Maybe<Scalars['String']['output']>;
  push_notifications: Scalars['Boolean']['output'];
  theme?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_preferences" */
export type User_Preferences_Aggregate = {
  __typename?: 'user_preferences_aggregate';
  aggregate?: Maybe<User_Preferences_Aggregate_Fields>;
  nodes: Array<User_Preferences>;
};

export type User_Preferences_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Preferences_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Preferences_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Preferences_Aggregate_Bool_Exp_Count>;
};

export type User_Preferences_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Preferences_Select_Column_User_Preferences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Preferences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Preferences_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Preferences_Select_Column_User_Preferences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Preferences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Preferences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Preferences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_preferences" */
export type User_Preferences_Aggregate_Fields = {
  __typename?: 'user_preferences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Preferences_Max_Fields>;
  min?: Maybe<User_Preferences_Min_Fields>;
};


/** aggregate fields of "user_preferences" */
export type User_Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_preferences" */
export type User_Preferences_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Preferences_Max_Order_By>;
  min?: InputMaybe<User_Preferences_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_preferences" */
export type User_Preferences_Arr_Rel_Insert_Input = {
  data: Array<User_Preferences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_preferences". All fields are combined with a logical 'AND'. */
export type User_Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<User_Preferences_Bool_Exp>>;
  _not?: InputMaybe<User_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<User_Preferences_Bool_Exp>>;
  currency?: InputMaybe<String_Comparison_Exp>;
  email_notifications?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  push_notifications?: InputMaybe<Boolean_Comparison_Exp>;
  theme?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_preferences" */
export enum User_Preferences_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPreferencesPkey = 'user_preferences_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserPreferencesUserIdKey = 'user_preferences_user_id_key'
}

/** input type for inserting data into table "user_preferences" */
export type User_Preferences_Insert_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  email_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  push_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Preferences_Max_Fields = {
  __typename?: 'user_preferences_max_fields';
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_preferences" */
export type User_Preferences_Max_Order_By = {
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Preferences_Min_Fields = {
  __typename?: 'user_preferences_min_fields';
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_preferences" */
export type User_Preferences_Min_Order_By = {
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_preferences" */
export type User_Preferences_Mutation_Response = {
  __typename?: 'user_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Preferences>;
};

/** on_conflict condition type for table "user_preferences" */
export type User_Preferences_On_Conflict = {
  constraint: User_Preferences_Constraint;
  update_columns?: Array<User_Preferences_Update_Column>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "user_preferences". */
export type User_Preferences_Order_By = {
  currency?: InputMaybe<Order_By>;
  email_notifications?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  push_notifications?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_preferences */
export type User_Preferences_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_preferences" */
export enum User_Preferences_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  EmailNotifications = 'email_notifications',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  PushNotifications = 'push_notifications',
  /** column name */
  Theme = 'theme',
  /** column name */
  UserId = 'user_id'
}

/** select "user_preferences_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_preferences" */
export enum User_Preferences_Select_Column_User_Preferences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EmailNotifications = 'email_notifications',
  /** column name */
  PushNotifications = 'push_notifications'
}

/** select "user_preferences_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_preferences" */
export enum User_Preferences_Select_Column_User_Preferences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EmailNotifications = 'email_notifications',
  /** column name */
  PushNotifications = 'push_notifications'
}

/** input type for updating data in table "user_preferences" */
export type User_Preferences_Set_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  email_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  push_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_preferences" */
export type User_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Preferences_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars['String']['input']>;
  email_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  push_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_preferences" */
export enum User_Preferences_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  EmailNotifications = 'email_notifications',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  PushNotifications = 'push_notifications',
  /** column name */
  Theme = 'theme',
  /** column name */
  UserId = 'user_id'
}

export type User_Preferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Preferences_Bool_Exp;
};

/** columns and relationships of "user_property_notifiation" */
export type User_Property_Notifiation = {
  __typename?: 'user_property_notifiation';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  natural_language_query?: Maybe<Natural_Language_Queries>;
  /** An object relationship */
  notification?: Maybe<Notification>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property: Array<Property>;
  property_id: Scalars['uuid']['output'];
  query_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "user_property_notifiation" */
export type User_Property_NotifiationPropertyArgs = {
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Property_Order_By>>;
  where?: InputMaybe<Property_Bool_Exp_Remote_Rel_User_Property_Notifiationproperty>;
};

/** aggregated selection of "user_property_notifiation" */
export type User_Property_Notifiation_Aggregate = {
  __typename?: 'user_property_notifiation_aggregate';
  aggregate?: Maybe<User_Property_Notifiation_Aggregate_Fields>;
  nodes: Array<User_Property_Notifiation>;
};

export type User_Property_Notifiation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Property_Notifiation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Property_Notifiation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Property_Notifiation_Aggregate_Bool_Exp_Count>;
};

export type User_Property_Notifiation_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Property_Notifiation_Select_Column_User_Property_Notifiation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Property_Notifiation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Property_Notifiation_Select_Column_User_Property_Notifiation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Property_Notifiation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_property_notifiation" */
export type User_Property_Notifiation_Aggregate_Fields = {
  __typename?: 'user_property_notifiation_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Property_Notifiation_Max_Fields>;
  min?: Maybe<User_Property_Notifiation_Min_Fields>;
};


/** aggregate fields of "user_property_notifiation" */
export type User_Property_Notifiation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Property_Notifiation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_property_notifiation" */
export type User_Property_Notifiation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Property_Notifiation_Max_Order_By>;
  min?: InputMaybe<User_Property_Notifiation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_property_notifiation" */
export type User_Property_Notifiation_Arr_Rel_Insert_Input = {
  data: Array<User_Property_Notifiation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Property_Notifiation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_property_notifiation". All fields are combined with a logical 'AND'. */
export type User_Property_Notifiation_Bool_Exp = {
  _and?: InputMaybe<Array<User_Property_Notifiation_Bool_Exp>>;
  _not?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
  _or?: InputMaybe<Array<User_Property_Notifiation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  natural_language_query?: InputMaybe<Natural_Language_Queries_Bool_Exp>;
  notification?: InputMaybe<Notification_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  query_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_property_notifiation" */
export enum User_Property_Notifiation_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserNotifiationsPkey = 'user_notifiations_pkey'
}

/** input type for inserting data into table "user_property_notifiation" */
export type User_Property_Notifiation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  natural_language_query?: InputMaybe<Natural_Language_Queries_Obj_Rel_Insert_Input>;
  notification?: InputMaybe<Notification_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  query_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type User_Property_Notifiation_Max_Fields = {
  __typename?: 'user_property_notifiation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  query_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "user_property_notifiation" */
export type User_Property_Notifiation_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  query_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Property_Notifiation_Min_Fields = {
  __typename?: 'user_property_notifiation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  query_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "user_property_notifiation" */
export type User_Property_Notifiation_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  query_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_property_notifiation" */
export type User_Property_Notifiation_Mutation_Response = {
  __typename?: 'user_property_notifiation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Property_Notifiation>;
};

/** on_conflict condition type for table "user_property_notifiation" */
export type User_Property_Notifiation_On_Conflict = {
  constraint: User_Property_Notifiation_Constraint;
  update_columns?: Array<User_Property_Notifiation_Update_Column>;
  where?: InputMaybe<User_Property_Notifiation_Bool_Exp>;
};

/** Ordering options when selecting data from "user_property_notifiation". */
export type User_Property_Notifiation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  natural_language_query?: InputMaybe<Natural_Language_Queries_Order_By>;
  notification?: InputMaybe<Notification_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  query_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_property_notifiation */
export type User_Property_Notifiation_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_property_notifiation" */
export enum User_Property_Notifiation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  QueryId = 'query_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "user_property_notifiation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_property_notifiation" */
export enum User_Property_Notifiation_Select_Column_User_Property_Notifiation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "user_property_notifiation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_property_notifiation" */
export enum User_Property_Notifiation_Select_Column_User_Property_Notifiation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "user_property_notifiation" */
export type User_Property_Notifiation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  query_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user_property_notifiation" */
export type User_Property_Notifiation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Property_Notifiation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Property_Notifiation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  query_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user_property_notifiation" */
export enum User_Property_Notifiation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  QueryId = 'query_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Property_Notifiation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Property_Notifiation_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Property_Notifiation_Bool_Exp;
};

/** saved properties of a user */
export type User_Saved_Properties = {
  __typename?: 'user_saved_properties';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_deleted: Scalars['Boolean']['output'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user: Array<Users>;
  user_aggregate: Users_Aggregate;
  user_id: Scalars['uuid']['output'];
};


/** saved properties of a user */
export type User_Saved_PropertiesUserArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** saved properties of a user */
export type User_Saved_PropertiesUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "user_saved_properties" */
export type User_Saved_Properties_Aggregate = {
  __typename?: 'user_saved_properties_aggregate';
  aggregate?: Maybe<User_Saved_Properties_Aggregate_Fields>;
  nodes: Array<User_Saved_Properties>;
};

export type User_Saved_Properties_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Saved_Properties_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Saved_Properties_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Saved_Properties_Aggregate_Bool_Exp_Count>;
};

export type User_Saved_Properties_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Saved_Properties_Select_Column_User_Saved_Properties_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Saved_Properties_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Saved_Properties_Select_Column_User_Saved_Properties_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Saved_Properties_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_saved_properties" */
export type User_Saved_Properties_Aggregate_Fields = {
  __typename?: 'user_saved_properties_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Saved_Properties_Max_Fields>;
  min?: Maybe<User_Saved_Properties_Min_Fields>;
};


/** aggregate fields of "user_saved_properties" */
export type User_Saved_Properties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Saved_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_saved_properties" */
export type User_Saved_Properties_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Saved_Properties_Max_Order_By>;
  min?: InputMaybe<User_Saved_Properties_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_saved_properties" */
export type User_Saved_Properties_Arr_Rel_Insert_Input = {
  data: Array<User_Saved_Properties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Saved_Properties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_saved_properties". All fields are combined with a logical 'AND'. */
export type User_Saved_Properties_Bool_Exp = {
  _and?: InputMaybe<Array<User_Saved_Properties_Bool_Exp>>;
  _not?: InputMaybe<User_Saved_Properties_Bool_Exp>;
  _or?: InputMaybe<Array<User_Saved_Properties_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_saved_properties" */
export enum User_Saved_Properties_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSavedPropertiesIdKey = 'user_saved_properties_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSavedPropertiesPkey = 'user_saved_properties_pkey'
}

/** input type for inserting data into table "user_saved_properties" */
export type User_Saved_Properties_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Saved_Properties_Max_Fields = {
  __typename?: 'user_saved_properties_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_saved_properties" */
export type User_Saved_Properties_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Saved_Properties_Min_Fields = {
  __typename?: 'user_saved_properties_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_saved_properties" */
export type User_Saved_Properties_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_saved_properties" */
export type User_Saved_Properties_Mutation_Response = {
  __typename?: 'user_saved_properties_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Saved_Properties>;
};

/** on_conflict condition type for table "user_saved_properties" */
export type User_Saved_Properties_On_Conflict = {
  constraint: User_Saved_Properties_Constraint;
  update_columns?: Array<User_Saved_Properties_Update_Column>;
  where?: InputMaybe<User_Saved_Properties_Bool_Exp>;
};

/** Ordering options when selecting data from "user_saved_properties". */
export type User_Saved_Properties_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_saved_properties */
export type User_Saved_Properties_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_saved_properties" */
export enum User_Saved_Properties_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_saved_properties_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_saved_properties" */
export enum User_Saved_Properties_Select_Column_User_Saved_Properties_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "user_saved_properties_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_saved_properties" */
export enum User_Saved_Properties_Select_Column_User_Saved_Properties_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "user_saved_properties" */
export type User_Saved_Properties_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_saved_properties" */
export type User_Saved_Properties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Saved_Properties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Saved_Properties_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_saved_properties" */
export enum User_Saved_Properties_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Saved_Properties_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Saved_Properties_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Saved_Properties_Bool_Exp;
};

/** columns and relationships of "user_seen_property" */
export type User_Seen_Property = {
  __typename?: 'user_seen_property';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  property_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_seen_property" */
export type User_Seen_Property_Aggregate = {
  __typename?: 'user_seen_property_aggregate';
  aggregate?: Maybe<User_Seen_Property_Aggregate_Fields>;
  nodes: Array<User_Seen_Property>;
};

export type User_Seen_Property_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Seen_Property_Aggregate_Bool_Exp_Count>;
};

export type User_Seen_Property_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Seen_Property_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_seen_property" */
export type User_Seen_Property_Aggregate_Fields = {
  __typename?: 'user_seen_property_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Seen_Property_Max_Fields>;
  min?: Maybe<User_Seen_Property_Min_Fields>;
};


/** aggregate fields of "user_seen_property" */
export type User_Seen_Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Seen_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_seen_property" */
export type User_Seen_Property_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Seen_Property_Max_Order_By>;
  min?: InputMaybe<User_Seen_Property_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_seen_property" */
export type User_Seen_Property_Arr_Rel_Insert_Input = {
  data: Array<User_Seen_Property_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Seen_Property_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_seen_property". All fields are combined with a logical 'AND'. */
export type User_Seen_Property_Bool_Exp = {
  _and?: InputMaybe<Array<User_Seen_Property_Bool_Exp>>;
  _not?: InputMaybe<User_Seen_Property_Bool_Exp>;
  _or?: InputMaybe<Array<User_Seen_Property_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_seen_property" */
export enum User_Seen_Property_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSeenPropertyPkey = 'user_seen_property_pkey',
  /** unique or primary key constraint on columns "user_id", "property_id" */
  UserSeenPropertyUserIdPropertyIdKey = 'user_seen_property_user_id_property_id_key'
}

/** input type for inserting data into table "user_seen_property" */
export type User_Seen_Property_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Seen_Property_Max_Fields = {
  __typename?: 'user_seen_property_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_seen_property" */
export type User_Seen_Property_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Seen_Property_Min_Fields = {
  __typename?: 'user_seen_property_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  property_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_seen_property" */
export type User_Seen_Property_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_seen_property" */
export type User_Seen_Property_Mutation_Response = {
  __typename?: 'user_seen_property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Seen_Property>;
};

/** on_conflict condition type for table "user_seen_property" */
export type User_Seen_Property_On_Conflict = {
  constraint: User_Seen_Property_Constraint;
  update_columns?: Array<User_Seen_Property_Update_Column>;
  where?: InputMaybe<User_Seen_Property_Bool_Exp>;
};

/** Ordering options when selecting data from "user_seen_property". */
export type User_Seen_Property_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_seen_property */
export type User_Seen_Property_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_seen_property" */
export enum User_Seen_Property_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_seen_property" */
export type User_Seen_Property_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_seen_property" */
export type User_Seen_Property_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Seen_Property_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Seen_Property_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  property_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_seen_property" */
export enum User_Seen_Property_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Seen_Property_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Seen_Property_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Seen_Property_Bool_Exp;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  __typename?: 'users';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole: AuthRoles_Enum;
  /** An object relationship */
  defaultRoleByRole: AuthRoles;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['citext']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  isAnonymous: Scalars['Boolean']['output'];
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale: Scalars['String']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt: Scalars['timestamptz']['output'];
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokens_Aggregate;
  /** An array relationship */
  roles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  roles_aggregate: AuthUserRoles_Aggregate;
  /** An array relationship */
  search_sessions: Array<Search_Sessions>;
  /** An aggregate relationship */
  search_sessions_aggregate: Search_Sessions_Aggregate;
  /** An array relationship */
  securityKeys: Array<AuthUserSecurityKeys>;
  /** An aggregate relationship */
  securityKeys_aggregate: AuthUserSecurityKeys_Aggregate;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt: Scalars['timestamptz']['output'];
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProviders_Aggregate;
  /** An array relationship */
  user_preferences: Array<User_Preferences>;
  /** An aggregate relationship */
  user_preferences_aggregate: User_Preferences_Aggregate;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSearch_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Sessions_Order_By>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSearch_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Search_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Search_Sessions_Order_By>>;
  where?: InputMaybe<Search_Sessions_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};

/** aggregated selection of "auth.users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "auth.users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  activeMfaType?: InputMaybe<String_Comparison_Exp>;
  avatarUrl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currentChallenge?: InputMaybe<String_Comparison_Exp>;
  defaultRole?: InputMaybe<AuthRoles_Enum_Comparison_Exp>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Bool_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  emailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isAnonymous?: InputMaybe<Boolean_Comparison_Exp>;
  lastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  newEmail?: InputMaybe<Citext_Comparison_Exp>;
  otpHash?: InputMaybe<String_Comparison_Exp>;
  otpHashExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  otpMethodLastUsed?: InputMaybe<String_Comparison_Exp>;
  passwordHash?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  phoneNumberVerified?: InputMaybe<Boolean_Comparison_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  roles?: InputMaybe<AuthUserRoles_Bool_Exp>;
  roles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp>;
  search_sessions?: InputMaybe<Search_Sessions_Bool_Exp>;
  search_sessions_aggregate?: InputMaybe<Search_Sessions_Aggregate_Bool_Exp>;
  securityKeys?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeys_Aggregate_Bool_Exp>;
  ticket?: InputMaybe<String_Comparison_Exp>;
  ticketExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  totpSecret?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userProviders?: InputMaybe<AuthUserProviders_Bool_Exp>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp>;
  user_preferences?: InputMaybe<User_Preferences_Bool_Exp>;
  user_preferences_aggregate?: InputMaybe<User_Preferences_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.users" */
export type Users_Insert_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<AuthRoles_Enum>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Obj_Rel_Insert_Input>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<AuthUserRoles_Arr_Rel_Insert_Input>;
  search_sessions?: InputMaybe<Search_Sessions_Arr_Rel_Insert_Input>;
  securityKeys?: InputMaybe<AuthUserSecurityKeys_Arr_Rel_Insert_Input>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<AuthUserProviders_Arr_Rel_Insert_Input>;
  user_preferences?: InputMaybe<User_Preferences_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "auth.users" */
export type Users_Max_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "auth.users" */
export type Users_Min_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "auth.users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "auth.users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Users_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Order_By>;
  disabled?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailVerified?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isAnonymous?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  phoneNumberVerified?: InputMaybe<Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Order_By>;
  search_sessions_aggregate?: InputMaybe<Search_Sessions_Aggregate_Order_By>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeys_Aggregate_Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Order_By>;
  user_preferences_aggregate?: InputMaybe<User_Preferences_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.users" */
export enum Users_Select_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

/** input type for updating data in table "auth.users" */
export type Users_Set_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<AuthRoles_Enum>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<AuthRoles_Enum>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "auth.users" */
export enum Users_Update_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_Notification_Amenitiesproperty_Amenity = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_Notification_Featurefeature = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_Notificationcity = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_Notificationcity_Zone = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_Notificationproperty_Composition = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_Queries_Property_Resultproperty = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_User_Filters_Featuresfeature = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_User_Filterscity = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_User_Filterscity_Zone = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp_Remote_Rel_User_Property_Notifiationproperty = {
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "storage.virus" */
export type Virus = {
  __typename?: 'virus';
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  fileId: Scalars['uuid']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userSession: Scalars['jsonb']['output'];
  virus: Scalars['String']['output'];
};


/** columns and relationships of "storage.virus" */
export type VirusUserSessionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "storage.virus" */
export type Virus_Aggregate = {
  __typename?: 'virus_aggregate';
  aggregate?: Maybe<Virus_Aggregate_Fields>;
  nodes: Array<Virus>;
};

/** aggregate fields of "storage.virus" */
export type Virus_Aggregate_Fields = {
  __typename?: 'virus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Virus_Max_Fields>;
  min?: Maybe<Virus_Min_Fields>;
};


/** aggregate fields of "storage.virus" */
export type Virus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Virus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Virus_Append_Input = {
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "storage.virus". All fields are combined with a logical 'AND'. */
export type Virus_Bool_Exp = {
  _and?: InputMaybe<Array<Virus_Bool_Exp>>;
  _not?: InputMaybe<Virus_Bool_Exp>;
  _or?: InputMaybe<Array<Virus_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userSession?: InputMaybe<Jsonb_Comparison_Exp>;
  virus?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage.virus" */
export enum Virus_Constraint {
  /** unique or primary key constraint on columns "id" */
  VirusPkey = 'virus_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Virus_Delete_At_Path_Input = {
  userSession?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Virus_Delete_Elem_Input = {
  userSession?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Virus_Delete_Key_Input = {
  userSession?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "storage.virus" */
export type Virus_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  fileId?: InputMaybe<Scalars['uuid']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
  virus?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Virus_Max_Fields = {
  __typename?: 'virus_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fileId?: Maybe<Scalars['uuid']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  virus?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Virus_Min_Fields = {
  __typename?: 'virus_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fileId?: Maybe<Scalars['uuid']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  virus?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "storage.virus" */
export type Virus_Mutation_Response = {
  __typename?: 'virus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Virus>;
};

/** on_conflict condition type for table "storage.virus" */
export type Virus_On_Conflict = {
  constraint: Virus_Constraint;
  update_columns?: Array<Virus_Update_Column>;
  where?: InputMaybe<Virus_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.virus". */
export type Virus_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  fileId?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userSession?: InputMaybe<Order_By>;
  virus?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage.virus */
export type Virus_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Virus_Prepend_Input = {
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "storage.virus" */
export enum Virus_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserSession = 'userSession',
  /** column name */
  Virus = 'virus'
}

/** input type for updating data in table "storage.virus" */
export type Virus_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fileId?: InputMaybe<Scalars['uuid']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
  virus?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "virus" */
export type Virus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Virus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Virus_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fileId?: InputMaybe<Scalars['uuid']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userSession?: InputMaybe<Scalars['jsonb']['input']>;
  virus?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "storage.virus" */
export enum Virus_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserSession = 'userSession',
  /** column name */
  Virus = 'virus'
}

export type Virus_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Virus_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Virus_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Virus_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Virus_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Virus_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Virus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Virus_Bool_Exp;
};

/** columns and relationships of "zone_data_method" */
export type Zone_Data_Method = {
  __typename?: 'zone_data_method';
  method: Scalars['String']['output'];
};

/** aggregated selection of "zone_data_method" */
export type Zone_Data_Method_Aggregate = {
  __typename?: 'zone_data_method_aggregate';
  aggregate?: Maybe<Zone_Data_Method_Aggregate_Fields>;
  nodes: Array<Zone_Data_Method>;
};

/** aggregate fields of "zone_data_method" */
export type Zone_Data_Method_Aggregate_Fields = {
  __typename?: 'zone_data_method_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Zone_Data_Method_Max_Fields>;
  min?: Maybe<Zone_Data_Method_Min_Fields>;
};


/** aggregate fields of "zone_data_method" */
export type Zone_Data_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zone_Data_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "zone_data_method". All fields are combined with a logical 'AND'. */
export type Zone_Data_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Zone_Data_Method_Bool_Exp>>;
  _not?: InputMaybe<Zone_Data_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Zone_Data_Method_Bool_Exp>>;
  method?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zone_data_method" */
export enum Zone_Data_Method_Constraint {
  /** unique or primary key constraint on columns "method" */
  ZoneDataMethodPkey = 'zone_data_method_pkey'
}

export enum Zone_Data_Method_Enum {
  Custom = 'custom',
  GoogleMapsGeocodingApi = 'google_maps_geocoding_api',
  GoogleMapsPlacesApi = 'google_maps_places_api'
}

/** Boolean expression to compare columns of type "zone_data_method_enum". All fields are combined with logical 'AND'. */
export type Zone_Data_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Zone_Data_Method_Enum>;
  _in?: InputMaybe<Array<Zone_Data_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Zone_Data_Method_Enum>;
  _nin?: InputMaybe<Array<Zone_Data_Method_Enum>>;
};

/** input type for inserting data into table "zone_data_method" */
export type Zone_Data_Method_Insert_Input = {
  method?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Zone_Data_Method_Max_Fields = {
  __typename?: 'zone_data_method_max_fields';
  method?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Zone_Data_Method_Min_Fields = {
  __typename?: 'zone_data_method_min_fields';
  method?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "zone_data_method" */
export type Zone_Data_Method_Mutation_Response = {
  __typename?: 'zone_data_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Zone_Data_Method>;
};

/** input type for inserting object relation for remote table "zone_data_method" */
export type Zone_Data_Method_Obj_Rel_Insert_Input = {
  data: Zone_Data_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zone_Data_Method_On_Conflict>;
};

/** on_conflict condition type for table "zone_data_method" */
export type Zone_Data_Method_On_Conflict = {
  constraint: Zone_Data_Method_Constraint;
  update_columns?: Array<Zone_Data_Method_Update_Column>;
  where?: InputMaybe<Zone_Data_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "zone_data_method". */
export type Zone_Data_Method_Order_By = {
  method?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zone_data_method */
export type Zone_Data_Method_Pk_Columns_Input = {
  method: Scalars['String']['input'];
};

/** select columns of table "zone_data_method" */
export enum Zone_Data_Method_Select_Column {
  /** column name */
  Method = 'method'
}

/** input type for updating data in table "zone_data_method" */
export type Zone_Data_Method_Set_Input = {
  method?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "zone_data_method" */
export type Zone_Data_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zone_Data_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zone_Data_Method_Stream_Cursor_Value_Input = {
  method?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "zone_data_method" */
export enum Zone_Data_Method_Update_Column {
  /** column name */
  Method = 'method'
}

export type Zone_Data_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zone_Data_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zone_Data_Method_Bool_Exp;
};

export type UpdateCityZoneByPkMutationVariables = Exact<{
  set?: InputMaybe<City_Zone_Set_Input>;
  id: Scalars['uuid']['input'];
}>;


export type UpdateCityZoneByPkMutation = { __typename?: 'mutation_root', update_city_zone_by_pk?: { __typename?: 'city_zone', id: any, bounds?: any | null, created_at?: any | null, formatted_address?: string | null, location?: any | null, location_type?: string | null, place_id?: string | null, data_method?: Zone_Data_Method_Enum | null, surface_area?: any | null, types?: any | null, updated_at?: any | null, viewport?: any | null, zone_name: string, city: { __typename?: 'city', city_name: string } } | null };

export type GetCityZoneQueryVariables = Exact<{
  where: City_Zone_Bool_Exp;
}>;


export type GetCityZoneQuery = { __typename?: 'query_root', city_zone: Array<{ __typename?: 'city_zone', id: any, bounds?: any | null, created_at?: any | null, formatted_address?: string | null, location?: any | null, location_type?: string | null, place_id?: string | null, data_method?: Zone_Data_Method_Enum | null, surface_area?: any | null, types?: any | null, updated_at?: any | null, viewport?: any | null, zone_name: string, is_problematic?: boolean | null, origin?: City_Zone_Origin_Enum | null, city: { __typename?: 'city', city_name: string } }> };

export type GetAddressTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddressTypesQuery = { __typename?: 'query_root', address_types: Array<{ __typename?: 'address_types', type: string }> };

export type GetCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCitiesQuery = { __typename?: 'query_root', city: Array<{ __typename?: 'city', city_name: string }> };


export const UpdateCityZoneByPkDocument = gql`
    mutation updateCityZoneByPk($set: city_zone_set_input, $id: uuid!) {
  update_city_zone_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
    bounds
    city {
      city_name
    }
    created_at
    formatted_address
    location
    location_type
    place_id
    data_method
    surface_area
    types
    updated_at
    viewport
    zone_name
  }
}
    `;
export type UpdateCityZoneByPkMutationFn = Apollo.MutationFunction<UpdateCityZoneByPkMutation, UpdateCityZoneByPkMutationVariables>;

/**
 * __useUpdateCityZoneByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCityZoneByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCityZoneByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCityZoneByPkMutation, { data, loading, error }] = useUpdateCityZoneByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCityZoneByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCityZoneByPkMutation, UpdateCityZoneByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCityZoneByPkMutation, UpdateCityZoneByPkMutationVariables>(UpdateCityZoneByPkDocument, options);
      }
export type UpdateCityZoneByPkMutationHookResult = ReturnType<typeof useUpdateCityZoneByPkMutation>;
export type UpdateCityZoneByPkMutationResult = Apollo.MutationResult<UpdateCityZoneByPkMutation>;
export type UpdateCityZoneByPkMutationOptions = Apollo.BaseMutationOptions<UpdateCityZoneByPkMutation, UpdateCityZoneByPkMutationVariables>;
export const GetCityZoneDocument = gql`
    query getCityZone($where: city_zone_bool_exp!) {
  city_zone(where: $where, order_by: {surface_area: desc}) {
    id
    bounds
    city {
      city_name
    }
    created_at
    formatted_address
    location
    location_type
    place_id
    data_method
    surface_area
    types
    updated_at
    viewport
    zone_name
    is_problematic
    origin
  }
}
    `;

/**
 * __useGetCityZoneQuery__
 *
 * To run a query within a React component, call `useGetCityZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityZoneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCityZoneQuery(baseOptions: Apollo.QueryHookOptions<GetCityZoneQuery, GetCityZoneQueryVariables> & ({ variables: GetCityZoneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCityZoneQuery, GetCityZoneQueryVariables>(GetCityZoneDocument, options);
      }
export function useGetCityZoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCityZoneQuery, GetCityZoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCityZoneQuery, GetCityZoneQueryVariables>(GetCityZoneDocument, options);
        }
export function useGetCityZoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCityZoneQuery, GetCityZoneQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCityZoneQuery, GetCityZoneQueryVariables>(GetCityZoneDocument, options);
        }
export type GetCityZoneQueryHookResult = ReturnType<typeof useGetCityZoneQuery>;
export type GetCityZoneLazyQueryHookResult = ReturnType<typeof useGetCityZoneLazyQuery>;
export type GetCityZoneSuspenseQueryHookResult = ReturnType<typeof useGetCityZoneSuspenseQuery>;
export type GetCityZoneQueryResult = Apollo.QueryResult<GetCityZoneQuery, GetCityZoneQueryVariables>;
export const GetAddressTypesDocument = gql`
    query getAddressTypes {
  address_types {
    type
  }
}
    `;

/**
 * __useGetAddressTypesQuery__
 *
 * To run a query within a React component, call `useGetAddressTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAddressTypesQuery, GetAddressTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressTypesQuery, GetAddressTypesQueryVariables>(GetAddressTypesDocument, options);
      }
export function useGetAddressTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressTypesQuery, GetAddressTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressTypesQuery, GetAddressTypesQueryVariables>(GetAddressTypesDocument, options);
        }
export function useGetAddressTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAddressTypesQuery, GetAddressTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAddressTypesQuery, GetAddressTypesQueryVariables>(GetAddressTypesDocument, options);
        }
export type GetAddressTypesQueryHookResult = ReturnType<typeof useGetAddressTypesQuery>;
export type GetAddressTypesLazyQueryHookResult = ReturnType<typeof useGetAddressTypesLazyQuery>;
export type GetAddressTypesSuspenseQueryHookResult = ReturnType<typeof useGetAddressTypesSuspenseQuery>;
export type GetAddressTypesQueryResult = Apollo.QueryResult<GetAddressTypesQuery, GetAddressTypesQueryVariables>;
export const GetCitiesDocument = gql`
    query getCities {
  city {
    city_name
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export function useGetCitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesSuspenseQueryHookResult = ReturnType<typeof useGetCitiesSuspenseQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;